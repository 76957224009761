import {
  Box,
  Button,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { format, parse } from 'date-fns';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HelmetComponent } from '../components/HelmetComponent';
import { DATE_FORMAT } from '../lib/date_time';
import { ReportFromTrafficValueBox } from '../components/ReportFromTrafficValueBox';
import {
  DocumentFile,
  GetInternalTerminalReportInfoQuery,
  InternalTerminalReportInfoResponse,
  useGetInternalTerminalReportInfoQuery,
  useGetValueBoxForDateQuery,
} from '../generated/graphql';
import { ReportFromTrafficInfo } from '../components/ReportFromTrafficInfo';
import { TrafficGridValues } from './TrafficGridValues';
import { useUserConfiguration } from '../providers/UserConfigurationProvider';
import { useHttpClient } from '../providers/HttpClientProvider';
import { LegsResponse } from './TruckFillAndTime';
import PreviousIcon from '@mui/icons-material/NavigateBefore';
import NextIcon from '@mui/icons-material/NavigateNext';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { DownloadTftPdf } from '../components/DownloadTftPdf';
import DownloadIcon from '@mui/icons-material/Download';
import { getTimeAsNumberOfMinutes } from '../lib/date';
import { ReportToTerminalSporadicRoutes } from '../components/ReportToTerminalSporadicRoutes';
import PageviewIcon from '@mui/icons-material/Pageview';
const useStyles = makeStyles((theme: Theme) => ({
  filterButtonRoot: {
    marginLeft: 2,
    border: '2px solid #e8e8e8',
    borderRadius: 3,
    marginTop: 4,
    marginBottom: 4,
    padding: 2,
  },
  filterButton: {
    marginLeft: 4,
    marginTop: 4,
    border: '2px solid #e8e8e8',
    borderRadius: 2,
    padding: 4,
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(0, 0.5),
    },
  },
  btnSelected: {
    border: '1px solid #68BC46',
    backgroundColor: '#AAE590',
  },
  inputValTime: {
    height: 15,
    width: 120,
    fontSize: 15,
  },
}));

interface ViewReportFromTerminalProps {
  terminal: {
    id: number;
    name: string;
    shortCountryCode: string;
  };
}
export type BoxType = {
  boxId: number;
  entryId: number;
  boxNameText: string;
  boxValue: number;
  isReadOnly: boolean;
  isFixedBox: boolean;
  isYesNoBox: boolean;
  isAddedFromTerminal: boolean;
  isTimeBox: boolean;
};

export function ViewReportFromTerminal(props: ViewReportFromTerminalProps) {
  const classes = useStyles();
  const { httpClient } = useHttpClient();
  const { t } = useTranslation();
  const selectedTerminal = props.terminal;
  const { getTFTTime, setMasterDate } = useUserConfiguration();
  const NEW_DATE_FORMAT = 'yyyy-MM-dd HH:mm';
  const myDate = new Date();
  const startHour = parseInt(getTFTTime(true).split(':')[0]);
  const startMinute = parseInt(getTFTTime(true).split(':')[1]);
  const endHour = parseInt(getTFTTime(false).split(':')[0]);
  const endMinute = parseInt(getTFTTime(false).split(':')[1]);
  const [viewCancelledLegs, setViewCancelledLegs] = useState(true);
  const [startDateTime, setStartDateTime] = useState(
    myDate.setHours(startHour, startMinute),
  );
  const [endDateTime, setEndDateTime] = useState(
    new Date(myDate.getTime() + 24 * 60 * 60 * 1000).setHours(
      endHour,
      endMinute,
    ),
  );
  const [tftStartDate, setTftStartDate] = useState<string>(
    format(startDateTime, NEW_DATE_FORMAT),
  );
  const [tftEndDate, setTftEndDate] = useState<string>(
    format(endDateTime, NEW_DATE_FORMAT),
  );
  const [legsData, setLegsData] = useState<LegsResponse[]>([]);
  const [loading, setLoading] = useState(false);
  const [isBothDateValid, setBothDateValid] = useState<boolean>(false);
  const [exportExcelLoading, setExportExcelLoading] = useState(false);
  const [view, setView] = useState({
    routeView: false,
    infoView: false,
    gridView: false,
    valueBoxesView: false,
  });
  const initView = {
    routeView: false,
    infoView: false,
    gridView: false,
    valueBoxesView: false,
  };
  const [startDate, setStartDate] = useState<{ date: string; day: string }>({
    date: format(new Date(), DATE_FORMAT),
    day: new Date().toLocaleDateString(undefined, {
      weekday: 'long',
    }),
  });

  const { id } = selectedTerminal;

  //for valueBox API
  const { data: valueBoxData, loading: valueBoxLoading } =
    useGetValueBoxForDateQuery({
      fetchPolicy: 'cache-and-network',
      variables: {
        internalTerminalId: id,
        date: startDate.date,
        forTerminal: false,
      },
    });

  //for info API
  const POLLING_INTERVALL = 60 * 1000;
  const { data: infoData, loading: infoLoading } =
    useGetInternalTerminalReportInfoQuery({
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      pollInterval: POLLING_INTERVALL,
      variables: {
        internalTerminalId: id,
        dateForAddInfo: startDate.date,
        forTerminal: false,
      },
    });

  useEffect(() => {
    if (tftStartDate !== '' && tftStartDate != 'Invalid Date') {
      const selDate = tftStartDate.split(' ')[0];
      setStartDate({
        date: selDate,
        day: parse(selDate, DATE_FORMAT, new Date()).toLocaleDateString(
          undefined,
          {
            weekday: 'long',
          },
        ),
      });
    }

    if (tftStartDate !== '' && tftEndDate !== '') {
      if (tftStartDate.split(' ')[0] === tftEndDate.split(' ')[0]) {
        setBothDateValid(
          isValidTime(tftStartDate.split(' ')[1], tftEndDate.split(' ')[1]),
        );
      } else {
        setBothDateValid(true);
      }
    } else {
      setBothDateValid(false);
    }
  }, [tftStartDate, tftEndDate]);

  let refinedInfo: Array<
    { __typename?: 'InternalTerminalReportInfoResponse' } & Pick<
      InternalTerminalReportInfoResponse,
      | 'id'
      | 'internalTerminalId'
      | 'dateForAddInfo'
      | 'info'
      | 'infoType'
      | 'isAddedFromTerminal'
      | 'replyInfo'
      | 'replyText'
    > & {
        files: Array<
          { __typename?: 'DocumentFile' } & Pick<
            DocumentFile,
            'id' | 'originalname' | 'mimetype' | 'path' | 'size'
          >
        >;
      }
  > = [];

  if (
    infoData &&
    infoData.internalTerminalReportInfo &&
    infoData.internalTerminalReportInfo.length > 0
  ) {
    const infoList: GetInternalTerminalReportInfoQuery['internalTerminalReportInfo'] =
      infoData.internalTerminalReportInfo;

    refinedInfo = infoList?.length
      ? infoList.flatMap((info) => {
          return {
            id: info.id,
            dateForAddInfo: info.dateForAddInfo,
            info: info.info,
            infoType: info.infoType,
            isAddedFromTerminal: info.isAddedFromTerminal,
            internalTerminalId: info.internalTerminalId,
            replyInfo: info.replyInfo ?? '',
            replyText: info.replyText ?? '',
            files: info.files,
          };
        })
      : [];
  }

  let finalBoxes: BoxType[] = [];
  if (
    valueBoxData &&
    valueBoxData.getValueBoxesForDay &&
    valueBoxData.getValueBoxesForDay.length > 0
  ) {
    valueBoxData.getValueBoxesForDay.forEach((b) => {
      if (b.boxValue !== 0) {
        finalBoxes = finalBoxes.concat(b);
      }
    });
  }

  const getRouteLegsData = (
    tftStartDateTime: string,
    tftEndDateTime: string,
    sporadicOnly = false,
    allCancelledLegs = false,
  ) => {
    setLoading(true);
    httpClient
      .getIncomingRouteLegs({
        terminalId: [selectedTerminal.id],
        startDateTime: tftStartDateTime,
        endDateTime: tftEndDateTime,
        sporadicOnly: sporadicOnly,
        allCancelledLegs: allCancelledLegs,
      })
      .then((res) => {
        if (res.data && res.data.status === 'OK') {
          setLegsData(res.data.data);
        } else if (res.data && res.data.status === 'FAIL') {
          setLegsData([]);
          console.error('# tft API error=', res.data.message);
        }

        setLoading(false);
      })
      .catch((e) => {
        console.error('# tft UI error=', e);
        setLoading(false);
      });
  };

  const handleStartDateChanged = (value: any) => {
    if (value && value != 'Invalid Date') {
      setStartDateTime(value);
      setTftStartDate(format(new Date(value.toString()), NEW_DATE_FORMAT));
    } else {
      setTftStartDate('');
    }
  };

  const handleEndDateChanged = (value: any) => {
    if (value && value != 'Invalid Date') {
      setEndDateTime(value);
      setTftEndDate(format(new Date(value.toString()), NEW_DATE_FORMAT));
    } else {
      setTftEndDate('');
    }
  };

  const changeDatesAction = (isNext: boolean) => {
    const newDateStart = isNext
      ? new Date(new Date(tftStartDate).getTime() + 1000 * 60 * 60 * 24)
      : new Date(new Date(tftStartDate).getTime() - 1000 * 60 * 60 * 24);
    const newDateEnd = isNext
      ? new Date(new Date(tftEndDate).getTime() + 1000 * 60 * 60 * 24)
      : new Date(new Date(tftEndDate).getTime() - 1000 * 60 * 60 * 24);

    setStartDateTime(newDateStart.getTime());
    setEndDateTime(newDateEnd.getTime());
    const newTFTStartDateTime = format(newDateStart, NEW_DATE_FORMAT);
    const newTFTEndDateTime = format(newDateEnd, NEW_DATE_FORMAT);
    setTftStartDate(newTFTStartDateTime);
    setTftEndDate(newTFTEndDateTime);

    setMasterDate(`${newTFTStartDateTime}#${newTFTEndDateTime}`, true);
    setLoading(true);
    getRouteLegsData(newTFTStartDateTime, newTFTEndDateTime, true);
  };

  const onExportTFTClicked = () => {
    if (legsData && legsData.length > 0) {
      setExportExcelLoading(true);
      httpClient
        .downloadTftExcelBlob({
          terminalId: [selectedTerminal.id],
          startDateTime: tftStartDate,
          endDateTime: tftEndDate,
        })
        .then((res) => {
          const blob = res.data;
          const url = window.URL.createObjectURL(blob);
          const anchor = document.createElement('a');
          anchor.setAttribute('href', url);
          anchor.setAttribute(
            'download',
            `TFT-(${tftStartDate}_to_${tftEndDate})`,
          );
          setExportExcelLoading(false);
          anchor.click();
          window.URL.revokeObjectURL(url);
        });
    }
  };
  const isValidTime = (startTime: string, endTime: string): boolean => {
    const startMins = getTimeAsNumberOfMinutes(startTime);
    const endMins = getTimeAsNumberOfMinutes(endTime);
    return startMins < endMins;
  };

  return (
    <>
      <HelmetComponent
        title={t('resource.viewReportFromTraffic.capitalized')}
      />

      <Grid container>
        <Grid item xs={5} justifyContent="flex-start">
          <Box className={classes.filterButtonRoot}>
            <Grid item container justifyContent="center">
              <Tooltip title={`Previous Date`}>
                <IconButton
                  style={{
                    backgroundColor: '#e2e2e2',
                    height: 38,
                    width: 38,
                    marginRight: 0,
                  }}
                  onClick={() => {
                    changeDatesAction(false);
                  }}
                >
                  <PreviousIcon />
                </IconButton>
              </Tooltip>
              <KeyboardDateTimePicker
                variant="inline"
                ampm={false}
                allowKeyboardControl={true}
                label="Production Start"
                value={startDateTime}
                onChange={(date) => handleStartDateChanged(date)}
                format="yyyy-MM-dd HH:mm"
                id="startDT"
                InputProps={{
                  style: {
                    fontSize: 15,
                  },
                  classes: { input: classes.inputValTime },
                  maxRows: 1,
                }}
                invalidDateMessage={'Invalid DateTime'}
                maxDate={endDateTime}
                maxDateMessage={'Invalid Range'}
                onError={(e) => {
                  if (e) setBothDateValid(false);
                }}
                KeyboardButtonProps={{
                  disabled: true,
                  style: { display: 'none' },
                }}
              />
              <KeyboardDateTimePicker
                variant="inline"
                ampm={false}
                allowKeyboardControl={true}
                label="Production End"
                value={endDateTime}
                onChange={(date) => handleEndDateChanged(date)}
                format="yyyy-MM-dd HH:mm"
                id="endDT"
                InputProps={{
                  style: {
                    fontSize: 15,
                  },
                  classes: { input: classes.inputValTime },
                  maxRows: 1,
                }}
                minDate={startDateTime}
                minDateMessage={'Invalid Range'}
                invalidDateMessage={'Invalid DateTime'}
                onError={(e) => {
                  if (e) setBothDateValid(false);
                }}
                KeyboardButtonProps={{
                  disabled: true,
                  style: { display: 'none' },
                }}
              />
              <Tooltip title={`Next Date`}>
                <IconButton
                  style={{
                    backgroundColor: '#e2e2e2',
                    height: 38,
                    width: 38,
                  }}
                  onClick={() => {
                    changeDatesAction(true);
                  }}
                >
                  <NextIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item container justifyContent="center">
              <Button
                style={{ marginTop: 5, marginRight: 2 }}
                variant="contained"
                color="primary"
                disabled={!isBothDateValid || !selectedTerminal.id}
                onClick={() => {
                  if (tftStartDate !== '' && tftEndDate !== '') {
                    setMasterDate(`${tftStartDate}#${tftEndDate}`, true);
                    getRouteLegsData(tftStartDate, tftEndDate, true);
                  }
                }}
                size="small"
              >
                {`VIEW`}
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{ marginRight: 2, marginTop: 5, marginLeft: 2 }}
                disabled={exportExcelLoading || legsData.length === 0}
                onClick={() => onExportTFTClicked()}
                size="small"
              >
                {exportExcelLoading
                  ? `${t('validation.loadingApi')}`
                  : `${t('attributes.getExcel')}`}
              </Button>
              {!valueBoxLoading &&
              !infoLoading &&
              !loading &&
              selectedTerminal.id ? (
                <DownloadTftPdf
                  startDateTime={tftStartDate}
                  endDateTime={tftEndDate}
                  routeLegs={legsData}
                  terminalName={
                    selectedTerminal && selectedTerminal.name
                      ? selectedTerminal.name
                      : ''
                  }
                  reportInfoData={refinedInfo}
                  reportValueBoxData={finalBoxes}
                  viewSendReport={false}
                />
              ) : (
                <Button
                  variant="contained"
                  startIcon={<DownloadIcon />}
                  style={{ marginRight: 2, marginTop: 5, marginLeft: 2 }}
                  color="primary"
                  disabled={true}
                >
                  {'GET PDF'}
                </Button>
              )}
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={7}>
          <div className={classes.filterButton}>
            <>
              {Object.entries(view).map(([key, value]) => (
                <Button
                  key={key}
                  className={value ? classes.btnSelected : undefined}
                  variant="contained"
                  size="small"
                  disabled={!isBothDateValid || !selectedTerminal.id}
                  onClick={() => {
                    const thisView = view[key as keyof typeof view] ?? false;
                    const newView = {
                      ...initView,
                      [key]: !thisView,
                    };

                    setView(newView);
                    if (
                      key === 'routeView' &&
                      tftStartDate !== '' &&
                      tftEndDate !== ''
                    )
                      getRouteLegsData(tftStartDate, tftEndDate, true);
                  }}
                >
                  {t(`view.${key}`)}
                </Button>
              ))}
            </>
          </div>

          <div className={classes.filterButton}>
            <Typography variant="subtitle1" align="center">
              <strong>
                {`${selectedTerminal.name} | ${startDate.date} | ${startDate.day}`}
              </strong>
            </Typography>
          </div>
        </Grid>
      </Grid>

      {refinedInfo && view.infoView && (
        <Grid item container>
          <Box height="100%" sx={{ marginTop: 8 }}>
            <ReportFromTrafficInfo
              refinedInfo={refinedInfo}
              isReplyEnabled={false}
            />
          </Box>
        </Grid>
      )}
      {finalBoxes && view.valueBoxesView && (
        <Grid item container>
          <Box height="100%" sx={{ marginTop: 8 }}>
            <ReportFromTrafficValueBox finalBoxes={finalBoxes} />
          </Box>
        </Grid>
      )}
      {view.gridView && (
        <Grid item container direction="row">
          <TrafficGridValues
            internalTerminalId={selectedTerminal.id}
            selectedDate={startDate.date}
            gridType="Traffic"
          />
        </Grid>
      )}
      {selectedTerminal.shortCountryCode && view.routeView && (
        <Grid container style={{ marginTop: 10 }}>
          <Grid
            item
            xs={10}
            style={{
              backgroundColor: '#68bc46',
              borderRadius: 3,
            }}
          >
            <Typography variant="subtitle1" align="center">
              <strong>{`${t('attributes.tftTitle')}`}</strong>
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Tooltip
              title={
                viewCancelledLegs
                  ? `${t('attributes.viewAllLegs')}`
                  : `${t('attributes.viewSporadicOnly')}`
              }
            >
              <Button
                startIcon={<PageviewIcon />}
                color="primary"
                variant="contained"
                disabled={loading}
                onClick={() => {
                  if (viewCancelledLegs)
                    getRouteLegsData(tftStartDate, tftEndDate, false, true);
                  else getRouteLegsData(tftStartDate, tftEndDate, true);
                  setViewCancelledLegs(!viewCancelledLegs);
                }}
              >
                {viewCancelledLegs
                  ? `${t('button.allCancelled')}`
                  : `${t('button.onlySporadic')}`}
              </Button>
            </Tooltip>
          </Grid>
          <div style={{ width: '100%', marginTop: 10 }}>
            {loading ? (
              'Loading...'
            ) : (
              <ReportToTerminalSporadicRoutes
                shortCountryCode={selectedTerminal.shortCountryCode}
                legs={legsData}
                onGetTFTExcelClicked={() => onExportTFTClicked()}
              />
            )}
          </div>
        </Grid>
      )}
    </>
  );
}

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
};

export type AddGridValueInput = {
  columnPosition: Scalars['Int'];
  gridMasterId: Scalars['Int'];
  rowPosition: Scalars['Int'];
  value: Scalars['String'];
  valueAddedDate: Scalars['String'];
};

export type AddMasterGridDataInput = {
  columnHeaders: Array<Scalars['String']>;
  gridName: Scalars['String'];
  gridType: Scalars['String'];
  internalTerminalId: Scalars['Int'];
  isNumberGridWithTotal: Scalars['Boolean'];
  rowHeaders: Array<Scalars['String']>;
};

export type AddMasterValueBoxInput = {
  boxName: Scalars['String'];
  frequency: Array<FrequencyDays>;
  internalTerminalId: Scalars['Int'];
  isAddedFromTerminal: Scalars['Boolean'];
  isFixedBox: Scalars['Boolean'];
  isTimeBox: Scalars['Boolean'];
  isYesNoBox: Scalars['Boolean'];
};

export type AssociatedRoutesResponse = {
  __typename?: 'AssociatedRoutesResponse';
  arrivalLocation: Scalars['String'];
  arrivalTime?: Maybe<Scalars['String']>;
  departureLocation: Scalars['String'];
  departureTime?: Maybe<Scalars['String']>;
  frequency?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  routeId: Scalars['Int'];
  routeName: Scalars['String'];
  routePosition: Scalars['Int'];
  subContractorName: Scalars['String'];
  tourId: Scalars['Int'];
  tourName: Scalars['String'];
};

export type BookMarkItemResponse = {
  __typename?: 'BookMarkItemResponse';
  capacity?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  legs?: Maybe<Array<RouteLeg>>;
  routeId: Scalars['String'];
  status: FixedTourGenerateResponseStatus;
  tourRouteId: Scalars['Int'];
  truckLicencePlateNo?: Maybe<Scalars['String']>;
};

export type BookmarkTourRouteTemplate = {
  __typename?: 'BookmarkTourRouteTemplate';
  bookmark: Scalars['Boolean'];
  id: Scalars['Int'];
  tourRouteTemplate: TourRouteTemplate;
  tourRouteTemplateId: Scalars['Int'];
  user: User;
  userId: Scalars['Int'];
};

export type Card = {
  __typename?: 'Card';
  contacts: Array<CardContact>;
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  customerAccounts: Array<CustomerAccount>;
  debits: Array<PeakPlanningDebit>;
  deletedBy: Scalars['String'];
  deviationToCustomer?: Maybe<DeviationToCustomer>;
  files: Array<CardFile>;
  id: Scalars['Int'];
  lastUpdatedBy: Scalars['String'];
  locations: Array<LocationEntity>;
  name: Scalars['String'];
  notes: Array<CardNote>;
  type: CardType;
  updatedAt: Scalars['DateTime'];
};

export type CardContact = {
  __typename?: 'CardContact';
  card: Card;
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  debits: Array<PeakPlanningDebit>;
  deletedBy: Scalars['String'];
  email: Scalars['String'];
  id: Scalars['Int'];
  lastUpdatedBy: Scalars['String'];
  name: Scalars['String'];
  note: Scalars['String'];
  phoneNumber: Scalars['String'];
  role: Scalars['String'];
  tags: Array<CardContactTag>;
  updatedAt: Scalars['DateTime'];
};

export type CardContactTag = {
  __typename?: 'CardContactTag';
  contacts: Array<CardContact>;
  createdAt: Scalars['DateTime'];
  deletedAt: Scalars['DateTime'];
  id: Scalars['Int'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type CardFile = {
  __typename?: 'CardFile';
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  deletedBy: Scalars['String'];
  id: Scalars['Int'];
  mimetype: Scalars['String'];
  originalname: Scalars['String'];
  path: Scalars['String'];
  size: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
};

export type CardNote = {
  __typename?: 'CardNote';
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  deletedBy: Scalars['String'];
  id: Scalars['Int'];
  lastUpdatedBy: Scalars['String'];
  text: Scalars['String'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export enum CardType {
  Checkpoint = 'Checkpoint',
  Customer = 'Customer',
  Subcontractor = 'Subcontractor',
  Terminal = 'Terminal'
}

export type ChecklistRouteLegLoadinglistItems = {
  id?: Maybe<Scalars['Float']>;
  locationId: Scalars['Float'];
  note?: Maybe<Scalars['String']>;
  packages?: Maybe<Scalars['Float']>;
  palletSpace: Scalars['Float'];
  pallets: Scalars['Float'];
  weight?: Maybe<Scalars['Float']>;
};

export type ChecklistRouteLegs = {
  arrivalTime: Scalars['String'];
  departureTime?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  idNum: Scalars['Float'];
  load: Scalars['Boolean'];
  loadingListItems: Array<ChecklistRouteLegLoadinglistItems>;
  locationId: Scalars['Float'];
  note?: Maybe<Scalars['String']>;
  position: Scalars['Float'];
  productionDate: Scalars['String'];
  transportationDate: Scalars['String'];
  unload: Scalars['Boolean'];
};

export type ChecklistRoutes = {
  idNum: Scalars['Float'];
  legs: Array<ChecklistRouteLegs>;
};

export type Checkpoint = {
  __typename?: 'Checkpoint';
  contacts: Array<CardContact>;
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  deletedAt: Scalars['DateTime'];
  deletedBy: Scalars['String'];
  files: Array<CardFile>;
  id: Scalars['Int'];
  lastUpdatedBy: Scalars['String'];
  locations: Array<LocationEntity>;
  name: Scalars['String'];
  notes: Array<CardNote>;
  updatedAt: Scalars['DateTime'];
};

export type CostAllocation = {
  __typename?: 'CostAllocation';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  items: Array<CostAllocationItem>;
  route: Route;
  updatedAt: Scalars['DateTime'];
};

export type CostAllocationInput = {
  id?: Maybe<Scalars['Int']>;
  items: Array<CostAllocationItemInput>;
};

export type CostAllocationItem = {
  __typename?: 'CostAllocationItem';
  additionalCost: Scalars['Boolean'];
  additionalDiscount: Scalars['Boolean'];
  bringCost: Scalars['Boolean'];
  comment: Scalars['String'];
  cost: Scalars['Float'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  department: Department;
  departmentId: Scalars['String'];
  id: Scalars['Int'];
  includedKpi: Scalars['Boolean'];
  includedPrice: Scalars['Boolean'];
  type: CostAllocationItemType;
  updatedAt: Scalars['DateTime'];
};

export type CostAllocationItemInput = {
  additionalCost: Scalars['Boolean'];
  additionalDiscount: Scalars['Boolean'];
  bringCost: Scalars['Boolean'];
  comment: Scalars['String'];
  cost: Scalars['Float'];
  departmentId: Scalars['String'];
  id?: Maybe<Scalars['Int']>;
  includedKpi: Scalars['Boolean'];
  includedPrice: Scalars['Boolean'];
  type: CostAllocationItemType;
};

export enum CostAllocationItemType {
  Barrier = 'Barrier',
  Bridge = 'Bridge',
  Customs = 'Customs',
  Ferry = 'Ferry',
  Pickup = 'Pickup',
  Road = 'Road',
  Trailer = 'Trailer',
  Transport = 'Transport',
  TransportHd = 'TransportHD',
  Waitingtime = 'Waitingtime'
}

export type CreateCardContactInput = {
  email?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  tagIds: Array<Scalars['Int']>;
};

export type CreateCardContactTagInput = {
  title: Scalars['String'];
};

export type CreateCheckpointInput = {
  name: Scalars['String'];
};

export type CreateCustomerAccountInput = {
  accountNumber: Scalars['String'];
  logisticPersonName?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  salesPersonName?: Maybe<Scalars['String']>;
};

export type CreateCustomerInput = {
  name: Scalars['String'];
};

export type CreateDocumentTagInput = {
  title: Scalars['String'];
};

export type CreateHolidayInput = {
  country: Scalars['String'];
  date: Scalars['String'];
  title: Scalars['String'];
};

export type CreateLocationInput = {
  address: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  locationShortCode: Scalars['String'];
  name: Scalars['String'];
  phLocationId: Scalars['String'];
  postalCode: Scalars['String'];
};

export type CreateNoteInput = {
  text: Scalars['String'];
  title: Scalars['String'];
};

export type CreateShipmentInput = {
  createdFrom?: Maybe<Scalars['String']>;
  fromLocationId: Scalars['Int'];
  id?: Maybe<Scalars['Int']>;
  loadingListItem: Array<CreateShipmentLoadingList>;
  name: Scalars['String'];
  shipmentDate?: Maybe<Scalars['String']>;
  toLocationId: Scalars['Int'];
};

export type CreateShipmentLoadingList = {
  checked: Scalars['Boolean'];
  classification?: Maybe<DangerousGoodsClassification>;
  customsProcedure?: Maybe<CustomsProcedure>;
  id?: Maybe<Scalars['Int']>;
  isDangerous: Scalars['Boolean'];
  isLimitedQty: Scalars['Boolean'];
  isRemainingGoods: Scalars['Boolean'];
  isVoecCustomer: Scalars['Boolean'];
  locationId: Scalars['Int'];
  note: Scalars['String'];
  packages?: Maybe<Scalars['Int']>;
  palletSpace?: Maybe<Scalars['Float']>;
  pallets?: Maybe<Scalars['Int']>;
  unNumber?: Maybe<Scalars['Int']>;
  weight?: Maybe<Scalars['Float']>;
};

export type CreateSubcontractorInput = {
  name: Scalars['String'];
};

export type CreateTerminalInput = {
  name: Scalars['String'];
};

export type Customer = {
  __typename?: 'Customer';
  contacts: Array<CardContact>;
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  deletedAt: Scalars['DateTime'];
  deletedBy: Scalars['String'];
  files: Array<CardFile>;
  id: Scalars['Int'];
  lastUpdatedBy: Scalars['String'];
  locations: Array<LocationEntity>;
  name: Scalars['String'];
  notes: Array<CardNote>;
  updatedAt: Scalars['DateTime'];
};

export type CustomerAccount = {
  __typename?: 'CustomerAccount';
  accountNumber: Scalars['String'];
  card: Card;
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  deletedBy: Scalars['String'];
  id: Scalars['Int'];
  lastUpdatedBy: Scalars['String'];
  logisticPersonName: Scalars['String'];
  note: Scalars['String'];
  salesPersonName: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type CustomerLevelDeviationResponse = {
  __typename?: 'CustomerLevelDeviationResponse';
  actualArrivalTime?: Maybe<Scalars['String']>;
  cause?: Maybe<DeviationCause>;
  consequence: Scalars['Boolean'];
  createdBy: Scalars['String'];
  customerId: Scalars['Int'];
  customerLocation?: Maybe<Scalars['String']>;
  customerName: Scalars['String'];
  deviationApplicableDate: Scalars['String'];
  deviationId: Scalars['Int'];
  emailSent: Scalars['Boolean'];
  emailSentTimestamp?: Maybe<Scalars['DateTime']>;
  fault: DeviationFault;
  faultLocation?: Maybe<Scalars['String']>;
  faultLocationId?: Maybe<Scalars['Int']>;
  fromToToCountry?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isDangerous?: Maybe<Scalars['Boolean']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  locationItemNote?: Maybe<Scalars['String']>;
  newCustomer?: Maybe<Scalars['String']>;
  otherReason?: Maybe<Scalars['String']>;
  packages?: Maybe<Scalars['Int']>;
  palletSpace?: Maybe<Scalars['Float']>;
  pallets?: Maybe<Scalars['Int']>;
  reason?: Maybe<Reason>;
  routeId?: Maybe<Scalars['Int']>;
  routeNameOrFaultLocation: Scalars['String'];
  toCity?: Maybe<Scalars['String']>;
  toLocation?: Maybe<Scalars['String']>;
  toLocationId?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
  weight?: Maybe<Scalars['Float']>;
};

export enum CustomsProcedure {
  LetterVolume = 'LetterVolume',
  T2 = 'T2',
  Tvinn = 'TVINN',
  Voec = 'VOEC'
}

export enum DangerousGoodsClassification {
  CorrosiveSubstances = 'CorrosiveSubstances',
  Explosives = 'Explosives',
  FlammableLiquids = 'FlammableLiquids',
  FlammableSolids = 'FlammableSolids',
  Gases = 'Gases',
  MiscellaneousDangerousSubstancesandArticles = 'MiscellaneousDangerousSubstancesandArticles',
  OxidizingSubstancesandOrganicPeroxides = 'OxidizingSubstancesandOrganicPeroxides',
  RadioactiveMaterial = 'RadioactiveMaterial',
  ToxicandInfectiousSubstances = 'ToxicandInfectiousSubstances'
}


export enum Days {
  Fri = 'Fri',
  Mon = 'Mon',
  Sat = 'Sat',
  Sun = 'Sun',
  Thu = 'Thu',
  Tue = 'Tue',
  Wed = 'Wed'
}

export type DeleteAttachmentFileDto = {
  entityId: Scalars['Int'];
  entityType: Scalars['String'];
  fileId: Scalars['Int'];
};

export type DeleteResult = {
  __typename?: 'DeleteResult';
  result: Scalars['Boolean'];
};

export type Department = {
  __typename?: 'Department';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type Deviation = {
  __typename?: 'Deviation';
  actualArrivalTime?: Maybe<Scalars['String']>;
  cause?: Maybe<DeviationCause>;
  consequence: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  customers: Array<Customer>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy: Scalars['String'];
  deviationApplicableDate?: Maybe<Scalars['DateTime']>;
  deviationToCustomer?: Maybe<Array<DeviationToCustomer>>;
  deviationToLocation?: Maybe<Array<DeviationToLocation>>;
  emailSent?: Maybe<Scalars['DateTime']>;
  englishMessageCustomers: Scalars['String'];
  fault: DeviationFault;
  faultLocation?: Maybe<LocationEntity>;
  faultLocationId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  lastUpdatedBy: Scalars['String'];
  localMessageCustomers: Scalars['String'];
  note: Scalars['String'];
  route: Route;
  routeId: Scalars['Int'];
  toLocation?: Maybe<LocationEntity>;
  toLocationId?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
};

export enum DeviationCause {
  CustomsIssues = 'CustomsIssues',
  DelayedFerry = 'DelayedFerry',
  LateDeparture = 'LateDeparture',
  LeftoverCargo = 'LeftoverCargo',
  PoliceControl = 'PoliceControl',
  TechnicalIssueOnEquipmentOrTruck = 'TechnicalIssueOnEquipmentOrTruck',
  TrafficIssues = 'TrafficIssues'
}

export enum DeviationFault {
  AddedFromTerminal = 'AddedFromTerminal',
  BringCustoms = 'BringCustoms',
  Customer = 'Customer',
  ExternalPart = 'ExternalPart',
  FaultFromTerminal = 'FaultFromTerminal',
  Other = 'Other',
  Subcontractor = 'Subcontractor',
  Terminal = 'Terminal',
  WeatherConditions = 'WeatherConditions'
}

export type DeviationInput = {
  actualArrivalTime?: Maybe<Scalars['String']>;
  cause?: Maybe<DeviationCause>;
  consequence: Scalars['Boolean'];
  fault: DeviationFault;
  faultLocationId?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  routeId: Scalars['Int'];
};

export type DeviationToCustomer = {
  __typename?: 'DeviationToCustomer';
  customer: Customer;
  customerId: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  deviation: Deviation;
  deviationId: Scalars['Int'];
  id: Scalars['Int'];
  lastEmailSent?: Maybe<Scalars['DateTime']>;
};

export type DeviationToLocation = {
  __typename?: 'DeviationToLocation';
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deviation: Deviation;
  deviationId: Scalars['Int'];
  id: Scalars['Int'];
  isDangerous?: Maybe<Scalars['Boolean']>;
  lastEmailSent?: Maybe<Scalars['DateTime']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  location: LocationEntity;
  locationId: Scalars['Int'];
  locationItemNote?: Maybe<Scalars['String']>;
  newCustomer?: Maybe<Scalars['String']>;
  otherReason?: Maybe<Scalars['String']>;
  packages?: Maybe<Scalars['Int']>;
  palletSpace?: Maybe<Scalars['Float']>;
  pallets?: Maybe<Scalars['Int']>;
  reason?: Maybe<Reason>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight?: Maybe<Scalars['Float']>;
};

export type DocumentFile = {
  __typename?: 'DocumentFile';
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  deletedBy: Scalars['String'];
  id: Scalars['Int'];
  lastUpdatedBy: Scalars['String'];
  mimetype: Scalars['String'];
  originalname: Scalars['String'];
  path: Scalars['String'];
  size: Scalars['Float'];
  tags: Array<DocumentFileTag>;
  updatedAt: Scalars['DateTime'];
};

export type DocumentFileTag = {
  __typename?: 'DocumentFileTag';
  createdAt: Scalars['DateTime'];
  files: Array<DocumentFile>;
  id: Scalars['Int'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type DriverInfo = {
  driverName?: Maybe<Scalars['String']>;
  driverPhoneNumber?: Maybe<Scalars['String']>;
  licensePlate?: Maybe<Scalars['String']>;
};

export type EditDeviationInput = {
  actualArrivalTime?: Maybe<Scalars['String']>;
  cause?: Maybe<DeviationCause>;
  consequence: Scalars['Boolean'];
  emailSent?: Maybe<Scalars['DateTime']>;
  fault: DeviationFault;
  faultLocation?: Maybe<UpdateLocationInput>;
  faultLocationId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  note?: Maybe<Scalars['String']>;
  routeId: Scalars['Int'];
};

export type EditInternalTerminalReportInfoInput = {
  info: Scalars['String'];
  infoType: InfoType;
  internalTerminalReportInfoId: Scalars['Int'];
};

export type EditInternalTerminalReportInfoReplyInput = {
  internalTerminalReportInfoId: Scalars['Int'];
  replyText: Scalars['String'];
};

export type EditLocationDeviationInput = {
  consequence: Scalars['Boolean'];
  customerDeviationListItemsInput: Array<EditLocationDeviationListItemsInput>;
  deviationApplicableDate: Scalars['DateTime'];
  fault: DeviationFault;
  faultLocationId: Scalars['Int'];
  id: Scalars['Int'];
  note?: Maybe<Scalars['String']>;
  routeId?: Maybe<Scalars['Int']>;
  toLocationId: Scalars['Int'];
  toLocationIdArray?: Maybe<Array<Scalars['Int']>>;
};

export type EditLocationDeviationListItemsInput = {
  deviationId: Scalars['Int'];
  id: Scalars['Int'];
  isDangerous?: Maybe<Scalars['Boolean']>;
  isNewCustomer?: Maybe<Scalars['Boolean']>;
  locationId: Scalars['Int'];
  locationItemNote?: Maybe<Scalars['String']>;
  newCustomer?: Maybe<Scalars['String']>;
  otherReason?: Maybe<Scalars['String']>;
  packages?: Maybe<Scalars['Int']>;
  palletSpace?: Maybe<Scalars['Float']>;
  pallets?: Maybe<Scalars['Int']>;
  reason?: Maybe<Reason>;
  weight?: Maybe<Scalars['Float']>;
};

export type EditMasterValueBoxInput = {
  boxId: Scalars['Int'];
  boxName: Scalars['String'];
  frequency: Array<FrequencyDays>;
};

export type EditShipmentLoadingList = {
  checked: Scalars['Boolean'];
  classification?: Maybe<DangerousGoodsClassification>;
  connectedNB?: Maybe<Scalars['String']>;
  customCleared: Scalars['Boolean'];
  customClearedForImport: Scalars['Boolean'];
  customCmrNo?: Maybe<Scalars['String']>;
  customInvoiceNo?: Maybe<Scalars['String']>;
  customMrnNo?: Maybe<Scalars['String']>;
  customNote?: Maybe<Scalars['String']>;
  customVOECId?: Maybe<Scalars['String']>;
  customWaybillNo?: Maybe<Scalars['String']>;
  customsProcedure?: Maybe<CustomsProcedure>;
  id?: Maybe<Scalars['Int']>;
  isDangerous: Scalars['Boolean'];
  isImportedFromShipment?: Maybe<Scalars['Boolean']>;
  isLimitedQty?: Maybe<Scalars['Boolean']>;
  isRemainingGoods: Scalars['Boolean'];
  isVoecCustomer: Scalars['Boolean'];
  locationId: Scalars['Int'];
  note: Scalars['String'];
  packages?: Maybe<Scalars['Int']>;
  palletSpace?: Maybe<Scalars['Float']>;
  pallets?: Maybe<Scalars['Int']>;
  terminalCleared: Scalars['Boolean'];
  terminalDevInfo?: Maybe<Scalars['String']>;
  terminalDevPackages?: Maybe<Scalars['Int']>;
  terminalDevPalletSpace?: Maybe<Scalars['Float']>;
  terminalDevPallets?: Maybe<Scalars['Int']>;
  terminalDevWeight?: Maybe<Scalars['Float']>;
  terminalNotCleared: Scalars['Boolean'];
  terminalShipmentNotArrived: Scalars['Boolean'];
  unNumber?: Maybe<Scalars['Int']>;
  weight?: Maybe<Scalars['Float']>;
};

export type EditValueBoxEntry = {
  boxNameText: Scalars['String'];
  boxValue: Scalars['Int'];
  entryId: Scalars['Int'];
  valueAddedDate: Scalars['String'];
  valueAddedDay: Scalars['String'];
  valueBoxId: Scalars['Int'];
};

export type ExcelImportDataInput = {
  Address: Scalars['String'];
  CardName: Scalars['String'];
  City: Scalars['String'];
  CountryCode: Scalars['String'];
  LocationName: Scalars['String'];
  PostalCode: Scalars['String'];
};

export type FileItemInput = {
  id: Scalars['Float'];
};

export type FixedTourGenerateInput = {
  dateString: Scalars['String'];
  routeId: Scalars['Int'];
};

export type FixedTourGenerateResponse = {
  __typename?: 'FixedTourGenerateResponse';
  createdRouteId?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['String']>;
  routeType?: Maybe<RouteType>;
  status: FixedTourGenerateResponseStatus;
};

export enum FixedTourGenerateResponseStatus {
  NotFound = 'NotFound',
  RouteAlreadyDispatched = 'RouteAlreadyDispatched',
  Success = 'Success'
}

export type FixedTrafficLeg = {
  __typename?: 'FixedTrafficLeg';
  arrivalCity?: Maybe<Scalars['String']>;
  arrivalCountry?: Maybe<Scalars['String']>;
  arrivalTime: Scalars['String'];
  capacity?: Maybe<Scalars['Int']>;
  days: Array<Days>;
  departureCity?: Maybe<Scalars['String']>;
  departureCountry?: Maybe<Scalars['String']>;
  departureTime?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  endExceptionDate?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  load: Scalars['Boolean'];
  note: Scalars['String'];
  routeForId: Scalars['Int'];
  routeId: Scalars['String'];
  startDate: Scalars['String'];
  startExceptionDate?: Maybe<Scalars['String']>;
  subcontractor: Subcontractor;
  tourId: Scalars['Int'];
  unload: Scalars['Boolean'];
};

export enum FrequencyDays {
  Fri = 'Fri',
  Mon = 'Mon',
  Sat = 'Sat',
  Sun = 'Sun',
  Thu = 'Thu',
  Tue = 'Tue',
  Wed = 'Wed'
}

export enum FuelType {
  Bio = 'BIO',
  Diesel = 'Diesel',
  Electric = 'Electric',
  Gasoline = 'Gasoline',
  Hvo = 'HVO',
  Train = 'Train'
}

export type GridValues = {
  __typename?: 'GridValues';
  columnHeaders: Array<Scalars['String']>;
  gridEntries?: Maybe<Scalars['JSON']>;
  gridEntryId: Scalars['Int'];
  gridName: Scalars['String'];
  gridType: Scalars['String'];
  id: Scalars['Int'];
  internalTerminalId: Scalars['Int'];
  isGridDeleted: Scalars['Boolean'];
  isNumberGridWithTotal: Scalars['Boolean'];
  location?: Maybe<Scalars['String']>;
  rowHeaders: Array<Scalars['String']>;
  valueAddedDate?: Maybe<Scalars['String']>;
};

export type Holiday = {
  __typename?: 'Holiday';
  country: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  date: Scalars['String'];
  deletedBy: Scalars['String'];
  id: Scalars['Int'];
  title: Scalars['String'];
};

export type IncomingRoutesToTerminal = {
  __typename?: 'IncomingRoutesToTerminal';
  inbound?: Maybe<Array<ReportToTerminalInboundOutboundRouteList>>;
  outbound?: Maybe<Array<ReportToTerminalInboundOutboundRouteList>>;
};

export enum InfoType {
  Important = 'Important',
  Info = 'Info'
}

export type InternalTerminal = {
  __typename?: 'InternalTerminal';
  createdAt: Scalars['DateTime'];
  deletedAt: Scalars['DateTime'];
  department: Department;
  departmentId: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  reportExportedAt?: Maybe<Scalars['DateTime']>;
  reportExportedBy?: Maybe<Scalars['String']>;
  terminal: Terminal;
  terminalId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type InternalTerminalGridEntries = {
  __typename?: 'InternalTerminalGridEntries';
  createdAt: Scalars['DateTime'];
  deletedAt: Scalars['DateTime'];
  gridEntries?: Maybe<Scalars['JSON']>;
  gridEntryId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  valueAddedDate?: Maybe<Scalars['String']>;
};

export type InternalTerminalReportInfo = {
  __typename?: 'InternalTerminalReportInfo';
  createdBy?: Maybe<Scalars['String']>;
  dateForAddInfo: Scalars['DateTime'];
  files: Array<DocumentFile>;
  id: Scalars['Int'];
  info: Scalars['String'];
  infoType: InfoType;
  internalTerminal: InternalTerminal;
  internalTerminalId: Scalars['Int'];
  isAddedFromTerminal: Scalars['Boolean'];
  replyInfo?: Maybe<Scalars['String']>;
  replyText?: Maybe<Scalars['String']>;
};

export type InternalTerminalReportInfoInput = {
  createUntilDate: Scalars['DateTime'];
  dateForAddInfo: Scalars['DateTime'];
  info: Scalars['String'];
  infoType: InfoType;
  internalTerminalId: Scalars['Int'];
  isAddedFromTerminal?: Maybe<Scalars['Boolean']>;
};

export type InternalTerminalReportInfoResponse = {
  __typename?: 'InternalTerminalReportInfoResponse';
  createdBy: Scalars['String'];
  dateForAddInfo: Scalars['String'];
  files: Array<DocumentFile>;
  id: Scalars['Int'];
  info: Scalars['String'];
  infoType: InfoType;
  internalTerminalId: Scalars['Int'];
  isAddedFromTerminal: Scalars['Boolean'];
  replyInfo?: Maybe<Scalars['String']>;
  replyText?: Maybe<Scalars['String']>;
};

export type InternalTerminalValueBoxEntries = {
  __typename?: 'InternalTerminalValueBoxEntries';
  boxNameText: Scalars['String'];
  boxValue: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  deletedAt: Scalars['DateTime'];
  id: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  valueAddedDate: Scalars['String'];
  valueAddedDay: Scalars['String'];
  valueBoxId: Scalars['Int'];
};

export type InternalTerminalValueBoxMaster = {
  __typename?: 'InternalTerminalValueBoxMaster';
  boxEntries: Array<InternalTerminalValueBoxEntries>;
  boxName: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt: Scalars['DateTime'];
  frequency: Array<FrequencyDays>;
  id: Scalars['Int'];
  internalTerminal: InternalTerminal;
  internalTerminalId: Scalars['Int'];
  isAddedFromTerminal: Scalars['Boolean'];
  isFixedBox: Scalars['Boolean'];
  isTimeBox: Scalars['Boolean'];
  isYesNoBox: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
};

export type InvoiceDataInput = {
  agreedPrice?: Maybe<Scalars['Float']>;
  invoiceNote?: Maybe<Scalars['String']>;
  invoiceNumber?: Maybe<Scalars['String']>;
};



export type KpiItem = {
  __typename?: 'KPIItem';
  arrivalCity: Scalars['String'];
  arrivalCountry: Scalars['String'];
  capacity?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
  departureCity: Scalars['String'];
  departureCountry: Scalars['String'];
  departureTime?: Maybe<Scalars['String']>;
  frequency: Array<Days>;
  id: Scalars['Int'];
  load: Scalars['Boolean'];
  position?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Float']>;
  routeId: Scalars['String'];
  status: KpiItemStatus;
  subcontractor: Scalars['String'];
  unload: Scalars['Boolean'];
};

export enum KpiItemStatus {
  Active = 'Active',
  Archived = 'Archived',
  Inactive = 'Inactive',
  TourOrRouteDeleted = 'TourOrRouteDeleted'
}

export type KpiReportItem = {
  __typename?: 'KPIReportItem';
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Boolean'];
  deletedAt: Scalars['DateTime'];
  internalTerminal: InternalTerminal;
  internalTerminalId: Scalars['Int'];
  leg: TourRouteLegTemplate;
  legId: Scalars['Int'];
  position?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
};

export type LegLoadingListInput = {
  id: Scalars['Int'];
  loadingListItems: Array<LoadingListItemInput>;
};

export type LoadingListItem = {
  __typename?: 'LoadingListItem';
  checked: Scalars['Boolean'];
  classification?: Maybe<DangerousGoodsClassification>;
  connectedNB?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  customCleared: Scalars['Boolean'];
  customClearedForImport: Scalars['Boolean'];
  customCmrNo?: Maybe<Scalars['String']>;
  customInvoiceNo?: Maybe<Scalars['String']>;
  customMrnNo?: Maybe<Scalars['String']>;
  customNote?: Maybe<Scalars['String']>;
  customVOECId?: Maybe<Scalars['String']>;
  customWaybillNo?: Maybe<Scalars['String']>;
  customsProcedure?: Maybe<CustomsProcedure>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy: Scalars['String'];
  files: Array<DocumentFile>;
  id: Scalars['Int'];
  isDangerous: Scalars['Boolean'];
  isImportedFromShipment: Scalars['Boolean'];
  isLimitedQty: Scalars['Boolean'];
  isRemainingGoods: Scalars['Boolean'];
  isVoecCustomer: Scalars['Boolean'];
  lastUpdatedBy: Scalars['String'];
  leg: RouteLeg;
  location?: Maybe<LocationEntity>;
  locationId: Scalars['Int'];
  note: Scalars['String'];
  packages?: Maybe<Scalars['Int']>;
  palletSpace?: Maybe<Scalars['Float']>;
  pallets?: Maybe<Scalars['Int']>;
  routeDetail: RouteDetailsInLl;
  shipment: Shipment;
  terminalCleared: Scalars['Boolean'];
  terminalDevInfo?: Maybe<Scalars['String']>;
  terminalDevPackages?: Maybe<Scalars['Int']>;
  terminalDevPalletSpace?: Maybe<Scalars['Float']>;
  terminalDevPallets?: Maybe<Scalars['Int']>;
  terminalDevWeight?: Maybe<Scalars['Float']>;
  terminalNotCleared: Scalars['Boolean'];
  terminalShipmentNotArrived: Scalars['Boolean'];
  unNumber?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
  weight?: Maybe<Scalars['Float']>;
};

export type LoadingListItemInput = {
  checked: Scalars['Boolean'];
  classification?: Maybe<DangerousGoodsClassification>;
  customsProcedure?: Maybe<CustomsProcedure>;
  id?: Maybe<Scalars['Int']>;
  isDangerous: Scalars['Boolean'];
  isLimitedQty: Scalars['Boolean'];
  locationId: Scalars['Int'];
  note: Scalars['String'];
  packages?: Maybe<Scalars['Int']>;
  palletSpace?: Maybe<Scalars['Float']>;
  pallets?: Maybe<Scalars['Int']>;
  unNumber?: Maybe<Scalars['Int']>;
  weight?: Maybe<Scalars['Float']>;
};

export type LocationDeviationInput = {
  consequence: Scalars['Boolean'];
  customerDeviationListItemsInput: Array<LocationDeviationListItemsInput>;
  deviationApplicableDate: Scalars['DateTime'];
  fault: DeviationFault;
  faultLocationId: Scalars['Int'];
  id?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  toLocationId: Scalars['Int'];
  toLocationIdArray?: Maybe<Array<Scalars['Int']>>;
};

export type LocationDeviationListItemsInput = {
  createdBy?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  isDangerous?: Maybe<Scalars['Boolean']>;
  isNewCustomer?: Maybe<Scalars['Boolean']>;
  locationId: Scalars['Int'];
  locationItemNote?: Maybe<Scalars['String']>;
  newCustomer?: Maybe<Scalars['String']>;
  otherReason?: Maybe<Scalars['String']>;
  packages?: Maybe<Scalars['Int']>;
  palletSpace?: Maybe<Scalars['Float']>;
  pallets?: Maybe<Scalars['Int']>;
  reason?: Maybe<Reason>;
  weight?: Maybe<Scalars['Float']>;
};

export type LocationEntity = {
  __typename?: 'LocationEntity';
  address: Scalars['String'];
  card: Card;
  city: Scalars['String'];
  country: Scalars['String'];
  countryLong: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  debits: Array<PeakPlanningDebit>;
  deletedAt: Scalars['DateTime'];
  deletedBy: Scalars['String'];
  deviationToLocation?: Maybe<DeviationToLocation>;
  deviations: Array<Deviation>;
  id: Scalars['Int'];
  lastUpdatedBy: Scalars['String'];
  locationShortCode: Scalars['String'];
  name: Scalars['String'];
  peakPlanningLocations: Array<PeakPlanningLocation>;
  phLocationId: Scalars['String'];
  postalCode: Scalars['String'];
  toDeviations?: Maybe<Array<Deviation>>;
  updatedAt: Scalars['DateTime'];
};

export type MasterGridResponse = {
  __typename?: 'MasterGridResponse';
  columnHeaders: Array<Scalars['String']>;
  gridName: Scalars['String'];
  id: Scalars['Int'];
  internalTerminalId: Scalars['Int'];
  isNumberGridWithTotal: Scalars['Boolean'];
  rowHeaders: Array<Scalars['String']>;
};

export type MasterValueBoxResponse = {
  __typename?: 'MasterValueBoxResponse';
  boxName: Scalars['String'];
  frequency: Array<FrequencyDays>;
  id: Scalars['Int'];
  internalTerminalId: Scalars['Int'];
  isAddedFromTerminal: Scalars['Boolean'];
  isFixedBox: Scalars['Boolean'];
  isTimeBox: Scalars['Boolean'];
  isYesNoBox: Scalars['Boolean'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addCardLocation: Card;
  addCardNote: Card;
  addCustomerCardLocation: Customer;
  addFixedValueBoxes: Scalars['Boolean'];
  addGridValue: InternalTerminalGridEntries;
  addHoliday: Holiday;
  addInternalTerminalReportInfo: InternalTerminal;
  addMasterGridBox: InternalTerminal;
  addMasterValueBox: InternalTerminal;
  connectTerminal: PublicUser;
  createCardContact: CardContact;
  createCheckpoint: Checkpoint;
  createContactTag: CardContactTag;
  createCustomer: Customer;
  createCustomerAccount: CustomerAccount;
  createDeviation: Deviation;
  createDocumentFileTag: DocumentFileTag;
  createLocation: LocationEntity;
  createLocationDeviation: Array<Deviation>;
  createMultipleSporadicRoute: Scalars['Int'];
  createOrder: Order;
  createPeakPlanningDebit: PeakPlanningDebit;
  createPeakPlanningItem: PeakPlanningItem;
  createShipment: Shipment;
  createSporadicRoute: Route;
  createSporadicRouteLoadingListItemTemplate: SporadicRouteLoadingListItemTemplate;
  createSporadicRouteTemplate: SporadicRouteTemplate;
  createSporadicRouteWithoutDispatcher: Route;
  createSubcontractor: Subcontractor;
  createTerminal: Terminal;
  createTourTemplate: TourTemplate;
  deleteAttachmentFile: Scalars['Boolean'];
  deleteCardContact: Scalars['Boolean'];
  deleteCardFile: Scalars['Boolean'];
  deleteCardNote: Scalars['Boolean'];
  deleteCheckpoint: Scalars['Boolean'];
  deleteContactTag: Scalars['Boolean'];
  deleteCustomer: Scalars['Boolean'];
  deleteCustomerAccount: Scalars['Boolean'];
  deleteDeviation: Scalars['Boolean'];
  deleteDocumentFile: Scalars['Boolean'];
  deleteHoliday: Scalars['Boolean'];
  deleteInternalTerminalReportInfo: DeleteResult;
  deleteLoadingListItem: Scalars['Boolean'];
  deleteLocation: Scalars['Boolean'];
  deleteMasterGrid: DeleteResult;
  deleteMasterValueBox: DeleteResult;
  deleteMultipleRoute: Scalars['Int'];
  deletePeakPlanningItem: Scalars['Boolean'];
  deleteRoute: Scalars['Boolean'];
  deleteShipment: Scalars['Boolean'];
  deleteSporadicRouteTemplate: Scalars['Boolean'];
  deleteSubcontractor: Scalars['Boolean'];
  deleteTerminal: Scalars['Boolean'];
  deleteTourTemplate: Scalars['Boolean'];
  deleteValueBoxEntry: DeleteResult;
  demoteInternalTerminal: Scalars['Boolean'];
  editDeviation: Deviation;
  editInternalTerminalReportInfo: InternalTerminalReportInfo;
  editLocationDeviation: Deviation;
  editMasterValueBox: InternalTerminalValueBoxMaster;
  editReportInfoReply: InternalTerminalReportInfo;
  editValueBoxEntry: InternalTerminalValueBoxEntries;
  generateFixedTour: FixedTourGenerateResponse;
  importCheckpoints: Array<Checkpoint>;
  importCustomers: Array<Customer>;
  importSubcontractors: Array<Subcontractor>;
  importTerminals: Array<Terminal>;
  linkOrUnlinkOrderInLeg: Scalars['Int'];
  promoteToInternalTerminal: InternalTerminal;
  reportMail: Scalars['String'];
  sendDeviation: Deviation;
  truckFillAndTime: RouteLeg;
  updateBookmark: UpdateBookMarkResponse;
  updateCardContact: CardContact;
  updateCardNote: CardNote;
  updateCheckpoint: Checkpoint;
  updateConfiguration: PublicUser;
  updateCostAllocation: Scalars['Boolean'];
  updateCustomer: Customer;
  updateCustomerAccount: CustomerAccount;
  updateDriver: Route;
  updateExtraColumns: Route;
  updateInvoice: Route;
  updateLegWithLoadingList: RouteLeg;
  updateLoadingListItem: LoadingListItem;
  updateLocation: LocationEntity;
  updateLock: RouteLock;
  updateLockOnBulkRoutes: Scalars['Boolean'];
  updateMinimalRoutes: Scalars['Boolean'];
  updateOrder: Order;
  updatePartialShipment: Scalars['Boolean'];
  updatePeakPlanningDebit: PeakPlanningDebit;
  updatePeakPlanningItem: PeakPlanningItem;
  updateRouteGroupTag: Scalars['Boolean'];
  updateRouteLegDataColumns: RouteLeg;
  updateShipment: Shipment;
  updateSporadicRoute: Route;
  updateSporadicRouteLoadingListItemTemplate: SporadicRouteLoadingListItemTemplate;
  updateSporadicRouteTemplate: SporadicRouteTemplate;
  updateSubcontractor: Subcontractor;
  updateTerminal: Terminal;
  updateTftConfiguration: PublicUser;
  updateTourTemplate: TourTemplate;
  updateTripInfoFromProdApp: Scalars['Boolean'];
};


export type MutationAddCardLocationArgs = {
  id: Scalars['Int'];
  locationInput: CreateLocationInput;
};


export type MutationAddCardNoteArgs = {
  id: Scalars['Int'];
  input: CreateNoteInput;
};


export type MutationAddCustomerCardLocationArgs = {
  id: Scalars['Int'];
  locationInput: CreateLocationInput;
};


export type MutationAddFixedValueBoxesArgs = {
  internalTerminalId: Scalars['Int'];
};


export type MutationAddGridValueArgs = {
  gridValue: AddGridValueInput;
};


export type MutationAddHolidayArgs = {
  input: CreateHolidayInput;
};


export type MutationAddInternalTerminalReportInfoArgs = {
  input: InternalTerminalReportInfoInput;
};


export type MutationAddMasterGridBoxArgs = {
  gridData: AddMasterGridDataInput;
};


export type MutationAddMasterValueBoxArgs = {
  boxData: AddMasterValueBoxInput;
};


export type MutationConnectTerminalArgs = {
  terminalId: Scalars['Int'];
};


export type MutationCreateCardContactArgs = {
  cardId: Scalars['Int'];
  input: CreateCardContactInput;
};


export type MutationCreateCheckpointArgs = {
  input: CreateCheckpointInput;
};


export type MutationCreateContactTagArgs = {
  input: CreateCardContactTagInput;
};


export type MutationCreateCustomerArgs = {
  input: CreateCustomerInput;
};


export type MutationCreateCustomerAccountArgs = {
  cardId: Scalars['Int'];
  input: CreateCustomerAccountInput;
};


export type MutationCreateDeviationArgs = {
  input: DeviationInput;
};


export type MutationCreateDocumentFileTagArgs = {
  input: CreateDocumentTagInput;
};


export type MutationCreateLocationArgs = {
  input: CreateLocationInput;
};


export type MutationCreateLocationDeviationArgs = {
  input: Array<LocationDeviationInput>;
};


export type MutationCreateMultipleSporadicRouteArgs = {
  dates: Array<Scalars['String']>;
  input: SporadicRouteInput;
};


export type MutationCreateOrderArgs = {
  input: OrderInput;
};


export type MutationCreatePeakPlanningDebitArgs = {
  input: PeakPlanningDebitInput;
};


export type MutationCreatePeakPlanningItemArgs = {
  input: PeakPlanningItemInput;
};


export type MutationCreateShipmentArgs = {
  input: CreateShipmentInput;
};


export type MutationCreateSporadicRouteArgs = {
  input: SporadicRouteInput;
};


export type MutationCreateSporadicRouteLoadingListItemTemplateArgs = {
  input: SporadicRouteLoadingListItemTemplateInput;
};


export type MutationCreateSporadicRouteTemplateArgs = {
  input: SporadicRouteTemplateInput;
};


export type MutationCreateSporadicRouteWithoutDispatcherArgs = {
  input: SporadicRouteInput;
};


export type MutationCreateSubcontractorArgs = {
  input: CreateSubcontractorInput;
};


export type MutationCreateTerminalArgs = {
  input: CreateTerminalInput;
};


export type MutationCreateTourTemplateArgs = {
  input: TourTemplateInput;
};


export type MutationDeleteAttachmentFileArgs = {
  input: DeleteAttachmentFileDto;
};


export type MutationDeleteCardContactArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteCardFileArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteCardNoteArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteCheckpointArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteContactTagArgs = {
  tagId: Scalars['Int'];
};


export type MutationDeleteCustomerArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteCustomerAccountArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteDeviationArgs = {
  customerId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
};


export type MutationDeleteDocumentFileArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteHolidayArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteInternalTerminalReportInfoArgs = {
  internalTerminalReportInfoId: Scalars['Float'];
};


export type MutationDeleteLoadingListItemArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteLocationArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteMasterGridArgs = {
  Id: Scalars['Int'];
};


export type MutationDeleteMasterValueBoxArgs = {
  boxId: Scalars['Int'];
};


export type MutationDeleteMultipleRouteArgs = {
  ids: Array<Scalars['Int']>;
};


export type MutationDeletePeakPlanningItemArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteRouteArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteShipmentArgs = {
  id: Scalars['Int'];
  shipmentDate: Scalars['String'];
  updatedFrom: Scalars['String'];
};


export type MutationDeleteSporadicRouteTemplateArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteSubcontractorArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteTerminalArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteTourTemplateArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteValueBoxEntryArgs = {
  entryId: Scalars['Int'];
};


export type MutationDemoteInternalTerminalArgs = {
  id: Scalars['Int'];
};


export type MutationEditDeviationArgs = {
  input: EditDeviationInput;
};


export type MutationEditInternalTerminalReportInfoArgs = {
  input: EditInternalTerminalReportInfoInput;
};


export type MutationEditLocationDeviationArgs = {
  input: EditLocationDeviationInput;
};


export type MutationEditMasterValueBoxArgs = {
  boxData: EditMasterValueBoxInput;
};


export type MutationEditReportInfoReplyArgs = {
  input: EditInternalTerminalReportInfoReplyInput;
};


export type MutationEditValueBoxEntryArgs = {
  boxEntry: EditValueBoxEntry;
};


export type MutationGenerateFixedTourArgs = {
  input: FixedTourGenerateInput;
};


export type MutationImportCheckpointsArgs = {
  input: Array<ExcelImportDataInput>;
};


export type MutationImportCustomersArgs = {
  input: Array<ExcelImportDataInput>;
};


export type MutationImportSubcontractorsArgs = {
  input: Array<ExcelImportDataInput>;
};


export type MutationImportTerminalsArgs = {
  input: Array<ExcelImportDataInput>;
};


export type MutationLinkOrUnlinkOrderInLegArgs = {
  input: OrderLinkUnlinkInput;
};


export type MutationPromoteToInternalTerminalArgs = {
  id: Scalars['Int'];
};


export type MutationReportMailArgs = {
  excelSheetAttachment?: Maybe<Scalars['String']>;
  mailIds: Array<Scalars['String']>;
  pdfAttachment: Scalars['String'];
  terminalName: Scalars['String'];
};


export type MutationSendDeviationArgs = {
  input: SendDeviationInput;
};


export type MutationTruckFillAndTimeArgs = {
  input: TruckFillAndTimeInput;
};


export type MutationUpdateBookmarkArgs = {
  bookmark: Scalars['Boolean'];
  id: Scalars['Int'];
};


export type MutationUpdateCardContactArgs = {
  input: UpdateCardContactInput;
};


export type MutationUpdateCardNoteArgs = {
  input: UpdateNoteInput;
};


export type MutationUpdateCheckpointArgs = {
  input: UpdateCheckpointInput;
};


export type MutationUpdateConfigurationArgs = {
  input: UserConfigurationInput;
};


export type MutationUpdateCostAllocationArgs = {
  costAllocation: UpdateCostAllocationInput;
  id: Scalars['Int'];
};


export type MutationUpdateCustomerArgs = {
  input: UpdateCustomerInput;
};


export type MutationUpdateCustomerAccountArgs = {
  input: UpdateCustomerAccountInput;
};


export type MutationUpdateDriverArgs = {
  driverData: DriverInfo;
  id: Scalars['Int'];
};


export type MutationUpdateExtraColumnsArgs = {
  extraColumnData: ExtraColumnInfo;
  id: Scalars['Int'];
};


export type MutationUpdateInvoiceArgs = {
  id: Scalars['Int'];
  invoiceData: InvoiceDataInput;
};


export type MutationUpdateLegWithLoadingListArgs = {
  input: LegLoadingListInput;
};


export type MutationUpdateLoadingListItemArgs = {
  input: ShipmentLoadingList;
  shipmentId: Scalars['Int'];
};


export type MutationUpdateLocationArgs = {
  input: UpdateLocationInput;
};


export type MutationUpdateLockArgs = {
  lock: Scalars['Int'];
  reload: Scalars['Int'];
  routeId: Scalars['Int'];
};


export type MutationUpdateLockOnBulkRoutesArgs = {
  forGroup: Scalars['String'];
  isLock: Scalars['Boolean'];
  routeIds: Array<Scalars['Int']>;
};


export type MutationUpdateMinimalRoutesArgs = {
  input: UpdateMinimalRouteInput;
};


export type MutationUpdateOrderArgs = {
  id: Scalars['Int'];
  input: OrderInput;
};


export type MutationUpdatePartialShipmentArgs = {
  input: ShipmentPartialUpdateInput;
};


export type MutationUpdatePeakPlanningDebitArgs = {
  id: Scalars['Int'];
  input: PeakPlanningDebitInput;
};


export type MutationUpdatePeakPlanningItemArgs = {
  id: Scalars['Int'];
  input: PeakPlanningItemInput;
};


export type MutationUpdateRouteGroupTagArgs = {
  id: Scalars['Int'];
  routeGroupTag: Scalars['String'];
};


export type MutationUpdateRouteLegDataColumnsArgs = {
  id: Scalars['Int'];
  routeLegData: RouteLegDataColumnInfo;
};


export type MutationUpdateShipmentArgs = {
  input: ShipmentEditInput;
  isNewOrders?: Maybe<Scalars['Boolean']>;
  orderListUnchanged?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateSporadicRouteArgs = {
  id: Scalars['Int'];
  input: SporadicRouteInput;
};


export type MutationUpdateSporadicRouteLoadingListItemTemplateArgs = {
  id: Scalars['Int'];
  input: SporadicRouteLoadingListItemTemplateInput;
};


export type MutationUpdateSporadicRouteTemplateArgs = {
  id: Scalars['Int'];
  input: SporadicRouteTemplateInput;
};


export type MutationUpdateSubcontractorArgs = {
  input: UpdateSubcontractorInput;
};


export type MutationUpdateTerminalArgs = {
  input: UpdateTerminalInput;
};


export type MutationUpdateTftConfigurationArgs = {
  input: UserConfigurationInput;
};


export type MutationUpdateTourTemplateArgs = {
  id: Scalars['Int'];
  input: TourTemplateInput;
};


export type MutationUpdateTripInfoFromProdAppArgs = {
  capacity?: Maybe<Scalars['Int']>;
  carRegNumber?: Maybe<Scalars['String']>;
  dateTimeStamp: Scalars['String'];
  driverName?: Maybe<Scalars['String']>;
  gateNumber: Scalars['String'];
  legId: Scalars['Int'];
  trailerRegNumber?: Maybe<Scalars['String']>;
  tripStatus: Scalars['String'];
};

export type Order = {
  __typename?: 'Order';
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  customer: Customer;
  customerId: Scalars['Int'];
  id: Scalars['Int'];
  lastUpdatedBy: Scalars['String'];
  note: Scalars['String'];
  packages: Scalars['Int'];
  palletSpace: Scalars['Int'];
  pallets: Scalars['Int'];
  transportationDate: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  weight: Scalars['Int'];
};

export type OrderInput = {
  customerId: Scalars['Int'];
  id?: Maybe<Scalars['Int']>;
  note: Scalars['String'];
  packages: Scalars['Int'];
  palletSpace: Scalars['Int'];
  pallets: Scalars['Int'];
  transportationDate: Scalars['String'];
  weight: Scalars['Int'];
};

export type OrderLinkUnlinkInput = {
  isLink: Scalars['Boolean'];
  legId: Scalars['Int'];
  orderId: Scalars['Int'];
  terminalId: Scalars['Int'];
  transDate: Scalars['String'];
};

export type PeakPlanningDebit = {
  __typename?: 'PeakPlanningDebit';
  bookedPPL?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  customer: Card;
  customerCost?: Maybe<Scalars['Float']>;
  customerCurrency?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['Int']>;
  customerNumber?: Maybe<Scalars['String']>;
  deletedAt: Scalars['DateTime'];
  deletedBy: Scalars['String'];
  destinationCountry?: Maybe<Scalars['String']>;
  difference: Scalars['Int'];
  id: Scalars['Int'];
  lastUpdatedBy: Scalars['String'];
  location: LocationEntity;
  locationId?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  productionDate: Scalars['String'];
  salesContact?: Maybe<CardContact>;
  salesContactId?: Maybe<Scalars['Int']>;
  selfCost?: Maybe<Scalars['Float']>;
  selfCostCurrency?: Maybe<Scalars['String']>;
  sentPPL?: Maybe<Scalars['Int']>;
  sporadicRoute: Route;
  sporadicRouteId: Scalars['Int'];
  status: PeakPlanningDebitStatus;
  updatedAt: Scalars['DateTime'];
};

export type PeakPlanningDebitInput = {
  bookedPPL?: Maybe<Scalars['Float']>;
  customerCost?: Maybe<Scalars['Float']>;
  customerCurrency?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['Int']>;
  customerNumber?: Maybe<Scalars['String']>;
  destinationCountry?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  locationId?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  productionDate?: Maybe<Scalars['String']>;
  salesContactId?: Maybe<Scalars['Int']>;
  selfCost?: Maybe<Scalars['Float']>;
  selfCostCurrency?: Maybe<Scalars['String']>;
  sentPPL?: Maybe<Scalars['Int']>;
  sporadicRouteId?: Maybe<Scalars['Int']>;
  status?: Maybe<PeakPlanningDebitStatus>;
};

export enum PeakPlanningDebitStatus {
  AwaitingSalesReply = 'AwaitingSalesReply',
  Debited = 'Debited',
  MissingData = 'MissingData',
  NotRegistered = 'NotRegistered',
  NotToBeDebited = 'NotToBeDebited'
}

export type PeakPlanningItem = {
  __typename?: 'PeakPlanningItem';
  capacity?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  debitLines?: Maybe<Scalars['Int']>;
  deletedAt: Scalars['DateTime'];
  deletedBy: Scalars['String'];
  departmentId: Scalars['String'];
  generated: Scalars['Boolean'];
  id: Scalars['Int'];
  lastUpdatedBy: Scalars['String'];
  note: Scalars['String'];
  peakPlanningLocations: Array<PeakPlanningLocation>;
  productCountry?: Maybe<Scalars['String']>;
  productType?: Maybe<PeakPlanningProductType>;
  productionDate: Scalars['String'];
  responsible?: Maybe<Scalars['String']>;
  routeId?: Maybe<Scalars['Int']>;
  sporadicRoute?: Maybe<Route>;
  sporadicRouteId?: Maybe<Scalars['Int']>;
  status: PeakPlanningStatus;
  subcontractor?: Maybe<Subcontractor>;
  subcontractorId?: Maybe<Scalars['Int']>;
  unloaded?: Maybe<Scalars['Boolean']>;
  unusedCapacity: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  utilization: Scalars['Float'];
};

export type PeakPlanningItemInput = {
  arrivalCity?: Maybe<Scalars['String']>;
  arrivalCountry?: Maybe<Scalars['String']>;
  arrivalDate?: Maybe<Scalars['DateTime']>;
  capacity?: Maybe<Scalars['Int']>;
  departureCity?: Maybe<Scalars['String']>;
  departureCountry?: Maybe<Scalars['String']>;
  departureDate?: Maybe<Scalars['DateTime']>;
  generated?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  peakPlanningLocations?: Maybe<Array<PeakPlanningItemLocationInput>>;
  productCountry?: Maybe<Scalars['String']>;
  productType?: Maybe<PeakPlanningProductType>;
  productionDate?: Maybe<Scalars['String']>;
  responsible?: Maybe<Scalars['String']>;
  sporadicRouteId?: Maybe<Scalars['Int']>;
  status?: Maybe<PeakPlanningStatus>;
  subcontractorId?: Maybe<Scalars['Int']>;
};

export type PeakPlanningItemLocationInput = {
  bookedPPL?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  loadEnd?: Maybe<Scalars['String']>;
  loadStart?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['Int']>;
  position: Scalars['Int'];
};

export type PeakPlanningLocation = {
  __typename?: 'PeakPlanningLocation';
  bookedPPL?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  deletedAt: Scalars['DateTime'];
  deletedBy: Scalars['String'];
  id: Scalars['Int'];
  lastUpdatedBy: Scalars['String'];
  loadEnd?: Maybe<Scalars['String']>;
  loadStart?: Maybe<Scalars['String']>;
  location?: Maybe<LocationEntity>;
  locationId?: Maybe<Scalars['Int']>;
  peakPlanningItem: PeakPlanningItem;
  position: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export enum PeakPlanningProductType {
  Hd = 'HD',
  Mix = 'Mix',
  Parcel = 'Parcel'
}

export enum PeakPlanningStatus {
  Booked = 'Booked',
  BookedMissingInfo = 'BookedMissingInfo',
  NotBooked = 'NotBooked'
}

export type PublicUser = {
  __typename?: 'PublicUser';
  admin: Scalars['Boolean'];
  configuration?: Maybe<Scalars['JSONObject']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customsAccess: Scalars['Boolean'];
  department: Scalars['String'];
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  internalTerminal?: Maybe<InternalTerminal>;
  internalTerminalId?: Maybe<Scalars['Int']>;
  lastName: Scalars['String'];
  roles: Array<Scalars['String']>;
  terminalAccess: Scalars['Boolean'];
  tftConfiguration?: Maybe<Scalars['JSONObject']>;
  trafficAccess: Scalars['Boolean'];
};

export type Query = {
  __typename?: 'Query';
  allCards: Array<Card>;
  associatedRoutes: Array<AssociatedRoutesResponse>;
  bookmarkedDispatchedRoutes: Array<BookMarkItemResponse>;
  card: Card;
  cardContact: CardContact;
  cardContactTag: CardContactTag;
  cardContactTags: Array<CardContactTag>;
  cardContacts: Array<CardContact>;
  cards: Array<Card>;
  checkpoint: Checkpoint;
  checkpoints: Array<Checkpoint>;
  customer: Customer;
  customerAccount: CustomerAccount;
  customerAccounts: Array<CustomerAccount>;
  customers: Array<Customer>;
  department: Department;
  departments: Array<Department>;
  deviation: Deviation;
  deviationToLocationForDeviationId: Array<DeviationToLocation>;
  deviations: Array<Deviation>;
  deviationsForRoute: Array<Deviation>;
  documentFileTags: Array<DocumentFileTag>;
  fetchLegsMatchingShipment: Array<RouteLeg>;
  fetchRoutesForProdApp: Array<RouteLeg>;
  fetchRoutesForProdAppWithToken: Array<RouteLeg>;
  files: Array<DocumentFile>;
  fixedTrafficLegs: Array<FixedTrafficLeg>;
  getAllRouteGroupTags: Array<Scalars['String']>;
  getCardTypeLocations: Array<LocationEntity>;
  getGridValues: Array<GridValues>;
  getGridValuesForTraffic: Array<GridValues>;
  getLocationDeviations: Array<CustomerLevelDeviationResponse>;
  getMasterGrid: Array<MasterGridResponse>;
  getMasterValueBoxes: Array<MasterValueBoxResponse>;
  getProductionGroup: Array<Scalars['String']>;
  getTftConfig: PublicUser;
  getTourRoute: TourRouteTemplate;
  getUserTerminalData: ResponseUserDataWithTerminal;
  getValueBoxesForDay: Array<ValueBoxForDayResponse>;
  holidays: Array<Holiday>;
  incomingRoutesToTerminal: IncomingRoutesToTerminal;
  internalTerminal: InternalTerminal;
  internalTerminalReportInfo: Array<InternalTerminalReportInfoResponse>;
  internalTerminals: Array<InternalTerminal>;
  loadingListItem: LoadingListItem;
  loadingListItems: Array<LoadingListItem>;
  location: LocationEntity;
  locations: Array<LocationEntity>;
  me: PublicUser;
  multipleDates: Scalars['String'];
  order: Order;
  orders: Array<Order>;
  otherTours: Array<TourTemplate>;
  peakPlanningDebits: Array<PeakPlanningDebit>;
  peakPlanningItems: Array<PeakPlanningItem>;
  pingUser: Scalars['Boolean'];
  refetchLatestLeg: RouteLeg;
  route: Route;
  routeLegForViewLoadingList: Array<RouteLeg>;
  routeLock: RouteLock;
  routes: Array<Route>;
  shipmentById: Shipment;
  shipmentTemplates: Array<Shipment>;
  shipments: Array<Shipment>;
  shipmentsByDate: Array<Shipment>;
  sporadicRouteLoadingListItemTemplate: SporadicRouteLoadingListItemTemplate;
  sporadicRouteLoadingListItemTemplates: Array<SporadicRouteLoadingListItemTemplate>;
  sporadicRouteTemplate: SporadicRouteTemplate;
  sporadicRouteTemplates: Array<SporadicRouteTemplate>;
  status: Scalars['String'];
  subcontractor: Subcontractor;
  subcontractors: Array<Subcontractor>;
  terminal: Terminal;
  terminals: Array<Terminal>;
  tourRouteTemplates: Array<TourRouteTemplate>;
  tourTemplate: TourTemplate;
  tourTemplates: Array<TourTemplate>;
  tourTemplatesWithGroupTag: Array<Scalars['String']>;
  tourTemplatesWithResponsiblePerson: Array<Scalars['String']>;
};


export type QueryAllCardsArgs = {
  search?: Maybe<Scalars['String']>;
  withTerminal?: Maybe<Scalars['Boolean']>;
};


export type QueryAssociatedRoutesArgs = {
  id: Scalars['Int'];
};


export type QueryBookmarkedDispatchedRoutesArgs = {
  date?: Maybe<Scalars['String']>;
};


export type QueryCardArgs = {
  id: Scalars['Int'];
};


export type QueryCardContactArgs = {
  id: Scalars['Int'];
};


export type QueryCardContactTagArgs = {
  id: Scalars['Int'];
};


export type QueryCardContactsArgs = {
  cardId?: Maybe<Scalars['Int']>;
  contactId?: Maybe<Scalars['Int']>;
};


export type QueryCheckpointArgs = {
  id: Scalars['Int'];
};


export type QueryCheckpointsArgs = {
  search?: Maybe<Scalars['String']>;
};


export type QueryCustomerArgs = {
  id: Scalars['Int'];
};


export type QueryCustomerAccountArgs = {
  id: Scalars['Int'];
};


export type QueryCustomerAccountsArgs = {
  cardId: Scalars['Int'];
};


export type QueryCustomersArgs = {
  search?: Maybe<Scalars['String']>;
};


export type QueryDepartmentArgs = {
  id: Scalars['String'];
};


export type QueryDeviationArgs = {
  id: Scalars['Int'];
};


export type QueryDeviationToLocationForDeviationIdArgs = {
  deviationId: Scalars['Int'];
};


export type QueryDeviationsArgs = {
  endDate: Scalars['String'];
  searchTerm?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
};


export type QueryDeviationsForRouteArgs = {
  routeId: Scalars['Int'];
};


export type QueryFetchLegsMatchingShipmentArgs = {
  fromLocationId: Scalars['Int'];
  toLocationId: Scalars['Int'];
  transportDate: Scalars['String'];
};


export type QueryFetchRoutesForProdAppArgs = {
  cetDateTimeString?: Maybe<Scalars['String']>;
  event: Scalars['String'];
  futureHours: Scalars['Int'];
  gateNumbers?: Maybe<Array<Scalars['String']>>;
  pastHours?: Maybe<Scalars['Int']>;
  tripStatus?: Maybe<Scalars['String']>;
  unitId: Scalars['String'];
};


export type QueryFetchRoutesForProdAppWithTokenArgs = {
  cetDateTimeString?: Maybe<Scalars['String']>;
  event: Scalars['String'];
  futureHours: Scalars['Int'];
  gateNumbers?: Maybe<Array<Scalars['String']>>;
  pastHours?: Maybe<Scalars['Int']>;
  tripStatus?: Maybe<Scalars['String']>;
  unitId: Scalars['String'];
};


export type QueryFixedTrafficLegsArgs = {
  terminalId: Scalars['Int'];
};


export type QueryGetAllRouteGroupTagsArgs = {
  transportDate: Scalars['String'];
};


export type QueryGetCardTypeLocationsArgs = {
  cardType: Scalars['String'];
  search?: Maybe<Scalars['String']>;
};


export type QueryGetGridValuesArgs = {
  gridType: Scalars['String'];
  internalTerminalId: Scalars['Int'];
  valueAddedDate: Scalars['String'];
};


export type QueryGetGridValuesForTrafficArgs = {
  gridType: Scalars['String'];
  internalTerminalId: Scalars['Int'];
  valueAddedDate: Scalars['String'];
};


export type QueryGetLocationDeviationsArgs = {
  deviationId?: Maybe<Scalars['Int']>;
  endDate: Scalars['String'];
  searchTerm?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  terminalIds?: Maybe<Array<Scalars['Int']>>;
};


export type QueryGetMasterGridArgs = {
  internalTerminalId: Scalars['Int'];
};


export type QueryGetMasterValueBoxesArgs = {
  forTerminal: Scalars['Boolean'];
  internalTerminalId: Scalars['Int'];
};


export type QueryGetProductionGroupArgs = {
  endDate: Scalars['String'];
  startDate: Scalars['String'];
  terminalId: Scalars['Int'];
};


export type QueryGetTourRouteArgs = {
  routeId: Scalars['Int'];
};


export type QueryGetValueBoxesForDayArgs = {
  date: Scalars['String'];
  forTerminal: Scalars['Boolean'];
  internalTerminalId: Scalars['Int'];
};


export type QueryHolidaysArgs = {
  country: Scalars['String'];
};


export type QueryIncomingRoutesToTerminalArgs = {
  date: Scalars['String'];
  terminalId: Scalars['Int'];
};


export type QueryInternalTerminalArgs = {
  id: Scalars['Int'];
};


export type QueryInternalTerminalReportInfoArgs = {
  dateForAddInfo: Scalars['String'];
  forTerminal: Scalars['Boolean'];
  internalTerminalId: Scalars['Int'];
};


export type QueryInternalTerminalsArgs = {
  fetchFromAllDepartments?: Maybe<Scalars['Boolean']>;
};


export type QueryLoadingListItemArgs = {
  id: Scalars['Int'];
};


export type QueryLocationArgs = {
  id: Scalars['Int'];
};


export type QueryLocationsArgs = {
  cardId?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
};


export type QueryMultipleDatesArgs = {
  id: Scalars['Int'];
};


export type QueryOrderArgs = {
  id: Scalars['Int'];
};


export type QueryOtherToursArgs = {
  excludeId: Scalars['Int'];
  searchTerm?: Maybe<Scalars['String']>;
};


export type QueryPeakPlanningDebitsArgs = {
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};


export type QueryPeakPlanningItemsArgs = {
  departmentId: Scalars['String'];
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};


export type QueryPingUserArgs = {
  routeId: Scalars['Int'];
};


export type QueryRefetchLatestLegArgs = {
  legId: Scalars['Int'];
  terminalId: Scalars['Int'];
};


export type QueryRouteArgs = {
  id: Scalars['Int'];
};


export type QueryRouteLegForViewLoadingListArgs = {
  legId: Scalars['Int'];
};


export type QueryRouteLockArgs = {
  routeId: Scalars['Int'];
};


export type QueryRoutesArgs = {
  endDate: Scalars['String'];
  startDate: Scalars['String'];
  type?: Maybe<RouteType>;
};


export type QueryShipmentByIdArgs = {
  id: Scalars['Int'];
};


export type QueryShipmentsArgs = {
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};


export type QueryShipmentsByDateArgs = {
  forDate: Scalars['String'];
  fromLocationId?: Maybe<Scalars['Int']>;
};


export type QuerySporadicRouteLoadingListItemTemplateArgs = {
  id: Scalars['Int'];
};


export type QuerySporadicRouteTemplateArgs = {
  id: Scalars['Int'];
};


export type QuerySubcontractorArgs = {
  id: Scalars['Int'];
};


export type QuerySubcontractorsArgs = {
  search?: Maybe<Scalars['String']>;
  withTerminal?: Maybe<Scalars['Boolean']>;
};


export type QueryTerminalArgs = {
  id: Scalars['Int'];
};


export type QueryTerminalsArgs = {
  search?: Maybe<Scalars['String']>;
};


export type QueryTourRouteTemplatesArgs = {
  fetchBookmarked?: Maybe<Scalars['Boolean']>;
};


export type QueryTourTemplateArgs = {
  id: Scalars['Int'];
};

export enum Reason {
  CustomsIssues = 'CustomsIssues',
  DamagedItems = 'DamagedItems',
  DangerousGoods = 'DangerousGoods',
  DelayedFerry = 'DelayedFerry',
  LackOfCapacity = 'LackOfCapacity',
  LackOfDocuments = 'LackOfDocuments',
  LateArrivalToTerminal = 'LateArrivalToTerminal',
  LateDeparture = 'LateDeparture',
  LateProduction = 'LateProduction',
  LeftoverCargo = 'LeftoverCargo',
  MissingEdi = 'MissingEdi',
  Other = 'Other',
  PlannedOnNextDayRoutes = 'PlannedOnNextDayRoutes',
  PoliceControl = 'PoliceControl',
  RedChannel = 'RedChannel',
  TechnicalIssueOnEquipmentOrTruck = 'TechnicalIssueOnEquipmentOrTruck',
  TrafficIssues = 'TrafficIssues'
}

export type ReportToTerminalInboundOutboundRouteList = {
  __typename?: 'ReportToTerminalInboundOutboundRouteList';
  arrivalTime: Scalars['String'];
  capacity?: Maybe<Scalars['Int']>;
  departureTime?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  licensePlateAndRouteNote: Scalars['String'];
  routeEntityId: Scalars['Int'];
  routeId: Scalars['String'];
  subcontractorId: Scalars['Int'];
  subcontractorName: Scalars['String'];
};

export type ResponseUserDataWithTerminal = {
  __typename?: 'ResponseUserDataWithTerminal';
  internalTerminalId: Scalars['Float'];
  reportExportedAt: Scalars['String'];
  reportExportedBy: Scalars['String'];
  userFullName: Scalars['String'];
};

export type Route = {
  __typename?: 'Route';
  agreedPrice?: Maybe<Scalars['Float']>;
  arrivalAddress?: Maybe<LocationEntity>;
  arrivalLocation?: Maybe<LocationEntity>;
  bpx?: Maybe<Scalars['Int']>;
  brev?: Maybe<Scalars['Int']>;
  capacity?: Maybe<Scalars['Int']>;
  carCarrierId?: Maybe<Scalars['String']>;
  containerId?: Maybe<Scalars['String']>;
  costAllocation?: Maybe<CostAllocation>;
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  createdByUserId: Scalars['String'];
  currency: Scalars['String'];
  debits: Array<PeakPlanningDebit>;
  debitsCount: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy: Scalars['String'];
  departureAddress?: Maybe<LocationEntity>;
  departureLocation?: Maybe<LocationEntity>;
  deviations: Array<Deviation>;
  dispatcher?: Maybe<CardContact>;
  dispatcherId?: Maybe<Scalars['Int']>;
  driverName: Scalars['String'];
  driverPhoneNumber: Scalars['String'];
  externalNote?: Maybe<Scalars['String']>;
  files: Array<DocumentFile>;
  ftl?: Maybe<RouteFtl>;
  fuel: FuelType;
  id: Scalars['Int'];
  inneholdsbeskrivelse?: Maybe<Scalars['String']>;
  invoiceNote?: Maybe<Scalars['String']>;
  invoiceNumber?: Maybe<Scalars['String']>;
  isCopyDriverPlateInfo: Scalars['Boolean'];
  isCreatedFromPlanned: Scalars['Boolean'];
  isCreatedFromTerminal: Scalars['Boolean'];
  kilometer?: Maybe<Scalars['Int']>;
  lastUpdatedBy: Scalars['String'];
  legs: Array<RouteLeg>;
  licensePlate: Scalars['String'];
  multipleSporadicRouteId: Scalars['Int'];
  note: Scalars['String'];
  parti?: Maybe<Scalars['Int']>;
  peakPlanningItem?: Maybe<PeakPlanningItem>;
  pri03?: Maybe<Scalars['Int']>;
  pri49?: Maybe<Scalars['Int']>;
  pru?: Maybe<Scalars['Int']>;
  routeAgreementNo?: Maybe<Scalars['Int']>;
  routeCarRegNumber?: Maybe<Scalars['String']>;
  routeDriverName?: Maybe<Scalars['String']>;
  routeDriverPhoneNumber?: Maybe<Scalars['String']>;
  routeGroupTag?: Maybe<Scalars['String']>;
  routeId: Scalars['String'];
  routeLock?: Maybe<RouteLock>;
  routeTrailerRegNumber?: Maybe<Scalars['String']>;
  rutekode?: Maybe<Scalars['String']>;
  subcontractor: Subcontractor;
  subcontractorId: Scalars['Int'];
  togref?: Maybe<Scalars['String']>;
  tom?: Maybe<Scalars['Boolean']>;
  tour?: Maybe<TourTemplate>;
  tourRoute?: Maybe<TourRouteTemplate>;
  trailerCarrierId?: Maybe<Scalars['String']>;
  trainNumber?: Maybe<Scalars['Int']>;
  transportType?: Maybe<TransportType>;
  transportationDate: Scalars['String'];
  turnummer?: Maybe<Scalars['Int']>;
  type: RouteType;
  updatedAt: Scalars['DateTime'];
  upri03?: Maybe<Scalars['Int']>;
  upri49?: Maybe<Scalars['Int']>;
  weight?: Maybe<Scalars['Int']>;
};

export type RouteDetailsInLl = {
  __typename?: 'RouteDetailsInLL';
  id?: Maybe<Scalars['Int']>;
  routeName?: Maybe<Scalars['String']>;
};

export type RouteFtl = {
  __typename?: 'RouteFTL';
  cdc: Scalars['String'];
  cmr: Scalars['String'];
  createdAt: Scalars['DateTime'];
  currency: Scalars['String'];
  customer?: Maybe<Customer>;
  customerId?: Maybe<Scalars['Int']>;
  customerNumber: Scalars['String'];
  customerReference: Scalars['String'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  invoiceNote: Scalars['String'];
  price: Scalars['Float'];
  route: Route;
  updatedAt: Scalars['DateTime'];
};

export type RouteFtlInput = {
  cdc?: Maybe<Scalars['String']>;
  cmr?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['Int']>;
  customerNumber?: Maybe<Scalars['String']>;
  customerReference?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  invoiceNote?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
};

export type RouteFtlTemplate = {
  __typename?: 'RouteFTLTemplate';
  cdc: Scalars['String'];
  cmr: Scalars['String'];
  createdAt: Scalars['DateTime'];
  currency: Scalars['String'];
  customer: Customer;
  customerId?: Maybe<Scalars['Int']>;
  customerNumber: Scalars['String'];
  customerReference: Scalars['String'];
  id: Scalars['Int'];
  invoiceNote: Scalars['String'];
  price: Scalars['Float'];
  route: SporadicRouteTemplate;
  updatedAt: Scalars['DateTime'];
};

export type RouteFtlTemplateInput = {
  cdc: Scalars['String'];
  cmr: Scalars['String'];
  currency: Scalars['String'];
  customerId?: Maybe<Scalars['Int']>;
  customerNumber: Scalars['String'];
  customerReference: Scalars['String'];
  id?: Maybe<Scalars['Int']>;
  invoiceNote: Scalars['String'];
  price: Scalars['Float'];
};

export type RouteLeg = {
  __typename?: 'RouteLeg';
  actualArrivalDate?: Maybe<Scalars['String']>;
  actualArrivalTime?: Maybe<Scalars['String']>;
  actualCapacity?: Maybe<Scalars['Int']>;
  actualDepartureDate?: Maybe<Scalars['String']>;
  actualDepartureTime?: Maybe<Scalars['String']>;
  arrivalStatus?: Maybe<Scalars['String']>;
  arrivalTime: Scalars['String'];
  carRegistrationNumber?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  departureTime?: Maybe<Scalars['String']>;
  files: Array<DocumentFile>;
  fuel: FuelType;
  gateNumber?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isNonArrivalMarked: Scalars['Boolean'];
  isSealBroken?: Maybe<Scalars['Boolean']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  load: Scalars['Boolean'];
  loadCages?: Maybe<Scalars['Int']>;
  loadCar?: Maybe<Scalars['Int']>;
  loadCarPPL?: Maybe<Scalars['Int']>;
  loadTrailer?: Maybe<Scalars['Int']>;
  loadTrailerPPL?: Maybe<Scalars['Int']>;
  loadingListItems: Array<LoadingListItem>;
  location?: Maybe<LocationEntity>;
  locationId: Scalars['Int'];
  nonArrivalMarkedAt?: Maybe<Scalars['String']>;
  nonArrivalMarkedByUserName?: Maybe<Scalars['String']>;
  nonArrivalMarkedFrom?: Maybe<Scalars['String']>;
  note: Scalars['String'];
  position: Scalars['Int'];
  productionDate: Scalars['String'];
  productionGroup: Scalars['String'];
  route: Route;
  routeDriverName?: Maybe<Scalars['String']>;
  routeDriverPhoneNumber?: Maybe<Scalars['String']>;
  sealNumber?: Maybe<Scalars['String']>;
  terminalNickname?: Maybe<Scalars['String']>;
  terminalNote: Scalars['String'];
  tourRouteLeg?: Maybe<TourRouteLegTemplate>;
  trailerRegistrationNumber?: Maybe<Scalars['String']>;
  transportationDate: Scalars['String'];
  transportationDateOffset: Scalars['Int'];
  tripEndDateTime?: Maybe<Scalars['String']>;
  tripStartDateTime?: Maybe<Scalars['String']>;
  tripStatus?: Maybe<Scalars['String']>;
  unload: Scalars['Boolean'];
  unloadCages?: Maybe<Scalars['Int']>;
  unloadCar?: Maybe<Scalars['Int']>;
  unloadCarPPL?: Maybe<Scalars['Int']>;
  unloadTrailer?: Maybe<Scalars['Int']>;
  unloadTrailerPPL?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
};

export type RouteLegDataColumnInfo = {
  carRegistrationNumber?: Maybe<Scalars['String']>;
  trailerRegistrationNumber?: Maybe<Scalars['String']>;
};

export type RouteLegInput = {
  actualArrivalDate?: Maybe<Scalars['String']>;
  actualDepartureDate?: Maybe<Scalars['String']>;
  actualDepartureTime?: Maybe<Scalars['String']>;
  arrivalTime: Scalars['String'];
  carRegistrationNumber: Scalars['String'];
  departureTime?: Maybe<Scalars['String']>;
  fuel: FuelType;
  gateNumber: Scalars['String'];
  id?: Maybe<Scalars['Int']>;
  isNonArrivalMarked?: Maybe<Scalars['Boolean']>;
  load: Scalars['Boolean'];
  loadCar?: Maybe<Scalars['Int']>;
  loadTrailer?: Maybe<Scalars['Int']>;
  loadingListItems: Array<LoadingListItemInput>;
  locationId: Scalars['Int'];
  note: Scalars['String'];
  position: Scalars['Int'];
  productionDate: Scalars['String'];
  routeDriverName: Scalars['String'];
  routeDriverPhoneNumber: Scalars['String'];
  terminalNickname?: Maybe<Scalars['String']>;
  trailerRegistrationNumber: Scalars['String'];
  transportationDate: Scalars['String'];
  transportationDateOffset: Scalars['Int'];
  unload: Scalars['Boolean'];
};

export type RouteLegTemplateInput = {
  arrivalTime: Scalars['String'];
  carRegistrationNumber?: Maybe<Scalars['String']>;
  departureTime?: Maybe<Scalars['String']>;
  fuel: FuelType;
  gateNumber?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  load: Scalars['Boolean'];
  loadingListItems: Array<SporadicRouteLoadingListItemTemplateInput>;
  locationId: Scalars['Int'];
  note: Scalars['String'];
  position: Scalars['Int'];
  routeDriverName?: Maybe<Scalars['String']>;
  routeDriverPhoneNumber?: Maybe<Scalars['String']>;
  trailerRegistrationNumber?: Maybe<Scalars['String']>;
  transportationDateOffset: Scalars['Int'];
  unload: Scalars['Boolean'];
};

export type RouteLock = {
  __typename?: 'RouteLock';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  isChecklistLocked: Scalars['Boolean'];
  lockedAt?: Maybe<Scalars['DateTime']>;
  lockedBy: Scalars['String'];
  lockedByUserId?: Maybe<Scalars['String']>;
  route: Route;
  routeId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export enum RouteType {
  Fixed = 'Fixed',
  Sporadic = 'Sporadic'
}

export type SendDeviationInput = {
  customers: Array<Scalars['Int']>;
  englishMessageCustomers: Scalars['String'];
  id: Scalars['Int'];
  localMessageCustomers: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};

export type Shipment = {
  __typename?: 'Shipment';
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  createdFrom?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy: Scalars['String'];
  fromLocationId: Scalars['Int'];
  id: Scalars['Int'];
  lastUpdatedBy: Scalars['String'];
  loadingListItems: Array<LoadingListItem>;
  name: Scalars['String'];
  shipmentDate?: Maybe<Scalars['String']>;
  shipmentStatus?: Maybe<Scalars['String']>;
  toLocationId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  updatedFrom?: Maybe<Scalars['String']>;
};

export type ShipmentEditInput = {
  fromLocationId: Scalars['Int'];
  id: Scalars['Int'];
  loadingListItems: Array<EditShipmentLoadingList>;
  name: Scalars['String'];
  shipmentDate?: Maybe<Scalars['String']>;
  toLocationId: Scalars['Int'];
  updatedFrom?: Maybe<Scalars['String']>;
};

export type ShipmentLinkUnlinkSubscription = {
  __typename?: 'ShipmentLinkUnlinkSubscription';
  isLink: Scalars['Boolean'];
  routeId: Scalars['Float'];
  terminalId: Scalars['Float'];
  transDate: Scalars['String'];
};

export type ShipmentListUpdateSubscription = {
  __typename?: 'ShipmentListUpdateSubscription';
  shipmentDate: Scalars['String'];
};

export type ShipmentLoadingList = {
  checked?: Maybe<Scalars['Boolean']>;
  classification?: Maybe<DangerousGoodsClassification>;
  connectedNB?: Maybe<Scalars['String']>;
  customCleared?: Maybe<Scalars['Boolean']>;
  customClearedForImport?: Maybe<Scalars['Boolean']>;
  customCmrNo?: Maybe<Scalars['String']>;
  customInvoiceNo?: Maybe<Scalars['String']>;
  customMrnNo?: Maybe<Scalars['String']>;
  customNote?: Maybe<Scalars['String']>;
  customVOECId?: Maybe<Scalars['String']>;
  customWaybillNo?: Maybe<Scalars['String']>;
  customsProcedure?: Maybe<CustomsProcedure>;
  id: Scalars['Int'];
  isDangerous?: Maybe<Scalars['Boolean']>;
  isImportedFromShipment?: Maybe<Scalars['Boolean']>;
  isLimitedQty?: Maybe<Scalars['Boolean']>;
  isRemainingGoods?: Maybe<Scalars['Boolean']>;
  isVoecCustomer?: Maybe<Scalars['Boolean']>;
  locationId?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  packages?: Maybe<Scalars['Int']>;
  palletSpace?: Maybe<Scalars['Float']>;
  pallets?: Maybe<Scalars['Int']>;
  terminalCleared?: Maybe<Scalars['Boolean']>;
  terminalDevInfo?: Maybe<Scalars['String']>;
  terminalDevPackages?: Maybe<Scalars['Int']>;
  terminalDevPalletSpace?: Maybe<Scalars['Float']>;
  terminalDevPallets?: Maybe<Scalars['Int']>;
  terminalDevWeight?: Maybe<Scalars['Float']>;
  terminalNotCleared?: Maybe<Scalars['Boolean']>;
  terminalShipmentNotArrived?: Maybe<Scalars['Boolean']>;
  unNumber?: Maybe<Scalars['Int']>;
};

export type ShipmentPartialUpdateInput = {
  forFieldName: Scalars['String'];
  forFieldValue: Scalars['String'];
  orderId: Scalars['Int'];
  shipmentDate: Scalars['String'];
  shipmentId: Scalars['Int'];
};

export type ShipmentPartialUpdateSubscription = {
  __typename?: 'ShipmentPartialUpdateSubscription';
  forFieldName: Scalars['String'];
  forFieldValue: Scalars['String'];
  orderId: Scalars['Int'];
  shipmentId: Scalars['Int'];
  userId: Scalars['String'];
};

export type SporadicRouteInput = {
  agreedPrice?: Maybe<Scalars['Float']>;
  capacity?: Maybe<Scalars['Int']>;
  costAllocation?: Maybe<CostAllocationInput>;
  currency: Scalars['String'];
  deviations?: Maybe<Array<DeviationInput>>;
  dispatcherId?: Maybe<Scalars['Int']>;
  driverName: Scalars['String'];
  driverPhoneNumber: Scalars['String'];
  externalNote: Scalars['String'];
  files: Array<FileItemInput>;
  ftl?: Maybe<RouteFtlInput>;
  id?: Maybe<Scalars['Int']>;
  invoiceNote?: Maybe<Scalars['String']>;
  invoiceNumber?: Maybe<Scalars['String']>;
  isCopyDriverPlateInfo?: Maybe<Scalars['Boolean']>;
  isCreatedFromPlanned: Scalars['Boolean'];
  isCreatedFromTerminal: Scalars['Boolean'];
  kilometer?: Maybe<Scalars['Int']>;
  legs: Array<RouteLegInput>;
  licensePlate: Scalars['String'];
  note: Scalars['String'];
  routeCarRegNumber?: Maybe<Scalars['String']>;
  routeDriverName?: Maybe<Scalars['String']>;
  routeDriverPhoneNumber?: Maybe<Scalars['String']>;
  routeId: Scalars['String'];
  routeTrailerRegNumber?: Maybe<Scalars['String']>;
  subcontractorId: Scalars['Int'];
  transportationDate: Scalars['String'];
  weight?: Maybe<Scalars['Int']>;
};

export type SporadicRouteLegTemplate = {
  __typename?: 'SporadicRouteLegTemplate';
  arrivalTime: Scalars['String'];
  carRegistrationNumber?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  departureTime?: Maybe<Scalars['String']>;
  fuel: FuelType;
  gateNumber?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  load: Scalars['Boolean'];
  loadingListItems: Array<SporadicRouteLoadingListItemTemplate>;
  location: LocationEntity;
  locationId: Scalars['Int'];
  note: Scalars['String'];
  position: Scalars['Int'];
  routeDriverName?: Maybe<Scalars['String']>;
  routeDriverPhoneNumber?: Maybe<Scalars['String']>;
  trailerRegistrationNumber?: Maybe<Scalars['String']>;
  transportationDateOffset: Scalars['Int'];
  unload: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
};

export type SporadicRouteLoadingListItemTemplate = {
  __typename?: 'SporadicRouteLoadingListItemTemplate';
  checked: Scalars['Boolean'];
  classification?: Maybe<DangerousGoodsClassification>;
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  customer: Customer;
  deletedBy: Scalars['String'];
  id: Scalars['Int'];
  isDangerous: Scalars['Boolean'];
  isLimitedQty: Scalars['Boolean'];
  lastUpdatedBy: Scalars['String'];
  leg: SporadicRouteLegTemplate;
  locationId: Scalars['Int'];
  note: Scalars['String'];
  packages?: Maybe<Scalars['Int']>;
  palletSpace?: Maybe<Scalars['Float']>;
  pallets?: Maybe<Scalars['Int']>;
  unNumber?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
  weight?: Maybe<Scalars['Float']>;
};

export type SporadicRouteLoadingListItemTemplateInput = {
  checked: Scalars['Boolean'];
  classification?: Maybe<DangerousGoodsClassification>;
  customsProcedure?: Maybe<CustomsProcedure>;
  id?: Maybe<Scalars['Int']>;
  isDangerous: Scalars['Boolean'];
  isLimitedQty: Scalars['Boolean'];
  locationId: Scalars['Int'];
  note: Scalars['String'];
  packages?: Maybe<Scalars['Int']>;
  palletSpace?: Maybe<Scalars['Float']>;
  pallets?: Maybe<Scalars['Int']>;
  unNumber?: Maybe<Scalars['Int']>;
  weight?: Maybe<Scalars['Float']>;
};

export type SporadicRouteTemplate = {
  __typename?: 'SporadicRouteTemplate';
  agreedPrice?: Maybe<Scalars['Float']>;
  capacity?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  currency: Scalars['String'];
  deletedAt: Scalars['DateTime'];
  deletedBy: Scalars['String'];
  dispatcher?: Maybe<CardContact>;
  dispatcherId?: Maybe<Scalars['Int']>;
  driverName: Scalars['String'];
  driverPhoneNumber: Scalars['String'];
  externalNote?: Maybe<Scalars['String']>;
  files: Array<DocumentFile>;
  ftl?: Maybe<RouteFtlTemplate>;
  id: Scalars['Int'];
  kilometer?: Maybe<Scalars['Int']>;
  lastUpdatedBy: Scalars['String'];
  legs: Array<SporadicRouteLegTemplate>;
  licensePlate: Scalars['String'];
  name: Scalars['String'];
  note: Scalars['String'];
  routeId: Scalars['String'];
  subcontractor?: Maybe<Subcontractor>;
  subcontractorId?: Maybe<Scalars['Int']>;
  test?: Maybe<Scalars['String']>;
  test1?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  weight?: Maybe<Scalars['Int']>;
};

export type SporadicRouteTemplateInput = {
  agreedPrice?: Maybe<Scalars['Float']>;
  capacity?: Maybe<Scalars['Int']>;
  currency: Scalars['String'];
  dispatcherId?: Maybe<Scalars['Int']>;
  driverName: Scalars['String'];
  driverPhoneNumber: Scalars['String'];
  externalNote: Scalars['String'];
  files: Array<FileItemInput>;
  ftl?: Maybe<RouteFtlTemplateInput>;
  id?: Maybe<Scalars['Int']>;
  invoiceNote?: Maybe<Scalars['String']>;
  invoiceNumber?: Maybe<Scalars['String']>;
  kilometer?: Maybe<Scalars['Int']>;
  legs: Array<RouteLegTemplateInput>;
  licensePlate: Scalars['String'];
  name: Scalars['String'];
  note: Scalars['String'];
  routeId: Scalars['String'];
  subcontractorId?: Maybe<Scalars['Int']>;
  weight?: Maybe<Scalars['Int']>;
};

export type Subcontractor = {
  __typename?: 'Subcontractor';
  contacts: Array<CardContact>;
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  deletedAt: Scalars['DateTime'];
  deletedBy: Scalars['String'];
  files: Array<CardFile>;
  id: Scalars['Int'];
  lastUpdatedBy: Scalars['String'];
  locations: Array<LocationEntity>;
  name: Scalars['String'];
  notes: Array<CardNote>;
  updatedAt: Scalars['DateTime'];
};

export type Subscription = {
  __typename?: 'Subscription';
  checklistReset: ChecklistResetSubscription;
  routeLockPing: RouteLockPingSubscription;
  routeLockSub: RouteLockSubscription;
  shipmentLinkUnlinkSub: ShipmentLinkUnlinkSubscription;
  shipmentListUpdateSub: ShipmentListUpdateSubscription;
  shipmentPartialUpdateSub: ShipmentPartialUpdateSubscription;
  truckFillAndTimeUpdate: TruckFillAndTimeSubscription;
};


export type SubscriptionChecklistResetArgs = {
  forGroup: Scalars['String'];
  ownerUserId: Scalars['String'];
};


export type SubscriptionRouteLockPingArgs = {
  routeId: Scalars['Int'];
};


export type SubscriptionRouteLockSubArgs = {
  routeId: Scalars['Int'];
};


export type SubscriptionShipmentLinkUnlinkSubArgs = {
  terminalId: Scalars['Int'];
  transDate: Scalars['String'];
};


export type SubscriptionShipmentListUpdateSubArgs = {
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};


export type SubscriptionShipmentPartialUpdateSubArgs = {
  shipmentId: Scalars['Int'];
  userId: Scalars['String'];
};


export type SubscriptionTruckFillAndTimeUpdateArgs = {
  terminalId: Scalars['Int'];
};

export type Terminal = {
  __typename?: 'Terminal';
  contacts: Array<CardContact>;
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  deletedAt: Scalars['DateTime'];
  deletedBy: Scalars['String'];
  files: Array<CardFile>;
  id: Scalars['Int'];
  isInternalTerminal: Scalars['Boolean'];
  lastUpdatedBy: Scalars['String'];
  locations: Array<LocationEntity>;
  name: Scalars['String'];
  notes: Array<CardNote>;
  updatedAt: Scalars['DateTime'];
};

export type TourRouteCostAllocationItem = {
  __typename?: 'TourRouteCostAllocationItem';
  additionalCost: Scalars['Boolean'];
  additionalDiscount: Scalars['Boolean'];
  bringCost: Scalars['Boolean'];
  comment: Scalars['String'];
  cost: Scalars['Float'];
  deletedAt: Scalars['DateTime'];
  department: Department;
  departmentId: Scalars['String'];
  id: Scalars['Int'];
  includedKpi: Scalars['Boolean'];
  includedPrice: Scalars['Boolean'];
  type: CostAllocationItemType;
  updatedAt: Scalars['DateTime'];
};

export type TourRouteFtl = {
  __typename?: 'TourRouteFTL';
  cdc: Scalars['String'];
  cmr: Scalars['String'];
  createdAt: Scalars['DateTime'];
  currency: Scalars['String'];
  customer?: Maybe<Customer>;
  customerId?: Maybe<Scalars['Int']>;
  customerNumber: Scalars['String'];
  customerReference: Scalars['String'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  invoiceNote: Scalars['String'];
  price: Scalars['Float'];
  tourRoute: TourRouteTemplate;
  updatedAt: Scalars['DateTime'];
};

export type TourRouteFtlInput = {
  cdc?: Maybe<Scalars['String']>;
  cmr?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['Int']>;
  customerNumber?: Maybe<Scalars['String']>;
  customerReference?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  invoiceNote?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
};

export type TourRouteLegLoadingListItemTemplate = {
  __typename?: 'TourRouteLegLoadingListItemTemplate';
  checked: Scalars['Boolean'];
  classification?: Maybe<DangerousGoodsClassification>;
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  customer: Customer;
  deletedAt: Scalars['DateTime'];
  deletedBy: Scalars['String'];
  id: Scalars['Int'];
  isDangerous: Scalars['Boolean'];
  isLimitedQty: Scalars['Boolean'];
  lastUpdatedBy: Scalars['String'];
  leg: TourRouteLegTemplate;
  location?: Maybe<LocationEntity>;
  locationId: Scalars['Int'];
  note: Scalars['String'];
  packages?: Maybe<Scalars['Int']>;
  palletSpace?: Maybe<Scalars['Float']>;
  pallets?: Maybe<Scalars['Int']>;
  unNumber?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
  weight?: Maybe<Scalars['Float']>;
};

export type TourRouteLegLoadingListItemTemplateInput = {
  checked: Scalars['Boolean'];
  classification?: Maybe<DangerousGoodsClassification>;
  customsProcedure?: Maybe<CustomsProcedure>;
  id?: Maybe<Scalars['Int']>;
  isDangerous: Scalars['Boolean'];
  isLimitedQty: Scalars['Boolean'];
  locationId: Scalars['Int'];
  note: Scalars['String'];
  packages?: Maybe<Scalars['Int']>;
  palletSpace?: Maybe<Scalars['Float']>;
  pallets?: Maybe<Scalars['Int']>;
  unNumber?: Maybe<Scalars['Int']>;
  weight?: Maybe<Scalars['Float']>;
};

export type TourRouteLegTemplate = {
  __typename?: 'TourRouteLegTemplate';
  arrivalTime: Scalars['String'];
  carRegistrationNumber?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deletedAt: Scalars['DateTime'];
  departureTime?: Maybe<Scalars['String']>;
  fuel: FuelType;
  gateNumber?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  kpi: KpiReportItem;
  load: Scalars['Boolean'];
  loadingListItems: Array<TourRouteLegLoadingListItemTemplate>;
  location: LocationEntity;
  locationId: Scalars['Int'];
  note: Scalars['String'];
  position: Scalars['Int'];
  routeDriverName?: Maybe<Scalars['String']>;
  routeDriverPhoneNumber?: Maybe<Scalars['String']>;
  trailerRegistrationNumber?: Maybe<Scalars['String']>;
  transportationDateOffset: Scalars['Int'];
  unload: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
};

export type TourRouteLegTemplateInput = {
  arrivalTime: Scalars['String'];
  carRegistrationNumber: Scalars['String'];
  departureTime?: Maybe<Scalars['String']>;
  fuel: FuelType;
  gateNumber: Scalars['String'];
  id?: Maybe<Scalars['Int']>;
  load: Scalars['Boolean'];
  loadingListItems: Array<TourRouteLegLoadingListItemTemplateInput>;
  locationId: Scalars['Int'];
  note: Scalars['String'];
  position: Scalars['Int'];
  routeDriverName?: Maybe<Scalars['String']>;
  routeDriverPhoneNumber?: Maybe<Scalars['String']>;
  trailerRegistrationNumber: Scalars['String'];
  transportationDateOffset: Scalars['Int'];
  unload: Scalars['Boolean'];
};

export type TourRouteTemplate = {
  __typename?: 'TourRouteTemplate';
  arrivalLocation?: Maybe<LocationEntity>;
  bookmarkTourRouteTemplate?: Maybe<Array<BookmarkTourRouteTemplate>>;
  capacity?: Maybe<Scalars['Int']>;
  costAllocations: Array<TourRouteCostAllocationItem>;
  createdAt: Scalars['DateTime'];
  days: Array<Days>;
  deletedAt: Scalars['DateTime'];
  departureLocation?: Maybe<LocationEntity>;
  endDate?: Maybe<Scalars['String']>;
  endExceptionDate?: Maybe<Scalars['String']>;
  exceptionDates?: Maybe<Array<Scalars['String']>>;
  externalNote?: Maybe<Scalars['String']>;
  files: Array<DocumentFile>;
  ftl?: Maybe<TourRouteFtl>;
  id: Scalars['Int'];
  isCopyDriverPlateInfo: Scalars['Boolean'];
  kilometer?: Maybe<Scalars['Int']>;
  legs: Array<TourRouteLegTemplate>;
  note: Scalars['String'];
  originalStartDate: Scalars['String'];
  position: Scalars['Int'];
  price: Scalars['Float'];
  routeCarRegNumber?: Maybe<Scalars['String']>;
  routeDateType?: Maybe<Scalars['String']>;
  routeDriverName?: Maybe<Scalars['String']>;
  routeDriverPhoneNumber?: Maybe<Scalars['String']>;
  routeId: Scalars['String'];
  routeTrailerRegNumber?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  startExceptionDate?: Maybe<Scalars['String']>;
  tour: TourTemplate;
  tourId: Scalars['Int'];
  tourRouteType: TourRouteType;
  updatedAt: Scalars['DateTime'];
  weight?: Maybe<Scalars['Int']>;
};

export type TourRouteTemplateFileItemInput = {
  id: Scalars['Float'];
};

export type TourRouteTemplateInput = {
  capacity?: Maybe<Scalars['Int']>;
  costAllocations: Array<CostAllocationItemInput>;
  days: Array<Days>;
  endDate?: Maybe<Scalars['String']>;
  endExceptionDate?: Maybe<Scalars['String']>;
  exceptionDates?: Maybe<Array<Scalars['String']>>;
  externalNote: Scalars['String'];
  files: Array<TourRouteTemplateFileItemInput>;
  ftl?: Maybe<TourRouteFtlInput>;
  id?: Maybe<Scalars['Int']>;
  isCopyDriverPlateInfo?: Maybe<Scalars['Boolean']>;
  kilometer?: Maybe<Scalars['Int']>;
  legs: Array<TourRouteLegTemplateInput>;
  note: Scalars['String'];
  position: Scalars['Int'];
  price: Scalars['Float'];
  routeCarRegNumber?: Maybe<Scalars['String']>;
  routeDateType?: Maybe<Scalars['String']>;
  routeDriverName?: Maybe<Scalars['String']>;
  routeDriverPhoneNumber?: Maybe<Scalars['String']>;
  routeId: Scalars['String'];
  routeTrailerRegNumber?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  startExceptionDate?: Maybe<Scalars['String']>;
  tourRouteType: TourRouteType;
  weight?: Maybe<Scalars['Int']>;
};

export enum TourRouteType {
  Fixed = 'Fixed',
  MultipleSporadic = 'MultipleSporadic',
  Old = 'Old',
  SingleSporadic = 'SingleSporadic'
}

export type TourTemplate = {
  __typename?: 'TourTemplate';
  capacity?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  currency: Scalars['String'];
  deletedAt: Scalars['DateTime'];
  deletedBy: Scalars['String'];
  dispatcher?: Maybe<CardContact>;
  dispatcherId?: Maybe<Scalars['Int']>;
  driverName: Scalars['String'];
  driverPhoneNumber: Scalars['String'];
  endDate?: Maybe<Scalars['String']>;
  endExceptionDate?: Maybe<Scalars['String']>;
  exceptionDates?: Maybe<Array<Scalars['String']>>;
  fuel: FuelType;
  groupingTag?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isActive: Scalars['Boolean'];
  isNewVersion: Scalars['Boolean'];
  isPriceEquallyDivided: Scalars['Boolean'];
  lastUpdatedBy: Scalars['String'];
  licensePlate: Scalars['String'];
  name: Scalars['String'];
  note: Scalars['String'];
  originalStartDate?: Maybe<Scalars['String']>;
  price: Scalars['Float'];
  responsiblePerson?: Maybe<Scalars['String']>;
  routes: Array<TourRouteTemplate>;
  startDate: Scalars['String'];
  startExceptionDate?: Maybe<Scalars['String']>;
  subcontractor: Subcontractor;
  subcontractorId: Scalars['Int'];
  tourAgreementNo?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
};

export type TourTemplateInput = {
  currency: Scalars['String'];
  dispatcherId?: Maybe<Scalars['Int']>;
  driverName: Scalars['String'];
  driverPhoneNumber: Scalars['String'];
  groupingTag?: Maybe<Scalars['String']>;
  isPriceEquallyDivided?: Maybe<Scalars['Boolean']>;
  licensePlate: Scalars['String'];
  name: Scalars['String'];
  note: Scalars['String'];
  price: Scalars['Float'];
  responsiblePerson?: Maybe<Scalars['String']>;
  routes: Array<TourRouteTemplateInput>;
  subcontractorId: Scalars['Int'];
};

export type TruckFillAndTimeInput = {
  actualArrivalDate?: Maybe<Scalars['String']>;
  actualArrivalTime?: Maybe<Scalars['String']>;
  actualCapacity?: Maybe<Scalars['Int']>;
  actualDepartureDate?: Maybe<Scalars['String']>;
  actualDepartureTime?: Maybe<Scalars['String']>;
  carRegistrationNumber?: Maybe<Scalars['String']>;
  gateNumber?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isNonArrivalMarked?: Maybe<Scalars['Boolean']>;
  isSealBroken?: Maybe<Scalars['Boolean']>;
  loadCages?: Maybe<Scalars['Int']>;
  loadCar?: Maybe<Scalars['Int']>;
  loadCarPPL?: Maybe<Scalars['Int']>;
  loadTrailer?: Maybe<Scalars['Int']>;
  loadTrailerPPL?: Maybe<Scalars['Int']>;
  productionGroup?: Maybe<Scalars['String']>;
  sealNumber?: Maybe<Scalars['String']>;
  terminalId: Scalars['Int'];
  terminalNickname?: Maybe<Scalars['String']>;
  terminalNote?: Maybe<Scalars['String']>;
  trailerRegistrationNumber?: Maybe<Scalars['String']>;
  unloadCages?: Maybe<Scalars['Int']>;
  unloadCar?: Maybe<Scalars['Int']>;
  unloadCarPPL?: Maybe<Scalars['Int']>;
  unloadTrailer?: Maybe<Scalars['Int']>;
  unloadTrailerPPL?: Maybe<Scalars['Int']>;
};

export type TruckFillAndTimeSubscription = {
  __typename?: 'TruckFillAndTimeSubscription';
  refreshWholeGrid?: Maybe<Scalars['Boolean']>;
  routeLeg: RouteLeg;
  terminalId: Scalars['Int'];
};

export type UpdateBookMarkResponse = {
  __typename?: 'UpdateBookMarkResponse';
  bookmark: Scalars['Boolean'];
  id: Scalars['Int'];
};

export type UpdateCardContactInput = {
  email?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  tagIds: Array<Scalars['Int']>;
};

export type UpdateCheckpointInput = {
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type UpdateCostAllocationInput = {
  items: Array<CostAllocationItemInput>;
};

export type UpdateCustomerAccountInput = {
  accountNumber: Scalars['String'];
  id: Scalars['Int'];
  logisticPersonName?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  salesPersonName?: Maybe<Scalars['String']>;
};

export type UpdateCustomerInput = {
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type UpdateLocationInput = {
  address: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  id: Scalars['Int'];
  locationShortCode: Scalars['String'];
  name: Scalars['String'];
  phLocationId: Scalars['String'];
  postalCode: Scalars['String'];
};

export type UpdateMinimalRouteInput = {
  routes: Array<ChecklistRoutes>;
};

export type UpdateNoteInput = {
  id: Scalars['Float'];
  text: Scalars['String'];
  title: Scalars['String'];
};

export type UpdateSubcontractorInput = {
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type UpdateTerminalInput = {
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  bookmarkTourRouteTemplate?: Maybe<Array<BookmarkTourRouteTemplate>>;
};

export type UserConfigurationInput = {
  configuration: Scalars['JSONObject'];
};

export type ValueBoxForDayResponse = {
  __typename?: 'ValueBoxForDayResponse';
  boxId: Scalars['Int'];
  boxNameText: Scalars['String'];
  boxValue: Scalars['Int'];
  entryId: Scalars['Int'];
  isAddedFromTerminal: Scalars['Boolean'];
  isFixedBox: Scalars['Boolean'];
  isReadOnly: Scalars['Boolean'];
  isTimeBox: Scalars['Boolean'];
  isYesNoBox: Scalars['Boolean'];
};

export type ChecklistResetSubscription = {
  __typename?: 'checklistResetSubscription';
  forGroup: Scalars['String'];
  ownerUserId: Scalars['String'];
};

export type ExtraColumnInfo = {
  bpx?: Maybe<Scalars['Int']>;
  brev?: Maybe<Scalars['Int']>;
  containerId?: Maybe<Scalars['String']>;
  inneholdsbeskrivelse?: Maybe<Scalars['String']>;
  parti?: Maybe<Scalars['Int']>;
  pri03?: Maybe<Scalars['Int']>;
  pri49?: Maybe<Scalars['Int']>;
  pru?: Maybe<Scalars['Int']>;
  rutekode?: Maybe<Scalars['String']>;
  togref?: Maybe<Scalars['String']>;
  tom?: Maybe<Scalars['Boolean']>;
  trainNumber?: Maybe<Scalars['Int']>;
  transportType?: Maybe<TransportType>;
  turnummer?: Maybe<Scalars['Int']>;
  upri03?: Maybe<Scalars['Int']>;
  upri49?: Maybe<Scalars['Int']>;
};

export type RouteLockPingSubscription = {
  __typename?: 'routeLockPingSubscription';
  lockedByUserId: Scalars['String'];
  pingedByUserName: Scalars['String'];
  routeId: Scalars['Float'];
};

export type RouteLockSubscription = {
  __typename?: 'routeLockSubscription';
  lock: Scalars['Float'];
  lockedAt: Scalars['String'];
  lockedBy: Scalars['String'];
  reload: Scalars['Float'];
  routeId: Scalars['Float'];
};

export enum TransportType {
  Car = 'Car',
  Module = 'Module',
  Trailer = 'Trailer',
  Train = 'Train'
}

export type CardContactFieldsFragment = (
  { __typename?: 'CardContact' }
  & Pick<CardContact, 'id' | 'name' | 'role' | 'email' | 'phoneNumber' | 'note' | 'lastUpdatedBy' | 'createdBy'>
  & { tags: Array<(
    { __typename?: 'CardContactTag' }
    & Pick<CardContactTag, 'id' | 'title'>
  )> }
);

export type CustomerAccountFieldsFragment = (
  { __typename?: 'CustomerAccount' }
  & Pick<CustomerAccount, 'id' | 'accountNumber' | 'salesPersonName' | 'logisticPersonName' | 'note' | 'lastUpdatedBy' | 'updatedAt' | 'createdBy'>
);

export type LiveUpdateRouteLegFragment = (
  { __typename?: 'RouteLeg' }
  & Pick<RouteLeg, 'id' | 'fuel' | 'arrivalTime' | 'actualArrivalDate' | 'departureTime' | 'actualDepartureDate' | 'load' | 'unload' | 'loadCar' | 'loadCarPPL' | 'loadCages' | 'loadTrailer' | 'loadTrailerPPL' | 'unloadCar' | 'unloadCarPPL' | 'unloadCages' | 'unloadTrailer' | 'unloadTrailerPPL' | 'actualDepartureTime' | 'actualArrivalTime' | 'terminalNote' | 'isNonArrivalMarked' | 'actualCapacity' | 'lastUpdatedBy' | 'updatedAt' | 'transportationDate' | 'productionDate' | 'gateNumber' | 'carRegistrationNumber' | 'trailerRegistrationNumber' | 'routeDriverName' | 'routeDriverPhoneNumber' | 'isSealBroken' | 'sealNumber' | 'terminalNickname' | 'productionGroup' | 'note' | 'arrivalStatus'>
  & { files: Array<(
    { __typename?: 'DocumentFile' }
    & Pick<DocumentFile, 'id' | 'originalname' | 'mimetype' | 'path' | 'size'>
  )> }
);

export type LocationsFieldsFragment = (
  { __typename?: 'LocationEntity' }
  & Pick<LocationEntity, 'id' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'phLocationId' | 'locationShortCode' | 'countryLong' | 'createdAt' | 'updatedAt' | 'lastUpdatedBy' | 'createdBy'>
);

export type TransportAgreementFieldsFragment = (
  { __typename?: 'Route' }
  & Pick<Route, 'id' | 'routeId' | 'transportationDate' | 'capacity' | 'kilometer' | 'weight' | 'currency' | 'agreedPrice' | 'note' | 'createdBy' | 'licensePlate'>
  & { subcontractor: (
    { __typename?: 'Subcontractor' }
    & Pick<Subcontractor, 'name'>
  ), dispatcher?: Maybe<(
    { __typename?: 'CardContact' }
    & Pick<CardContact, 'name'>
  )>, files: Array<(
    { __typename?: 'DocumentFile' }
    & Pick<DocumentFile, 'path'>
  )>, legs: Array<(
    { __typename?: 'RouteLeg' }
    & Pick<RouteLeg, 'arrivalTime' | 'departureTime' | 'transportationDate' | 'transportationDateOffset' | 'carRegistrationNumber' | 'trailerRegistrationNumber' | 'fuel' | 'note' | 'position'>
    & { location?: Maybe<(
      { __typename?: 'LocationEntity' }
      & Pick<LocationEntity, 'name' | 'address' | 'country' | 'postalCode' | 'city' | 'deletedBy'>
    )> }
  )> }
);

export type CreateCardContactMutationVariables = Exact<{
  cardId: Scalars['Int'];
  input: CreateCardContactInput;
}>;


export type CreateCardContactMutation = (
  { __typename?: 'Mutation' }
  & { createCardContact: (
    { __typename?: 'CardContact' }
    & Pick<CardContact, 'id' | 'name' | 'email' | 'phoneNumber' | 'updatedAt' | 'createdAt' | 'note' | 'lastUpdatedBy' | 'createdBy'>
    & { tags: Array<(
      { __typename?: 'CardContactTag' }
      & Pick<CardContactTag, 'id' | 'title'>
    )> }
  ) }
);

export type DeleteCardContactMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteCardContactMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCardContact'>
);

export type UpdateContactMutationVariables = Exact<{
  input: UpdateCardContactInput;
}>;


export type UpdateContactMutation = (
  { __typename?: 'Mutation' }
  & { updateCardContact: (
    { __typename?: 'CardContact' }
    & Pick<CardContact, 'id' | 'name' | 'email' | 'phoneNumber' | 'note' | 'lastUpdatedBy'>
    & { tags: Array<(
      { __typename?: 'CardContactTag' }
      & Pick<CardContactTag, 'id' | 'title'>
    )> }
  ) }
);

export type CreateContactTagMutationVariables = Exact<{
  input: CreateCardContactTagInput;
}>;


export type CreateContactTagMutation = (
  { __typename?: 'Mutation' }
  & { createContactTag: (
    { __typename?: 'CardContactTag' }
    & Pick<CardContactTag, 'id'>
  ) }
);

export type DeleteContactTagMutationVariables = Exact<{
  tagId: Scalars['Int'];
}>;


export type DeleteContactTagMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteContactTag'>
);

export type AddCardLocationMutationVariables = Exact<{
  id: Scalars['Int'];
  locationInput: CreateLocationInput;
}>;


export type AddCardLocationMutation = (
  { __typename?: 'Mutation' }
  & { addCardLocation: (
    { __typename?: 'Card' }
    & Pick<Card, 'id'>
    & { locations: Array<(
      { __typename?: 'LocationEntity' }
      & LocationsFieldsFragment
    )> }
  ) }
);

export type DeleteLocationMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteLocationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteLocation'>
);

export type CreateCustomerAccountMutationVariables = Exact<{
  cardId: Scalars['Int'];
  input: CreateCustomerAccountInput;
}>;


export type CreateCustomerAccountMutation = (
  { __typename?: 'Mutation' }
  & { createCustomerAccount: (
    { __typename?: 'CustomerAccount' }
    & CustomerAccountFieldsFragment
  ) }
);

export type DeleteCustomerAccountMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteCustomerAccountMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCustomerAccount'>
);

export type UpdateCustomerAccountMutationVariables = Exact<{
  input: UpdateCustomerAccountInput;
}>;


export type UpdateCustomerAccountMutation = (
  { __typename?: 'Mutation' }
  & { updateCustomerAccount: (
    { __typename?: 'CustomerAccount' }
    & CustomerAccountFieldsFragment
  ) }
);

export type DeleteCardFileMutationVariables = Exact<{
  deleteCardFileId: Scalars['Int'];
}>;


export type DeleteCardFileMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCardFile'>
);

export type AddCardNoteMutationVariables = Exact<{
  cardId: Scalars['Int'];
  input: CreateNoteInput;
}>;


export type AddCardNoteMutation = (
  { __typename?: 'Mutation' }
  & { addCardNote: (
    { __typename?: 'Card' }
    & Pick<Card, 'id'>
    & { notes: Array<(
      { __typename?: 'CardNote' }
      & Pick<CardNote, 'id' | 'title' | 'text'>
    )> }
  ) }
);

export type DeleteCardNoteMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteCardNoteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCardNote'>
);

export type UpdateCardNoteMutationVariables = Exact<{
  input: UpdateNoteInput;
}>;


export type UpdateCardNoteMutation = (
  { __typename?: 'Mutation' }
  & { updateCardNote: (
    { __typename?: 'CardNote' }
    & Pick<CardNote, 'id' | 'title' | 'text'>
  ) }
);

export type CreateCheckpointMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type CreateCheckpointMutation = (
  { __typename?: 'Mutation' }
  & { createCheckpoint: (
    { __typename?: 'Checkpoint' }
    & Pick<Checkpoint, 'name' | 'id'>
  ) }
);

export type DeleteCheckpointMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteCheckpointMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCheckpoint'>
);

export type ImportCheckpointsMutationVariables = Exact<{
  input: Array<ExcelImportDataInput> | ExcelImportDataInput;
}>;


export type ImportCheckpointsMutation = (
  { __typename?: 'Mutation' }
  & { importCheckpoints: Array<(
    { __typename?: 'Checkpoint' }
    & Pick<Checkpoint, 'id' | 'name'>
  )> }
);

export type UpdateCheckpointMutationVariables = Exact<{
  name: Scalars['String'];
  id: Scalars['Int'];
}>;


export type UpdateCheckpointMutation = (
  { __typename?: 'Mutation' }
  & { updateCheckpoint: (
    { __typename?: 'Checkpoint' }
    & Pick<Checkpoint, 'id' | 'name'>
  ) }
);

export type AddCustomerLocationMutationVariables = Exact<{
  id: Scalars['Int'];
  locationInput: CreateLocationInput;
}>;


export type AddCustomerLocationMutation = (
  { __typename?: 'Mutation' }
  & { addCustomerCardLocation: (
    { __typename?: 'Customer' }
    & Pick<Customer, 'id'>
    & { locations: Array<(
      { __typename?: 'LocationEntity' }
      & LocationsFieldsFragment
    )> }
  ) }
);

export type CreateCustomerMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type CreateCustomerMutation = (
  { __typename?: 'Mutation' }
  & { createCustomer: (
    { __typename?: 'Customer' }
    & Pick<Customer, 'name' | 'id'>
  ) }
);

export type DeleteCustomerMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteCustomerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCustomer'>
);

export type ImportCustomersMutationVariables = Exact<{
  input: Array<ExcelImportDataInput> | ExcelImportDataInput;
}>;


export type ImportCustomersMutation = (
  { __typename?: 'Mutation' }
  & { importCustomers: Array<(
    { __typename?: 'Customer' }
    & Pick<Customer, 'id' | 'name'>
  )> }
);

export type UpdateCustomerMutationVariables = Exact<{
  name: Scalars['String'];
  id: Scalars['Int'];
}>;


export type UpdateCustomerMutation = (
  { __typename?: 'Mutation' }
  & { updateCustomer: (
    { __typename?: 'Customer' }
    & Pick<Customer, 'id' | 'name'>
  ) }
);

export type CreateDeviationMutationVariables = Exact<{
  input: DeviationInput;
}>;


export type CreateDeviationMutation = (
  { __typename?: 'Mutation' }
  & { createDeviation: (
    { __typename?: 'Deviation' }
    & Pick<Deviation, 'id' | 'cause' | 'fault' | 'routeId' | 'note' | 'consequence' | 'actualArrivalTime' | 'createdBy'>
    & { faultLocation?: Maybe<(
      { __typename?: 'LocationEntity' }
      & Pick<LocationEntity, 'id' | 'name' | 'address' | 'postalCode' | 'city' | 'country'>
      & { card: (
        { __typename?: 'Card' }
        & Pick<Card, 'name'>
      ) }
    )> }
  ) }
);

export type CreateLocationDeviationMutationVariables = Exact<{
  input: Array<LocationDeviationInput> | LocationDeviationInput;
}>;


export type CreateLocationDeviationMutation = (
  { __typename?: 'Mutation' }
  & { createLocationDeviation: Array<(
    { __typename?: 'Deviation' }
    & Pick<Deviation, 'id'>
  )> }
);

export type DeleteDeviationMutationVariables = Exact<{
  id: Scalars['Int'];
  customerId?: Maybe<Scalars['Int']>;
}>;


export type DeleteDeviationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteDeviation'>
);

export type EditDeviationMutationVariables = Exact<{
  input: EditDeviationInput;
}>;


export type EditDeviationMutation = (
  { __typename?: 'Mutation' }
  & { editDeviation: (
    { __typename?: 'Deviation' }
    & Pick<Deviation, 'id' | 'cause' | 'fault' | 'routeId' | 'note' | 'consequence' | 'actualArrivalTime' | 'createdBy'>
    & { faultLocation?: Maybe<(
      { __typename?: 'LocationEntity' }
      & Pick<LocationEntity, 'id' | 'name' | 'address' | 'postalCode' | 'city' | 'country'>
      & { card: (
        { __typename?: 'Card' }
        & Pick<Card, 'name'>
      ) }
    )> }
  ) }
);

export type EditLocationDeviationMutationVariables = Exact<{
  input: EditLocationDeviationInput;
}>;


export type EditLocationDeviationMutation = (
  { __typename?: 'Mutation' }
  & { editLocationDeviation: (
    { __typename?: 'Deviation' }
    & Pick<Deviation, 'id'>
  ) }
);

export type SendDeviationMutationVariables = Exact<{
  input: SendDeviationInput;
}>;


export type SendDeviationMutation = (
  { __typename?: 'Mutation' }
  & { sendDeviation: (
    { __typename?: 'Deviation' }
    & Pick<Deviation, 'id' | 'localMessageCustomers' | 'englishMessageCustomers' | 'emailSent'>
  ) }
);

export type DeleteAttachmentFileMutationVariables = Exact<{
  input: DeleteAttachmentFileDto;
}>;


export type DeleteAttachmentFileMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAttachmentFile'>
);

export type DeleteDocumentFileMutationVariables = Exact<{
  deleteDocumentFileId: Scalars['Int'];
}>;


export type DeleteDocumentFileMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteDocumentFile'>
);

export type AddHolidayMutationVariables = Exact<{
  input: CreateHolidayInput;
}>;


export type AddHolidayMutation = (
  { __typename?: 'Mutation' }
  & { addHoliday: (
    { __typename?: 'Holiday' }
    & Pick<Holiday, 'id' | 'date' | 'title' | 'createdBy' | 'createdAt'>
  ) }
);

export type DeleteHolidayMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteHolidayMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteHoliday'>
);

export type AddEditValueBoxEntryMutationVariables = Exact<{
  boxEntry: EditValueBoxEntry;
}>;


export type AddEditValueBoxEntryMutation = (
  { __typename?: 'Mutation' }
  & { editValueBoxEntry: (
    { __typename?: 'InternalTerminalValueBoxEntries' }
    & Pick<InternalTerminalValueBoxEntries, 'id'>
  ) }
);

export type AddFixedValueBoxesMutationVariables = Exact<{
  internalTerminalId: Scalars['Int'];
}>;


export type AddFixedValueBoxesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addFixedValueBoxes'>
);

export type AddGridValueMutationVariables = Exact<{
  gridValue: AddGridValueInput;
}>;


export type AddGridValueMutation = (
  { __typename?: 'Mutation' }
  & { addGridValue: (
    { __typename?: 'InternalTerminalGridEntries' }
    & Pick<InternalTerminalGridEntries, 'id'>
  ) }
);

export type AddInternalTerminalReportInfoMutationVariables = Exact<{
  input: InternalTerminalReportInfoInput;
}>;


export type AddInternalTerminalReportInfoMutation = (
  { __typename?: 'Mutation' }
  & { addInternalTerminalReportInfo: (
    { __typename?: 'InternalTerminal' }
    & Pick<InternalTerminal, 'id'>
  ) }
);

export type AddMasterGridMutationVariables = Exact<{
  gridData: AddMasterGridDataInput;
}>;


export type AddMasterGridMutation = (
  { __typename?: 'Mutation' }
  & { addMasterGridBox: (
    { __typename?: 'InternalTerminal' }
    & Pick<InternalTerminal, 'id'>
  ) }
);

export type AddMasterValueBoxMutationVariables = Exact<{
  boxData: AddMasterValueBoxInput;
}>;


export type AddMasterValueBoxMutation = (
  { __typename?: 'Mutation' }
  & { addMasterValueBox: (
    { __typename?: 'InternalTerminal' }
    & Pick<InternalTerminal, 'id'>
  ) }
);

export type DeleteInternalTerminalReportInfoMutationVariables = Exact<{
  internalTerminalReportInfoId: Scalars['Float'];
}>;


export type DeleteInternalTerminalReportInfoMutation = (
  { __typename?: 'Mutation' }
  & { deleteInternalTerminalReportInfo: (
    { __typename?: 'DeleteResult' }
    & Pick<DeleteResult, 'result'>
  ) }
);

export type DeleteMasterGridMutationVariables = Exact<{
  Id: Scalars['Int'];
}>;


export type DeleteMasterGridMutation = (
  { __typename?: 'Mutation' }
  & { deleteMasterGrid: (
    { __typename?: 'DeleteResult' }
    & Pick<DeleteResult, 'result'>
  ) }
);

export type DeleteMasterValueBoxMutationVariables = Exact<{
  boxId: Scalars['Int'];
}>;


export type DeleteMasterValueBoxMutation = (
  { __typename?: 'Mutation' }
  & { deleteMasterValueBox: (
    { __typename?: 'DeleteResult' }
    & Pick<DeleteResult, 'result'>
  ) }
);

export type DeleteValueBoxEntryMutationVariables = Exact<{
  entryId: Scalars['Int'];
}>;


export type DeleteValueBoxEntryMutation = (
  { __typename?: 'Mutation' }
  & { deleteValueBoxEntry: (
    { __typename?: 'DeleteResult' }
    & Pick<DeleteResult, 'result'>
  ) }
);

export type DemoteTerminalMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DemoteTerminalMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'demoteInternalTerminal'>
);

export type EditInternalTerminalReportInfoMutationVariables = Exact<{
  input: EditInternalTerminalReportInfoInput;
}>;


export type EditInternalTerminalReportInfoMutation = (
  { __typename?: 'Mutation' }
  & { editInternalTerminalReportInfo: (
    { __typename?: 'InternalTerminalReportInfo' }
    & Pick<InternalTerminalReportInfo, 'id'>
  ) }
);

export type EditMasterValueBoxMutationVariables = Exact<{
  boxData: EditMasterValueBoxInput;
}>;


export type EditMasterValueBoxMutation = (
  { __typename?: 'Mutation' }
  & { editMasterValueBox: (
    { __typename?: 'InternalTerminalValueBoxMaster' }
    & Pick<InternalTerminalValueBoxMaster, 'id'>
  ) }
);

export type EditReportInfoReplyMutationVariables = Exact<{
  input: EditInternalTerminalReportInfoReplyInput;
}>;


export type EditReportInfoReplyMutation = (
  { __typename?: 'Mutation' }
  & { editReportInfoReply: (
    { __typename?: 'InternalTerminalReportInfo' }
    & Pick<InternalTerminalReportInfo, 'id'>
  ) }
);

export type PromoteTerminalMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type PromoteTerminalMutation = (
  { __typename?: 'Mutation' }
  & { promoteToInternalTerminal: (
    { __typename?: 'InternalTerminal' }
    & Pick<InternalTerminal, 'id' | 'terminalId' | 'departmentId'>
  ) }
);

export type GetMasterGridQueryVariables = Exact<{
  internalTerminalId: Scalars['Int'];
}>;


export type GetMasterGridQuery = (
  { __typename?: 'Query' }
  & { getMasterGrid: Array<(
    { __typename?: 'MasterGridResponse' }
    & Pick<MasterGridResponse, 'id' | 'internalTerminalId' | 'columnHeaders' | 'rowHeaders' | 'gridName'>
  )> }
);

export type ReportMailMutationVariables = Exact<{
  terminalName: Scalars['String'];
  mailIds: Array<Scalars['String']> | Scalars['String'];
  pdfAttachment: Scalars['String'];
  excelSheetAttachment?: Maybe<Scalars['String']>;
}>;


export type ReportMailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'reportMail'>
);

export type LinkUnlinkLegWithShipmentMutationVariables = Exact<{
  input: OrderLinkUnlinkInput;
}>;


export type LinkUnlinkLegWithShipmentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'linkOrUnlinkOrderInLeg'>
);

export type TruckFillAndTimeMutationVariables = Exact<{
  truckFillAndTimeInput: TruckFillAndTimeInput;
}>;


export type TruckFillAndTimeMutation = (
  { __typename?: 'Mutation' }
  & { truckFillAndTime: (
    { __typename?: 'RouteLeg' }
    & Pick<RouteLeg, 'id' | 'loadCar' | 'loadTrailer' | 'loadCarPPL' | 'loadTrailerPPL' | 'unloadCar' | 'unloadTrailer' | 'unloadCarPPL' | 'unloadTrailerPPL' | 'actualDepartureTime' | 'actualDepartureDate' | 'actualArrivalTime' | 'actualArrivalDate' | 'terminalNote' | 'lastUpdatedBy' | 'updatedAt' | 'gateNumber' | 'carRegistrationNumber' | 'trailerRegistrationNumber' | 'terminalNickname' | 'isNonArrivalMarked' | 'actualCapacity' | 'sealNumber' | 'isSealBroken' | 'productionGroup' | 'arrivalStatus'>
    & { files: Array<(
      { __typename?: 'DocumentFile' }
      & Pick<DocumentFile, 'id' | 'originalname' | 'mimetype' | 'path' | 'size'>
    )> }
  ) }
);

export type UpdateLegWithLoadingListMutationVariables = Exact<{
  input: LegLoadingListInput;
}>;


export type UpdateLegWithLoadingListMutation = (
  { __typename?: 'Mutation' }
  & { updateLegWithLoadingList: (
    { __typename?: 'RouteLeg' }
    & Pick<RouteLeg, 'id'>
    & { loadingListItems: Array<(
      { __typename?: 'LoadingListItem' }
      & Pick<LoadingListItem, 'id' | 'locationId' | 'packages' | 'pallets' | 'palletSpace' | 'weight' | 'checked' | 'isDangerous' | 'classification' | 'unNumber' | 'isLimitedQty' | 'note'>
    )> }
  ) }
);

export type UpdateTripInfoMutationVariables = Exact<{
  legId: Scalars['Int'];
  tripStatus: Scalars['String'];
  dateTimeStamp: Scalars['String'];
  gateNumber: Scalars['String'];
  driverName?: Maybe<Scalars['String']>;
  carRegNumber?: Maybe<Scalars['String']>;
  trailerRegNumber?: Maybe<Scalars['String']>;
  capacity?: Maybe<Scalars['Int']>;
}>;


export type UpdateTripInfoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateTripInfoFromProdApp'>
);

export type DeleteLoadingListItemMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteLoadingListItemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteLoadingListItem'>
);

export type UpdateLoadingListItemMutationVariables = Exact<{
  input: ShipmentLoadingList;
  shipmentId: Scalars['Int'];
}>;


export type UpdateLoadingListItemMutation = (
  { __typename?: 'Mutation' }
  & { updateLoadingListItem: (
    { __typename?: 'LoadingListItem' }
    & Pick<LoadingListItem, 'id'>
  ) }
);

export type UpdateLocationMutationVariables = Exact<{
  id: Scalars['Int'];
  name: Scalars['String'];
  address: Scalars['String'];
  postalCode: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  phLocationId: Scalars['String'];
  locationShortCode: Scalars['String'];
}>;


export type UpdateLocationMutation = (
  { __typename?: 'Mutation' }
  & { updateLocation: (
    { __typename?: 'LocationEntity' }
    & Pick<LocationEntity, 'id' | 'name' | 'address' | 'postalCode' | 'city' | 'country'>
  ) }
);

export type CreateLocationMutationVariables = Exact<{
  name: Scalars['String'];
  address: Scalars['String'];
  postalCode: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  phLocationId: Scalars['String'];
  locationShortCode: Scalars['String'];
}>;


export type CreateLocationMutation = (
  { __typename?: 'Mutation' }
  & { createLocation: (
    { __typename?: 'LocationEntity' }
    & Pick<LocationEntity, 'id' | 'name' | 'address' | 'postalCode' | 'city' | 'country'>
  ) }
);

export type CreateOrderMutationVariables = Exact<{
  input: OrderInput;
}>;


export type CreateOrderMutation = (
  { __typename?: 'Mutation' }
  & { createOrder: (
    { __typename?: 'Order' }
    & Pick<Order, 'id' | 'transportationDate' | 'customerId' | 'note' | 'pallets' | 'palletSpace' | 'weight' | 'packages' | 'updatedAt' | 'createdBy' | 'lastUpdatedBy'>
  ) }
);

export type UpdateOrderMutationVariables = Exact<{
  id: Scalars['Int'];
  input: OrderInput;
}>;


export type UpdateOrderMutation = (
  { __typename?: 'Mutation' }
  & { updateOrder: (
    { __typename?: 'Order' }
    & Pick<Order, 'id' | 'transportationDate' | 'customerId' | 'note' | 'pallets' | 'packages' | 'palletSpace' | 'weight' | 'updatedAt' | 'createdBy' | 'lastUpdatedBy'>
  ) }
);

export type CreatePeakPlanningItemMutationVariables = Exact<{
  input: PeakPlanningItemInput;
}>;


export type CreatePeakPlanningItemMutation = (
  { __typename?: 'Mutation' }
  & { createPeakPlanningItem: (
    { __typename?: 'PeakPlanningItem' }
    & Pick<PeakPlanningItem, 'id'>
  ) }
);

export type DeletePeakPlanningItemMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeletePeakPlanningItemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePeakPlanningItem'>
);

export type UpdatePeakPlanningItemMutationVariables = Exact<{
  id: Scalars['Int'];
  input: PeakPlanningItemInput;
}>;


export type UpdatePeakPlanningItemMutation = (
  { __typename?: 'Mutation' }
  & { updatePeakPlanningItem: (
    { __typename?: 'PeakPlanningItem' }
    & Pick<PeakPlanningItem, 'id'>
  ) }
);

export type CreatePeakPlanningDebitMutationVariables = Exact<{
  input: PeakPlanningDebitInput;
}>;


export type CreatePeakPlanningDebitMutation = (
  { __typename?: 'Mutation' }
  & { createPeakPlanningDebit: (
    { __typename?: 'PeakPlanningDebit' }
    & Pick<PeakPlanningDebit, 'id'>
  ) }
);

export type UpdatePeakPlanningDebitMutationVariables = Exact<{
  id: Scalars['Int'];
  input: PeakPlanningDebitInput;
}>;


export type UpdatePeakPlanningDebitMutation = (
  { __typename?: 'Mutation' }
  & { updatePeakPlanningDebit: (
    { __typename?: 'PeakPlanningDebit' }
    & Pick<PeakPlanningDebit, 'id'>
  ) }
);

export type CreateMultipleSporadicRouteMutationVariables = Exact<{
  input: SporadicRouteInput;
  dates: Array<Scalars['String']> | Scalars['String'];
}>;


export type CreateMultipleSporadicRouteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createMultipleSporadicRoute'>
);

export type CreateSporadicRouteMutationVariables = Exact<{
  input: SporadicRouteInput;
}>;


export type CreateSporadicRouteMutation = (
  { __typename?: 'Mutation' }
  & { createSporadicRoute: (
    { __typename?: 'Route' }
    & Pick<Route, 'id'>
  ) }
);

export type CreateSporadicRouteWithoutDispatcherMutationVariables = Exact<{
  input: SporadicRouteInput;
}>;


export type CreateSporadicRouteWithoutDispatcherMutation = (
  { __typename?: 'Mutation' }
  & { createSporadicRouteWithoutDispatcher: (
    { __typename?: 'Route' }
    & Pick<Route, 'id'>
  ) }
);

export type DeleteMultiplePlannedRouteMutationVariables = Exact<{
  ids: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type DeleteMultiplePlannedRouteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteMultipleRoute'>
);

export type DeleteSporadicRouteMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteSporadicRouteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteRoute'>
);

export type UpdateDriverMutationVariables = Exact<{
  id: Scalars['Int'];
  driverData: DriverInfo;
}>;


export type UpdateDriverMutation = (
  { __typename?: 'Mutation' }
  & { updateDriver: (
    { __typename?: 'Route' }
    & Pick<Route, 'id' | 'driverName' | 'driverPhoneNumber' | 'licensePlate'>
  ) }
);

export type UpdateExtraColumnsMutationVariables = Exact<{
  id: Scalars['Int'];
  extraColumnData: ExtraColumnInfo;
}>;


export type UpdateExtraColumnsMutation = (
  { __typename?: 'Mutation' }
  & { updateExtraColumns: (
    { __typename?: 'Route' }
    & Pick<Route, 'id' | 'turnummer' | 'togref' | 'bpx' | 'pri03' | 'pri49' | 'upri03' | 'upri49' | 'parti' | 'brev' | 'pru' | 'tom' | 'rutekode' | 'trainNumber' | 'inneholdsbeskrivelse' | 'containerId' | 'transportType'>
  ) }
);

export type UpdateInvoiceMutationVariables = Exact<{
  id: Scalars['Int'];
  invoiceData: InvoiceDataInput;
}>;


export type UpdateInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { updateInvoice: (
    { __typename?: 'Route' }
    & Pick<Route, 'id' | 'invoiceNote' | 'invoiceNumber' | 'agreedPrice'>
  ) }
);

export type UpdateLockMutationVariables = Exact<{
  routeId: Scalars['Int'];
  lock: Scalars['Int'];
  reload: Scalars['Int'];
}>;


export type UpdateLockMutation = (
  { __typename?: 'Mutation' }
  & { updateLock: (
    { __typename?: 'RouteLock' }
    & Pick<RouteLock, 'routeId' | 'lockedAt' | 'lockedBy'>
  ) }
);

export type UpdateLockOnBulkRoutesMutationVariables = Exact<{
  routeIds: Array<Scalars['Int']> | Scalars['Int'];
  isLock: Scalars['Boolean'];
  forGroup: Scalars['String'];
}>;


export type UpdateLockOnBulkRoutesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateLockOnBulkRoutes'>
);

export type UpdateMinimalRouteMutationVariables = Exact<{
  input: UpdateMinimalRouteInput;
}>;


export type UpdateMinimalRouteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateMinimalRoutes'>
);

export type UpdateRouteGroupTagMutationVariables = Exact<{
  id: Scalars['Int'];
  routeGroupTag: Scalars['String'];
}>;


export type UpdateRouteGroupTagMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateRouteGroupTag'>
);

export type UpdateRouteLegDataColumnsMutationVariables = Exact<{
  id: Scalars['Int'];
  routeLegData: RouteLegDataColumnInfo;
}>;


export type UpdateRouteLegDataColumnsMutation = (
  { __typename?: 'Mutation' }
  & { updateRouteLegDataColumns: (
    { __typename?: 'RouteLeg' }
    & Pick<RouteLeg, 'id' | 'carRegistrationNumber' | 'trailerRegistrationNumber'>
  ) }
);

export type UpdateSporadicRouteMutationVariables = Exact<{
  routeId: Scalars['Int'];
  input: SporadicRouteInput;
}>;


export type UpdateSporadicRouteMutation = (
  { __typename?: 'Mutation' }
  & { updateSporadicRoute: (
    { __typename?: 'Route' }
    & Pick<Route, 'agreedPrice' | 'capacity' | 'currency' | 'driverName' | 'driverPhoneNumber' | 'id' | 'invoiceNote' | 'invoiceNumber' | 'licensePlate' | 'note' | 'externalNote' | 'routeId' | 'subcontractorId' | 'transportationDate' | 'type' | 'isCreatedFromTerminal' | 'isCreatedFromPlanned'>
    & { dispatcher?: Maybe<(
      { __typename?: 'CardContact' }
      & Pick<CardContact, 'id' | 'email' | 'name' | 'phoneNumber'>
      & { tags: Array<(
        { __typename?: 'CardContactTag' }
        & Pick<CardContactTag, 'id' | 'title'>
      )> }
    )>, files: Array<(
      { __typename?: 'DocumentFile' }
      & Pick<DocumentFile, 'id' | 'mimetype' | 'originalname' | 'path' | 'size'>
      & { tags: Array<(
        { __typename?: 'DocumentFileTag' }
        & Pick<DocumentFileTag, 'id' | 'title'>
      )> }
    )>, ftl?: Maybe<(
      { __typename?: 'RouteFTL' }
      & Pick<RouteFtl, 'cdc' | 'cmr' | 'currency' | 'customerId' | 'customerNumber' | 'customerReference' | 'id' | 'invoiceNote' | 'price'>
    )>, legs: Array<(
      { __typename?: 'RouteLeg' }
      & Pick<RouteLeg, 'gateNumber' | 'carRegistrationNumber' | 'trailerRegistrationNumber' | 'routeDriverName' | 'routeDriverPhoneNumber' | 'arrivalTime' | 'departureTime' | 'id' | 'load' | 'note' | 'position' | 'unload' | 'locationId'>
    )> }
  ) }
);

export type CreateRouteTemplateMutationVariables = Exact<{
  RouteTemplateInput: SporadicRouteTemplateInput;
}>;


export type CreateRouteTemplateMutation = (
  { __typename?: 'Mutation' }
  & { createSporadicRouteTemplate: (
    { __typename?: 'SporadicRouteTemplate' }
    & Pick<SporadicRouteTemplate, 'id'>
  ) }
);

export type CreateShipmentMutationVariables = Exact<{
  input: CreateShipmentInput;
}>;


export type CreateShipmentMutation = (
  { __typename?: 'Mutation' }
  & { createShipment: (
    { __typename?: 'Shipment' }
    & Pick<Shipment, 'id'>
  ) }
);

export type DeleteShipmentMutationVariables = Exact<{
  id: Scalars['Int'];
  updatedFrom: Scalars['String'];
  shipmentDate: Scalars['String'];
}>;


export type DeleteShipmentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteShipment'>
);

export type GetShipmentQueryVariables = Exact<{
  startDate: Scalars['String'];
  endDate: Scalars['String'];
}>;


export type GetShipmentQuery = (
  { __typename?: 'Query' }
  & { shipments: Array<(
    { __typename?: 'Shipment' }
    & Pick<Shipment, 'id' | 'name' | 'shipmentDate' | 'createdFrom' | 'updatedFrom' | 'createdBy' | 'lastUpdatedBy' | 'updatedAt' | 'fromLocationId' | 'toLocationId'>
    & { loadingListItems: Array<(
      { __typename?: 'LoadingListItem' }
      & Pick<LoadingListItem, 'customClearedForImport' | 'isRemainingGoods' | 'isVoecCustomer' | 'customCleared' | 'terminalCleared' | 'isImportedFromShipment' | 'packages' | 'pallets' | 'palletSpace'>
    )> }
  )> }
);

export type GetShipmentByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetShipmentByIdQuery = (
  { __typename?: 'Query' }
  & { shipmentById: (
    { __typename?: 'Shipment' }
    & Pick<Shipment, 'id' | 'name' | 'shipmentDate' | 'fromLocationId' | 'toLocationId' | 'lastUpdatedBy' | 'createdFrom' | 'updatedFrom' | 'createdBy'>
    & { loadingListItems: Array<(
      { __typename?: 'LoadingListItem' }
      & Pick<LoadingListItem, 'id' | 'locationId' | 'packages' | 'pallets' | 'palletSpace' | 'weight' | 'checked' | 'isDangerous' | 'classification' | 'unNumber' | 'isLimitedQty' | 'note' | 'customVOECId' | 'customInvoiceNo' | 'customMrnNo' | 'customWaybillNo' | 'customCmrNo' | 'customNote' | 'connectedNB' | 'customCleared' | 'customClearedForImport' | 'isRemainingGoods' | 'isVoecCustomer' | 'terminalShipmentNotArrived' | 'terminalCleared' | 'terminalNotCleared' | 'terminalDevPackages' | 'terminalDevPallets' | 'terminalDevPalletSpace' | 'terminalDevWeight' | 'terminalDevInfo' | 'isImportedFromShipment' | 'customsProcedure'>
      & { location?: Maybe<(
        { __typename?: 'LocationEntity' }
        & Pick<LocationEntity, 'name'>
      )>, routeDetail: (
        { __typename?: 'RouteDetailsInLL' }
        & Pick<RouteDetailsInLl, 'id' | 'routeName'>
      ) }
    )> }
  ) }
);

export type GetShipmentTemplatesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetShipmentTemplatesQuery = (
  { __typename?: 'Query' }
  & { shipmentTemplates: Array<(
    { __typename?: 'Shipment' }
    & Pick<Shipment, 'id' | 'name' | 'createdFrom' | 'updatedFrom' | 'createdBy' | 'lastUpdatedBy'>
  )> }
);

export type GetShipmentsByDateQueryVariables = Exact<{
  forDate: Scalars['String'];
  fromLocationId?: Maybe<Scalars['Int']>;
}>;


export type GetShipmentsByDateQuery = (
  { __typename?: 'Query' }
  & { shipmentsByDate: Array<(
    { __typename?: 'Shipment' }
    & Pick<Shipment, 'id' | 'name' | 'shipmentDate' | 'shipmentStatus' | 'fromLocationId' | 'toLocationId' | 'createdBy' | 'lastUpdatedBy' | 'createdFrom' | 'updatedFrom'>
    & { loadingListItems: Array<(
      { __typename?: 'LoadingListItem' }
      & Pick<LoadingListItem, 'id' | 'locationId' | 'checked' | 'isDangerous' | 'packages' | 'pallets' | 'palletSpace' | 'weight' | 'note' | 'unNumber' | 'isLimitedQty' | 'classification' | 'isImportedFromShipment' | 'customVOECId' | 'customInvoiceNo' | 'customMrnNo' | 'customWaybillNo' | 'customCmrNo' | 'customNote' | 'connectedNB' | 'customCleared' | 'customClearedForImport' | 'isRemainingGoods' | 'isVoecCustomer' | 'terminalShipmentNotArrived' | 'terminalCleared' | 'terminalNotCleared' | 'terminalDevPackages' | 'terminalDevPallets' | 'terminalDevPalletSpace' | 'terminalDevWeight' | 'terminalDevInfo'>
      & { routeDetail: (
        { __typename?: 'RouteDetailsInLL' }
        & Pick<RouteDetailsInLl, 'id' | 'routeName'>
      ), files: Array<(
        { __typename?: 'DocumentFile' }
        & Pick<DocumentFile, 'id' | 'originalname' | 'mimetype' | 'path' | 'size'>
      )> }
    )> }
  )> }
);

export type UpdatePartialShipmentMutationVariables = Exact<{
  input: ShipmentPartialUpdateInput;
}>;


export type UpdatePartialShipmentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updatePartialShipment'>
);

export type EditShipmentMutationVariables = Exact<{
  input: ShipmentEditInput;
  isNewOrders?: Maybe<Scalars['Boolean']>;
  orderListUnchanged?: Maybe<Scalars['Boolean']>;
}>;


export type EditShipmentMutation = (
  { __typename?: 'Mutation' }
  & { updateShipment: (
    { __typename?: 'Shipment' }
    & Pick<Shipment, 'id'>
  ) }
);

export type CreateSporadicRouteTemplateMutationVariables = Exact<{
  SporadicRouteTemplateInput: SporadicRouteTemplateInput;
}>;


export type CreateSporadicRouteTemplateMutation = (
  { __typename?: 'Mutation' }
  & { createSporadicRouteTemplate: (
    { __typename?: 'SporadicRouteTemplate' }
    & Pick<SporadicRouteTemplate, 'id'>
  ) }
);

export type DeleteSporadicRouteTemplateMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteSporadicRouteTemplateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSporadicRouteTemplate'>
);

export type UpdateSporadicRouteTemplateMutationVariables = Exact<{
  id: Scalars['Int'];
  SporadicRouteTemplateInput: SporadicRouteTemplateInput;
}>;


export type UpdateSporadicRouteTemplateMutation = (
  { __typename?: 'Mutation' }
  & { updateSporadicRouteTemplate: (
    { __typename?: 'SporadicRouteTemplate' }
    & Pick<SporadicRouteTemplate, 'id' | 'name' | 'agreedPrice' | 'currency' | 'note' | 'externalNote' | 'driverName' | 'driverPhoneNumber' | 'licensePlate' | 'capacity' | 'routeId'>
  ) }
);

export type CreateSubcontractorMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type CreateSubcontractorMutation = (
  { __typename?: 'Mutation' }
  & { createSubcontractor: (
    { __typename?: 'Subcontractor' }
    & Pick<Subcontractor, 'name' | 'id'>
  ) }
);

export type DeleteSubcontractorMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteSubcontractorMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSubcontractor'>
);

export type ImportSubcontractorsMutationVariables = Exact<{
  input: Array<ExcelImportDataInput> | ExcelImportDataInput;
}>;


export type ImportSubcontractorsMutation = (
  { __typename?: 'Mutation' }
  & { importSubcontractors: Array<(
    { __typename?: 'Subcontractor' }
    & Pick<Subcontractor, 'id' | 'name'>
  )> }
);

export type UpdateSubcontractorMutationVariables = Exact<{
  name: Scalars['String'];
  id: Scalars['Int'];
}>;


export type UpdateSubcontractorMutation = (
  { __typename?: 'Mutation' }
  & { updateSubcontractor: (
    { __typename?: 'Subcontractor' }
    & Pick<Subcontractor, 'id' | 'name'>
  ) }
);

export type DeleteTerminalMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteTerminalMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteTerminal'>
);

export type ImportTerminalsMutationVariables = Exact<{
  input: Array<ExcelImportDataInput> | ExcelImportDataInput;
}>;


export type ImportTerminalsMutation = (
  { __typename?: 'Mutation' }
  & { importTerminals: Array<(
    { __typename?: 'Terminal' }
    & Pick<Terminal, 'id' | 'name'>
  )> }
);

export type CreateTerminalMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type CreateTerminalMutation = (
  { __typename?: 'Mutation' }
  & { createTerminal: (
    { __typename?: 'Terminal' }
    & Pick<Terminal, 'name' | 'id'>
  ) }
);

export type UpdateTerminalMutationVariables = Exact<{
  name: Scalars['String'];
  id: Scalars['Int'];
}>;


export type UpdateTerminalMutation = (
  { __typename?: 'Mutation' }
  & { updateTerminal: (
    { __typename?: 'Terminal' }
    & Pick<Terminal, 'id' | 'name'>
  ) }
);

export type CreateTourTemplateMutationVariables = Exact<{
  TourTemplateInput: TourTemplateInput;
}>;


export type CreateTourTemplateMutation = (
  { __typename?: 'Mutation' }
  & { createTourTemplate: (
    { __typename?: 'TourTemplate' }
    & Pick<TourTemplate, 'id'>
  ) }
);

export type DeleteTourTemplateMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteTourTemplateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteTourTemplate'>
);

export type DispatchRouteMutationVariables = Exact<{
  input: FixedTourGenerateInput;
}>;


export type DispatchRouteMutation = (
  { __typename?: 'Mutation' }
  & { generateFixedTour: (
    { __typename?: 'FixedTourGenerateResponse' }
    & Pick<FixedTourGenerateResponse, 'status' | 'createdRouteId' | 'routeType' | 'date'>
  ) }
);

export type UpdateBookmarkMutationVariables = Exact<{
  id: Scalars['Int'];
  bookmark: Scalars['Boolean'];
}>;


export type UpdateBookmarkMutation = (
  { __typename?: 'Mutation' }
  & { updateBookmark: (
    { __typename?: 'UpdateBookMarkResponse' }
    & Pick<UpdateBookMarkResponse, 'id' | 'bookmark'>
  ) }
);

export type UpdateTourTemplateMutationVariables = Exact<{
  id: Scalars['Int'];
  input: TourTemplateInput;
}>;


export type UpdateTourTemplateMutation = (
  { __typename?: 'Mutation' }
  & { updateTourTemplate: (
    { __typename?: 'TourTemplate' }
    & Pick<TourTemplate, 'id' | 'name' | 'subcontractorId' | 'dispatcherId' | 'capacity' | 'fuel' | 'price' | 'currency' | 'startDate' | 'endDate' | 'originalStartDate' | 'startExceptionDate' | 'endExceptionDate' | 'exceptionDates' | 'note' | 'driverName' | 'driverPhoneNumber' | 'licensePlate' | 'createdBy' | 'lastUpdatedBy' | 'createdAt' | 'updatedAt' | 'responsiblePerson' | 'groupingTag' | 'isPriceEquallyDivided'>
  ) }
);

export type ConnectTerminalMutationVariables = Exact<{
  terminalId: Scalars['Int'];
}>;


export type ConnectTerminalMutation = (
  { __typename?: 'Mutation' }
  & { connectTerminal: (
    { __typename?: 'PublicUser' }
    & Pick<PublicUser, 'roles' | 'lastName' | 'fullName' | 'firstName' | 'department' | 'createdAt' | 'id' | 'internalTerminalId'>
    & { internalTerminal?: Maybe<(
      { __typename?: 'InternalTerminal' }
      & Pick<InternalTerminal, 'name' | 'id'>
    )> }
  ) }
);

export type GetStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStatusQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'status'>
);

export type GetCardTypeQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetCardTypeQuery = (
  { __typename?: 'Query' }
  & { card: (
    { __typename?: 'Card' }
    & Pick<Card, 'id' | 'type'>
  ) }
);

export type GetAllCardsQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  withTerminal?: Maybe<Scalars['Boolean']>;
}>;


export type GetAllCardsQuery = (
  { __typename?: 'Query' }
  & { allCards: Array<(
    { __typename?: 'Card' }
    & Pick<Card, 'id' | 'name' | 'type'>
    & { locations: Array<(
      { __typename?: 'LocationEntity' }
      & LocationsFieldsFragment
    )> }
  )> }
);

export type GetCardQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetCardQuery = (
  { __typename?: 'Query' }
  & { card: (
    { __typename?: 'Card' }
    & Pick<Card, 'id' | 'name' | 'type' | 'createdAt' | 'updatedAt'>
    & { locations: Array<(
      { __typename?: 'LocationEntity' }
      & LocationsFieldsFragment
    )>, contacts: Array<(
      { __typename?: 'CardContact' }
      & CardContactFieldsFragment
    )>, customerAccounts: Array<(
      { __typename?: 'CustomerAccount' }
      & CustomerAccountFieldsFragment
    )>, files: Array<(
      { __typename?: 'CardFile' }
      & Pick<CardFile, 'id' | 'originalname' | 'mimetype' | 'path' | 'size' | 'createdAt' | 'createdBy'>
    )>, notes: Array<(
      { __typename?: 'CardNote' }
      & Pick<CardNote, 'id' | 'title' | 'text' | 'createdBy' | 'lastUpdatedBy' | 'updatedAt'>
    )> }
  ) }
);

export type GetCardsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCardsQuery = (
  { __typename?: 'Query' }
  & { cards: Array<(
    { __typename?: 'Card' }
    & Pick<Card, 'id' | 'name' | 'type' | 'createdAt' | 'updatedAt'>
    & { locations: Array<(
      { __typename?: 'LocationEntity' }
      & LocationsFieldsFragment
    )>, contacts: Array<(
      { __typename?: 'CardContact' }
      & CardContactFieldsFragment
    )>, customerAccounts: Array<(
      { __typename?: 'CustomerAccount' }
      & CustomerAccountFieldsFragment
    )> }
  )> }
);

export type GetCheckpointQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetCheckpointQuery = (
  { __typename?: 'Query' }
  & { checkpoint: (
    { __typename?: 'Checkpoint' }
    & Pick<Checkpoint, 'id' | 'name' | 'createdBy' | 'updatedAt' | 'lastUpdatedBy'>
    & { files: Array<(
      { __typename?: 'CardFile' }
      & Pick<CardFile, 'originalname' | 'path' | 'id' | 'createdAt' | 'mimetype' | 'size' | 'createdBy'>
    )>, contacts: Array<(
      { __typename?: 'CardContact' }
      & CardContactFieldsFragment
    )> }
  ) }
);

export type GetCheckpointsQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
}>;


export type GetCheckpointsQuery = (
  { __typename?: 'Query' }
  & { checkpoints: Array<(
    { __typename?: 'Checkpoint' }
    & Pick<Checkpoint, 'id' | 'name'>
    & { locations: Array<(
      { __typename?: 'LocationEntity' }
      & LocationsFieldsFragment
    )> }
  )> }
);

export type GetContactQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetContactQuery = (
  { __typename?: 'Query' }
  & { cardContact: (
    { __typename?: 'CardContact' }
    & Pick<CardContact, 'id' | 'name' | 'role' | 'email' | 'phoneNumber' | 'note' | 'lastUpdatedBy' | 'createdBy' | 'updatedAt'>
    & { tags: Array<(
      { __typename?: 'CardContactTag' }
      & Pick<CardContactTag, 'id' | 'title'>
    )> }
  ) }
);

export type GetContactsForCardQueryVariables = Exact<{
  cardId?: Maybe<Scalars['Int']>;
  contactId?: Maybe<Scalars['Int']>;
}>;


export type GetContactsForCardQuery = (
  { __typename?: 'Query' }
  & { cardContacts: Array<(
    { __typename?: 'CardContact' }
    & Pick<CardContact, 'id' | 'name' | 'phoneNumber' | 'email' | 'note'>
    & { tags: Array<(
      { __typename?: 'CardContactTag' }
      & Pick<CardContactTag, 'title'>
    )> }
  )> }
);

export type GetCustomerQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetCustomerQuery = (
  { __typename?: 'Query' }
  & { customer: (
    { __typename?: 'Customer' }
    & Pick<Customer, 'id' | 'name' | 'createdBy' | 'updatedAt' | 'lastUpdatedBy'>
    & { files: Array<(
      { __typename?: 'CardFile' }
      & Pick<CardFile, 'originalname' | 'path' | 'id' | 'createdAt' | 'mimetype' | 'size' | 'createdBy'>
    )>, contacts: Array<(
      { __typename?: 'CardContact' }
      & CardContactFieldsFragment
    )> }
  ) }
);

export type GetCustomersQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
}>;


export type GetCustomersQuery = (
  { __typename?: 'Query' }
  & { customers: Array<(
    { __typename?: 'Customer' }
    & Pick<Customer, 'id' | 'name'>
    & { locations: Array<(
      { __typename?: 'LocationEntity' }
      & LocationsFieldsFragment
    )> }
  )> }
);

export type SearchCustomersQueryVariables = Exact<{
  customersSearch?: Maybe<Scalars['String']>;
}>;


export type SearchCustomersQuery = (
  { __typename?: 'Query' }
  & { customers: Array<(
    { __typename?: 'Customer' }
    & Pick<Customer, 'id' | 'name'>
  )> }
);

export type GetCustomerAccountQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetCustomerAccountQuery = (
  { __typename?: 'Query' }
  & { customerAccount: (
    { __typename?: 'CustomerAccount' }
    & CustomerAccountFieldsFragment
  ) }
);

export type GetCustomerAccountsForCardQueryVariables = Exact<{
  cardId: Scalars['Int'];
}>;


export type GetCustomerAccountsForCardQuery = (
  { __typename?: 'Query' }
  & { customerAccounts: Array<(
    { __typename?: 'CustomerAccount' }
    & CustomerAccountFieldsFragment
  )> }
);

export type GetDepartmentsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDepartmentsQuery = (
  { __typename?: 'Query' }
  & { departments: Array<(
    { __typename?: 'Department' }
    & Pick<Department, 'id' | 'name'>
  )> }
);

export type GetDeviationForEmailQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetDeviationForEmailQuery = (
  { __typename?: 'Query' }
  & { deviation: (
    { __typename?: 'Deviation' }
    & Pick<Deviation, 'id' | 'localMessageCustomers' | 'englishMessageCustomers'>
    & { customers: Array<(
      { __typename?: 'Customer' }
      & Pick<Customer, 'id' | 'name'>
      & { contacts: Array<(
        { __typename?: 'CardContact' }
        & Pick<CardContact, 'id' | 'name' | 'email'>
      )> }
    )>, deviationToCustomer?: Maybe<Array<(
      { __typename?: 'DeviationToCustomer' }
      & Pick<DeviationToCustomer, 'customerId' | 'deviationId' | 'lastEmailSent'>
    )>>, deviationToLocation?: Maybe<Array<(
      { __typename?: 'DeviationToLocation' }
      & Pick<DeviationToLocation, 'deviationId' | 'lastEmailSent'>
      & { location: (
        { __typename?: 'LocationEntity' }
        & { card: (
          { __typename?: 'Card' }
          & Pick<Card, 'id'>
        ) }
      ) }
    )>> }
  ) }
);

export type GetDeviationToLocationForDeviationIdQueryVariables = Exact<{
  deviationId: Scalars['Int'];
}>;


export type GetDeviationToLocationForDeviationIdQuery = (
  { __typename?: 'Query' }
  & { deviationToLocationForDeviationId: Array<(
    { __typename?: 'DeviationToLocation' }
    & Pick<DeviationToLocation, 'id' | 'deviationId' | 'locationId' | 'lastEmailSent' | 'pallets' | 'locationItemNote' | 'packages' | 'palletSpace' | 'weight' | 'reason' | 'otherReason' | 'newCustomer' | 'isDangerous'>
  )> }
);

export type GetDeviationsQueryVariables = Exact<{
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  searchTerm?: Maybe<Scalars['String']>;
}>;


export type GetDeviationsQuery = (
  { __typename?: 'Query' }
  & { deviations: Array<(
    { __typename?: 'Deviation' }
    & Pick<Deviation, 'id' | 'cause' | 'fault' | 'routeId' | 'note' | 'consequence' | 'actualArrivalTime' | 'createdBy'>
    & { route: (
      { __typename?: 'Route' }
      & Pick<Route, 'id' | 'routeId' | 'type' | 'licensePlate' | 'capacity' | 'transportationDate'>
      & { subcontractor: (
        { __typename?: 'Subcontractor' }
        & Pick<Subcontractor, 'id' | 'name'>
      ), legs: Array<(
        { __typename?: 'RouteLeg' }
        & Pick<RouteLeg, 'arrivalTime' | 'position'>
        & { location?: Maybe<(
          { __typename?: 'LocationEntity' }
          & Pick<LocationEntity, 'city' | 'country'>
        )> }
      )> }
    ), faultLocation?: Maybe<(
      { __typename?: 'LocationEntity' }
      & Pick<LocationEntity, 'id' | 'name' | 'address' | 'postalCode' | 'city' | 'country'>
      & { card: (
        { __typename?: 'Card' }
        & Pick<Card, 'name'>
      ) }
    )> }
  )> }
);

export type GetDeviationsForRouteQueryVariables = Exact<{
  routeId: Scalars['Int'];
}>;


export type GetDeviationsForRouteQuery = (
  { __typename?: 'Query' }
  & { deviationsForRoute: Array<(
    { __typename?: 'Deviation' }
    & Pick<Deviation, 'id' | 'cause' | 'fault' | 'routeId' | 'emailSent' | 'note' | 'consequence' | 'actualArrivalTime' | 'createdBy'>
    & { faultLocation?: Maybe<(
      { __typename?: 'LocationEntity' }
      & Pick<LocationEntity, 'id' | 'name' | 'address' | 'postalCode' | 'city' | 'country'>
      & { card: (
        { __typename?: 'Card' }
        & Pick<Card, 'name'>
      ) }
    )>, route: (
      { __typename?: 'Route' }
      & { legs: Array<(
        { __typename?: 'RouteLeg' }
        & Pick<RouteLeg, 'arrivalTime'>
      )> }
    ) }
  )> }
);

export type GetLocationDeviationsQueryVariables = Exact<{
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  searchTerm?: Maybe<Scalars['String']>;
  terminalIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type GetLocationDeviationsQuery = (
  { __typename?: 'Query' }
  & { getLocationDeviations: Array<(
    { __typename?: 'CustomerLevelDeviationResponse' }
    & Pick<CustomerLevelDeviationResponse, 'id' | 'deviationId' | 'routeId' | 'faultLocationId' | 'toLocationId' | 'deviationApplicableDate' | 'routeNameOrFaultLocation' | 'customerId' | 'customerName' | 'pallets' | 'packages' | 'palletSpace' | 'weight' | 'reason' | 'otherReason' | 'newCustomer' | 'locationItemNote' | 'cause' | 'fault' | 'faultLocation' | 'toLocation' | 'fromToToCountry' | 'toCity' | 'customerLocation' | 'type' | 'emailSent' | 'emailSentTimestamp' | 'consequence' | 'actualArrivalTime' | 'createdBy' | 'lastUpdatedBy' | 'isDangerous'>
  )> }
);

export type GetFilesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFilesQuery = (
  { __typename?: 'Query' }
  & { files: Array<(
    { __typename?: 'DocumentFile' }
    & Pick<DocumentFile, 'id' | 'originalname' | 'mimetype' | 'path' | 'size' | 'createdAt' | 'createdBy'>
    & { tags: Array<(
      { __typename?: 'DocumentFileTag' }
      & Pick<DocumentFileTag, 'id' | 'title'>
    )> }
  )> }
);

export type GetDocumentFileTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDocumentFileTagsQuery = (
  { __typename?: 'Query' }
  & { documentFileTags: Array<(
    { __typename?: 'DocumentFileTag' }
    & Pick<DocumentFileTag, 'id' | 'title'>
  )> }
);

export type GetCardFilesQueryVariables = Exact<{
  cardId: Scalars['Int'];
}>;


export type GetCardFilesQuery = (
  { __typename?: 'Query' }
  & { card: (
    { __typename?: 'Card' }
    & { files: Array<(
      { __typename?: 'CardFile' }
      & Pick<CardFile, 'createdBy' | 'createdAt' | 'mimetype' | 'id' | 'originalname' | 'path' | 'size' | 'updatedAt'>
    )> }
  ) }
);

export type GetFixedTrafficLegsQueryVariables = Exact<{
  terminalId: Scalars['Int'];
}>;


export type GetFixedTrafficLegsQuery = (
  { __typename?: 'Query' }
  & { fixedTrafficLegs: Array<(
    { __typename?: 'FixedTrafficLeg' }
    & Pick<FixedTrafficLeg, 'id' | 'routeId' | 'tourId' | 'capacity' | 'days' | 'arrivalTime' | 'departureTime' | 'departureCity' | 'arrivalCity' | 'load' | 'unload' | 'note' | 'startDate' | 'endDate' | 'startExceptionDate' | 'endExceptionDate'>
    & { subcontractor: (
      { __typename?: 'Subcontractor' }
      & Pick<Subcontractor, 'id' | 'name'>
    ) }
  )> }
);

export type GetHolidaysQueryVariables = Exact<{
  country: Scalars['String'];
}>;


export type GetHolidaysQuery = (
  { __typename?: 'Query' }
  & { holidays: Array<(
    { __typename?: 'Holiday' }
    & Pick<Holiday, 'id' | 'title' | 'date' | 'country'>
  )> }
);

export type GetGridValuesForDateQueryVariables = Exact<{
  internalTerminalId: Scalars['Int'];
  valueAddedDate: Scalars['String'];
  gridType: Scalars['String'];
}>;


export type GetGridValuesForDateQuery = (
  { __typename?: 'Query' }
  & { getGridValues: Array<(
    { __typename?: 'GridValues' }
    & Pick<GridValues, 'id' | 'gridEntryId' | 'gridEntries' | 'valueAddedDate' | 'columnHeaders' | 'rowHeaders' | 'gridName' | 'internalTerminalId' | 'isGridDeleted' | 'gridType' | 'isNumberGridWithTotal'>
  )> }
);

export type GetGridValuesForTrafficQueryVariables = Exact<{
  internalTerminalId: Scalars['Int'];
  valueAddedDate: Scalars['String'];
  gridType: Scalars['String'];
}>;


export type GetGridValuesForTrafficQuery = (
  { __typename?: 'Query' }
  & { getGridValuesForTraffic: Array<(
    { __typename?: 'GridValues' }
    & Pick<GridValues, 'id' | 'gridName' | 'gridEntryId' | 'internalTerminalId' | 'columnHeaders' | 'rowHeaders' | 'gridEntries' | 'valueAddedDate' | 'location' | 'gridType' | 'isNumberGridWithTotal'>
  )> }
);

export type GetInternalTerminalFromIdQueryVariables = Exact<{
  internalTerminalId: Scalars['Int'];
}>;


export type GetInternalTerminalFromIdQuery = (
  { __typename?: 'Query' }
  & { internalTerminal: (
    { __typename?: 'InternalTerminal' }
    & Pick<InternalTerminal, 'id' | 'name' | 'reportExportedAt' | 'reportExportedBy'>
    & { terminal: (
      { __typename?: 'Terminal' }
      & { locations: Array<(
        { __typename?: 'LocationEntity' }
        & Pick<LocationEntity, 'id' | 'country'>
      )> }
    ) }
  ) }
);

export type GetInternalTerminalReportInfoQueryVariables = Exact<{
  internalTerminalId: Scalars['Int'];
  dateForAddInfo: Scalars['String'];
  forTerminal: Scalars['Boolean'];
}>;


export type GetInternalTerminalReportInfoQuery = (
  { __typename?: 'Query' }
  & { internalTerminalReportInfo: Array<(
    { __typename?: 'InternalTerminalReportInfoResponse' }
    & Pick<InternalTerminalReportInfoResponse, 'id' | 'internalTerminalId' | 'dateForAddInfo' | 'info' | 'infoType' | 'isAddedFromTerminal' | 'replyInfo' | 'replyText'>
    & { files: Array<(
      { __typename?: 'DocumentFile' }
      & Pick<DocumentFile, 'id' | 'originalname' | 'mimetype' | 'path' | 'size'>
    )> }
  )> }
);

export type GetInternalTerminalsQueryVariables = Exact<{
  fetchFromAllDepartments?: Maybe<Scalars['Boolean']>;
}>;


export type GetInternalTerminalsQuery = (
  { __typename?: 'Query' }
  & { internalTerminals: Array<(
    { __typename?: 'InternalTerminal' }
    & Pick<InternalTerminal, 'id'>
    & { terminal: (
      { __typename?: 'Terminal' }
      & Pick<Terminal, 'id' | 'name'>
      & { locations: Array<(
        { __typename?: 'LocationEntity' }
        & LocationsFieldsFragment
      )>, contacts: Array<(
        { __typename?: 'CardContact' }
        & Pick<CardContact, 'id' | 'name' | 'email' | 'role'>
        & { tags: Array<(
          { __typename?: 'CardContactTag' }
          & Pick<CardContactTag, 'title'>
        )> }
      )> }
    ) }
  )> }
);

export type GetMasterValueBoxQueryVariables = Exact<{
  internalTerminalId: Scalars['Int'];
  forTerminal: Scalars['Boolean'];
}>;


export type GetMasterValueBoxQuery = (
  { __typename?: 'Query' }
  & { getMasterValueBoxes: Array<(
    { __typename?: 'MasterValueBoxResponse' }
    & Pick<MasterValueBoxResponse, 'id' | 'internalTerminalId' | 'boxName' | 'frequency' | 'isFixedBox' | 'isYesNoBox' | 'isTimeBox' | 'isAddedFromTerminal'>
  )> }
);

export type GetValueBoxForDateQueryVariables = Exact<{
  internalTerminalId: Scalars['Int'];
  date: Scalars['String'];
  forTerminal: Scalars['Boolean'];
}>;


export type GetValueBoxForDateQuery = (
  { __typename?: 'Query' }
  & { getValueBoxesForDay: Array<(
    { __typename?: 'ValueBoxForDayResponse' }
    & Pick<ValueBoxForDayResponse, 'boxId' | 'entryId' | 'boxNameText' | 'boxValue' | 'isReadOnly' | 'isFixedBox' | 'isYesNoBox' | 'isTimeBox' | 'isAddedFromTerminal'>
  )> }
);

export type GetCardTypeLocationsQueryVariables = Exact<{
  cardType: Scalars['String'];
  search?: Maybe<Scalars['String']>;
}>;


export type GetCardTypeLocationsQuery = (
  { __typename?: 'Query' }
  & { getCardTypeLocations: Array<(
    { __typename?: 'LocationEntity' }
    & Pick<LocationEntity, 'id' | 'name' | 'address' | 'city' | 'country' | 'postalCode'>
    & { card: (
      { __typename?: 'Card' }
      & Pick<Card, 'name' | 'type' | 'id'>
    ) }
  )> }
);

export type GetLocationQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetLocationQuery = (
  { __typename?: 'Query' }
  & { location: (
    { __typename?: 'LocationEntity' }
    & LocationsFieldsFragment
  ) }
);

export type GetLocationForSelectQueryVariables = Exact<{
  locationId: Scalars['Int'];
}>;


export type GetLocationForSelectQuery = (
  { __typename?: 'Query' }
  & { location: (
    { __typename?: 'LocationEntity' }
    & Pick<LocationEntity, 'id' | 'name' | 'address' | 'city' | 'country' | 'postalCode'>
    & { card: (
      { __typename?: 'Card' }
      & Pick<Card, 'name' | 'type' | 'id'>
    ) }
  ) }
);

export type GetLocationsForCardQueryVariables = Exact<{
  cardId?: Maybe<Scalars['Int']>;
}>;


export type GetLocationsForCardQuery = (
  { __typename?: 'Query' }
  & { locations: Array<(
    { __typename?: 'LocationEntity' }
    & LocationsFieldsFragment
  )> }
);

export type GetLocationsForLegQueryVariables = Exact<{
  locationsSearch?: Maybe<Scalars['String']>;
}>;


export type GetLocationsForLegQuery = (
  { __typename?: 'Query' }
  & { locations: Array<(
    { __typename?: 'LocationEntity' }
    & Pick<LocationEntity, 'id' | 'name' | 'address' | 'city' | 'country' | 'postalCode'>
    & { card: (
      { __typename?: 'Card' }
      & Pick<Card, 'name' | 'type' | 'id'>
    ) }
  )> }
);

export type GetLocationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLocationsQuery = (
  { __typename?: 'Query' }
  & { locations: Array<(
    { __typename?: 'LocationEntity' }
    & LocationsFieldsFragment
  )> }
);

export type GetNotesForCardQueryVariables = Exact<{
  cardId: Scalars['Int'];
}>;


export type GetNotesForCardQuery = (
  { __typename?: 'Query' }
  & { card: (
    { __typename?: 'Card' }
    & Pick<Card, 'id'>
    & { notes: Array<(
      { __typename?: 'CardNote' }
      & Pick<CardNote, 'createdBy' | 'createdAt' | 'id' | 'lastUpdatedBy' | 'text' | 'title' | 'updatedAt' | 'deletedBy'>
    )> }
  ) }
);

export type GetOrderQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetOrderQuery = (
  { __typename?: 'Query' }
  & { order: (
    { __typename?: 'Order' }
    & Pick<Order, 'id' | 'transportationDate' | 'customerId' | 'note' | 'pallets' | 'packages' | 'palletSpace' | 'weight' | 'updatedAt' | 'createdBy' | 'lastUpdatedBy'>
  ) }
);

export type GetOrdersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOrdersQuery = (
  { __typename?: 'Query' }
  & { orders: Array<(
    { __typename?: 'Order' }
    & Pick<Order, 'id' | 'transportationDate' | 'customerId' | 'note' | 'pallets' | 'palletSpace' | 'weight' | 'packages' | 'updatedAt' | 'createdBy' | 'lastUpdatedBy'>
    & { customer: (
      { __typename?: 'Customer' }
      & Pick<Customer, 'name'>
    ) }
  )> }
);

export type GetPeakPlanningDebitsQueryVariables = Exact<{
  startDate: Scalars['String'];
  endDate: Scalars['String'];
}>;


export type GetPeakPlanningDebitsQuery = (
  { __typename?: 'Query' }
  & { peakPlanningDebits: Array<(
    { __typename?: 'PeakPlanningDebit' }
    & Pick<PeakPlanningDebit, 'id' | 'status' | 'sporadicRouteId' | 'productionDate' | 'customerId' | 'customerNumber' | 'salesContactId' | 'destinationCountry' | 'bookedPPL' | 'sentPPL' | 'note' | 'selfCost' | 'selfCostCurrency' | 'customerCost' | 'customerCurrency' | 'createdAt' | 'updatedAt' | 'createdBy' | 'lastUpdatedBy'>
    & { sporadicRoute: (
      { __typename?: 'Route' }
      & Pick<Route, 'id' | 'driverName' | 'licensePlate' | 'agreedPrice' | 'currency' | 'driverPhoneNumber' | 'capacity' | 'routeId' | 'deletedBy' | 'createdBy' | 'lastUpdatedBy' | 'updatedAt' | 'dispatcherId' | 'note' | 'subcontractorId'>
      & { legs: Array<(
        { __typename?: 'RouteLeg' }
        & Pick<RouteLeg, 'id' | 'locationId' | 'note' | 'arrivalTime' | 'departureTime' | 'position' | 'load' | 'unload'>
        & { location?: Maybe<(
          { __typename?: 'LocationEntity' }
          & Pick<LocationEntity, 'name' | 'address' | 'postalCode' | 'city' | 'country'>
        )>, loadingListItems: Array<(
          { __typename?: 'LoadingListItem' }
          & Pick<LoadingListItem, 'id' | 'locationId' | 'packages' | 'pallets' | 'palletSpace' | 'weight' | 'note' | 'lastUpdatedBy' | 'createdBy' | 'updatedAt'>
        )> }
      )> }
    ), location: (
      { __typename?: 'LocationEntity' }
      & Pick<LocationEntity, 'id' | 'name' | 'address' | 'city' | 'country'>
    ), customer: (
      { __typename?: 'Card' }
      & Pick<Card, 'name'>
    ), salesContact?: Maybe<(
      { __typename?: 'CardContact' }
      & Pick<CardContact, 'name'>
    )> }
  )> }
);

export type GetPeakPlanningItemsQueryVariables = Exact<{
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  departmentId: Scalars['String'];
}>;


export type GetPeakPlanningItemsQuery = (
  { __typename?: 'Query' }
  & { peakPlanningItems: Array<(
    { __typename?: 'PeakPlanningItem' }
    & Pick<PeakPlanningItem, 'id' | 'note' | 'status' | 'generated' | 'responsible' | 'routeId' | 'productType' | 'productCountry' | 'productionDate' | 'subcontractorId' | 'capacity' | 'unloaded' | 'unusedCapacity' | 'utilization' | 'debitLines'>
    & { subcontractor?: Maybe<(
      { __typename?: 'Subcontractor' }
      & Pick<Subcontractor, 'name'>
    )>, peakPlanningLocations: Array<(
      { __typename?: 'PeakPlanningLocation' }
      & Pick<PeakPlanningLocation, 'id' | 'loadStart' | 'loadEnd' | 'bookedPPL' | 'position'>
      & { location?: Maybe<(
        { __typename?: 'LocationEntity' }
        & Pick<LocationEntity, 'id' | 'name' | 'address' | 'country' | 'city' | 'postalCode'>
      )> }
    )>, sporadicRoute?: Maybe<(
      { __typename?: 'Route' }
      & Pick<Route, 'id' | 'driverName' | 'licensePlate' | 'agreedPrice' | 'currency' | 'driverPhoneNumber' | 'capacity' | 'routeId' | 'deletedBy' | 'createdBy' | 'lastUpdatedBy' | 'updatedAt' | 'deletedAt' | 'debitsCount' | 'dispatcherId' | 'note' | 'subcontractorId'>
      & { legs: Array<(
        { __typename?: 'RouteLeg' }
        & Pick<RouteLeg, 'id' | 'locationId' | 'note' | 'arrivalTime' | 'departureTime' | 'position' | 'load' | 'unload'>
        & { location?: Maybe<(
          { __typename?: 'LocationEntity' }
          & Pick<LocationEntity, 'name' | 'address' | 'postalCode' | 'city' | 'country'>
        )>, loadingListItems: Array<(
          { __typename?: 'LoadingListItem' }
          & Pick<LoadingListItem, 'id' | 'locationId' | 'packages' | 'pallets' | 'palletSpace' | 'weight' | 'note' | 'lastUpdatedBy' | 'createdBy' | 'updatedAt'>
        )> }
      )> }
    )> }
  )> }
);

export type GetAllRouteGroupTagsQueryVariables = Exact<{
  transportDate: Scalars['String'];
}>;


export type GetAllRouteGroupTagsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getAllRouteGroupTags'>
);

export type GetIncomingRoutesToTerminalQueryVariables = Exact<{
  terminalId: Scalars['Int'];
  date: Scalars['String'];
}>;


export type GetIncomingRoutesToTerminalQuery = (
  { __typename?: 'Query' }
  & { incomingRoutesToTerminal: (
    { __typename?: 'IncomingRoutesToTerminal' }
    & { inbound?: Maybe<Array<(
      { __typename?: 'ReportToTerminalInboundOutboundRouteList' }
      & Pick<ReportToTerminalInboundOutboundRouteList, 'id' | 'routeEntityId' | 'routeId' | 'subcontractorId' | 'subcontractorName' | 'licensePlateAndRouteNote' | 'capacity' | 'arrivalTime' | 'departureTime'>
    )>>, outbound?: Maybe<Array<(
      { __typename?: 'ReportToTerminalInboundOutboundRouteList' }
      & Pick<ReportToTerminalInboundOutboundRouteList, 'id' | 'routeEntityId' | 'routeId' | 'subcontractorId' | 'subcontractorName' | 'licensePlateAndRouteNote' | 'capacity' | 'arrivalTime' | 'departureTime'>
    )>> }
  ) }
);

export type GetLegsMatchingShipmentQueryVariables = Exact<{
  fromLocationId: Scalars['Int'];
  toLocationId: Scalars['Int'];
  transportDate: Scalars['String'];
}>;


export type GetLegsMatchingShipmentQuery = (
  { __typename?: 'Query' }
  & { fetchLegsMatchingShipment: Array<(
    { __typename?: 'RouteLeg' }
    & Pick<RouteLeg, 'id' | 'position' | 'arrivalTime'>
    & { route: (
      { __typename?: 'Route' }
      & Pick<Route, 'id' | 'routeId' | 'type'>
      & { routeLock?: Maybe<(
        { __typename?: 'RouteLock' }
        & Pick<RouteLock, 'id' | 'lockedAt' | 'lockedBy' | 'lockedByUserId' | 'isChecklistLocked'>
      )> }
    ) }
  )> }
);

export type GetMultipleDatesQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetMultipleDatesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'multipleDates'>
);

export type GetProductionGroupQueryVariables = Exact<{
  terminalId: Scalars['Int'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
}>;


export type GetProductionGroupQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getProductionGroup'>
);

export type GetRouteQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetRouteQuery = (
  { __typename?: 'Query' }
  & { route: (
    { __typename?: 'Route' }
    & Pick<Route, 'routeAgreementNo' | 'agreedPrice' | 'isCreatedFromTerminal' | 'isCreatedFromPlanned' | 'capacity' | 'kilometer' | 'weight' | 'currency' | 'dispatcherId' | 'driverName' | 'driverPhoneNumber' | 'routeDriverName' | 'routeDriverPhoneNumber' | 'routeCarRegNumber' | 'routeTrailerRegNumber' | 'isCopyDriverPlateInfo' | 'lastUpdatedBy' | 'updatedAt' | 'createdBy' | 'deletedAt' | 'deletedBy' | 'id' | 'invoiceNote' | 'invoiceNumber' | 'licensePlate' | 'note' | 'externalNote' | 'routeId' | 'subcontractorId' | 'transportationDate' | 'type'>
    & { files: Array<(
      { __typename?: 'DocumentFile' }
      & Pick<DocumentFile, 'id' | 'mimetype' | 'originalname' | 'path' | 'size' | 'createdBy' | 'createdAt'>
      & { tags: Array<(
        { __typename?: 'DocumentFileTag' }
        & Pick<DocumentFileTag, 'id' | 'title'>
      )> }
    )>, ftl?: Maybe<(
      { __typename?: 'RouteFTL' }
      & Pick<RouteFtl, 'cdc' | 'cmr' | 'currency' | 'customerId' | 'customerNumber' | 'customerReference' | 'id' | 'invoiceNote' | 'price'>
    )>, routeLock?: Maybe<(
      { __typename?: 'RouteLock' }
      & Pick<RouteLock, 'id' | 'routeId' | 'lockedAt' | 'lockedBy' | 'isChecklistLocked'>
    )>, costAllocation?: Maybe<(
      { __typename?: 'CostAllocation' }
      & Pick<CostAllocation, 'id'>
      & { items: Array<(
        { __typename?: 'CostAllocationItem' }
        & Pick<CostAllocationItem, 'id' | 'departmentId' | 'cost' | 'includedKpi' | 'includedPrice' | 'bringCost' | 'additionalCost' | 'additionalDiscount' | 'type' | 'comment'>
      )> }
    )>, legs: Array<(
      { __typename?: 'RouteLeg' }
      & Pick<RouteLeg, 'gateNumber' | 'carRegistrationNumber' | 'trailerRegistrationNumber' | 'routeDriverName' | 'routeDriverPhoneNumber' | 'arrivalTime' | 'departureTime' | 'fuel' | 'id' | 'load' | 'note' | 'position' | 'unload' | 'isNonArrivalMarked' | 'nonArrivalMarkedFrom' | 'productionDate' | 'transportationDate' | 'transportationDateOffset' | 'locationId'>
      & { loadingListItems: Array<(
        { __typename?: 'LoadingListItem' }
        & Pick<LoadingListItem, 'id' | 'locationId' | 'packages' | 'pallets' | 'palletSpace' | 'weight' | 'checked' | 'isDangerous' | 'classification' | 'unNumber' | 'isLimitedQty' | 'note' | 'isImportedFromShipment' | 'customWaybillNo' | 'lastUpdatedBy' | 'createdBy' | 'updatedAt'>
        & { location?: Maybe<(
          { __typename?: 'LocationEntity' }
          & Pick<LocationEntity, 'id' | 'name' | 'address' | 'postalCode' | 'city' | 'country'>
        )> }
      )> }
    )>, tourRoute?: Maybe<(
      { __typename?: 'TourRouteTemplate' }
      & Pick<TourRouteTemplate, 'id'>
      & { bookmarkTourRouteTemplate?: Maybe<Array<(
        { __typename?: 'BookmarkTourRouteTemplate' }
        & Pick<BookmarkTourRouteTemplate, 'bookmark'>
      )>> }
    )> }
    & TransportAgreementFieldsFragment
  ) }
);

export type GetRouteLegForViewLoadingListQueryVariables = Exact<{
  legId: Scalars['Int'];
}>;


export type GetRouteLegForViewLoadingListQuery = (
  { __typename?: 'Query' }
  & { routeLegForViewLoadingList: Array<(
    { __typename?: 'RouteLeg' }
    & Pick<RouteLeg, 'id' | 'load' | 'position' | 'locationId'>
    & { loadingListItems: Array<(
      { __typename?: 'LoadingListItem' }
      & Pick<LoadingListItem, 'id' | 'locationId' | 'packages' | 'pallets' | 'palletSpace' | 'weight' | 'checked' | 'isDangerous' | 'classification' | 'unNumber' | 'isLimitedQty' | 'note' | 'isImportedFromShipment' | 'customWaybillNo'>
    )>, route: (
      { __typename?: 'Route' }
      & Pick<Route, 'createdBy' | 'updatedAt' | 'lastUpdatedBy' | 'deletedBy' | 'deletedAt'>
    ) }
  )> }
);

export type GetRouteLegsForProdAppQueryVariables = Exact<{
  unitId: Scalars['String'];
  event: Scalars['String'];
  futureHours: Scalars['Int'];
  cetDateTimeString?: Maybe<Scalars['String']>;
  pastHours?: Maybe<Scalars['Int']>;
  gateNumbers?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  tripStatus?: Maybe<Scalars['String']>;
}>;


export type GetRouteLegsForProdAppQuery = (
  { __typename?: 'Query' }
  & { fetchRoutesForProdApp: Array<(
    { __typename?: 'RouteLeg' }
    & Pick<RouteLeg, 'id' | 'position' | 'transportationDate' | 'arrivalTime' | 'actualArrivalTime' | 'actualArrivalDate' | 'departureTime' | 'actualDepartureTime' | 'actualDepartureDate' | 'gateNumber' | 'carRegistrationNumber' | 'trailerRegistrationNumber' | 'routeDriverName' | 'sealNumber' | 'tripStartDateTime' | 'tripEndDateTime' | 'actualCapacity'>
    & { route: (
      { __typename?: 'Route' }
      & Pick<Route, 'id' | 'routeId' | 'capacity' | 'kilometer' | 'type'>
      & { departureAddress?: Maybe<(
        { __typename?: 'LocationEntity' }
        & Pick<LocationEntity, 'city' | 'country'>
      )>, arrivalAddress?: Maybe<(
        { __typename?: 'LocationEntity' }
        & Pick<LocationEntity, 'city' | 'country'>
      )> }
    ) }
  )> }
);

export type GetRouteLegsForProdAppWithTokenQueryVariables = Exact<{
  unitId: Scalars['String'];
  event: Scalars['String'];
  futureHours: Scalars['Int'];
  cetDateTimeString?: Maybe<Scalars['String']>;
  pastHours?: Maybe<Scalars['Int']>;
  gateNumbers?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  tripStatus?: Maybe<Scalars['String']>;
}>;


export type GetRouteLegsForProdAppWithTokenQuery = (
  { __typename?: 'Query' }
  & { fetchRoutesForProdAppWithToken: Array<(
    { __typename?: 'RouteLeg' }
    & Pick<RouteLeg, 'id' | 'position' | 'transportationDate' | 'arrivalTime' | 'actualArrivalTime' | 'actualArrivalDate' | 'departureTime' | 'actualDepartureTime' | 'actualDepartureDate' | 'gateNumber' | 'carRegistrationNumber' | 'trailerRegistrationNumber' | 'routeDriverName' | 'sealNumber' | 'tripStartDateTime' | 'tripEndDateTime' | 'actualCapacity'>
    & { route: (
      { __typename?: 'Route' }
      & Pick<Route, 'id' | 'routeId' | 'capacity' | 'kilometer' | 'type'>
      & { departureAddress?: Maybe<(
        { __typename?: 'LocationEntity' }
        & Pick<LocationEntity, 'city' | 'country'>
      )>, arrivalAddress?: Maybe<(
        { __typename?: 'LocationEntity' }
        & Pick<LocationEntity, 'city' | 'country'>
      )> }
    ) }
  )> }
);

export type GetRouteLockQueryVariables = Exact<{
  routeId: Scalars['Int'];
}>;


export type GetRouteLockQuery = (
  { __typename?: 'Query' }
  & { routeLock: (
    { __typename?: 'RouteLock' }
    & Pick<RouteLock, 'lockedBy' | 'lockedAt' | 'isChecklistLocked'>
  ) }
);

export type GetTransportAgreementDataQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetTransportAgreementDataQuery = (
  { __typename?: 'Query' }
  & { route: (
    { __typename?: 'Route' }
    & TransportAgreementFieldsFragment
  ) }
);

export type ListAssociatedRoutesQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ListAssociatedRoutesQuery = (
  { __typename?: 'Query' }
  & { associatedRoutes: Array<(
    { __typename?: 'AssociatedRoutesResponse' }
    & Pick<AssociatedRoutesResponse, 'id' | 'routeId' | 'routePosition' | 'tourId' | 'routeName' | 'tourName' | 'departureLocation' | 'departureTime' | 'arrivalLocation' | 'arrivalTime' | 'subContractorName' | 'frequency'>
  )> }
);

export type ListRoutesQueryVariables = Exact<{
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  type?: Maybe<RouteType>;
}>;


export type ListRoutesQuery = (
  { __typename?: 'Query' }
  & { routes: Array<(
    { __typename?: 'Route' }
    & Pick<Route, 'id' | 'isCreatedFromTerminal' | 'isCreatedFromPlanned' | 'routeId' | 'invoiceNumber' | 'invoiceNote' | 'transportationDate' | 'agreedPrice' | 'currency' | 'driverName' | 'driverPhoneNumber' | 'licensePlate' | 'fuel' | 'capacity' | 'kilometer' | 'weight' | 'note' | 'externalNote'>
    & { dispatcher?: Maybe<(
      { __typename?: 'CardContact' }
      & Pick<CardContact, 'name'>
    )>, subcontractor: (
      { __typename?: 'Subcontractor' }
      & Pick<Subcontractor, 'name'>
    ), legs: Array<(
      { __typename?: 'RouteLeg' }
      & Pick<RouteLeg, 'isNonArrivalMarked' | 'arrivalTime' | 'departureTime' | 'position'>
      & { location?: Maybe<(
        { __typename?: 'LocationEntity' }
        & Pick<LocationEntity, 'name' | 'city' | 'country'>
      )> }
    )>, ftl?: Maybe<(
      { __typename?: 'RouteFTL' }
      & Pick<RouteFtl, 'customerNumber' | 'price' | 'currency' | 'invoiceNote' | 'cmr' | 'cdc'>
    )>, tour?: Maybe<(
      { __typename?: 'TourTemplate' }
      & Pick<TourTemplate, 'name'>
    )> }
  )> }
);

export type PingRouteLockUserQueryVariables = Exact<{
  routeId: Scalars['Int'];
}>;


export type PingRouteLockUserQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'pingUser'>
);

export type RefetchRouteLegQueryVariables = Exact<{
  legId: Scalars['Int'];
  terminalId: Scalars['Int'];
}>;


export type RefetchRouteLegQuery = (
  { __typename?: 'Query' }
  & { refetchLatestLeg: (
    { __typename?: 'RouteLeg' }
    & LiveUpdateRouteLegFragment
  ) }
);

export type GetSporadicRouteTemplateQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetSporadicRouteTemplateQuery = (
  { __typename?: 'Query' }
  & { sporadicRouteTemplate: (
    { __typename?: 'SporadicRouteTemplate' }
    & Pick<SporadicRouteTemplate, 'id' | 'name' | 'test' | 'driverName' | 'licensePlate' | 'agreedPrice' | 'currency' | 'driverPhoneNumber' | 'capacity' | 'kilometer' | 'weight' | 'routeId' | 'deletedBy' | 'createdBy' | 'lastUpdatedBy' | 'updatedAt' | 'note' | 'externalNote'>
    & { dispatcher?: Maybe<(
      { __typename?: 'CardContact' }
      & Pick<CardContact, 'id'>
    )>, subcontractor?: Maybe<(
      { __typename?: 'Subcontractor' }
      & Pick<Subcontractor, 'id'>
    )>, files: Array<(
      { __typename?: 'DocumentFile' }
      & Pick<DocumentFile, 'originalname' | 'id' | 'path' | 'createdBy' | 'createdAt'>
    )>, ftl?: Maybe<(
      { __typename?: 'RouteFTLTemplate' }
      & Pick<RouteFtlTemplate, 'id' | 'cdc' | 'cmr' | 'currency' | 'customerId' | 'customerNumber' | 'customerReference' | 'invoiceNote' | 'price'>
    )>, legs: Array<(
      { __typename?: 'SporadicRouteLegTemplate' }
      & Pick<SporadicRouteLegTemplate, 'fuel' | 'gateNumber' | 'carRegistrationNumber' | 'trailerRegistrationNumber' | 'routeDriverName' | 'routeDriverPhoneNumber' | 'id' | 'locationId' | 'note' | 'arrivalTime' | 'departureTime' | 'position' | 'load' | 'unload' | 'transportationDateOffset'>
      & { location: (
        { __typename?: 'LocationEntity' }
        & Pick<LocationEntity, 'deletedBy'>
      ), loadingListItems: Array<(
        { __typename?: 'SporadicRouteLoadingListItemTemplate' }
        & Pick<SporadicRouteLoadingListItemTemplate, 'id' | 'locationId' | 'packages' | 'pallets' | 'palletSpace' | 'weight' | 'note' | 'checked' | 'isDangerous' | 'classification' | 'unNumber' | 'isLimitedQty' | 'lastUpdatedBy' | 'createdBy' | 'updatedAt'>
      )> }
    )> }
  ) }
);

export type SporadicRouteTemplatesQueryVariables = Exact<{ [key: string]: never; }>;


export type SporadicRouteTemplatesQuery = (
  { __typename?: 'Query' }
  & { sporadicRouteTemplates: Array<(
    { __typename?: 'SporadicRouteTemplate' }
    & Pick<SporadicRouteTemplate, 'id' | 'name' | 'routeId' | 'createdAt'>
    & { legs: Array<(
      { __typename?: 'SporadicRouteLegTemplate' }
      & Pick<SporadicRouteLegTemplate, 'position'>
      & { location: (
        { __typename?: 'LocationEntity' }
        & Pick<LocationEntity, 'country'>
      ) }
    )> }
  )> }
);

export type GetSubcontractorQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetSubcontractorQuery = (
  { __typename?: 'Query' }
  & { subcontractor: (
    { __typename?: 'Subcontractor' }
    & Pick<Subcontractor, 'id' | 'name' | 'createdBy' | 'updatedAt' | 'lastUpdatedBy'>
    & { files: Array<(
      { __typename?: 'CardFile' }
      & Pick<CardFile, 'originalname' | 'path' | 'id' | 'createdAt' | 'mimetype' | 'size' | 'createdBy'>
    )>, contacts: Array<(
      { __typename?: 'CardContact' }
      & CardContactFieldsFragment
    )> }
  ) }
);

export type GetSubcontractorsQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  withTerminal?: Maybe<Scalars['Boolean']>;
}>;


export type GetSubcontractorsQuery = (
  { __typename?: 'Query' }
  & { subcontractors: Array<(
    { __typename?: 'Subcontractor' }
    & Pick<Subcontractor, 'id' | 'name'>
    & { locations: Array<(
      { __typename?: 'LocationEntity' }
      & LocationsFieldsFragment
    )>, contacts: Array<(
      { __typename?: 'CardContact' }
      & Pick<CardContact, 'id' | 'name'>
    )> }
  )> }
);

export type SearchSubcontractorsQueryVariables = Exact<{
  subcontractorsSearch?: Maybe<Scalars['String']>;
}>;


export type SearchSubcontractorsQuery = (
  { __typename?: 'Query' }
  & { subcontractors: Array<(
    { __typename?: 'Subcontractor' }
    & Pick<Subcontractor, 'id' | 'name'>
    & { contacts: Array<(
      { __typename?: 'CardContact' }
      & Pick<CardContact, 'id' | 'name'>
    )> }
  )> }
);

export type GetTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTagsQuery = (
  { __typename?: 'Query' }
  & { cardContactTags: Array<(
    { __typename?: 'CardContactTag' }
    & Pick<CardContactTag, 'id' | 'title'>
  )> }
);

export type GetOneTerminalQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetOneTerminalQuery = (
  { __typename?: 'Query' }
  & { terminal: (
    { __typename?: 'Terminal' }
    & Pick<Terminal, 'id' | 'name' | 'isInternalTerminal' | 'createdBy' | 'updatedAt' | 'lastUpdatedBy'>
    & { locations: Array<(
      { __typename?: 'LocationEntity' }
      & Pick<LocationEntity, 'id'>
    )>, files: Array<(
      { __typename?: 'CardFile' }
      & Pick<CardFile, 'originalname' | 'path' | 'id' | 'createdAt' | 'mimetype' | 'size' | 'createdBy'>
    )>, contacts: Array<(
      { __typename?: 'CardContact' }
      & CardContactFieldsFragment
    )> }
  ) }
);

export type GetTerminalsQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
}>;


export type GetTerminalsQuery = (
  { __typename?: 'Query' }
  & { terminals: Array<(
    { __typename?: 'Terminal' }
    & Pick<Terminal, 'id' | 'name'>
    & { locations: Array<(
      { __typename?: 'LocationEntity' }
      & LocationsFieldsFragment
    )> }
  )> }
);

export type BookmarkedDispatchedRoutesQueryVariables = Exact<{
  date?: Maybe<Scalars['String']>;
}>;


export type BookmarkedDispatchedRoutesQuery = (
  { __typename?: 'Query' }
  & { bookmarkedDispatchedRoutes: Array<(
    { __typename?: 'BookMarkItemResponse' }
    & Pick<BookMarkItemResponse, 'id' | 'routeId' | 'tourRouteId' | 'capacity' | 'truckLicencePlateNo' | 'status'>
    & { legs?: Maybe<Array<(
      { __typename?: 'RouteLeg' }
      & Pick<RouteLeg, 'id'>
      & { loadingListItems: Array<(
        { __typename?: 'LoadingListItem' }
        & Pick<LoadingListItem, 'id' | 'packages' | 'pallets' | 'palletSpace' | 'weight' | 'note' | 'checked'>
      )> }
    )>> }
  )> }
);

export type GetOneTourRouteQueryVariables = Exact<{
  routeId: Scalars['Int'];
}>;


export type GetOneTourRouteQuery = (
  { __typename?: 'Query' }
  & { getTourRoute: (
    { __typename?: 'TourRouteTemplate' }
    & Pick<TourRouteTemplate, 'id' | 'routeId' | 'note' | 'externalNote' | 'days' | 'price' | 'createdAt' | 'updatedAt' | 'routeDriverName' | 'routeDriverPhoneNumber' | 'routeCarRegNumber' | 'routeTrailerRegNumber' | 'isCopyDriverPlateInfo' | 'capacity' | 'kilometer' | 'weight' | 'startDate' | 'originalStartDate' | 'endDate' | 'startExceptionDate' | 'endExceptionDate' | 'exceptionDates' | 'routeDateType' | 'tourRouteType'>
    & { files: Array<(
      { __typename?: 'DocumentFile' }
      & Pick<DocumentFile, 'originalname' | 'id' | 'path' | 'createdBy' | 'createdAt' | 'mimetype' | 'size'>
      & { tags: Array<(
        { __typename?: 'DocumentFileTag' }
        & Pick<DocumentFileTag, 'id' | 'title' | 'createdAt' | 'updatedAt'>
      )> }
    )>, ftl?: Maybe<(
      { __typename?: 'TourRouteFTL' }
      & Pick<TourRouteFtl, 'cdc' | 'cmr' | 'currency' | 'customerId' | 'customerNumber' | 'customerReference' | 'id' | 'invoiceNote' | 'price'>
    )>, legs: Array<(
      { __typename?: 'TourRouteLegTemplate' }
      & Pick<TourRouteLegTemplate, 'id' | 'fuel' | 'locationId' | 'gateNumber' | 'carRegistrationNumber' | 'trailerRegistrationNumber' | 'routeDriverName' | 'routeDriverPhoneNumber' | 'note' | 'arrivalTime' | 'departureTime' | 'position' | 'load' | 'unload' | 'transportationDateOffset'>
      & { location: (
        { __typename?: 'LocationEntity' }
        & Pick<LocationEntity, 'name' | 'city' | 'country'>
      ), loadingListItems: Array<(
        { __typename?: 'TourRouteLegLoadingListItemTemplate' }
        & Pick<TourRouteLegLoadingListItemTemplate, 'id' | 'locationId' | 'packages' | 'pallets' | 'palletSpace' | 'weight' | 'checked' | 'isDangerous' | 'classification' | 'unNumber' | 'isLimitedQty' | 'note' | 'lastUpdatedBy' | 'createdBy' | 'updatedAt'>
      )> }
    )>, costAllocations: Array<(
      { __typename?: 'TourRouteCostAllocationItem' }
      & Pick<TourRouteCostAllocationItem, 'id' | 'departmentId' | 'cost' | 'includedKpi' | 'includedPrice' | 'bringCost' | 'additionalCost' | 'additionalDiscount' | 'type' | 'comment'>
    )> }
  ) }
);

export type GetTourRouteTemplatesQueryVariables = Exact<{
  fetchBookmarked?: Maybe<Scalars['Boolean']>;
}>;


export type GetTourRouteTemplatesQuery = (
  { __typename?: 'Query' }
  & { tourRouteTemplates: Array<(
    { __typename?: 'TourRouteTemplate' }
    & Pick<TourRouteTemplate, 'id' | 'routeId' | 'price' | 'days' | 'note' | 'externalNote' | 'position'>
    & { departureLocation?: Maybe<(
      { __typename?: 'LocationEntity' }
      & Pick<LocationEntity, 'city' | 'country'>
    )>, arrivalLocation?: Maybe<(
      { __typename?: 'LocationEntity' }
      & Pick<LocationEntity, 'city' | 'country'>
    )>, tour: (
      { __typename?: 'TourTemplate' }
      & Pick<TourTemplate, 'id' | 'name' | 'capacity' | 'currency' | 'isActive' | 'fuel' | 'startDate' | 'endDate' | 'startExceptionDate' | 'endExceptionDate' | 'createdBy' | 'lastUpdatedBy' | 'updatedAt'>
      & { subcontractor: (
        { __typename?: 'Subcontractor' }
        & Pick<Subcontractor, 'name'>
      ), dispatcher?: Maybe<(
        { __typename?: 'CardContact' }
        & Pick<CardContact, 'name'>
      )> }
    ), legs: Array<(
      { __typename?: 'TourRouteLegTemplate' }
      & Pick<TourRouteLegTemplate, 'id' | 'locationId' | 'note' | 'arrivalTime' | 'departureTime' | 'position' | 'load' | 'unload' | 'transportationDateOffset'>
      & { location: (
        { __typename?: 'LocationEntity' }
        & Pick<LocationEntity, 'name' | 'city' | 'country'>
      ), loadingListItems: Array<(
        { __typename?: 'TourRouteLegLoadingListItemTemplate' }
        & Pick<TourRouteLegLoadingListItemTemplate, 'id' | 'locationId' | 'packages' | 'pallets' | 'palletSpace' | 'weight' | 'note' | 'checked' | 'isDangerous' | 'classification' | 'unNumber' | 'isLimitedQty' | 'lastUpdatedBy' | 'createdBy' | 'updatedAt'>
      )> }
    )> }
  )> }
);

export type GetGroupingTagQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGroupingTagQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'tourTemplatesWithGroupTag'>
);

export type GetOtherToursQueryVariables = Exact<{
  excludeId: Scalars['Int'];
  searchTerm?: Maybe<Scalars['String']>;
}>;


export type GetOtherToursQuery = (
  { __typename?: 'Query' }
  & { otherTours: Array<(
    { __typename?: 'TourTemplate' }
    & Pick<TourTemplate, 'id' | 'name' | 'subcontractorId' | 'capacity' | 'fuel' | 'price' | 'currency' | 'startDate' | 'endDate' | 'isActive' | 'note' | 'createdBy' | 'lastUpdatedBy' | 'updatedAt' | 'startExceptionDate' | 'endExceptionDate'>
    & { subcontractor: (
      { __typename?: 'Subcontractor' }
      & Pick<Subcontractor, 'name'>
    ) }
  )> }
);

export type GetResponsiblePersonQueryVariables = Exact<{ [key: string]: never; }>;


export type GetResponsiblePersonQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'tourTemplatesWithResponsiblePerson'>
);

export type GetTourTemplateQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetTourTemplateQuery = (
  { __typename?: 'Query' }
  & { tourTemplate: (
    { __typename?: 'TourTemplate' }
    & Pick<TourTemplate, 'id' | 'name' | 'tourAgreementNo' | 'subcontractorId' | 'dispatcherId' | 'createdBy' | 'capacity' | 'fuel' | 'price' | 'currency' | 'startDate' | 'endDate' | 'originalStartDate' | 'startExceptionDate' | 'endExceptionDate' | 'exceptionDates' | 'note' | 'driverName' | 'licensePlate' | 'driverPhoneNumber' | 'responsiblePerson' | 'groupingTag' | 'isPriceEquallyDivided' | 'isNewVersion' | 'lastUpdatedBy' | 'createdAt' | 'updatedAt'>
    & { subcontractor: (
      { __typename?: 'Subcontractor' }
      & Pick<Subcontractor, 'name'>
    ), dispatcher?: Maybe<(
      { __typename?: 'CardContact' }
      & Pick<CardContact, 'name'>
    )>, routes: Array<(
      { __typename?: 'TourRouteTemplate' }
      & Pick<TourRouteTemplate, 'id' | 'capacity' | 'kilometer' | 'weight' | 'startDate' | 'originalStartDate' | 'endDate' | 'startExceptionDate' | 'endExceptionDate' | 'exceptionDates' | 'routeDateType' | 'tourRouteType' | 'routeId' | 'note' | 'externalNote' | 'routeDriverName' | 'routeDriverPhoneNumber' | 'routeCarRegNumber' | 'routeTrailerRegNumber' | 'isCopyDriverPlateInfo' | 'days' | 'price' | 'createdAt' | 'updatedAt'>
      & { ftl?: Maybe<(
        { __typename?: 'TourRouteFTL' }
        & Pick<TourRouteFtl, 'cdc' | 'cmr' | 'currency' | 'customerId' | 'customerNumber' | 'customerReference' | 'id' | 'invoiceNote' | 'price'>
      )>, files: Array<(
        { __typename?: 'DocumentFile' }
        & Pick<DocumentFile, 'originalname' | 'id' | 'path' | 'createdBy' | 'createdAt' | 'mimetype' | 'size'>
        & { tags: Array<(
          { __typename?: 'DocumentFileTag' }
          & Pick<DocumentFileTag, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, legs: Array<(
        { __typename?: 'TourRouteLegTemplate' }
        & Pick<TourRouteLegTemplate, 'id' | 'fuel' | 'locationId' | 'gateNumber' | 'carRegistrationNumber' | 'trailerRegistrationNumber' | 'routeDriverName' | 'routeDriverPhoneNumber' | 'note' | 'arrivalTime' | 'departureTime' | 'position' | 'load' | 'unload' | 'transportationDateOffset'>
        & { location: (
          { __typename?: 'LocationEntity' }
          & Pick<LocationEntity, 'name' | 'city' | 'country' | 'postalCode' | 'address' | 'deletedBy'>
        ), loadingListItems: Array<(
          { __typename?: 'TourRouteLegLoadingListItemTemplate' }
          & Pick<TourRouteLegLoadingListItemTemplate, 'id' | 'locationId' | 'packages' | 'pallets' | 'palletSpace' | 'weight' | 'note' | 'checked' | 'isDangerous' | 'classification' | 'unNumber' | 'isLimitedQty' | 'lastUpdatedBy' | 'createdBy' | 'updatedAt'>
        )> }
      )>, costAllocations: Array<(
        { __typename?: 'TourRouteCostAllocationItem' }
        & Pick<TourRouteCostAllocationItem, 'id' | 'departmentId' | 'cost' | 'includedKpi' | 'includedPrice' | 'bringCost' | 'additionalCost' | 'additionalDiscount' | 'type' | 'comment'>
      )> }
    )> }
  ) }
);

export type GetTourTemplatesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTourTemplatesQuery = (
  { __typename?: 'Query' }
  & { tourTemplates: Array<(
    { __typename?: 'TourTemplate' }
    & Pick<TourTemplate, 'id' | 'name' | 'subcontractorId' | 'capacity' | 'fuel' | 'price' | 'currency' | 'startDate' | 'endDate' | 'originalStartDate' | 'isActive' | 'note' | 'createdBy' | 'lastUpdatedBy' | 'updatedAt' | 'startExceptionDate' | 'endExceptionDate' | 'exceptionDates' | 'responsiblePerson' | 'groupingTag' | 'isPriceEquallyDivided' | 'isNewVersion'>
    & { subcontractor: (
      { __typename?: 'Subcontractor' }
      & Pick<Subcontractor, 'name'>
    ), dispatcher?: Maybe<(
      { __typename?: 'CardContact' }
      & Pick<CardContact, 'name'>
    )> }
  )> }
);

export type GetMeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMeQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'PublicUser' }
    & Pick<PublicUser, 'id' | 'createdAt' | 'department' | 'firstName' | 'fullName' | 'configuration' | 'trafficAccess' | 'terminalAccess' | 'customsAccess' | 'admin' | 'lastName' | 'roles' | 'internalTerminalId'>
    & { internalTerminal?: Maybe<(
      { __typename?: 'InternalTerminal' }
      & Pick<InternalTerminal, 'terminalId' | 'id' | 'name'>
      & { terminal: (
        { __typename?: 'Terminal' }
        & Pick<Terminal, 'name'>
        & { locations: Array<(
          { __typename?: 'LocationEntity' }
          & Pick<LocationEntity, 'id' | 'country'>
        )> }
      ) }
    )> }
  ) }
);

export type GetTftConfigQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTftConfigQuery = (
  { __typename?: 'Query' }
  & { getTftConfig: (
    { __typename?: 'PublicUser' }
    & Pick<PublicUser, 'id' | 'tftConfiguration'>
  ) }
);

export type GetUserWithTerminalQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserWithTerminalQuery = (
  { __typename?: 'Query' }
  & { getUserTerminalData: (
    { __typename?: 'ResponseUserDataWithTerminal' }
    & Pick<ResponseUserDataWithTerminal, 'internalTerminalId' | 'reportExportedAt' | 'reportExportedBy' | 'userFullName'>
  ) }
);

export type UpdateTftConfigurationMutationVariables = Exact<{
  input: UserConfigurationInput;
}>;


export type UpdateTftConfigurationMutation = (
  { __typename?: 'Mutation' }
  & { updateTftConfiguration: (
    { __typename?: 'PublicUser' }
    & Pick<PublicUser, 'id'>
  ) }
);

export type UpdateUserConfigurationMutationVariables = Exact<{
  input: UserConfigurationInput;
}>;


export type UpdateUserConfigurationMutation = (
  { __typename?: 'Mutation' }
  & { updateConfiguration: (
    { __typename?: 'PublicUser' }
    & Pick<PublicUser, 'id' | 'createdAt' | 'department' | 'firstName' | 'fullName' | 'configuration' | 'lastName' | 'roles' | 'internalTerminalId'>
    & { internalTerminal?: Maybe<(
      { __typename?: 'InternalTerminal' }
      & Pick<InternalTerminal, 'terminalId' | 'id' | 'name'>
      & { terminal: (
        { __typename?: 'Terminal' }
        & Pick<Terminal, 'name'>
      ) }
    )> }
  ) }
);

export type ChecklistResetSubSubscriptionVariables = Exact<{
  ownerUserId: Scalars['String'];
  forGroup: Scalars['String'];
}>;


export type ChecklistResetSubSubscription = (
  { __typename?: 'Subscription' }
  & { checklistReset: (
    { __typename?: 'checklistResetSubscription' }
    & Pick<ChecklistResetSubscription, 'ownerUserId' | 'forGroup'>
  ) }
);

export type RouteLockSubSubscriptionVariables = Exact<{
  routeId: Scalars['Int'];
}>;


export type RouteLockSubSubscription = (
  { __typename?: 'Subscription' }
  & { routeLockSub: (
    { __typename?: 'routeLockSubscription' }
    & Pick<RouteLockSubscription, 'lockedBy' | 'lockedAt' | 'lock' | 'routeId' | 'reload'>
  ) }
);

export type RouteLockPingSubSubscriptionVariables = Exact<{
  routeId: Scalars['Int'];
}>;


export type RouteLockPingSubSubscription = (
  { __typename?: 'Subscription' }
  & { routeLockPing: (
    { __typename?: 'routeLockPingSubscription' }
    & Pick<RouteLockPingSubscription, 'lockedByUserId' | 'pingedByUserName' | 'routeId'>
  ) }
);

export type ShipmentLinkUnlinkSubSubscriptionVariables = Exact<{
  terminalId: Scalars['Int'];
  transDate: Scalars['String'];
}>;


export type ShipmentLinkUnlinkSubSubscription = (
  { __typename?: 'Subscription' }
  & { shipmentLinkUnlinkSub: (
    { __typename?: 'ShipmentLinkUnlinkSubscription' }
    & Pick<ShipmentLinkUnlinkSubscription, 'terminalId' | 'transDate' | 'isLink' | 'routeId'>
  ) }
);

export type ShipmentListUpdateSubSubscriptionVariables = Exact<{
  startDate: Scalars['String'];
  endDate: Scalars['String'];
}>;


export type ShipmentListUpdateSubSubscription = (
  { __typename?: 'Subscription' }
  & { shipmentListUpdateSub: (
    { __typename?: 'ShipmentListUpdateSubscription' }
    & Pick<ShipmentListUpdateSubscription, 'shipmentDate'>
  ) }
);

export type ShipmentPartialUpdateSubSubscriptionVariables = Exact<{
  shipmentId: Scalars['Int'];
  userId: Scalars['String'];
}>;


export type ShipmentPartialUpdateSubSubscription = (
  { __typename?: 'Subscription' }
  & { shipmentPartialUpdateSub: (
    { __typename?: 'ShipmentPartialUpdateSubscription' }
    & Pick<ShipmentPartialUpdateSubscription, 'shipmentId' | 'orderId' | 'forFieldName' | 'forFieldValue' | 'userId'>
  ) }
);

export type TruckFillAndTimeUpdateSubscriptionVariables = Exact<{
  terminalId: Scalars['Int'];
}>;


export type TruckFillAndTimeUpdateSubscription = (
  { __typename?: 'Subscription' }
  & { truckFillAndTimeUpdate: (
    { __typename?: 'TruckFillAndTimeSubscription' }
    & Pick<TruckFillAndTimeSubscription, 'refreshWholeGrid' | 'terminalId'>
    & { routeLeg: (
      { __typename?: 'RouteLeg' }
      & LiveUpdateRouteLegFragment
    ) }
  ) }
);

export const CardContactFieldsFragmentDoc = gql`
    fragment CardContactFields on CardContact {
  id
  name
  role
  email
  phoneNumber
  tags {
    id
    title
  }
  note
  lastUpdatedBy
  createdBy
}
    `;
export const CustomerAccountFieldsFragmentDoc = gql`
    fragment CustomerAccountFields on CustomerAccount {
  id
  accountNumber
  salesPersonName
  logisticPersonName
  note
  lastUpdatedBy
  updatedAt
  createdBy
}
    `;
export const LiveUpdateRouteLegFragmentDoc = gql`
    fragment LiveUpdateRouteLeg on RouteLeg {
  id
  fuel
  arrivalTime
  actualArrivalDate
  departureTime
  actualDepartureDate
  load
  unload
  loadCar
  loadCarPPL
  loadCages
  loadTrailer
  loadTrailerPPL
  unloadCar
  unloadCarPPL
  unloadCages
  unloadTrailer
  unloadTrailerPPL
  actualDepartureTime
  actualArrivalTime
  terminalNote
  isNonArrivalMarked
  actualCapacity
  lastUpdatedBy
  updatedAt
  transportationDate
  productionDate
  gateNumber
  carRegistrationNumber
  trailerRegistrationNumber
  routeDriverName
  routeDriverPhoneNumber
  isSealBroken
  sealNumber
  terminalNickname
  productionGroup
  note
  arrivalStatus
  files {
    id
    originalname
    mimetype
    path
    size
  }
}
    `;
export const LocationsFieldsFragmentDoc = gql`
    fragment LocationsFields on LocationEntity {
  id
  name
  address
  postalCode
  city
  country
  phLocationId
  locationShortCode
  countryLong @client
  createdAt
  updatedAt
  lastUpdatedBy
  createdBy
}
    `;
export const TransportAgreementFieldsFragmentDoc = gql`
    fragment TransportAgreementFields on Route {
  id
  routeId
  transportationDate
  capacity
  kilometer
  weight
  currency
  agreedPrice
  note
  createdBy
  subcontractor {
    name
  }
  licensePlate
  dispatcher {
    name
  }
  files {
    path
  }
  legs {
    arrivalTime
    departureTime
    transportationDate
    transportationDateOffset
    carRegistrationNumber
    trailerRegistrationNumber
    fuel
    note
    position
    location {
      name
      address
      country
      postalCode
      city
      deletedBy
    }
  }
}
    `;
export const CreateCardContactDocument = gql`
    mutation CreateCardContact($cardId: Int!, $input: CreateCardContactInput!) {
  createCardContact(cardId: $cardId, input: $input) {
    id
    name
    email
    phoneNumber
    tags {
      id
      title
    }
    updatedAt
    createdAt
    note
    lastUpdatedBy
    createdBy
  }
}
    `;
export type CreateCardContactMutationFn = Apollo.MutationFunction<CreateCardContactMutation, CreateCardContactMutationVariables>;

/**
 * __useCreateCardContactMutation__
 *
 * To run a mutation, you first call `useCreateCardContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCardContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCardContactMutation, { data, loading, error }] = useCreateCardContactMutation({
 *   variables: {
 *      cardId: // value for 'cardId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCardContactMutation(baseOptions?: Apollo.MutationHookOptions<CreateCardContactMutation, CreateCardContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCardContactMutation, CreateCardContactMutationVariables>(CreateCardContactDocument, options);
      }
export type CreateCardContactMutationHookResult = ReturnType<typeof useCreateCardContactMutation>;
export type CreateCardContactMutationResult = Apollo.MutationResult<CreateCardContactMutation>;
export type CreateCardContactMutationOptions = Apollo.BaseMutationOptions<CreateCardContactMutation, CreateCardContactMutationVariables>;
export const DeleteCardContactDocument = gql`
    mutation DeleteCardContact($id: Int!) {
  deleteCardContact(id: $id)
}
    `;
export type DeleteCardContactMutationFn = Apollo.MutationFunction<DeleteCardContactMutation, DeleteCardContactMutationVariables>;

/**
 * __useDeleteCardContactMutation__
 *
 * To run a mutation, you first call `useDeleteCardContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCardContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCardContactMutation, { data, loading, error }] = useDeleteCardContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCardContactMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCardContactMutation, DeleteCardContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCardContactMutation, DeleteCardContactMutationVariables>(DeleteCardContactDocument, options);
      }
export type DeleteCardContactMutationHookResult = ReturnType<typeof useDeleteCardContactMutation>;
export type DeleteCardContactMutationResult = Apollo.MutationResult<DeleteCardContactMutation>;
export type DeleteCardContactMutationOptions = Apollo.BaseMutationOptions<DeleteCardContactMutation, DeleteCardContactMutationVariables>;
export const UpdateContactDocument = gql`
    mutation UpdateContact($input: UpdateCardContactInput!) {
  updateCardContact(input: $input) {
    id
    name
    email
    phoneNumber
    tags {
      id
      title
    }
    note
    lastUpdatedBy
  }
}
    `;
export type UpdateContactMutationFn = Apollo.MutationFunction<UpdateContactMutation, UpdateContactMutationVariables>;

/**
 * __useUpdateContactMutation__
 *
 * To run a mutation, you first call `useUpdateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactMutation, { data, loading, error }] = useUpdateContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContactMutation(baseOptions?: Apollo.MutationHookOptions<UpdateContactMutation, UpdateContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateContactMutation, UpdateContactMutationVariables>(UpdateContactDocument, options);
      }
export type UpdateContactMutationHookResult = ReturnType<typeof useUpdateContactMutation>;
export type UpdateContactMutationResult = Apollo.MutationResult<UpdateContactMutation>;
export type UpdateContactMutationOptions = Apollo.BaseMutationOptions<UpdateContactMutation, UpdateContactMutationVariables>;
export const CreateContactTagDocument = gql`
    mutation CreateContactTag($input: CreateCardContactTagInput!) {
  createContactTag(input: $input) {
    id
  }
}
    `;
export type CreateContactTagMutationFn = Apollo.MutationFunction<CreateContactTagMutation, CreateContactTagMutationVariables>;

/**
 * __useCreateContactTagMutation__
 *
 * To run a mutation, you first call `useCreateContactTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactTagMutation, { data, loading, error }] = useCreateContactTagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContactTagMutation(baseOptions?: Apollo.MutationHookOptions<CreateContactTagMutation, CreateContactTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateContactTagMutation, CreateContactTagMutationVariables>(CreateContactTagDocument, options);
      }
export type CreateContactTagMutationHookResult = ReturnType<typeof useCreateContactTagMutation>;
export type CreateContactTagMutationResult = Apollo.MutationResult<CreateContactTagMutation>;
export type CreateContactTagMutationOptions = Apollo.BaseMutationOptions<CreateContactTagMutation, CreateContactTagMutationVariables>;
export const DeleteContactTagDocument = gql`
    mutation DeleteContactTag($tagId: Int!) {
  deleteContactTag(tagId: $tagId)
}
    `;
export type DeleteContactTagMutationFn = Apollo.MutationFunction<DeleteContactTagMutation, DeleteContactTagMutationVariables>;

/**
 * __useDeleteContactTagMutation__
 *
 * To run a mutation, you first call `useDeleteContactTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContactTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContactTagMutation, { data, loading, error }] = useDeleteContactTagMutation({
 *   variables: {
 *      tagId: // value for 'tagId'
 *   },
 * });
 */
export function useDeleteContactTagMutation(baseOptions?: Apollo.MutationHookOptions<DeleteContactTagMutation, DeleteContactTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteContactTagMutation, DeleteContactTagMutationVariables>(DeleteContactTagDocument, options);
      }
export type DeleteContactTagMutationHookResult = ReturnType<typeof useDeleteContactTagMutation>;
export type DeleteContactTagMutationResult = Apollo.MutationResult<DeleteContactTagMutation>;
export type DeleteContactTagMutationOptions = Apollo.BaseMutationOptions<DeleteContactTagMutation, DeleteContactTagMutationVariables>;
export const AddCardLocationDocument = gql`
    mutation AddCardLocation($id: Int!, $locationInput: CreateLocationInput!) {
  addCardLocation(id: $id, locationInput: $locationInput) {
    id
    locations {
      ...LocationsFields
    }
  }
}
    ${LocationsFieldsFragmentDoc}`;
export type AddCardLocationMutationFn = Apollo.MutationFunction<AddCardLocationMutation, AddCardLocationMutationVariables>;

/**
 * __useAddCardLocationMutation__
 *
 * To run a mutation, you first call `useAddCardLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCardLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCardLocationMutation, { data, loading, error }] = useAddCardLocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      locationInput: // value for 'locationInput'
 *   },
 * });
 */
export function useAddCardLocationMutation(baseOptions?: Apollo.MutationHookOptions<AddCardLocationMutation, AddCardLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCardLocationMutation, AddCardLocationMutationVariables>(AddCardLocationDocument, options);
      }
export type AddCardLocationMutationHookResult = ReturnType<typeof useAddCardLocationMutation>;
export type AddCardLocationMutationResult = Apollo.MutationResult<AddCardLocationMutation>;
export type AddCardLocationMutationOptions = Apollo.BaseMutationOptions<AddCardLocationMutation, AddCardLocationMutationVariables>;
export const DeleteLocationDocument = gql`
    mutation DeleteLocation($id: Int!) {
  deleteLocation(id: $id)
}
    `;
export type DeleteLocationMutationFn = Apollo.MutationFunction<DeleteLocationMutation, DeleteLocationMutationVariables>;

/**
 * __useDeleteLocationMutation__
 *
 * To run a mutation, you first call `useDeleteLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLocationMutation, { data, loading, error }] = useDeleteLocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLocationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLocationMutation, DeleteLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLocationMutation, DeleteLocationMutationVariables>(DeleteLocationDocument, options);
      }
export type DeleteLocationMutationHookResult = ReturnType<typeof useDeleteLocationMutation>;
export type DeleteLocationMutationResult = Apollo.MutationResult<DeleteLocationMutation>;
export type DeleteLocationMutationOptions = Apollo.BaseMutationOptions<DeleteLocationMutation, DeleteLocationMutationVariables>;
export const CreateCustomerAccountDocument = gql`
    mutation CreateCustomerAccount($cardId: Int!, $input: CreateCustomerAccountInput!) {
  createCustomerAccount(cardId: $cardId, input: $input) {
    ...CustomerAccountFields
  }
}
    ${CustomerAccountFieldsFragmentDoc}`;
export type CreateCustomerAccountMutationFn = Apollo.MutationFunction<CreateCustomerAccountMutation, CreateCustomerAccountMutationVariables>;

/**
 * __useCreateCustomerAccountMutation__
 *
 * To run a mutation, you first call `useCreateCustomerAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerAccountMutation, { data, loading, error }] = useCreateCustomerAccountMutation({
 *   variables: {
 *      cardId: // value for 'cardId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomerAccountMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomerAccountMutation, CreateCustomerAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCustomerAccountMutation, CreateCustomerAccountMutationVariables>(CreateCustomerAccountDocument, options);
      }
export type CreateCustomerAccountMutationHookResult = ReturnType<typeof useCreateCustomerAccountMutation>;
export type CreateCustomerAccountMutationResult = Apollo.MutationResult<CreateCustomerAccountMutation>;
export type CreateCustomerAccountMutationOptions = Apollo.BaseMutationOptions<CreateCustomerAccountMutation, CreateCustomerAccountMutationVariables>;
export const DeleteCustomerAccountDocument = gql`
    mutation DeleteCustomerAccount($id: Int!) {
  deleteCustomerAccount(id: $id)
}
    `;
export type DeleteCustomerAccountMutationFn = Apollo.MutationFunction<DeleteCustomerAccountMutation, DeleteCustomerAccountMutationVariables>;

/**
 * __useDeleteCustomerAccountMutation__
 *
 * To run a mutation, you first call `useDeleteCustomerAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomerAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomerAccountMutation, { data, loading, error }] = useDeleteCustomerAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCustomerAccountMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCustomerAccountMutation, DeleteCustomerAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCustomerAccountMutation, DeleteCustomerAccountMutationVariables>(DeleteCustomerAccountDocument, options);
      }
export type DeleteCustomerAccountMutationHookResult = ReturnType<typeof useDeleteCustomerAccountMutation>;
export type DeleteCustomerAccountMutationResult = Apollo.MutationResult<DeleteCustomerAccountMutation>;
export type DeleteCustomerAccountMutationOptions = Apollo.BaseMutationOptions<DeleteCustomerAccountMutation, DeleteCustomerAccountMutationVariables>;
export const UpdateCustomerAccountDocument = gql`
    mutation UpdateCustomerAccount($input: UpdateCustomerAccountInput!) {
  updateCustomerAccount(input: $input) {
    ...CustomerAccountFields
  }
}
    ${CustomerAccountFieldsFragmentDoc}`;
export type UpdateCustomerAccountMutationFn = Apollo.MutationFunction<UpdateCustomerAccountMutation, UpdateCustomerAccountMutationVariables>;

/**
 * __useUpdateCustomerAccountMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerAccountMutation, { data, loading, error }] = useUpdateCustomerAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomerAccountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomerAccountMutation, UpdateCustomerAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomerAccountMutation, UpdateCustomerAccountMutationVariables>(UpdateCustomerAccountDocument, options);
      }
export type UpdateCustomerAccountMutationHookResult = ReturnType<typeof useUpdateCustomerAccountMutation>;
export type UpdateCustomerAccountMutationResult = Apollo.MutationResult<UpdateCustomerAccountMutation>;
export type UpdateCustomerAccountMutationOptions = Apollo.BaseMutationOptions<UpdateCustomerAccountMutation, UpdateCustomerAccountMutationVariables>;
export const DeleteCardFileDocument = gql`
    mutation DeleteCardFile($deleteCardFileId: Int!) {
  deleteCardFile(id: $deleteCardFileId)
}
    `;
export type DeleteCardFileMutationFn = Apollo.MutationFunction<DeleteCardFileMutation, DeleteCardFileMutationVariables>;

/**
 * __useDeleteCardFileMutation__
 *
 * To run a mutation, you first call `useDeleteCardFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCardFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCardFileMutation, { data, loading, error }] = useDeleteCardFileMutation({
 *   variables: {
 *      deleteCardFileId: // value for 'deleteCardFileId'
 *   },
 * });
 */
export function useDeleteCardFileMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCardFileMutation, DeleteCardFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCardFileMutation, DeleteCardFileMutationVariables>(DeleteCardFileDocument, options);
      }
export type DeleteCardFileMutationHookResult = ReturnType<typeof useDeleteCardFileMutation>;
export type DeleteCardFileMutationResult = Apollo.MutationResult<DeleteCardFileMutation>;
export type DeleteCardFileMutationOptions = Apollo.BaseMutationOptions<DeleteCardFileMutation, DeleteCardFileMutationVariables>;
export const AddCardNoteDocument = gql`
    mutation AddCardNote($cardId: Int!, $input: CreateNoteInput!) {
  addCardNote(id: $cardId, input: $input) {
    id
    notes {
      id
      title
      text
    }
  }
}
    `;
export type AddCardNoteMutationFn = Apollo.MutationFunction<AddCardNoteMutation, AddCardNoteMutationVariables>;

/**
 * __useAddCardNoteMutation__
 *
 * To run a mutation, you first call `useAddCardNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCardNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCardNoteMutation, { data, loading, error }] = useAddCardNoteMutation({
 *   variables: {
 *      cardId: // value for 'cardId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCardNoteMutation(baseOptions?: Apollo.MutationHookOptions<AddCardNoteMutation, AddCardNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCardNoteMutation, AddCardNoteMutationVariables>(AddCardNoteDocument, options);
      }
export type AddCardNoteMutationHookResult = ReturnType<typeof useAddCardNoteMutation>;
export type AddCardNoteMutationResult = Apollo.MutationResult<AddCardNoteMutation>;
export type AddCardNoteMutationOptions = Apollo.BaseMutationOptions<AddCardNoteMutation, AddCardNoteMutationVariables>;
export const DeleteCardNoteDocument = gql`
    mutation DeleteCardNote($id: Int!) {
  deleteCardNote(id: $id)
}
    `;
export type DeleteCardNoteMutationFn = Apollo.MutationFunction<DeleteCardNoteMutation, DeleteCardNoteMutationVariables>;

/**
 * __useDeleteCardNoteMutation__
 *
 * To run a mutation, you first call `useDeleteCardNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCardNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCardNoteMutation, { data, loading, error }] = useDeleteCardNoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCardNoteMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCardNoteMutation, DeleteCardNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCardNoteMutation, DeleteCardNoteMutationVariables>(DeleteCardNoteDocument, options);
      }
export type DeleteCardNoteMutationHookResult = ReturnType<typeof useDeleteCardNoteMutation>;
export type DeleteCardNoteMutationResult = Apollo.MutationResult<DeleteCardNoteMutation>;
export type DeleteCardNoteMutationOptions = Apollo.BaseMutationOptions<DeleteCardNoteMutation, DeleteCardNoteMutationVariables>;
export const UpdateCardNoteDocument = gql`
    mutation UpdateCardNote($input: UpdateNoteInput!) {
  updateCardNote(input: $input) {
    id
    title
    text
  }
}
    `;
export type UpdateCardNoteMutationFn = Apollo.MutationFunction<UpdateCardNoteMutation, UpdateCardNoteMutationVariables>;

/**
 * __useUpdateCardNoteMutation__
 *
 * To run a mutation, you first call `useUpdateCardNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCardNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCardNoteMutation, { data, loading, error }] = useUpdateCardNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCardNoteMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCardNoteMutation, UpdateCardNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCardNoteMutation, UpdateCardNoteMutationVariables>(UpdateCardNoteDocument, options);
      }
export type UpdateCardNoteMutationHookResult = ReturnType<typeof useUpdateCardNoteMutation>;
export type UpdateCardNoteMutationResult = Apollo.MutationResult<UpdateCardNoteMutation>;
export type UpdateCardNoteMutationOptions = Apollo.BaseMutationOptions<UpdateCardNoteMutation, UpdateCardNoteMutationVariables>;
export const CreateCheckpointDocument = gql`
    mutation CreateCheckpoint($name: String!) {
  createCheckpoint(input: {name: $name}) {
    name
    id
  }
}
    `;
export type CreateCheckpointMutationFn = Apollo.MutationFunction<CreateCheckpointMutation, CreateCheckpointMutationVariables>;

/**
 * __useCreateCheckpointMutation__
 *
 * To run a mutation, you first call `useCreateCheckpointMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCheckpointMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCheckpointMutation, { data, loading, error }] = useCreateCheckpointMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateCheckpointMutation(baseOptions?: Apollo.MutationHookOptions<CreateCheckpointMutation, CreateCheckpointMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCheckpointMutation, CreateCheckpointMutationVariables>(CreateCheckpointDocument, options);
      }
export type CreateCheckpointMutationHookResult = ReturnType<typeof useCreateCheckpointMutation>;
export type CreateCheckpointMutationResult = Apollo.MutationResult<CreateCheckpointMutation>;
export type CreateCheckpointMutationOptions = Apollo.BaseMutationOptions<CreateCheckpointMutation, CreateCheckpointMutationVariables>;
export const DeleteCheckpointDocument = gql`
    mutation DeleteCheckpoint($id: Int!) {
  deleteCheckpoint(id: $id)
}
    `;
export type DeleteCheckpointMutationFn = Apollo.MutationFunction<DeleteCheckpointMutation, DeleteCheckpointMutationVariables>;

/**
 * __useDeleteCheckpointMutation__
 *
 * To run a mutation, you first call `useDeleteCheckpointMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCheckpointMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCheckpointMutation, { data, loading, error }] = useDeleteCheckpointMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCheckpointMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCheckpointMutation, DeleteCheckpointMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCheckpointMutation, DeleteCheckpointMutationVariables>(DeleteCheckpointDocument, options);
      }
export type DeleteCheckpointMutationHookResult = ReturnType<typeof useDeleteCheckpointMutation>;
export type DeleteCheckpointMutationResult = Apollo.MutationResult<DeleteCheckpointMutation>;
export type DeleteCheckpointMutationOptions = Apollo.BaseMutationOptions<DeleteCheckpointMutation, DeleteCheckpointMutationVariables>;
export const ImportCheckpointsDocument = gql`
    mutation ImportCheckpoints($input: [ExcelImportDataInput!]!) {
  importCheckpoints(input: $input) {
    id
    name
  }
}
    `;
export type ImportCheckpointsMutationFn = Apollo.MutationFunction<ImportCheckpointsMutation, ImportCheckpointsMutationVariables>;

/**
 * __useImportCheckpointsMutation__
 *
 * To run a mutation, you first call `useImportCheckpointsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportCheckpointsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importCheckpointsMutation, { data, loading, error }] = useImportCheckpointsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportCheckpointsMutation(baseOptions?: Apollo.MutationHookOptions<ImportCheckpointsMutation, ImportCheckpointsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportCheckpointsMutation, ImportCheckpointsMutationVariables>(ImportCheckpointsDocument, options);
      }
export type ImportCheckpointsMutationHookResult = ReturnType<typeof useImportCheckpointsMutation>;
export type ImportCheckpointsMutationResult = Apollo.MutationResult<ImportCheckpointsMutation>;
export type ImportCheckpointsMutationOptions = Apollo.BaseMutationOptions<ImportCheckpointsMutation, ImportCheckpointsMutationVariables>;
export const UpdateCheckpointDocument = gql`
    mutation UpdateCheckpoint($name: String!, $id: Int!) {
  updateCheckpoint(input: {name: $name, id: $id}) {
    id
    name
  }
}
    `;
export type UpdateCheckpointMutationFn = Apollo.MutationFunction<UpdateCheckpointMutation, UpdateCheckpointMutationVariables>;

/**
 * __useUpdateCheckpointMutation__
 *
 * To run a mutation, you first call `useUpdateCheckpointMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCheckpointMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCheckpointMutation, { data, loading, error }] = useUpdateCheckpointMutation({
 *   variables: {
 *      name: // value for 'name'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateCheckpointMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCheckpointMutation, UpdateCheckpointMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCheckpointMutation, UpdateCheckpointMutationVariables>(UpdateCheckpointDocument, options);
      }
export type UpdateCheckpointMutationHookResult = ReturnType<typeof useUpdateCheckpointMutation>;
export type UpdateCheckpointMutationResult = Apollo.MutationResult<UpdateCheckpointMutation>;
export type UpdateCheckpointMutationOptions = Apollo.BaseMutationOptions<UpdateCheckpointMutation, UpdateCheckpointMutationVariables>;
export const AddCustomerLocationDocument = gql`
    mutation AddCustomerLocation($id: Int!, $locationInput: CreateLocationInput!) {
  addCustomerCardLocation(id: $id, locationInput: $locationInput) {
    id
    locations {
      ...LocationsFields
    }
  }
}
    ${LocationsFieldsFragmentDoc}`;
export type AddCustomerLocationMutationFn = Apollo.MutationFunction<AddCustomerLocationMutation, AddCustomerLocationMutationVariables>;

/**
 * __useAddCustomerLocationMutation__
 *
 * To run a mutation, you first call `useAddCustomerLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCustomerLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCustomerLocationMutation, { data, loading, error }] = useAddCustomerLocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      locationInput: // value for 'locationInput'
 *   },
 * });
 */
export function useAddCustomerLocationMutation(baseOptions?: Apollo.MutationHookOptions<AddCustomerLocationMutation, AddCustomerLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCustomerLocationMutation, AddCustomerLocationMutationVariables>(AddCustomerLocationDocument, options);
      }
export type AddCustomerLocationMutationHookResult = ReturnType<typeof useAddCustomerLocationMutation>;
export type AddCustomerLocationMutationResult = Apollo.MutationResult<AddCustomerLocationMutation>;
export type AddCustomerLocationMutationOptions = Apollo.BaseMutationOptions<AddCustomerLocationMutation, AddCustomerLocationMutationVariables>;
export const CreateCustomerDocument = gql`
    mutation CreateCustomer($name: String!) {
  createCustomer(input: {name: $name}) {
    name
    id
  }
}
    `;
export type CreateCustomerMutationFn = Apollo.MutationFunction<CreateCustomerMutation, CreateCustomerMutationVariables>;

/**
 * __useCreateCustomerMutation__
 *
 * To run a mutation, you first call `useCreateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerMutation, { data, loading, error }] = useCreateCustomerMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateCustomerMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomerMutation, CreateCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCustomerMutation, CreateCustomerMutationVariables>(CreateCustomerDocument, options);
      }
export type CreateCustomerMutationHookResult = ReturnType<typeof useCreateCustomerMutation>;
export type CreateCustomerMutationResult = Apollo.MutationResult<CreateCustomerMutation>;
export type CreateCustomerMutationOptions = Apollo.BaseMutationOptions<CreateCustomerMutation, CreateCustomerMutationVariables>;
export const DeleteCustomerDocument = gql`
    mutation DeleteCustomer($id: Int!) {
  deleteCustomer(id: $id)
}
    `;
export type DeleteCustomerMutationFn = Apollo.MutationFunction<DeleteCustomerMutation, DeleteCustomerMutationVariables>;

/**
 * __useDeleteCustomerMutation__
 *
 * To run a mutation, you first call `useDeleteCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomerMutation, { data, loading, error }] = useDeleteCustomerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCustomerMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCustomerMutation, DeleteCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCustomerMutation, DeleteCustomerMutationVariables>(DeleteCustomerDocument, options);
      }
export type DeleteCustomerMutationHookResult = ReturnType<typeof useDeleteCustomerMutation>;
export type DeleteCustomerMutationResult = Apollo.MutationResult<DeleteCustomerMutation>;
export type DeleteCustomerMutationOptions = Apollo.BaseMutationOptions<DeleteCustomerMutation, DeleteCustomerMutationVariables>;
export const ImportCustomersDocument = gql`
    mutation ImportCustomers($input: [ExcelImportDataInput!]!) {
  importCustomers(input: $input) {
    id
    name
  }
}
    `;
export type ImportCustomersMutationFn = Apollo.MutationFunction<ImportCustomersMutation, ImportCustomersMutationVariables>;

/**
 * __useImportCustomersMutation__
 *
 * To run a mutation, you first call `useImportCustomersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportCustomersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importCustomersMutation, { data, loading, error }] = useImportCustomersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportCustomersMutation(baseOptions?: Apollo.MutationHookOptions<ImportCustomersMutation, ImportCustomersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportCustomersMutation, ImportCustomersMutationVariables>(ImportCustomersDocument, options);
      }
export type ImportCustomersMutationHookResult = ReturnType<typeof useImportCustomersMutation>;
export type ImportCustomersMutationResult = Apollo.MutationResult<ImportCustomersMutation>;
export type ImportCustomersMutationOptions = Apollo.BaseMutationOptions<ImportCustomersMutation, ImportCustomersMutationVariables>;
export const UpdateCustomerDocument = gql`
    mutation UpdateCustomer($name: String!, $id: Int!) {
  updateCustomer(input: {name: $name, id: $id}) {
    id
    name
  }
}
    `;
export type UpdateCustomerMutationFn = Apollo.MutationFunction<UpdateCustomerMutation, UpdateCustomerMutationVariables>;

/**
 * __useUpdateCustomerMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerMutation, { data, loading, error }] = useUpdateCustomerMutation({
 *   variables: {
 *      name: // value for 'name'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateCustomerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomerMutation, UpdateCustomerMutationVariables>(UpdateCustomerDocument, options);
      }
export type UpdateCustomerMutationHookResult = ReturnType<typeof useUpdateCustomerMutation>;
export type UpdateCustomerMutationResult = Apollo.MutationResult<UpdateCustomerMutation>;
export type UpdateCustomerMutationOptions = Apollo.BaseMutationOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>;
export const CreateDeviationDocument = gql`
    mutation CreateDeviation($input: DeviationInput!) {
  createDeviation(input: $input) {
    id
    cause
    fault
    routeId
    faultLocation {
      id
      name
      address
      postalCode
      city
      country
      card {
        name
      }
    }
    note
    consequence
    actualArrivalTime
    createdBy
  }
}
    `;
export type CreateDeviationMutationFn = Apollo.MutationFunction<CreateDeviationMutation, CreateDeviationMutationVariables>;

/**
 * __useCreateDeviationMutation__
 *
 * To run a mutation, you first call `useCreateDeviationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDeviationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDeviationMutation, { data, loading, error }] = useCreateDeviationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDeviationMutation(baseOptions?: Apollo.MutationHookOptions<CreateDeviationMutation, CreateDeviationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDeviationMutation, CreateDeviationMutationVariables>(CreateDeviationDocument, options);
      }
export type CreateDeviationMutationHookResult = ReturnType<typeof useCreateDeviationMutation>;
export type CreateDeviationMutationResult = Apollo.MutationResult<CreateDeviationMutation>;
export type CreateDeviationMutationOptions = Apollo.BaseMutationOptions<CreateDeviationMutation, CreateDeviationMutationVariables>;
export const CreateLocationDeviationDocument = gql`
    mutation CreateLocationDeviation($input: [LocationDeviationInput!]!) {
  createLocationDeviation(input: $input) {
    id
  }
}
    `;
export type CreateLocationDeviationMutationFn = Apollo.MutationFunction<CreateLocationDeviationMutation, CreateLocationDeviationMutationVariables>;

/**
 * __useCreateLocationDeviationMutation__
 *
 * To run a mutation, you first call `useCreateLocationDeviationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLocationDeviationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLocationDeviationMutation, { data, loading, error }] = useCreateLocationDeviationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLocationDeviationMutation(baseOptions?: Apollo.MutationHookOptions<CreateLocationDeviationMutation, CreateLocationDeviationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLocationDeviationMutation, CreateLocationDeviationMutationVariables>(CreateLocationDeviationDocument, options);
      }
export type CreateLocationDeviationMutationHookResult = ReturnType<typeof useCreateLocationDeviationMutation>;
export type CreateLocationDeviationMutationResult = Apollo.MutationResult<CreateLocationDeviationMutation>;
export type CreateLocationDeviationMutationOptions = Apollo.BaseMutationOptions<CreateLocationDeviationMutation, CreateLocationDeviationMutationVariables>;
export const DeleteDeviationDocument = gql`
    mutation DeleteDeviation($id: Int!, $customerId: Int) {
  deleteDeviation(id: $id, customerId: $customerId)
}
    `;
export type DeleteDeviationMutationFn = Apollo.MutationFunction<DeleteDeviationMutation, DeleteDeviationMutationVariables>;

/**
 * __useDeleteDeviationMutation__
 *
 * To run a mutation, you first call `useDeleteDeviationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDeviationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDeviationMutation, { data, loading, error }] = useDeleteDeviationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useDeleteDeviationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDeviationMutation, DeleteDeviationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDeviationMutation, DeleteDeviationMutationVariables>(DeleteDeviationDocument, options);
      }
export type DeleteDeviationMutationHookResult = ReturnType<typeof useDeleteDeviationMutation>;
export type DeleteDeviationMutationResult = Apollo.MutationResult<DeleteDeviationMutation>;
export type DeleteDeviationMutationOptions = Apollo.BaseMutationOptions<DeleteDeviationMutation, DeleteDeviationMutationVariables>;
export const EditDeviationDocument = gql`
    mutation EditDeviation($input: EditDeviationInput!) {
  editDeviation(input: $input) {
    id
    cause
    fault
    routeId
    faultLocation {
      id
      name
      address
      postalCode
      city
      country
      card {
        name
      }
    }
    note
    consequence
    actualArrivalTime
    createdBy
  }
}
    `;
export type EditDeviationMutationFn = Apollo.MutationFunction<EditDeviationMutation, EditDeviationMutationVariables>;

/**
 * __useEditDeviationMutation__
 *
 * To run a mutation, you first call `useEditDeviationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditDeviationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editDeviationMutation, { data, loading, error }] = useEditDeviationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditDeviationMutation(baseOptions?: Apollo.MutationHookOptions<EditDeviationMutation, EditDeviationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditDeviationMutation, EditDeviationMutationVariables>(EditDeviationDocument, options);
      }
export type EditDeviationMutationHookResult = ReturnType<typeof useEditDeviationMutation>;
export type EditDeviationMutationResult = Apollo.MutationResult<EditDeviationMutation>;
export type EditDeviationMutationOptions = Apollo.BaseMutationOptions<EditDeviationMutation, EditDeviationMutationVariables>;
export const EditLocationDeviationDocument = gql`
    mutation EditLocationDeviation($input: EditLocationDeviationInput!) {
  editLocationDeviation(input: $input) {
    id
  }
}
    `;
export type EditLocationDeviationMutationFn = Apollo.MutationFunction<EditLocationDeviationMutation, EditLocationDeviationMutationVariables>;

/**
 * __useEditLocationDeviationMutation__
 *
 * To run a mutation, you first call `useEditLocationDeviationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditLocationDeviationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editLocationDeviationMutation, { data, loading, error }] = useEditLocationDeviationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditLocationDeviationMutation(baseOptions?: Apollo.MutationHookOptions<EditLocationDeviationMutation, EditLocationDeviationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditLocationDeviationMutation, EditLocationDeviationMutationVariables>(EditLocationDeviationDocument, options);
      }
export type EditLocationDeviationMutationHookResult = ReturnType<typeof useEditLocationDeviationMutation>;
export type EditLocationDeviationMutationResult = Apollo.MutationResult<EditLocationDeviationMutation>;
export type EditLocationDeviationMutationOptions = Apollo.BaseMutationOptions<EditLocationDeviationMutation, EditLocationDeviationMutationVariables>;
export const SendDeviationDocument = gql`
    mutation SendDeviation($input: SendDeviationInput!) {
  sendDeviation(input: $input) {
    id
    localMessageCustomers
    englishMessageCustomers
    emailSent
  }
}
    `;
export type SendDeviationMutationFn = Apollo.MutationFunction<SendDeviationMutation, SendDeviationMutationVariables>;

/**
 * __useSendDeviationMutation__
 *
 * To run a mutation, you first call `useSendDeviationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendDeviationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendDeviationMutation, { data, loading, error }] = useSendDeviationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendDeviationMutation(baseOptions?: Apollo.MutationHookOptions<SendDeviationMutation, SendDeviationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendDeviationMutation, SendDeviationMutationVariables>(SendDeviationDocument, options);
      }
export type SendDeviationMutationHookResult = ReturnType<typeof useSendDeviationMutation>;
export type SendDeviationMutationResult = Apollo.MutationResult<SendDeviationMutation>;
export type SendDeviationMutationOptions = Apollo.BaseMutationOptions<SendDeviationMutation, SendDeviationMutationVariables>;
export const DeleteAttachmentFileDocument = gql`
    mutation DeleteAttachmentFile($input: DeleteAttachmentFileDto!) {
  deleteAttachmentFile(input: $input)
}
    `;
export type DeleteAttachmentFileMutationFn = Apollo.MutationFunction<DeleteAttachmentFileMutation, DeleteAttachmentFileMutationVariables>;

/**
 * __useDeleteAttachmentFileMutation__
 *
 * To run a mutation, you first call `useDeleteAttachmentFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAttachmentFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAttachmentFileMutation, { data, loading, error }] = useDeleteAttachmentFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAttachmentFileMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAttachmentFileMutation, DeleteAttachmentFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAttachmentFileMutation, DeleteAttachmentFileMutationVariables>(DeleteAttachmentFileDocument, options);
      }
export type DeleteAttachmentFileMutationHookResult = ReturnType<typeof useDeleteAttachmentFileMutation>;
export type DeleteAttachmentFileMutationResult = Apollo.MutationResult<DeleteAttachmentFileMutation>;
export type DeleteAttachmentFileMutationOptions = Apollo.BaseMutationOptions<DeleteAttachmentFileMutation, DeleteAttachmentFileMutationVariables>;
export const DeleteDocumentFileDocument = gql`
    mutation DeleteDocumentFile($deleteDocumentFileId: Int!) {
  deleteDocumentFile(id: $deleteDocumentFileId)
}
    `;
export type DeleteDocumentFileMutationFn = Apollo.MutationFunction<DeleteDocumentFileMutation, DeleteDocumentFileMutationVariables>;

/**
 * __useDeleteDocumentFileMutation__
 *
 * To run a mutation, you first call `useDeleteDocumentFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocumentFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocumentFileMutation, { data, loading, error }] = useDeleteDocumentFileMutation({
 *   variables: {
 *      deleteDocumentFileId: // value for 'deleteDocumentFileId'
 *   },
 * });
 */
export function useDeleteDocumentFileMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDocumentFileMutation, DeleteDocumentFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDocumentFileMutation, DeleteDocumentFileMutationVariables>(DeleteDocumentFileDocument, options);
      }
export type DeleteDocumentFileMutationHookResult = ReturnType<typeof useDeleteDocumentFileMutation>;
export type DeleteDocumentFileMutationResult = Apollo.MutationResult<DeleteDocumentFileMutation>;
export type DeleteDocumentFileMutationOptions = Apollo.BaseMutationOptions<DeleteDocumentFileMutation, DeleteDocumentFileMutationVariables>;
export const AddHolidayDocument = gql`
    mutation AddHoliday($input: CreateHolidayInput!) {
  addHoliday(input: $input) {
    id
    date
    title
    createdBy
    createdAt
  }
}
    `;
export type AddHolidayMutationFn = Apollo.MutationFunction<AddHolidayMutation, AddHolidayMutationVariables>;

/**
 * __useAddHolidayMutation__
 *
 * To run a mutation, you first call `useAddHolidayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddHolidayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addHolidayMutation, { data, loading, error }] = useAddHolidayMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddHolidayMutation(baseOptions?: Apollo.MutationHookOptions<AddHolidayMutation, AddHolidayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddHolidayMutation, AddHolidayMutationVariables>(AddHolidayDocument, options);
      }
export type AddHolidayMutationHookResult = ReturnType<typeof useAddHolidayMutation>;
export type AddHolidayMutationResult = Apollo.MutationResult<AddHolidayMutation>;
export type AddHolidayMutationOptions = Apollo.BaseMutationOptions<AddHolidayMutation, AddHolidayMutationVariables>;
export const DeleteHolidayDocument = gql`
    mutation DeleteHoliday($id: Int!) {
  deleteHoliday(id: $id)
}
    `;
export type DeleteHolidayMutationFn = Apollo.MutationFunction<DeleteHolidayMutation, DeleteHolidayMutationVariables>;

/**
 * __useDeleteHolidayMutation__
 *
 * To run a mutation, you first call `useDeleteHolidayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteHolidayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteHolidayMutation, { data, loading, error }] = useDeleteHolidayMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteHolidayMutation(baseOptions?: Apollo.MutationHookOptions<DeleteHolidayMutation, DeleteHolidayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteHolidayMutation, DeleteHolidayMutationVariables>(DeleteHolidayDocument, options);
      }
export type DeleteHolidayMutationHookResult = ReturnType<typeof useDeleteHolidayMutation>;
export type DeleteHolidayMutationResult = Apollo.MutationResult<DeleteHolidayMutation>;
export type DeleteHolidayMutationOptions = Apollo.BaseMutationOptions<DeleteHolidayMutation, DeleteHolidayMutationVariables>;
export const AddEditValueBoxEntryDocument = gql`
    mutation AddEditValueBoxEntry($boxEntry: EditValueBoxEntry!) {
  editValueBoxEntry(boxEntry: $boxEntry) {
    id
  }
}
    `;
export type AddEditValueBoxEntryMutationFn = Apollo.MutationFunction<AddEditValueBoxEntryMutation, AddEditValueBoxEntryMutationVariables>;

/**
 * __useAddEditValueBoxEntryMutation__
 *
 * To run a mutation, you first call `useAddEditValueBoxEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEditValueBoxEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEditValueBoxEntryMutation, { data, loading, error }] = useAddEditValueBoxEntryMutation({
 *   variables: {
 *      boxEntry: // value for 'boxEntry'
 *   },
 * });
 */
export function useAddEditValueBoxEntryMutation(baseOptions?: Apollo.MutationHookOptions<AddEditValueBoxEntryMutation, AddEditValueBoxEntryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddEditValueBoxEntryMutation, AddEditValueBoxEntryMutationVariables>(AddEditValueBoxEntryDocument, options);
      }
export type AddEditValueBoxEntryMutationHookResult = ReturnType<typeof useAddEditValueBoxEntryMutation>;
export type AddEditValueBoxEntryMutationResult = Apollo.MutationResult<AddEditValueBoxEntryMutation>;
export type AddEditValueBoxEntryMutationOptions = Apollo.BaseMutationOptions<AddEditValueBoxEntryMutation, AddEditValueBoxEntryMutationVariables>;
export const AddFixedValueBoxesDocument = gql`
    mutation AddFixedValueBoxes($internalTerminalId: Int!) {
  addFixedValueBoxes(internalTerminalId: $internalTerminalId)
}
    `;
export type AddFixedValueBoxesMutationFn = Apollo.MutationFunction<AddFixedValueBoxesMutation, AddFixedValueBoxesMutationVariables>;

/**
 * __useAddFixedValueBoxesMutation__
 *
 * To run a mutation, you first call `useAddFixedValueBoxesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFixedValueBoxesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFixedValueBoxesMutation, { data, loading, error }] = useAddFixedValueBoxesMutation({
 *   variables: {
 *      internalTerminalId: // value for 'internalTerminalId'
 *   },
 * });
 */
export function useAddFixedValueBoxesMutation(baseOptions?: Apollo.MutationHookOptions<AddFixedValueBoxesMutation, AddFixedValueBoxesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddFixedValueBoxesMutation, AddFixedValueBoxesMutationVariables>(AddFixedValueBoxesDocument, options);
      }
export type AddFixedValueBoxesMutationHookResult = ReturnType<typeof useAddFixedValueBoxesMutation>;
export type AddFixedValueBoxesMutationResult = Apollo.MutationResult<AddFixedValueBoxesMutation>;
export type AddFixedValueBoxesMutationOptions = Apollo.BaseMutationOptions<AddFixedValueBoxesMutation, AddFixedValueBoxesMutationVariables>;
export const AddGridValueDocument = gql`
    mutation AddGridValue($gridValue: AddGridValueInput!) {
  addGridValue(gridValue: $gridValue) {
    id
  }
}
    `;
export type AddGridValueMutationFn = Apollo.MutationFunction<AddGridValueMutation, AddGridValueMutationVariables>;

/**
 * __useAddGridValueMutation__
 *
 * To run a mutation, you first call `useAddGridValueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGridValueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGridValueMutation, { data, loading, error }] = useAddGridValueMutation({
 *   variables: {
 *      gridValue: // value for 'gridValue'
 *   },
 * });
 */
export function useAddGridValueMutation(baseOptions?: Apollo.MutationHookOptions<AddGridValueMutation, AddGridValueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddGridValueMutation, AddGridValueMutationVariables>(AddGridValueDocument, options);
      }
export type AddGridValueMutationHookResult = ReturnType<typeof useAddGridValueMutation>;
export type AddGridValueMutationResult = Apollo.MutationResult<AddGridValueMutation>;
export type AddGridValueMutationOptions = Apollo.BaseMutationOptions<AddGridValueMutation, AddGridValueMutationVariables>;
export const AddInternalTerminalReportInfoDocument = gql`
    mutation AddInternalTerminalReportInfo($input: InternalTerminalReportInfoInput!) {
  addInternalTerminalReportInfo(input: $input) {
    id
  }
}
    `;
export type AddInternalTerminalReportInfoMutationFn = Apollo.MutationFunction<AddInternalTerminalReportInfoMutation, AddInternalTerminalReportInfoMutationVariables>;

/**
 * __useAddInternalTerminalReportInfoMutation__
 *
 * To run a mutation, you first call `useAddInternalTerminalReportInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddInternalTerminalReportInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addInternalTerminalReportInfoMutation, { data, loading, error }] = useAddInternalTerminalReportInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddInternalTerminalReportInfoMutation(baseOptions?: Apollo.MutationHookOptions<AddInternalTerminalReportInfoMutation, AddInternalTerminalReportInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddInternalTerminalReportInfoMutation, AddInternalTerminalReportInfoMutationVariables>(AddInternalTerminalReportInfoDocument, options);
      }
export type AddInternalTerminalReportInfoMutationHookResult = ReturnType<typeof useAddInternalTerminalReportInfoMutation>;
export type AddInternalTerminalReportInfoMutationResult = Apollo.MutationResult<AddInternalTerminalReportInfoMutation>;
export type AddInternalTerminalReportInfoMutationOptions = Apollo.BaseMutationOptions<AddInternalTerminalReportInfoMutation, AddInternalTerminalReportInfoMutationVariables>;
export const AddMasterGridDocument = gql`
    mutation AddMasterGrid($gridData: AddMasterGridDataInput!) {
  addMasterGridBox(gridData: $gridData) {
    id
  }
}
    `;
export type AddMasterGridMutationFn = Apollo.MutationFunction<AddMasterGridMutation, AddMasterGridMutationVariables>;

/**
 * __useAddMasterGridMutation__
 *
 * To run a mutation, you first call `useAddMasterGridMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMasterGridMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMasterGridMutation, { data, loading, error }] = useAddMasterGridMutation({
 *   variables: {
 *      gridData: // value for 'gridData'
 *   },
 * });
 */
export function useAddMasterGridMutation(baseOptions?: Apollo.MutationHookOptions<AddMasterGridMutation, AddMasterGridMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddMasterGridMutation, AddMasterGridMutationVariables>(AddMasterGridDocument, options);
      }
export type AddMasterGridMutationHookResult = ReturnType<typeof useAddMasterGridMutation>;
export type AddMasterGridMutationResult = Apollo.MutationResult<AddMasterGridMutation>;
export type AddMasterGridMutationOptions = Apollo.BaseMutationOptions<AddMasterGridMutation, AddMasterGridMutationVariables>;
export const AddMasterValueBoxDocument = gql`
    mutation AddMasterValueBox($boxData: AddMasterValueBoxInput!) {
  addMasterValueBox(boxData: $boxData) {
    id
  }
}
    `;
export type AddMasterValueBoxMutationFn = Apollo.MutationFunction<AddMasterValueBoxMutation, AddMasterValueBoxMutationVariables>;

/**
 * __useAddMasterValueBoxMutation__
 *
 * To run a mutation, you first call `useAddMasterValueBoxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMasterValueBoxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMasterValueBoxMutation, { data, loading, error }] = useAddMasterValueBoxMutation({
 *   variables: {
 *      boxData: // value for 'boxData'
 *   },
 * });
 */
export function useAddMasterValueBoxMutation(baseOptions?: Apollo.MutationHookOptions<AddMasterValueBoxMutation, AddMasterValueBoxMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddMasterValueBoxMutation, AddMasterValueBoxMutationVariables>(AddMasterValueBoxDocument, options);
      }
export type AddMasterValueBoxMutationHookResult = ReturnType<typeof useAddMasterValueBoxMutation>;
export type AddMasterValueBoxMutationResult = Apollo.MutationResult<AddMasterValueBoxMutation>;
export type AddMasterValueBoxMutationOptions = Apollo.BaseMutationOptions<AddMasterValueBoxMutation, AddMasterValueBoxMutationVariables>;
export const DeleteInternalTerminalReportInfoDocument = gql`
    mutation DeleteInternalTerminalReportInfo($internalTerminalReportInfoId: Float!) {
  deleteInternalTerminalReportInfo(
    internalTerminalReportInfoId: $internalTerminalReportInfoId
  ) {
    result
  }
}
    `;
export type DeleteInternalTerminalReportInfoMutationFn = Apollo.MutationFunction<DeleteInternalTerminalReportInfoMutation, DeleteInternalTerminalReportInfoMutationVariables>;

/**
 * __useDeleteInternalTerminalReportInfoMutation__
 *
 * To run a mutation, you first call `useDeleteInternalTerminalReportInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInternalTerminalReportInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInternalTerminalReportInfoMutation, { data, loading, error }] = useDeleteInternalTerminalReportInfoMutation({
 *   variables: {
 *      internalTerminalReportInfoId: // value for 'internalTerminalReportInfoId'
 *   },
 * });
 */
export function useDeleteInternalTerminalReportInfoMutation(baseOptions?: Apollo.MutationHookOptions<DeleteInternalTerminalReportInfoMutation, DeleteInternalTerminalReportInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteInternalTerminalReportInfoMutation, DeleteInternalTerminalReportInfoMutationVariables>(DeleteInternalTerminalReportInfoDocument, options);
      }
export type DeleteInternalTerminalReportInfoMutationHookResult = ReturnType<typeof useDeleteInternalTerminalReportInfoMutation>;
export type DeleteInternalTerminalReportInfoMutationResult = Apollo.MutationResult<DeleteInternalTerminalReportInfoMutation>;
export type DeleteInternalTerminalReportInfoMutationOptions = Apollo.BaseMutationOptions<DeleteInternalTerminalReportInfoMutation, DeleteInternalTerminalReportInfoMutationVariables>;
export const DeleteMasterGridDocument = gql`
    mutation DeleteMasterGrid($Id: Int!) {
  deleteMasterGrid(Id: $Id) {
    result
  }
}
    `;
export type DeleteMasterGridMutationFn = Apollo.MutationFunction<DeleteMasterGridMutation, DeleteMasterGridMutationVariables>;

/**
 * __useDeleteMasterGridMutation__
 *
 * To run a mutation, you first call `useDeleteMasterGridMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMasterGridMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMasterGridMutation, { data, loading, error }] = useDeleteMasterGridMutation({
 *   variables: {
 *      Id: // value for 'Id'
 *   },
 * });
 */
export function useDeleteMasterGridMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMasterGridMutation, DeleteMasterGridMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMasterGridMutation, DeleteMasterGridMutationVariables>(DeleteMasterGridDocument, options);
      }
export type DeleteMasterGridMutationHookResult = ReturnType<typeof useDeleteMasterGridMutation>;
export type DeleteMasterGridMutationResult = Apollo.MutationResult<DeleteMasterGridMutation>;
export type DeleteMasterGridMutationOptions = Apollo.BaseMutationOptions<DeleteMasterGridMutation, DeleteMasterGridMutationVariables>;
export const DeleteMasterValueBoxDocument = gql`
    mutation DeleteMasterValueBox($boxId: Int!) {
  deleteMasterValueBox(boxId: $boxId) {
    result
  }
}
    `;
export type DeleteMasterValueBoxMutationFn = Apollo.MutationFunction<DeleteMasterValueBoxMutation, DeleteMasterValueBoxMutationVariables>;

/**
 * __useDeleteMasterValueBoxMutation__
 *
 * To run a mutation, you first call `useDeleteMasterValueBoxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMasterValueBoxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMasterValueBoxMutation, { data, loading, error }] = useDeleteMasterValueBoxMutation({
 *   variables: {
 *      boxId: // value for 'boxId'
 *   },
 * });
 */
export function useDeleteMasterValueBoxMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMasterValueBoxMutation, DeleteMasterValueBoxMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMasterValueBoxMutation, DeleteMasterValueBoxMutationVariables>(DeleteMasterValueBoxDocument, options);
      }
export type DeleteMasterValueBoxMutationHookResult = ReturnType<typeof useDeleteMasterValueBoxMutation>;
export type DeleteMasterValueBoxMutationResult = Apollo.MutationResult<DeleteMasterValueBoxMutation>;
export type DeleteMasterValueBoxMutationOptions = Apollo.BaseMutationOptions<DeleteMasterValueBoxMutation, DeleteMasterValueBoxMutationVariables>;
export const DeleteValueBoxEntryDocument = gql`
    mutation DeleteValueBoxEntry($entryId: Int!) {
  deleteValueBoxEntry(entryId: $entryId) {
    result
  }
}
    `;
export type DeleteValueBoxEntryMutationFn = Apollo.MutationFunction<DeleteValueBoxEntryMutation, DeleteValueBoxEntryMutationVariables>;

/**
 * __useDeleteValueBoxEntryMutation__
 *
 * To run a mutation, you first call `useDeleteValueBoxEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteValueBoxEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteValueBoxEntryMutation, { data, loading, error }] = useDeleteValueBoxEntryMutation({
 *   variables: {
 *      entryId: // value for 'entryId'
 *   },
 * });
 */
export function useDeleteValueBoxEntryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteValueBoxEntryMutation, DeleteValueBoxEntryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteValueBoxEntryMutation, DeleteValueBoxEntryMutationVariables>(DeleteValueBoxEntryDocument, options);
      }
export type DeleteValueBoxEntryMutationHookResult = ReturnType<typeof useDeleteValueBoxEntryMutation>;
export type DeleteValueBoxEntryMutationResult = Apollo.MutationResult<DeleteValueBoxEntryMutation>;
export type DeleteValueBoxEntryMutationOptions = Apollo.BaseMutationOptions<DeleteValueBoxEntryMutation, DeleteValueBoxEntryMutationVariables>;
export const DemoteTerminalDocument = gql`
    mutation DemoteTerminal($id: Int!) {
  demoteInternalTerminal(id: $id)
}
    `;
export type DemoteTerminalMutationFn = Apollo.MutationFunction<DemoteTerminalMutation, DemoteTerminalMutationVariables>;

/**
 * __useDemoteTerminalMutation__
 *
 * To run a mutation, you first call `useDemoteTerminalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDemoteTerminalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [demoteTerminalMutation, { data, loading, error }] = useDemoteTerminalMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDemoteTerminalMutation(baseOptions?: Apollo.MutationHookOptions<DemoteTerminalMutation, DemoteTerminalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DemoteTerminalMutation, DemoteTerminalMutationVariables>(DemoteTerminalDocument, options);
      }
export type DemoteTerminalMutationHookResult = ReturnType<typeof useDemoteTerminalMutation>;
export type DemoteTerminalMutationResult = Apollo.MutationResult<DemoteTerminalMutation>;
export type DemoteTerminalMutationOptions = Apollo.BaseMutationOptions<DemoteTerminalMutation, DemoteTerminalMutationVariables>;
export const EditInternalTerminalReportInfoDocument = gql`
    mutation EditInternalTerminalReportInfo($input: EditInternalTerminalReportInfoInput!) {
  editInternalTerminalReportInfo(input: $input) {
    id
  }
}
    `;
export type EditInternalTerminalReportInfoMutationFn = Apollo.MutationFunction<EditInternalTerminalReportInfoMutation, EditInternalTerminalReportInfoMutationVariables>;

/**
 * __useEditInternalTerminalReportInfoMutation__
 *
 * To run a mutation, you first call `useEditInternalTerminalReportInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditInternalTerminalReportInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editInternalTerminalReportInfoMutation, { data, loading, error }] = useEditInternalTerminalReportInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditInternalTerminalReportInfoMutation(baseOptions?: Apollo.MutationHookOptions<EditInternalTerminalReportInfoMutation, EditInternalTerminalReportInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditInternalTerminalReportInfoMutation, EditInternalTerminalReportInfoMutationVariables>(EditInternalTerminalReportInfoDocument, options);
      }
export type EditInternalTerminalReportInfoMutationHookResult = ReturnType<typeof useEditInternalTerminalReportInfoMutation>;
export type EditInternalTerminalReportInfoMutationResult = Apollo.MutationResult<EditInternalTerminalReportInfoMutation>;
export type EditInternalTerminalReportInfoMutationOptions = Apollo.BaseMutationOptions<EditInternalTerminalReportInfoMutation, EditInternalTerminalReportInfoMutationVariables>;
export const EditMasterValueBoxDocument = gql`
    mutation EditMasterValueBox($boxData: EditMasterValueBoxInput!) {
  editMasterValueBox(boxData: $boxData) {
    id
  }
}
    `;
export type EditMasterValueBoxMutationFn = Apollo.MutationFunction<EditMasterValueBoxMutation, EditMasterValueBoxMutationVariables>;

/**
 * __useEditMasterValueBoxMutation__
 *
 * To run a mutation, you first call `useEditMasterValueBoxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMasterValueBoxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMasterValueBoxMutation, { data, loading, error }] = useEditMasterValueBoxMutation({
 *   variables: {
 *      boxData: // value for 'boxData'
 *   },
 * });
 */
export function useEditMasterValueBoxMutation(baseOptions?: Apollo.MutationHookOptions<EditMasterValueBoxMutation, EditMasterValueBoxMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditMasterValueBoxMutation, EditMasterValueBoxMutationVariables>(EditMasterValueBoxDocument, options);
      }
export type EditMasterValueBoxMutationHookResult = ReturnType<typeof useEditMasterValueBoxMutation>;
export type EditMasterValueBoxMutationResult = Apollo.MutationResult<EditMasterValueBoxMutation>;
export type EditMasterValueBoxMutationOptions = Apollo.BaseMutationOptions<EditMasterValueBoxMutation, EditMasterValueBoxMutationVariables>;
export const EditReportInfoReplyDocument = gql`
    mutation EditReportInfoReply($input: EditInternalTerminalReportInfoReplyInput!) {
  editReportInfoReply(input: $input) {
    id
  }
}
    `;
export type EditReportInfoReplyMutationFn = Apollo.MutationFunction<EditReportInfoReplyMutation, EditReportInfoReplyMutationVariables>;

/**
 * __useEditReportInfoReplyMutation__
 *
 * To run a mutation, you first call `useEditReportInfoReplyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditReportInfoReplyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editReportInfoReplyMutation, { data, loading, error }] = useEditReportInfoReplyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditReportInfoReplyMutation(baseOptions?: Apollo.MutationHookOptions<EditReportInfoReplyMutation, EditReportInfoReplyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditReportInfoReplyMutation, EditReportInfoReplyMutationVariables>(EditReportInfoReplyDocument, options);
      }
export type EditReportInfoReplyMutationHookResult = ReturnType<typeof useEditReportInfoReplyMutation>;
export type EditReportInfoReplyMutationResult = Apollo.MutationResult<EditReportInfoReplyMutation>;
export type EditReportInfoReplyMutationOptions = Apollo.BaseMutationOptions<EditReportInfoReplyMutation, EditReportInfoReplyMutationVariables>;
export const PromoteTerminalDocument = gql`
    mutation PromoteTerminal($id: Int!) {
  promoteToInternalTerminal(id: $id) {
    id
    terminalId
    departmentId
  }
}
    `;
export type PromoteTerminalMutationFn = Apollo.MutationFunction<PromoteTerminalMutation, PromoteTerminalMutationVariables>;

/**
 * __usePromoteTerminalMutation__
 *
 * To run a mutation, you first call `usePromoteTerminalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePromoteTerminalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [promoteTerminalMutation, { data, loading, error }] = usePromoteTerminalMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePromoteTerminalMutation(baseOptions?: Apollo.MutationHookOptions<PromoteTerminalMutation, PromoteTerminalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PromoteTerminalMutation, PromoteTerminalMutationVariables>(PromoteTerminalDocument, options);
      }
export type PromoteTerminalMutationHookResult = ReturnType<typeof usePromoteTerminalMutation>;
export type PromoteTerminalMutationResult = Apollo.MutationResult<PromoteTerminalMutation>;
export type PromoteTerminalMutationOptions = Apollo.BaseMutationOptions<PromoteTerminalMutation, PromoteTerminalMutationVariables>;
export const GetMasterGridDocument = gql`
    query GetMasterGrid($internalTerminalId: Int!) {
  getMasterGrid(internalTerminalId: $internalTerminalId) {
    id
    internalTerminalId
    columnHeaders
    rowHeaders
    gridName
  }
}
    `;

/**
 * __useGetMasterGridQuery__
 *
 * To run a query within a React component, call `useGetMasterGridQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMasterGridQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMasterGridQuery({
 *   variables: {
 *      internalTerminalId: // value for 'internalTerminalId'
 *   },
 * });
 */
export function useGetMasterGridQuery(baseOptions: Apollo.QueryHookOptions<GetMasterGridQuery, GetMasterGridQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMasterGridQuery, GetMasterGridQueryVariables>(GetMasterGridDocument, options);
      }
export function useGetMasterGridLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMasterGridQuery, GetMasterGridQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMasterGridQuery, GetMasterGridQueryVariables>(GetMasterGridDocument, options);
        }
export type GetMasterGridQueryHookResult = ReturnType<typeof useGetMasterGridQuery>;
export type GetMasterGridLazyQueryHookResult = ReturnType<typeof useGetMasterGridLazyQuery>;
export type GetMasterGridQueryResult = Apollo.QueryResult<GetMasterGridQuery, GetMasterGridQueryVariables>;
export const ReportMailDocument = gql`
    mutation ReportMail($terminalName: String!, $mailIds: [String!]!, $pdfAttachment: String!, $excelSheetAttachment: String) {
  reportMail(
    terminalName: $terminalName
    mailIds: $mailIds
    pdfAttachment: $pdfAttachment
    excelSheetAttachment: $excelSheetAttachment
  )
}
    `;
export type ReportMailMutationFn = Apollo.MutationFunction<ReportMailMutation, ReportMailMutationVariables>;

/**
 * __useReportMailMutation__
 *
 * To run a mutation, you first call `useReportMailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportMailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportMailMutation, { data, loading, error }] = useReportMailMutation({
 *   variables: {
 *      terminalName: // value for 'terminalName'
 *      mailIds: // value for 'mailIds'
 *      pdfAttachment: // value for 'pdfAttachment'
 *      excelSheetAttachment: // value for 'excelSheetAttachment'
 *   },
 * });
 */
export function useReportMailMutation(baseOptions?: Apollo.MutationHookOptions<ReportMailMutation, ReportMailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReportMailMutation, ReportMailMutationVariables>(ReportMailDocument, options);
      }
export type ReportMailMutationHookResult = ReturnType<typeof useReportMailMutation>;
export type ReportMailMutationResult = Apollo.MutationResult<ReportMailMutation>;
export type ReportMailMutationOptions = Apollo.BaseMutationOptions<ReportMailMutation, ReportMailMutationVariables>;
export const LinkUnlinkLegWithShipmentDocument = gql`
    mutation LinkUnlinkLegWithShipment($input: OrderLinkUnlinkInput!) {
  linkOrUnlinkOrderInLeg(input: $input)
}
    `;
export type LinkUnlinkLegWithShipmentMutationFn = Apollo.MutationFunction<LinkUnlinkLegWithShipmentMutation, LinkUnlinkLegWithShipmentMutationVariables>;

/**
 * __useLinkUnlinkLegWithShipmentMutation__
 *
 * To run a mutation, you first call `useLinkUnlinkLegWithShipmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkUnlinkLegWithShipmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkUnlinkLegWithShipmentMutation, { data, loading, error }] = useLinkUnlinkLegWithShipmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLinkUnlinkLegWithShipmentMutation(baseOptions?: Apollo.MutationHookOptions<LinkUnlinkLegWithShipmentMutation, LinkUnlinkLegWithShipmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LinkUnlinkLegWithShipmentMutation, LinkUnlinkLegWithShipmentMutationVariables>(LinkUnlinkLegWithShipmentDocument, options);
      }
export type LinkUnlinkLegWithShipmentMutationHookResult = ReturnType<typeof useLinkUnlinkLegWithShipmentMutation>;
export type LinkUnlinkLegWithShipmentMutationResult = Apollo.MutationResult<LinkUnlinkLegWithShipmentMutation>;
export type LinkUnlinkLegWithShipmentMutationOptions = Apollo.BaseMutationOptions<LinkUnlinkLegWithShipmentMutation, LinkUnlinkLegWithShipmentMutationVariables>;
export const TruckFillAndTimeDocument = gql`
    mutation TruckFillAndTime($truckFillAndTimeInput: TruckFillAndTimeInput!) {
  truckFillAndTime(input: $truckFillAndTimeInput) {
    id
    loadCar
    loadTrailer
    loadCarPPL
    loadTrailerPPL
    unloadCar
    unloadTrailer
    unloadCarPPL
    unloadTrailerPPL
    actualDepartureTime
    actualDepartureDate
    actualArrivalTime
    actualArrivalDate
    terminalNote
    lastUpdatedBy
    updatedAt
    gateNumber
    carRegistrationNumber
    trailerRegistrationNumber
    terminalNickname
    isNonArrivalMarked
    actualCapacity
    sealNumber
    isSealBroken
    productionGroup
    arrivalStatus
    files {
      id
      originalname
      mimetype
      path
      size
    }
  }
}
    `;
export type TruckFillAndTimeMutationFn = Apollo.MutationFunction<TruckFillAndTimeMutation, TruckFillAndTimeMutationVariables>;

/**
 * __useTruckFillAndTimeMutation__
 *
 * To run a mutation, you first call `useTruckFillAndTimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTruckFillAndTimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [truckFillAndTimeMutation, { data, loading, error }] = useTruckFillAndTimeMutation({
 *   variables: {
 *      truckFillAndTimeInput: // value for 'truckFillAndTimeInput'
 *   },
 * });
 */
export function useTruckFillAndTimeMutation(baseOptions?: Apollo.MutationHookOptions<TruckFillAndTimeMutation, TruckFillAndTimeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TruckFillAndTimeMutation, TruckFillAndTimeMutationVariables>(TruckFillAndTimeDocument, options);
      }
export type TruckFillAndTimeMutationHookResult = ReturnType<typeof useTruckFillAndTimeMutation>;
export type TruckFillAndTimeMutationResult = Apollo.MutationResult<TruckFillAndTimeMutation>;
export type TruckFillAndTimeMutationOptions = Apollo.BaseMutationOptions<TruckFillAndTimeMutation, TruckFillAndTimeMutationVariables>;
export const UpdateLegWithLoadingListDocument = gql`
    mutation UpdateLegWithLoadingList($input: LegLoadingListInput!) {
  updateLegWithLoadingList(input: $input) {
    id
    loadingListItems {
      id
      locationId
      packages
      pallets
      palletSpace
      weight
      checked
      isDangerous
      classification
      unNumber
      isLimitedQty
      note
    }
  }
}
    `;
export type UpdateLegWithLoadingListMutationFn = Apollo.MutationFunction<UpdateLegWithLoadingListMutation, UpdateLegWithLoadingListMutationVariables>;

/**
 * __useUpdateLegWithLoadingListMutation__
 *
 * To run a mutation, you first call `useUpdateLegWithLoadingListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLegWithLoadingListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLegWithLoadingListMutation, { data, loading, error }] = useUpdateLegWithLoadingListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLegWithLoadingListMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLegWithLoadingListMutation, UpdateLegWithLoadingListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLegWithLoadingListMutation, UpdateLegWithLoadingListMutationVariables>(UpdateLegWithLoadingListDocument, options);
      }
export type UpdateLegWithLoadingListMutationHookResult = ReturnType<typeof useUpdateLegWithLoadingListMutation>;
export type UpdateLegWithLoadingListMutationResult = Apollo.MutationResult<UpdateLegWithLoadingListMutation>;
export type UpdateLegWithLoadingListMutationOptions = Apollo.BaseMutationOptions<UpdateLegWithLoadingListMutation, UpdateLegWithLoadingListMutationVariables>;
export const UpdateTripInfoDocument = gql`
    mutation UpdateTripInfo($legId: Int!, $tripStatus: String!, $dateTimeStamp: String!, $gateNumber: String!, $driverName: String, $carRegNumber: String, $trailerRegNumber: String, $capacity: Int) {
  updateTripInfoFromProdApp(
    legId: $legId
    tripStatus: $tripStatus
    dateTimeStamp: $dateTimeStamp
    gateNumber: $gateNumber
    driverName: $driverName
    carRegNumber: $carRegNumber
    trailerRegNumber: $trailerRegNumber
    capacity: $capacity
  )
}
    `;
export type UpdateTripInfoMutationFn = Apollo.MutationFunction<UpdateTripInfoMutation, UpdateTripInfoMutationVariables>;

/**
 * __useUpdateTripInfoMutation__
 *
 * To run a mutation, you first call `useUpdateTripInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTripInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTripInfoMutation, { data, loading, error }] = useUpdateTripInfoMutation({
 *   variables: {
 *      legId: // value for 'legId'
 *      tripStatus: // value for 'tripStatus'
 *      dateTimeStamp: // value for 'dateTimeStamp'
 *      gateNumber: // value for 'gateNumber'
 *      driverName: // value for 'driverName'
 *      carRegNumber: // value for 'carRegNumber'
 *      trailerRegNumber: // value for 'trailerRegNumber'
 *      capacity: // value for 'capacity'
 *   },
 * });
 */
export function useUpdateTripInfoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTripInfoMutation, UpdateTripInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTripInfoMutation, UpdateTripInfoMutationVariables>(UpdateTripInfoDocument, options);
      }
export type UpdateTripInfoMutationHookResult = ReturnType<typeof useUpdateTripInfoMutation>;
export type UpdateTripInfoMutationResult = Apollo.MutationResult<UpdateTripInfoMutation>;
export type UpdateTripInfoMutationOptions = Apollo.BaseMutationOptions<UpdateTripInfoMutation, UpdateTripInfoMutationVariables>;
export const DeleteLoadingListItemDocument = gql`
    mutation deleteLoadingListItem($id: Int!) {
  deleteLoadingListItem(id: $id)
}
    `;
export type DeleteLoadingListItemMutationFn = Apollo.MutationFunction<DeleteLoadingListItemMutation, DeleteLoadingListItemMutationVariables>;

/**
 * __useDeleteLoadingListItemMutation__
 *
 * To run a mutation, you first call `useDeleteLoadingListItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLoadingListItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLoadingListItemMutation, { data, loading, error }] = useDeleteLoadingListItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLoadingListItemMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLoadingListItemMutation, DeleteLoadingListItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLoadingListItemMutation, DeleteLoadingListItemMutationVariables>(DeleteLoadingListItemDocument, options);
      }
export type DeleteLoadingListItemMutationHookResult = ReturnType<typeof useDeleteLoadingListItemMutation>;
export type DeleteLoadingListItemMutationResult = Apollo.MutationResult<DeleteLoadingListItemMutation>;
export type DeleteLoadingListItemMutationOptions = Apollo.BaseMutationOptions<DeleteLoadingListItemMutation, DeleteLoadingListItemMutationVariables>;
export const UpdateLoadingListItemDocument = gql`
    mutation UpdateLoadingListItem($input: ShipmentLoadingList!, $shipmentId: Int!) {
  updateLoadingListItem(input: $input, shipmentId: $shipmentId) {
    id
  }
}
    `;
export type UpdateLoadingListItemMutationFn = Apollo.MutationFunction<UpdateLoadingListItemMutation, UpdateLoadingListItemMutationVariables>;

/**
 * __useUpdateLoadingListItemMutation__
 *
 * To run a mutation, you first call `useUpdateLoadingListItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLoadingListItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLoadingListItemMutation, { data, loading, error }] = useUpdateLoadingListItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *      shipmentId: // value for 'shipmentId'
 *   },
 * });
 */
export function useUpdateLoadingListItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLoadingListItemMutation, UpdateLoadingListItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLoadingListItemMutation, UpdateLoadingListItemMutationVariables>(UpdateLoadingListItemDocument, options);
      }
export type UpdateLoadingListItemMutationHookResult = ReturnType<typeof useUpdateLoadingListItemMutation>;
export type UpdateLoadingListItemMutationResult = Apollo.MutationResult<UpdateLoadingListItemMutation>;
export type UpdateLoadingListItemMutationOptions = Apollo.BaseMutationOptions<UpdateLoadingListItemMutation, UpdateLoadingListItemMutationVariables>;
export const UpdateLocationDocument = gql`
    mutation UpdateLocation($id: Int!, $name: String!, $address: String!, $postalCode: String!, $city: String!, $country: String!, $phLocationId: String!, $locationShortCode: String!) {
  updateLocation(
    input: {id: $id, name: $name, address: $address, postalCode: $postalCode, city: $city, country: $country, phLocationId: $phLocationId, locationShortCode: $locationShortCode}
  ) {
    id
    name
    address
    postalCode
    city
    country
  }
}
    `;
export type UpdateLocationMutationFn = Apollo.MutationFunction<UpdateLocationMutation, UpdateLocationMutationVariables>;

/**
 * __useUpdateLocationMutation__
 *
 * To run a mutation, you first call `useUpdateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationMutation, { data, loading, error }] = useUpdateLocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      address: // value for 'address'
 *      postalCode: // value for 'postalCode'
 *      city: // value for 'city'
 *      country: // value for 'country'
 *      phLocationId: // value for 'phLocationId'
 *      locationShortCode: // value for 'locationShortCode'
 *   },
 * });
 */
export function useUpdateLocationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLocationMutation, UpdateLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLocationMutation, UpdateLocationMutationVariables>(UpdateLocationDocument, options);
      }
export type UpdateLocationMutationHookResult = ReturnType<typeof useUpdateLocationMutation>;
export type UpdateLocationMutationResult = Apollo.MutationResult<UpdateLocationMutation>;
export type UpdateLocationMutationOptions = Apollo.BaseMutationOptions<UpdateLocationMutation, UpdateLocationMutationVariables>;
export const CreateLocationDocument = gql`
    mutation CreateLocation($name: String!, $address: String!, $postalCode: String!, $city: String!, $country: String!, $phLocationId: String!, $locationShortCode: String!) {
  createLocation(
    input: {name: $name, address: $address, postalCode: $postalCode, city: $city, country: $country, phLocationId: $phLocationId, locationShortCode: $locationShortCode}
  ) {
    id
    name
    address
    postalCode
    city
    country
  }
}
    `;
export type CreateLocationMutationFn = Apollo.MutationFunction<CreateLocationMutation, CreateLocationMutationVariables>;

/**
 * __useCreateLocationMutation__
 *
 * To run a mutation, you first call `useCreateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLocationMutation, { data, loading, error }] = useCreateLocationMutation({
 *   variables: {
 *      name: // value for 'name'
 *      address: // value for 'address'
 *      postalCode: // value for 'postalCode'
 *      city: // value for 'city'
 *      country: // value for 'country'
 *      phLocationId: // value for 'phLocationId'
 *      locationShortCode: // value for 'locationShortCode'
 *   },
 * });
 */
export function useCreateLocationMutation(baseOptions?: Apollo.MutationHookOptions<CreateLocationMutation, CreateLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLocationMutation, CreateLocationMutationVariables>(CreateLocationDocument, options);
      }
export type CreateLocationMutationHookResult = ReturnType<typeof useCreateLocationMutation>;
export type CreateLocationMutationResult = Apollo.MutationResult<CreateLocationMutation>;
export type CreateLocationMutationOptions = Apollo.BaseMutationOptions<CreateLocationMutation, CreateLocationMutationVariables>;
export const CreateOrderDocument = gql`
    mutation CreateOrder($input: OrderInput!) {
  createOrder(input: $input) {
    id
    transportationDate
    customerId
    note
    pallets
    palletSpace
    weight
    packages
    updatedAt
    createdBy
    lastUpdatedBy
  }
}
    `;
export type CreateOrderMutationFn = Apollo.MutationFunction<CreateOrderMutation, CreateOrderMutationVariables>;

/**
 * __useCreateOrderMutation__
 *
 * To run a mutation, you first call `useCreateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderMutation, { data, loading, error }] = useCreateOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrderMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrderMutation, CreateOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrderMutation, CreateOrderMutationVariables>(CreateOrderDocument, options);
      }
export type CreateOrderMutationHookResult = ReturnType<typeof useCreateOrderMutation>;
export type CreateOrderMutationResult = Apollo.MutationResult<CreateOrderMutation>;
export type CreateOrderMutationOptions = Apollo.BaseMutationOptions<CreateOrderMutation, CreateOrderMutationVariables>;
export const UpdateOrderDocument = gql`
    mutation UpdateOrder($id: Int!, $input: OrderInput!) {
  updateOrder(input: $input, id: $id) {
    id
    transportationDate
    customerId
    note
    pallets
    packages
    palletSpace
    weight
    updatedAt
    createdBy
    lastUpdatedBy
  }
}
    `;
export type UpdateOrderMutationFn = Apollo.MutationFunction<UpdateOrderMutation, UpdateOrderMutationVariables>;

/**
 * __useUpdateOrderMutation__
 *
 * To run a mutation, you first call `useUpdateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderMutation, { data, loading, error }] = useUpdateOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrderMutation, UpdateOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrderMutation, UpdateOrderMutationVariables>(UpdateOrderDocument, options);
      }
export type UpdateOrderMutationHookResult = ReturnType<typeof useUpdateOrderMutation>;
export type UpdateOrderMutationResult = Apollo.MutationResult<UpdateOrderMutation>;
export type UpdateOrderMutationOptions = Apollo.BaseMutationOptions<UpdateOrderMutation, UpdateOrderMutationVariables>;
export const CreatePeakPlanningItemDocument = gql`
    mutation CreatePeakPlanningItem($input: PeakPlanningItemInput!) {
  createPeakPlanningItem(input: $input) {
    id
  }
}
    `;
export type CreatePeakPlanningItemMutationFn = Apollo.MutationFunction<CreatePeakPlanningItemMutation, CreatePeakPlanningItemMutationVariables>;

/**
 * __useCreatePeakPlanningItemMutation__
 *
 * To run a mutation, you first call `useCreatePeakPlanningItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePeakPlanningItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPeakPlanningItemMutation, { data, loading, error }] = useCreatePeakPlanningItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePeakPlanningItemMutation(baseOptions?: Apollo.MutationHookOptions<CreatePeakPlanningItemMutation, CreatePeakPlanningItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePeakPlanningItemMutation, CreatePeakPlanningItemMutationVariables>(CreatePeakPlanningItemDocument, options);
      }
export type CreatePeakPlanningItemMutationHookResult = ReturnType<typeof useCreatePeakPlanningItemMutation>;
export type CreatePeakPlanningItemMutationResult = Apollo.MutationResult<CreatePeakPlanningItemMutation>;
export type CreatePeakPlanningItemMutationOptions = Apollo.BaseMutationOptions<CreatePeakPlanningItemMutation, CreatePeakPlanningItemMutationVariables>;
export const DeletePeakPlanningItemDocument = gql`
    mutation DeletePeakPlanningItem($id: Int!) {
  deletePeakPlanningItem(id: $id)
}
    `;
export type DeletePeakPlanningItemMutationFn = Apollo.MutationFunction<DeletePeakPlanningItemMutation, DeletePeakPlanningItemMutationVariables>;

/**
 * __useDeletePeakPlanningItemMutation__
 *
 * To run a mutation, you first call `useDeletePeakPlanningItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePeakPlanningItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePeakPlanningItemMutation, { data, loading, error }] = useDeletePeakPlanningItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePeakPlanningItemMutation(baseOptions?: Apollo.MutationHookOptions<DeletePeakPlanningItemMutation, DeletePeakPlanningItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePeakPlanningItemMutation, DeletePeakPlanningItemMutationVariables>(DeletePeakPlanningItemDocument, options);
      }
export type DeletePeakPlanningItemMutationHookResult = ReturnType<typeof useDeletePeakPlanningItemMutation>;
export type DeletePeakPlanningItemMutationResult = Apollo.MutationResult<DeletePeakPlanningItemMutation>;
export type DeletePeakPlanningItemMutationOptions = Apollo.BaseMutationOptions<DeletePeakPlanningItemMutation, DeletePeakPlanningItemMutationVariables>;
export const UpdatePeakPlanningItemDocument = gql`
    mutation UpdatePeakPlanningItem($id: Int!, $input: PeakPlanningItemInput!) {
  updatePeakPlanningItem(input: $input, id: $id) {
    id
  }
}
    `;
export type UpdatePeakPlanningItemMutationFn = Apollo.MutationFunction<UpdatePeakPlanningItemMutation, UpdatePeakPlanningItemMutationVariables>;

/**
 * __useUpdatePeakPlanningItemMutation__
 *
 * To run a mutation, you first call `useUpdatePeakPlanningItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePeakPlanningItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePeakPlanningItemMutation, { data, loading, error }] = useUpdatePeakPlanningItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePeakPlanningItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePeakPlanningItemMutation, UpdatePeakPlanningItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePeakPlanningItemMutation, UpdatePeakPlanningItemMutationVariables>(UpdatePeakPlanningItemDocument, options);
      }
export type UpdatePeakPlanningItemMutationHookResult = ReturnType<typeof useUpdatePeakPlanningItemMutation>;
export type UpdatePeakPlanningItemMutationResult = Apollo.MutationResult<UpdatePeakPlanningItemMutation>;
export type UpdatePeakPlanningItemMutationOptions = Apollo.BaseMutationOptions<UpdatePeakPlanningItemMutation, UpdatePeakPlanningItemMutationVariables>;
export const CreatePeakPlanningDebitDocument = gql`
    mutation CreatePeakPlanningDebit($input: PeakPlanningDebitInput!) {
  createPeakPlanningDebit(input: $input) {
    id
  }
}
    `;
export type CreatePeakPlanningDebitMutationFn = Apollo.MutationFunction<CreatePeakPlanningDebitMutation, CreatePeakPlanningDebitMutationVariables>;

/**
 * __useCreatePeakPlanningDebitMutation__
 *
 * To run a mutation, you first call `useCreatePeakPlanningDebitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePeakPlanningDebitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPeakPlanningDebitMutation, { data, loading, error }] = useCreatePeakPlanningDebitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePeakPlanningDebitMutation(baseOptions?: Apollo.MutationHookOptions<CreatePeakPlanningDebitMutation, CreatePeakPlanningDebitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePeakPlanningDebitMutation, CreatePeakPlanningDebitMutationVariables>(CreatePeakPlanningDebitDocument, options);
      }
export type CreatePeakPlanningDebitMutationHookResult = ReturnType<typeof useCreatePeakPlanningDebitMutation>;
export type CreatePeakPlanningDebitMutationResult = Apollo.MutationResult<CreatePeakPlanningDebitMutation>;
export type CreatePeakPlanningDebitMutationOptions = Apollo.BaseMutationOptions<CreatePeakPlanningDebitMutation, CreatePeakPlanningDebitMutationVariables>;
export const UpdatePeakPlanningDebitDocument = gql`
    mutation UpdatePeakPlanningDebit($id: Int!, $input: PeakPlanningDebitInput!) {
  updatePeakPlanningDebit(id: $id, input: $input) {
    id
  }
}
    `;
export type UpdatePeakPlanningDebitMutationFn = Apollo.MutationFunction<UpdatePeakPlanningDebitMutation, UpdatePeakPlanningDebitMutationVariables>;

/**
 * __useUpdatePeakPlanningDebitMutation__
 *
 * To run a mutation, you first call `useUpdatePeakPlanningDebitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePeakPlanningDebitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePeakPlanningDebitMutation, { data, loading, error }] = useUpdatePeakPlanningDebitMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePeakPlanningDebitMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePeakPlanningDebitMutation, UpdatePeakPlanningDebitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePeakPlanningDebitMutation, UpdatePeakPlanningDebitMutationVariables>(UpdatePeakPlanningDebitDocument, options);
      }
export type UpdatePeakPlanningDebitMutationHookResult = ReturnType<typeof useUpdatePeakPlanningDebitMutation>;
export type UpdatePeakPlanningDebitMutationResult = Apollo.MutationResult<UpdatePeakPlanningDebitMutation>;
export type UpdatePeakPlanningDebitMutationOptions = Apollo.BaseMutationOptions<UpdatePeakPlanningDebitMutation, UpdatePeakPlanningDebitMutationVariables>;
export const CreateMultipleSporadicRouteDocument = gql`
    mutation CreateMultipleSporadicRoute($input: SporadicRouteInput!, $dates: [String!]!) {
  createMultipleSporadicRoute(input: $input, dates: $dates)
}
    `;
export type CreateMultipleSporadicRouteMutationFn = Apollo.MutationFunction<CreateMultipleSporadicRouteMutation, CreateMultipleSporadicRouteMutationVariables>;

/**
 * __useCreateMultipleSporadicRouteMutation__
 *
 * To run a mutation, you first call `useCreateMultipleSporadicRouteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMultipleSporadicRouteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMultipleSporadicRouteMutation, { data, loading, error }] = useCreateMultipleSporadicRouteMutation({
 *   variables: {
 *      input: // value for 'input'
 *      dates: // value for 'dates'
 *   },
 * });
 */
export function useCreateMultipleSporadicRouteMutation(baseOptions?: Apollo.MutationHookOptions<CreateMultipleSporadicRouteMutation, CreateMultipleSporadicRouteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMultipleSporadicRouteMutation, CreateMultipleSporadicRouteMutationVariables>(CreateMultipleSporadicRouteDocument, options);
      }
export type CreateMultipleSporadicRouteMutationHookResult = ReturnType<typeof useCreateMultipleSporadicRouteMutation>;
export type CreateMultipleSporadicRouteMutationResult = Apollo.MutationResult<CreateMultipleSporadicRouteMutation>;
export type CreateMultipleSporadicRouteMutationOptions = Apollo.BaseMutationOptions<CreateMultipleSporadicRouteMutation, CreateMultipleSporadicRouteMutationVariables>;
export const CreateSporadicRouteDocument = gql`
    mutation CreateSporadicRoute($input: SporadicRouteInput!) {
  createSporadicRoute(input: $input) {
    id
  }
}
    `;
export type CreateSporadicRouteMutationFn = Apollo.MutationFunction<CreateSporadicRouteMutation, CreateSporadicRouteMutationVariables>;

/**
 * __useCreateSporadicRouteMutation__
 *
 * To run a mutation, you first call `useCreateSporadicRouteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSporadicRouteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSporadicRouteMutation, { data, loading, error }] = useCreateSporadicRouteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSporadicRouteMutation(baseOptions?: Apollo.MutationHookOptions<CreateSporadicRouteMutation, CreateSporadicRouteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSporadicRouteMutation, CreateSporadicRouteMutationVariables>(CreateSporadicRouteDocument, options);
      }
export type CreateSporadicRouteMutationHookResult = ReturnType<typeof useCreateSporadicRouteMutation>;
export type CreateSporadicRouteMutationResult = Apollo.MutationResult<CreateSporadicRouteMutation>;
export type CreateSporadicRouteMutationOptions = Apollo.BaseMutationOptions<CreateSporadicRouteMutation, CreateSporadicRouteMutationVariables>;
export const CreateSporadicRouteWithoutDispatcherDocument = gql`
    mutation CreateSporadicRouteWithoutDispatcher($input: SporadicRouteInput!) {
  createSporadicRouteWithoutDispatcher(input: $input) {
    id
  }
}
    `;
export type CreateSporadicRouteWithoutDispatcherMutationFn = Apollo.MutationFunction<CreateSporadicRouteWithoutDispatcherMutation, CreateSporadicRouteWithoutDispatcherMutationVariables>;

/**
 * __useCreateSporadicRouteWithoutDispatcherMutation__
 *
 * To run a mutation, you first call `useCreateSporadicRouteWithoutDispatcherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSporadicRouteWithoutDispatcherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSporadicRouteWithoutDispatcherMutation, { data, loading, error }] = useCreateSporadicRouteWithoutDispatcherMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSporadicRouteWithoutDispatcherMutation(baseOptions?: Apollo.MutationHookOptions<CreateSporadicRouteWithoutDispatcherMutation, CreateSporadicRouteWithoutDispatcherMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSporadicRouteWithoutDispatcherMutation, CreateSporadicRouteWithoutDispatcherMutationVariables>(CreateSporadicRouteWithoutDispatcherDocument, options);
      }
export type CreateSporadicRouteWithoutDispatcherMutationHookResult = ReturnType<typeof useCreateSporadicRouteWithoutDispatcherMutation>;
export type CreateSporadicRouteWithoutDispatcherMutationResult = Apollo.MutationResult<CreateSporadicRouteWithoutDispatcherMutation>;
export type CreateSporadicRouteWithoutDispatcherMutationOptions = Apollo.BaseMutationOptions<CreateSporadicRouteWithoutDispatcherMutation, CreateSporadicRouteWithoutDispatcherMutationVariables>;
export const DeleteMultiplePlannedRouteDocument = gql`
    mutation DeleteMultiplePlannedRoute($ids: [Int!]!) {
  deleteMultipleRoute(ids: $ids)
}
    `;
export type DeleteMultiplePlannedRouteMutationFn = Apollo.MutationFunction<DeleteMultiplePlannedRouteMutation, DeleteMultiplePlannedRouteMutationVariables>;

/**
 * __useDeleteMultiplePlannedRouteMutation__
 *
 * To run a mutation, you first call `useDeleteMultiplePlannedRouteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMultiplePlannedRouteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMultiplePlannedRouteMutation, { data, loading, error }] = useDeleteMultiplePlannedRouteMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteMultiplePlannedRouteMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMultiplePlannedRouteMutation, DeleteMultiplePlannedRouteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMultiplePlannedRouteMutation, DeleteMultiplePlannedRouteMutationVariables>(DeleteMultiplePlannedRouteDocument, options);
      }
export type DeleteMultiplePlannedRouteMutationHookResult = ReturnType<typeof useDeleteMultiplePlannedRouteMutation>;
export type DeleteMultiplePlannedRouteMutationResult = Apollo.MutationResult<DeleteMultiplePlannedRouteMutation>;
export type DeleteMultiplePlannedRouteMutationOptions = Apollo.BaseMutationOptions<DeleteMultiplePlannedRouteMutation, DeleteMultiplePlannedRouteMutationVariables>;
export const DeleteSporadicRouteDocument = gql`
    mutation DeleteSporadicRoute($id: Int!) {
  deleteRoute(id: $id)
}
    `;
export type DeleteSporadicRouteMutationFn = Apollo.MutationFunction<DeleteSporadicRouteMutation, DeleteSporadicRouteMutationVariables>;

/**
 * __useDeleteSporadicRouteMutation__
 *
 * To run a mutation, you first call `useDeleteSporadicRouteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSporadicRouteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSporadicRouteMutation, { data, loading, error }] = useDeleteSporadicRouteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSporadicRouteMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSporadicRouteMutation, DeleteSporadicRouteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSporadicRouteMutation, DeleteSporadicRouteMutationVariables>(DeleteSporadicRouteDocument, options);
      }
export type DeleteSporadicRouteMutationHookResult = ReturnType<typeof useDeleteSporadicRouteMutation>;
export type DeleteSporadicRouteMutationResult = Apollo.MutationResult<DeleteSporadicRouteMutation>;
export type DeleteSporadicRouteMutationOptions = Apollo.BaseMutationOptions<DeleteSporadicRouteMutation, DeleteSporadicRouteMutationVariables>;
export const UpdateDriverDocument = gql`
    mutation UpdateDriver($id: Int!, $driverData: DriverInfo!) {
  updateDriver(id: $id, driverData: $driverData) {
    id
    driverName
    driverPhoneNumber
    licensePlate
  }
}
    `;
export type UpdateDriverMutationFn = Apollo.MutationFunction<UpdateDriverMutation, UpdateDriverMutationVariables>;

/**
 * __useUpdateDriverMutation__
 *
 * To run a mutation, you first call `useUpdateDriverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDriverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDriverMutation, { data, loading, error }] = useUpdateDriverMutation({
 *   variables: {
 *      id: // value for 'id'
 *      driverData: // value for 'driverData'
 *   },
 * });
 */
export function useUpdateDriverMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDriverMutation, UpdateDriverMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDriverMutation, UpdateDriverMutationVariables>(UpdateDriverDocument, options);
      }
export type UpdateDriverMutationHookResult = ReturnType<typeof useUpdateDriverMutation>;
export type UpdateDriverMutationResult = Apollo.MutationResult<UpdateDriverMutation>;
export type UpdateDriverMutationOptions = Apollo.BaseMutationOptions<UpdateDriverMutation, UpdateDriverMutationVariables>;
export const UpdateExtraColumnsDocument = gql`
    mutation UpdateExtraColumns($id: Int!, $extraColumnData: extraColumnInfo!) {
  updateExtraColumns(id: $id, extraColumnData: $extraColumnData) {
    id
    turnummer
    togref
    bpx
    pri03
    pri49
    upri03
    upri49
    parti
    brev
    pru
    tom
    rutekode
    trainNumber
    inneholdsbeskrivelse
    containerId
    transportType
  }
}
    `;
export type UpdateExtraColumnsMutationFn = Apollo.MutationFunction<UpdateExtraColumnsMutation, UpdateExtraColumnsMutationVariables>;

/**
 * __useUpdateExtraColumnsMutation__
 *
 * To run a mutation, you first call `useUpdateExtraColumnsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExtraColumnsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExtraColumnsMutation, { data, loading, error }] = useUpdateExtraColumnsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      extraColumnData: // value for 'extraColumnData'
 *   },
 * });
 */
export function useUpdateExtraColumnsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateExtraColumnsMutation, UpdateExtraColumnsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateExtraColumnsMutation, UpdateExtraColumnsMutationVariables>(UpdateExtraColumnsDocument, options);
      }
export type UpdateExtraColumnsMutationHookResult = ReturnType<typeof useUpdateExtraColumnsMutation>;
export type UpdateExtraColumnsMutationResult = Apollo.MutationResult<UpdateExtraColumnsMutation>;
export type UpdateExtraColumnsMutationOptions = Apollo.BaseMutationOptions<UpdateExtraColumnsMutation, UpdateExtraColumnsMutationVariables>;
export const UpdateInvoiceDocument = gql`
    mutation UpdateInvoice($id: Int!, $invoiceData: InvoiceDataInput!) {
  updateInvoice(id: $id, invoiceData: $invoiceData) {
    id
    invoiceNote
    invoiceNumber
    agreedPrice
  }
}
    `;
export type UpdateInvoiceMutationFn = Apollo.MutationFunction<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>;

/**
 * __useUpdateInvoiceMutation__
 *
 * To run a mutation, you first call `useUpdateInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoiceMutation, { data, loading, error }] = useUpdateInvoiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      invoiceData: // value for 'invoiceData'
 *   },
 * });
 */
export function useUpdateInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>(UpdateInvoiceDocument, options);
      }
export type UpdateInvoiceMutationHookResult = ReturnType<typeof useUpdateInvoiceMutation>;
export type UpdateInvoiceMutationResult = Apollo.MutationResult<UpdateInvoiceMutation>;
export type UpdateInvoiceMutationOptions = Apollo.BaseMutationOptions<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>;
export const UpdateLockDocument = gql`
    mutation updateLock($routeId: Int!, $lock: Int!, $reload: Int!) {
  updateLock(routeId: $routeId, lock: $lock, reload: $reload) {
    routeId
    lockedAt
    lockedBy
  }
}
    `;
export type UpdateLockMutationFn = Apollo.MutationFunction<UpdateLockMutation, UpdateLockMutationVariables>;

/**
 * __useUpdateLockMutation__
 *
 * To run a mutation, you first call `useUpdateLockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLockMutation, { data, loading, error }] = useUpdateLockMutation({
 *   variables: {
 *      routeId: // value for 'routeId'
 *      lock: // value for 'lock'
 *      reload: // value for 'reload'
 *   },
 * });
 */
export function useUpdateLockMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLockMutation, UpdateLockMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLockMutation, UpdateLockMutationVariables>(UpdateLockDocument, options);
      }
export type UpdateLockMutationHookResult = ReturnType<typeof useUpdateLockMutation>;
export type UpdateLockMutationResult = Apollo.MutationResult<UpdateLockMutation>;
export type UpdateLockMutationOptions = Apollo.BaseMutationOptions<UpdateLockMutation, UpdateLockMutationVariables>;
export const UpdateLockOnBulkRoutesDocument = gql`
    mutation UpdateLockOnBulkRoutes($routeIds: [Int!]!, $isLock: Boolean!, $forGroup: String!) {
  updateLockOnBulkRoutes(
    routeIds: $routeIds
    isLock: $isLock
    forGroup: $forGroup
  )
}
    `;
export type UpdateLockOnBulkRoutesMutationFn = Apollo.MutationFunction<UpdateLockOnBulkRoutesMutation, UpdateLockOnBulkRoutesMutationVariables>;

/**
 * __useUpdateLockOnBulkRoutesMutation__
 *
 * To run a mutation, you first call `useUpdateLockOnBulkRoutesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLockOnBulkRoutesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLockOnBulkRoutesMutation, { data, loading, error }] = useUpdateLockOnBulkRoutesMutation({
 *   variables: {
 *      routeIds: // value for 'routeIds'
 *      isLock: // value for 'isLock'
 *      forGroup: // value for 'forGroup'
 *   },
 * });
 */
export function useUpdateLockOnBulkRoutesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLockOnBulkRoutesMutation, UpdateLockOnBulkRoutesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLockOnBulkRoutesMutation, UpdateLockOnBulkRoutesMutationVariables>(UpdateLockOnBulkRoutesDocument, options);
      }
export type UpdateLockOnBulkRoutesMutationHookResult = ReturnType<typeof useUpdateLockOnBulkRoutesMutation>;
export type UpdateLockOnBulkRoutesMutationResult = Apollo.MutationResult<UpdateLockOnBulkRoutesMutation>;
export type UpdateLockOnBulkRoutesMutationOptions = Apollo.BaseMutationOptions<UpdateLockOnBulkRoutesMutation, UpdateLockOnBulkRoutesMutationVariables>;
export const UpdateMinimalRouteDocument = gql`
    mutation UpdateMinimalRoute($input: UpdateMinimalRouteInput!) {
  updateMinimalRoutes(input: $input)
}
    `;
export type UpdateMinimalRouteMutationFn = Apollo.MutationFunction<UpdateMinimalRouteMutation, UpdateMinimalRouteMutationVariables>;

/**
 * __useUpdateMinimalRouteMutation__
 *
 * To run a mutation, you first call `useUpdateMinimalRouteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMinimalRouteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMinimalRouteMutation, { data, loading, error }] = useUpdateMinimalRouteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMinimalRouteMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMinimalRouteMutation, UpdateMinimalRouteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMinimalRouteMutation, UpdateMinimalRouteMutationVariables>(UpdateMinimalRouteDocument, options);
      }
export type UpdateMinimalRouteMutationHookResult = ReturnType<typeof useUpdateMinimalRouteMutation>;
export type UpdateMinimalRouteMutationResult = Apollo.MutationResult<UpdateMinimalRouteMutation>;
export type UpdateMinimalRouteMutationOptions = Apollo.BaseMutationOptions<UpdateMinimalRouteMutation, UpdateMinimalRouteMutationVariables>;
export const UpdateRouteGroupTagDocument = gql`
    mutation UpdateRouteGroupTag($id: Int!, $routeGroupTag: String!) {
  updateRouteGroupTag(id: $id, routeGroupTag: $routeGroupTag)
}
    `;
export type UpdateRouteGroupTagMutationFn = Apollo.MutationFunction<UpdateRouteGroupTagMutation, UpdateRouteGroupTagMutationVariables>;

/**
 * __useUpdateRouteGroupTagMutation__
 *
 * To run a mutation, you first call `useUpdateRouteGroupTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRouteGroupTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRouteGroupTagMutation, { data, loading, error }] = useUpdateRouteGroupTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *      routeGroupTag: // value for 'routeGroupTag'
 *   },
 * });
 */
export function useUpdateRouteGroupTagMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRouteGroupTagMutation, UpdateRouteGroupTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRouteGroupTagMutation, UpdateRouteGroupTagMutationVariables>(UpdateRouteGroupTagDocument, options);
      }
export type UpdateRouteGroupTagMutationHookResult = ReturnType<typeof useUpdateRouteGroupTagMutation>;
export type UpdateRouteGroupTagMutationResult = Apollo.MutationResult<UpdateRouteGroupTagMutation>;
export type UpdateRouteGroupTagMutationOptions = Apollo.BaseMutationOptions<UpdateRouteGroupTagMutation, UpdateRouteGroupTagMutationVariables>;
export const UpdateRouteLegDataColumnsDocument = gql`
    mutation updateRouteLegDataColumns($id: Int!, $routeLegData: RouteLegDataColumnInfo!) {
  updateRouteLegDataColumns(id: $id, routeLegData: $routeLegData) {
    id
    carRegistrationNumber
    trailerRegistrationNumber
  }
}
    `;
export type UpdateRouteLegDataColumnsMutationFn = Apollo.MutationFunction<UpdateRouteLegDataColumnsMutation, UpdateRouteLegDataColumnsMutationVariables>;

/**
 * __useUpdateRouteLegDataColumnsMutation__
 *
 * To run a mutation, you first call `useUpdateRouteLegDataColumnsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRouteLegDataColumnsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRouteLegDataColumnsMutation, { data, loading, error }] = useUpdateRouteLegDataColumnsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      routeLegData: // value for 'routeLegData'
 *   },
 * });
 */
export function useUpdateRouteLegDataColumnsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRouteLegDataColumnsMutation, UpdateRouteLegDataColumnsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRouteLegDataColumnsMutation, UpdateRouteLegDataColumnsMutationVariables>(UpdateRouteLegDataColumnsDocument, options);
      }
export type UpdateRouteLegDataColumnsMutationHookResult = ReturnType<typeof useUpdateRouteLegDataColumnsMutation>;
export type UpdateRouteLegDataColumnsMutationResult = Apollo.MutationResult<UpdateRouteLegDataColumnsMutation>;
export type UpdateRouteLegDataColumnsMutationOptions = Apollo.BaseMutationOptions<UpdateRouteLegDataColumnsMutation, UpdateRouteLegDataColumnsMutationVariables>;
export const UpdateSporadicRouteDocument = gql`
    mutation UpdateSporadicRoute($routeId: Int!, $input: SporadicRouteInput!) {
  updateSporadicRoute(id: $routeId, input: $input) {
    agreedPrice
    capacity
    currency
    dispatcher {
      id
      email
      name
      phoneNumber
      tags {
        id
        title
      }
    }
    driverName
    driverPhoneNumber
    files {
      id
      mimetype
      originalname
      path
      size
      tags {
        id
        title
      }
    }
    ftl {
      cdc
      cmr
      currency
      customerId
      customerNumber
      customerReference
      id
      invoiceNote
      price
    }
    id
    invoiceNote
    invoiceNumber
    legs {
      gateNumber
      carRegistrationNumber
      trailerRegistrationNumber
      routeDriverName
      routeDriverPhoneNumber
      arrivalTime
      departureTime
      id
      load
      note
      position
      unload
      locationId
    }
    licensePlate
    note
    externalNote
    routeId
    subcontractorId
    transportationDate
    type
    isCreatedFromTerminal
    isCreatedFromPlanned
  }
}
    `;
export type UpdateSporadicRouteMutationFn = Apollo.MutationFunction<UpdateSporadicRouteMutation, UpdateSporadicRouteMutationVariables>;

/**
 * __useUpdateSporadicRouteMutation__
 *
 * To run a mutation, you first call `useUpdateSporadicRouteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSporadicRouteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSporadicRouteMutation, { data, loading, error }] = useUpdateSporadicRouteMutation({
 *   variables: {
 *      routeId: // value for 'routeId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSporadicRouteMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSporadicRouteMutation, UpdateSporadicRouteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSporadicRouteMutation, UpdateSporadicRouteMutationVariables>(UpdateSporadicRouteDocument, options);
      }
export type UpdateSporadicRouteMutationHookResult = ReturnType<typeof useUpdateSporadicRouteMutation>;
export type UpdateSporadicRouteMutationResult = Apollo.MutationResult<UpdateSporadicRouteMutation>;
export type UpdateSporadicRouteMutationOptions = Apollo.BaseMutationOptions<UpdateSporadicRouteMutation, UpdateSporadicRouteMutationVariables>;
export const CreateRouteTemplateDocument = gql`
    mutation CreateRouteTemplate($RouteTemplateInput: SporadicRouteTemplateInput!) {
  createSporadicRouteTemplate(input: $RouteTemplateInput) {
    id
  }
}
    `;
export type CreateRouteTemplateMutationFn = Apollo.MutationFunction<CreateRouteTemplateMutation, CreateRouteTemplateMutationVariables>;

/**
 * __useCreateRouteTemplateMutation__
 *
 * To run a mutation, you first call `useCreateRouteTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRouteTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRouteTemplateMutation, { data, loading, error }] = useCreateRouteTemplateMutation({
 *   variables: {
 *      RouteTemplateInput: // value for 'RouteTemplateInput'
 *   },
 * });
 */
export function useCreateRouteTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreateRouteTemplateMutation, CreateRouteTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRouteTemplateMutation, CreateRouteTemplateMutationVariables>(CreateRouteTemplateDocument, options);
      }
export type CreateRouteTemplateMutationHookResult = ReturnType<typeof useCreateRouteTemplateMutation>;
export type CreateRouteTemplateMutationResult = Apollo.MutationResult<CreateRouteTemplateMutation>;
export type CreateRouteTemplateMutationOptions = Apollo.BaseMutationOptions<CreateRouteTemplateMutation, CreateRouteTemplateMutationVariables>;
export const CreateShipmentDocument = gql`
    mutation CreateShipment($input: CreateShipmentInput!) {
  createShipment(input: $input) {
    id
  }
}
    `;
export type CreateShipmentMutationFn = Apollo.MutationFunction<CreateShipmentMutation, CreateShipmentMutationVariables>;

/**
 * __useCreateShipmentMutation__
 *
 * To run a mutation, you first call `useCreateShipmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateShipmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createShipmentMutation, { data, loading, error }] = useCreateShipmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateShipmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateShipmentMutation, CreateShipmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateShipmentMutation, CreateShipmentMutationVariables>(CreateShipmentDocument, options);
      }
export type CreateShipmentMutationHookResult = ReturnType<typeof useCreateShipmentMutation>;
export type CreateShipmentMutationResult = Apollo.MutationResult<CreateShipmentMutation>;
export type CreateShipmentMutationOptions = Apollo.BaseMutationOptions<CreateShipmentMutation, CreateShipmentMutationVariables>;
export const DeleteShipmentDocument = gql`
    mutation DeleteShipment($id: Int!, $updatedFrom: String!, $shipmentDate: String!) {
  deleteShipment(id: $id, updatedFrom: $updatedFrom, shipmentDate: $shipmentDate)
}
    `;
export type DeleteShipmentMutationFn = Apollo.MutationFunction<DeleteShipmentMutation, DeleteShipmentMutationVariables>;

/**
 * __useDeleteShipmentMutation__
 *
 * To run a mutation, you first call `useDeleteShipmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteShipmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteShipmentMutation, { data, loading, error }] = useDeleteShipmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      updatedFrom: // value for 'updatedFrom'
 *      shipmentDate: // value for 'shipmentDate'
 *   },
 * });
 */
export function useDeleteShipmentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteShipmentMutation, DeleteShipmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteShipmentMutation, DeleteShipmentMutationVariables>(DeleteShipmentDocument, options);
      }
export type DeleteShipmentMutationHookResult = ReturnType<typeof useDeleteShipmentMutation>;
export type DeleteShipmentMutationResult = Apollo.MutationResult<DeleteShipmentMutation>;
export type DeleteShipmentMutationOptions = Apollo.BaseMutationOptions<DeleteShipmentMutation, DeleteShipmentMutationVariables>;
export const GetShipmentDocument = gql`
    query GetShipment($startDate: String!, $endDate: String!) {
  shipments(startDate: $startDate, endDate: $endDate) {
    id
    name
    shipmentDate
    createdFrom
    updatedFrom
    createdBy
    lastUpdatedBy
    updatedAt
    fromLocationId
    toLocationId
    loadingListItems {
      customClearedForImport
      isRemainingGoods
      isVoecCustomer
      customCleared
      terminalCleared
      isImportedFromShipment
      packages
      pallets
      palletSpace
    }
  }
}
    `;

/**
 * __useGetShipmentQuery__
 *
 * To run a query within a React component, call `useGetShipmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShipmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShipmentQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetShipmentQuery(baseOptions: Apollo.QueryHookOptions<GetShipmentQuery, GetShipmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetShipmentQuery, GetShipmentQueryVariables>(GetShipmentDocument, options);
      }
export function useGetShipmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetShipmentQuery, GetShipmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetShipmentQuery, GetShipmentQueryVariables>(GetShipmentDocument, options);
        }
export type GetShipmentQueryHookResult = ReturnType<typeof useGetShipmentQuery>;
export type GetShipmentLazyQueryHookResult = ReturnType<typeof useGetShipmentLazyQuery>;
export type GetShipmentQueryResult = Apollo.QueryResult<GetShipmentQuery, GetShipmentQueryVariables>;
export const GetShipmentByIdDocument = gql`
    query GetShipmentById($id: Int!) {
  shipmentById(id: $id) {
    id
    name
    shipmentDate
    fromLocationId
    toLocationId
    lastUpdatedBy
    createdFrom
    updatedFrom
    createdBy
    lastUpdatedBy
    loadingListItems {
      id
      locationId
      location {
        name
      }
      packages
      pallets
      palletSpace
      weight
      checked
      isDangerous
      classification
      unNumber
      isLimitedQty
      note
      customVOECId
      customInvoiceNo
      customMrnNo
      customWaybillNo
      customCmrNo
      customNote
      connectedNB
      customCleared
      customClearedForImport
      isRemainingGoods
      isVoecCustomer
      terminalShipmentNotArrived
      terminalCleared
      terminalNotCleared
      terminalDevPackages
      terminalDevPallets
      terminalDevPalletSpace
      terminalDevWeight
      terminalDevInfo
      isImportedFromShipment
      customsProcedure
      routeDetail {
        id
        routeName
      }
    }
  }
}
    `;

/**
 * __useGetShipmentByIdQuery__
 *
 * To run a query within a React component, call `useGetShipmentByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShipmentByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShipmentByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetShipmentByIdQuery(baseOptions: Apollo.QueryHookOptions<GetShipmentByIdQuery, GetShipmentByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetShipmentByIdQuery, GetShipmentByIdQueryVariables>(GetShipmentByIdDocument, options);
      }
export function useGetShipmentByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetShipmentByIdQuery, GetShipmentByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetShipmentByIdQuery, GetShipmentByIdQueryVariables>(GetShipmentByIdDocument, options);
        }
export type GetShipmentByIdQueryHookResult = ReturnType<typeof useGetShipmentByIdQuery>;
export type GetShipmentByIdLazyQueryHookResult = ReturnType<typeof useGetShipmentByIdLazyQuery>;
export type GetShipmentByIdQueryResult = Apollo.QueryResult<GetShipmentByIdQuery, GetShipmentByIdQueryVariables>;
export const GetShipmentTemplatesDocument = gql`
    query GetShipmentTemplates {
  shipmentTemplates {
    id
    name
    createdFrom
    updatedFrom
    createdBy
    lastUpdatedBy
  }
}
    `;

/**
 * __useGetShipmentTemplatesQuery__
 *
 * To run a query within a React component, call `useGetShipmentTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShipmentTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShipmentTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetShipmentTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<GetShipmentTemplatesQuery, GetShipmentTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetShipmentTemplatesQuery, GetShipmentTemplatesQueryVariables>(GetShipmentTemplatesDocument, options);
      }
export function useGetShipmentTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetShipmentTemplatesQuery, GetShipmentTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetShipmentTemplatesQuery, GetShipmentTemplatesQueryVariables>(GetShipmentTemplatesDocument, options);
        }
export type GetShipmentTemplatesQueryHookResult = ReturnType<typeof useGetShipmentTemplatesQuery>;
export type GetShipmentTemplatesLazyQueryHookResult = ReturnType<typeof useGetShipmentTemplatesLazyQuery>;
export type GetShipmentTemplatesQueryResult = Apollo.QueryResult<GetShipmentTemplatesQuery, GetShipmentTemplatesQueryVariables>;
export const GetShipmentsByDateDocument = gql`
    query GetShipmentsByDate($forDate: String!, $fromLocationId: Int) {
  shipmentsByDate(forDate: $forDate, fromLocationId: $fromLocationId) {
    id
    name
    shipmentDate
    shipmentStatus
    fromLocationId
    toLocationId
    createdBy
    lastUpdatedBy
    createdFrom
    updatedFrom
    loadingListItems {
      id
      locationId
      checked
      isDangerous
      packages
      pallets
      palletSpace
      weight
      note
      unNumber
      isLimitedQty
      classification
      isImportedFromShipment
      routeDetail {
        id
        routeName
      }
      customVOECId
      customInvoiceNo
      customMrnNo
      customWaybillNo
      customCmrNo
      customNote
      connectedNB
      customCleared
      customClearedForImport
      isRemainingGoods
      isVoecCustomer
      terminalShipmentNotArrived
      terminalCleared
      terminalNotCleared
      terminalDevPackages
      terminalDevPallets
      terminalDevPalletSpace
      terminalDevWeight
      terminalDevInfo
      files {
        id
        originalname
        mimetype
        path
        size
      }
    }
  }
}
    `;

/**
 * __useGetShipmentsByDateQuery__
 *
 * To run a query within a React component, call `useGetShipmentsByDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShipmentsByDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShipmentsByDateQuery({
 *   variables: {
 *      forDate: // value for 'forDate'
 *      fromLocationId: // value for 'fromLocationId'
 *   },
 * });
 */
export function useGetShipmentsByDateQuery(baseOptions: Apollo.QueryHookOptions<GetShipmentsByDateQuery, GetShipmentsByDateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetShipmentsByDateQuery, GetShipmentsByDateQueryVariables>(GetShipmentsByDateDocument, options);
      }
export function useGetShipmentsByDateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetShipmentsByDateQuery, GetShipmentsByDateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetShipmentsByDateQuery, GetShipmentsByDateQueryVariables>(GetShipmentsByDateDocument, options);
        }
export type GetShipmentsByDateQueryHookResult = ReturnType<typeof useGetShipmentsByDateQuery>;
export type GetShipmentsByDateLazyQueryHookResult = ReturnType<typeof useGetShipmentsByDateLazyQuery>;
export type GetShipmentsByDateQueryResult = Apollo.QueryResult<GetShipmentsByDateQuery, GetShipmentsByDateQueryVariables>;
export const UpdatePartialShipmentDocument = gql`
    mutation UpdatePartialShipment($input: ShipmentPartialUpdateInput!) {
  updatePartialShipment(input: $input)
}
    `;
export type UpdatePartialShipmentMutationFn = Apollo.MutationFunction<UpdatePartialShipmentMutation, UpdatePartialShipmentMutationVariables>;

/**
 * __useUpdatePartialShipmentMutation__
 *
 * To run a mutation, you first call `useUpdatePartialShipmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePartialShipmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePartialShipmentMutation, { data, loading, error }] = useUpdatePartialShipmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePartialShipmentMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePartialShipmentMutation, UpdatePartialShipmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePartialShipmentMutation, UpdatePartialShipmentMutationVariables>(UpdatePartialShipmentDocument, options);
      }
export type UpdatePartialShipmentMutationHookResult = ReturnType<typeof useUpdatePartialShipmentMutation>;
export type UpdatePartialShipmentMutationResult = Apollo.MutationResult<UpdatePartialShipmentMutation>;
export type UpdatePartialShipmentMutationOptions = Apollo.BaseMutationOptions<UpdatePartialShipmentMutation, UpdatePartialShipmentMutationVariables>;
export const EditShipmentDocument = gql`
    mutation EditShipment($input: ShipmentEditInput!, $isNewOrders: Boolean, $orderListUnchanged: Boolean) {
  updateShipment(
    input: $input
    isNewOrders: $isNewOrders
    orderListUnchanged: $orderListUnchanged
  ) {
    id
  }
}
    `;
export type EditShipmentMutationFn = Apollo.MutationFunction<EditShipmentMutation, EditShipmentMutationVariables>;

/**
 * __useEditShipmentMutation__
 *
 * To run a mutation, you first call `useEditShipmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditShipmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editShipmentMutation, { data, loading, error }] = useEditShipmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *      isNewOrders: // value for 'isNewOrders'
 *      orderListUnchanged: // value for 'orderListUnchanged'
 *   },
 * });
 */
export function useEditShipmentMutation(baseOptions?: Apollo.MutationHookOptions<EditShipmentMutation, EditShipmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditShipmentMutation, EditShipmentMutationVariables>(EditShipmentDocument, options);
      }
export type EditShipmentMutationHookResult = ReturnType<typeof useEditShipmentMutation>;
export type EditShipmentMutationResult = Apollo.MutationResult<EditShipmentMutation>;
export type EditShipmentMutationOptions = Apollo.BaseMutationOptions<EditShipmentMutation, EditShipmentMutationVariables>;
export const CreateSporadicRouteTemplateDocument = gql`
    mutation CreateSporadicRouteTemplate($SporadicRouteTemplateInput: SporadicRouteTemplateInput!) {
  createSporadicRouteTemplate(input: $SporadicRouteTemplateInput) {
    id
  }
}
    `;
export type CreateSporadicRouteTemplateMutationFn = Apollo.MutationFunction<CreateSporadicRouteTemplateMutation, CreateSporadicRouteTemplateMutationVariables>;

/**
 * __useCreateSporadicRouteTemplateMutation__
 *
 * To run a mutation, you first call `useCreateSporadicRouteTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSporadicRouteTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSporadicRouteTemplateMutation, { data, loading, error }] = useCreateSporadicRouteTemplateMutation({
 *   variables: {
 *      SporadicRouteTemplateInput: // value for 'SporadicRouteTemplateInput'
 *   },
 * });
 */
export function useCreateSporadicRouteTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreateSporadicRouteTemplateMutation, CreateSporadicRouteTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSporadicRouteTemplateMutation, CreateSporadicRouteTemplateMutationVariables>(CreateSporadicRouteTemplateDocument, options);
      }
export type CreateSporadicRouteTemplateMutationHookResult = ReturnType<typeof useCreateSporadicRouteTemplateMutation>;
export type CreateSporadicRouteTemplateMutationResult = Apollo.MutationResult<CreateSporadicRouteTemplateMutation>;
export type CreateSporadicRouteTemplateMutationOptions = Apollo.BaseMutationOptions<CreateSporadicRouteTemplateMutation, CreateSporadicRouteTemplateMutationVariables>;
export const DeleteSporadicRouteTemplateDocument = gql`
    mutation DeleteSporadicRouteTemplate($id: Int!) {
  deleteSporadicRouteTemplate(id: $id)
}
    `;
export type DeleteSporadicRouteTemplateMutationFn = Apollo.MutationFunction<DeleteSporadicRouteTemplateMutation, DeleteSporadicRouteTemplateMutationVariables>;

/**
 * __useDeleteSporadicRouteTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteSporadicRouteTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSporadicRouteTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSporadicRouteTemplateMutation, { data, loading, error }] = useDeleteSporadicRouteTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSporadicRouteTemplateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSporadicRouteTemplateMutation, DeleteSporadicRouteTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSporadicRouteTemplateMutation, DeleteSporadicRouteTemplateMutationVariables>(DeleteSporadicRouteTemplateDocument, options);
      }
export type DeleteSporadicRouteTemplateMutationHookResult = ReturnType<typeof useDeleteSporadicRouteTemplateMutation>;
export type DeleteSporadicRouteTemplateMutationResult = Apollo.MutationResult<DeleteSporadicRouteTemplateMutation>;
export type DeleteSporadicRouteTemplateMutationOptions = Apollo.BaseMutationOptions<DeleteSporadicRouteTemplateMutation, DeleteSporadicRouteTemplateMutationVariables>;
export const UpdateSporadicRouteTemplateDocument = gql`
    mutation UpdateSporadicRouteTemplate($id: Int!, $SporadicRouteTemplateInput: SporadicRouteTemplateInput!) {
  updateSporadicRouteTemplate(id: $id, input: $SporadicRouteTemplateInput) {
    id
    name
    agreedPrice
    currency
    note
    externalNote
    driverName
    driverPhoneNumber
    licensePlate
    capacity
    routeId
  }
}
    `;
export type UpdateSporadicRouteTemplateMutationFn = Apollo.MutationFunction<UpdateSporadicRouteTemplateMutation, UpdateSporadicRouteTemplateMutationVariables>;

/**
 * __useUpdateSporadicRouteTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateSporadicRouteTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSporadicRouteTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSporadicRouteTemplateMutation, { data, loading, error }] = useUpdateSporadicRouteTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      SporadicRouteTemplateInput: // value for 'SporadicRouteTemplateInput'
 *   },
 * });
 */
export function useUpdateSporadicRouteTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSporadicRouteTemplateMutation, UpdateSporadicRouteTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSporadicRouteTemplateMutation, UpdateSporadicRouteTemplateMutationVariables>(UpdateSporadicRouteTemplateDocument, options);
      }
export type UpdateSporadicRouteTemplateMutationHookResult = ReturnType<typeof useUpdateSporadicRouteTemplateMutation>;
export type UpdateSporadicRouteTemplateMutationResult = Apollo.MutationResult<UpdateSporadicRouteTemplateMutation>;
export type UpdateSporadicRouteTemplateMutationOptions = Apollo.BaseMutationOptions<UpdateSporadicRouteTemplateMutation, UpdateSporadicRouteTemplateMutationVariables>;
export const CreateSubcontractorDocument = gql`
    mutation CreateSubcontractor($name: String!) {
  createSubcontractor(input: {name: $name}) {
    name
    id
  }
}
    `;
export type CreateSubcontractorMutationFn = Apollo.MutationFunction<CreateSubcontractorMutation, CreateSubcontractorMutationVariables>;

/**
 * __useCreateSubcontractorMutation__
 *
 * To run a mutation, you first call `useCreateSubcontractorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubcontractorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubcontractorMutation, { data, loading, error }] = useCreateSubcontractorMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateSubcontractorMutation(baseOptions?: Apollo.MutationHookOptions<CreateSubcontractorMutation, CreateSubcontractorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSubcontractorMutation, CreateSubcontractorMutationVariables>(CreateSubcontractorDocument, options);
      }
export type CreateSubcontractorMutationHookResult = ReturnType<typeof useCreateSubcontractorMutation>;
export type CreateSubcontractorMutationResult = Apollo.MutationResult<CreateSubcontractorMutation>;
export type CreateSubcontractorMutationOptions = Apollo.BaseMutationOptions<CreateSubcontractorMutation, CreateSubcontractorMutationVariables>;
export const DeleteSubcontractorDocument = gql`
    mutation DeleteSubcontractor($id: Int!) {
  deleteSubcontractor(id: $id)
}
    `;
export type DeleteSubcontractorMutationFn = Apollo.MutationFunction<DeleteSubcontractorMutation, DeleteSubcontractorMutationVariables>;

/**
 * __useDeleteSubcontractorMutation__
 *
 * To run a mutation, you first call `useDeleteSubcontractorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSubcontractorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSubcontractorMutation, { data, loading, error }] = useDeleteSubcontractorMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSubcontractorMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSubcontractorMutation, DeleteSubcontractorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSubcontractorMutation, DeleteSubcontractorMutationVariables>(DeleteSubcontractorDocument, options);
      }
export type DeleteSubcontractorMutationHookResult = ReturnType<typeof useDeleteSubcontractorMutation>;
export type DeleteSubcontractorMutationResult = Apollo.MutationResult<DeleteSubcontractorMutation>;
export type DeleteSubcontractorMutationOptions = Apollo.BaseMutationOptions<DeleteSubcontractorMutation, DeleteSubcontractorMutationVariables>;
export const ImportSubcontractorsDocument = gql`
    mutation ImportSubcontractors($input: [ExcelImportDataInput!]!) {
  importSubcontractors(input: $input) {
    id
    name
  }
}
    `;
export type ImportSubcontractorsMutationFn = Apollo.MutationFunction<ImportSubcontractorsMutation, ImportSubcontractorsMutationVariables>;

/**
 * __useImportSubcontractorsMutation__
 *
 * To run a mutation, you first call `useImportSubcontractorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportSubcontractorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importSubcontractorsMutation, { data, loading, error }] = useImportSubcontractorsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportSubcontractorsMutation(baseOptions?: Apollo.MutationHookOptions<ImportSubcontractorsMutation, ImportSubcontractorsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportSubcontractorsMutation, ImportSubcontractorsMutationVariables>(ImportSubcontractorsDocument, options);
      }
export type ImportSubcontractorsMutationHookResult = ReturnType<typeof useImportSubcontractorsMutation>;
export type ImportSubcontractorsMutationResult = Apollo.MutationResult<ImportSubcontractorsMutation>;
export type ImportSubcontractorsMutationOptions = Apollo.BaseMutationOptions<ImportSubcontractorsMutation, ImportSubcontractorsMutationVariables>;
export const UpdateSubcontractorDocument = gql`
    mutation UpdateSubcontractor($name: String!, $id: Int!) {
  updateSubcontractor(input: {name: $name, id: $id}) {
    id
    name
  }
}
    `;
export type UpdateSubcontractorMutationFn = Apollo.MutationFunction<UpdateSubcontractorMutation, UpdateSubcontractorMutationVariables>;

/**
 * __useUpdateSubcontractorMutation__
 *
 * To run a mutation, you first call `useUpdateSubcontractorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubcontractorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubcontractorMutation, { data, loading, error }] = useUpdateSubcontractorMutation({
 *   variables: {
 *      name: // value for 'name'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateSubcontractorMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSubcontractorMutation, UpdateSubcontractorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSubcontractorMutation, UpdateSubcontractorMutationVariables>(UpdateSubcontractorDocument, options);
      }
export type UpdateSubcontractorMutationHookResult = ReturnType<typeof useUpdateSubcontractorMutation>;
export type UpdateSubcontractorMutationResult = Apollo.MutationResult<UpdateSubcontractorMutation>;
export type UpdateSubcontractorMutationOptions = Apollo.BaseMutationOptions<UpdateSubcontractorMutation, UpdateSubcontractorMutationVariables>;
export const DeleteTerminalDocument = gql`
    mutation DeleteTerminal($id: Int!) {
  deleteTerminal(id: $id)
}
    `;
export type DeleteTerminalMutationFn = Apollo.MutationFunction<DeleteTerminalMutation, DeleteTerminalMutationVariables>;

/**
 * __useDeleteTerminalMutation__
 *
 * To run a mutation, you first call `useDeleteTerminalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTerminalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTerminalMutation, { data, loading, error }] = useDeleteTerminalMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTerminalMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTerminalMutation, DeleteTerminalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTerminalMutation, DeleteTerminalMutationVariables>(DeleteTerminalDocument, options);
      }
export type DeleteTerminalMutationHookResult = ReturnType<typeof useDeleteTerminalMutation>;
export type DeleteTerminalMutationResult = Apollo.MutationResult<DeleteTerminalMutation>;
export type DeleteTerminalMutationOptions = Apollo.BaseMutationOptions<DeleteTerminalMutation, DeleteTerminalMutationVariables>;
export const ImportTerminalsDocument = gql`
    mutation ImportTerminals($input: [ExcelImportDataInput!]!) {
  importTerminals(input: $input) {
    id
    name
  }
}
    `;
export type ImportTerminalsMutationFn = Apollo.MutationFunction<ImportTerminalsMutation, ImportTerminalsMutationVariables>;

/**
 * __useImportTerminalsMutation__
 *
 * To run a mutation, you first call `useImportTerminalsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportTerminalsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importTerminalsMutation, { data, loading, error }] = useImportTerminalsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportTerminalsMutation(baseOptions?: Apollo.MutationHookOptions<ImportTerminalsMutation, ImportTerminalsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportTerminalsMutation, ImportTerminalsMutationVariables>(ImportTerminalsDocument, options);
      }
export type ImportTerminalsMutationHookResult = ReturnType<typeof useImportTerminalsMutation>;
export type ImportTerminalsMutationResult = Apollo.MutationResult<ImportTerminalsMutation>;
export type ImportTerminalsMutationOptions = Apollo.BaseMutationOptions<ImportTerminalsMutation, ImportTerminalsMutationVariables>;
export const CreateTerminalDocument = gql`
    mutation CreateTerminal($name: String!) {
  createTerminal(input: {name: $name}) {
    name
    id
  }
}
    `;
export type CreateTerminalMutationFn = Apollo.MutationFunction<CreateTerminalMutation, CreateTerminalMutationVariables>;

/**
 * __useCreateTerminalMutation__
 *
 * To run a mutation, you first call `useCreateTerminalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTerminalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTerminalMutation, { data, loading, error }] = useCreateTerminalMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateTerminalMutation(baseOptions?: Apollo.MutationHookOptions<CreateTerminalMutation, CreateTerminalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTerminalMutation, CreateTerminalMutationVariables>(CreateTerminalDocument, options);
      }
export type CreateTerminalMutationHookResult = ReturnType<typeof useCreateTerminalMutation>;
export type CreateTerminalMutationResult = Apollo.MutationResult<CreateTerminalMutation>;
export type CreateTerminalMutationOptions = Apollo.BaseMutationOptions<CreateTerminalMutation, CreateTerminalMutationVariables>;
export const UpdateTerminalDocument = gql`
    mutation UpdateTerminal($name: String!, $id: Int!) {
  updateTerminal(input: {name: $name, id: $id}) {
    id
    name
  }
}
    `;
export type UpdateTerminalMutationFn = Apollo.MutationFunction<UpdateTerminalMutation, UpdateTerminalMutationVariables>;

/**
 * __useUpdateTerminalMutation__
 *
 * To run a mutation, you first call `useUpdateTerminalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTerminalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTerminalMutation, { data, loading, error }] = useUpdateTerminalMutation({
 *   variables: {
 *      name: // value for 'name'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateTerminalMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTerminalMutation, UpdateTerminalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTerminalMutation, UpdateTerminalMutationVariables>(UpdateTerminalDocument, options);
      }
export type UpdateTerminalMutationHookResult = ReturnType<typeof useUpdateTerminalMutation>;
export type UpdateTerminalMutationResult = Apollo.MutationResult<UpdateTerminalMutation>;
export type UpdateTerminalMutationOptions = Apollo.BaseMutationOptions<UpdateTerminalMutation, UpdateTerminalMutationVariables>;
export const CreateTourTemplateDocument = gql`
    mutation CreateTourTemplate($TourTemplateInput: TourTemplateInput!) {
  createTourTemplate(input: $TourTemplateInput) {
    id
  }
}
    `;
export type CreateTourTemplateMutationFn = Apollo.MutationFunction<CreateTourTemplateMutation, CreateTourTemplateMutationVariables>;

/**
 * __useCreateTourTemplateMutation__
 *
 * To run a mutation, you first call `useCreateTourTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTourTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTourTemplateMutation, { data, loading, error }] = useCreateTourTemplateMutation({
 *   variables: {
 *      TourTemplateInput: // value for 'TourTemplateInput'
 *   },
 * });
 */
export function useCreateTourTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreateTourTemplateMutation, CreateTourTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTourTemplateMutation, CreateTourTemplateMutationVariables>(CreateTourTemplateDocument, options);
      }
export type CreateTourTemplateMutationHookResult = ReturnType<typeof useCreateTourTemplateMutation>;
export type CreateTourTemplateMutationResult = Apollo.MutationResult<CreateTourTemplateMutation>;
export type CreateTourTemplateMutationOptions = Apollo.BaseMutationOptions<CreateTourTemplateMutation, CreateTourTemplateMutationVariables>;
export const DeleteTourTemplateDocument = gql`
    mutation DeleteTourTemplate($id: Int!) {
  deleteTourTemplate(id: $id)
}
    `;
export type DeleteTourTemplateMutationFn = Apollo.MutationFunction<DeleteTourTemplateMutation, DeleteTourTemplateMutationVariables>;

/**
 * __useDeleteTourTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteTourTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTourTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTourTemplateMutation, { data, loading, error }] = useDeleteTourTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTourTemplateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTourTemplateMutation, DeleteTourTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTourTemplateMutation, DeleteTourTemplateMutationVariables>(DeleteTourTemplateDocument, options);
      }
export type DeleteTourTemplateMutationHookResult = ReturnType<typeof useDeleteTourTemplateMutation>;
export type DeleteTourTemplateMutationResult = Apollo.MutationResult<DeleteTourTemplateMutation>;
export type DeleteTourTemplateMutationOptions = Apollo.BaseMutationOptions<DeleteTourTemplateMutation, DeleteTourTemplateMutationVariables>;
export const DispatchRouteDocument = gql`
    mutation DispatchRoute($input: FixedTourGenerateInput!) {
  generateFixedTour(input: $input) {
    status
    createdRouteId
    routeType
    date
  }
}
    `;
export type DispatchRouteMutationFn = Apollo.MutationFunction<DispatchRouteMutation, DispatchRouteMutationVariables>;

/**
 * __useDispatchRouteMutation__
 *
 * To run a mutation, you first call `useDispatchRouteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDispatchRouteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dispatchRouteMutation, { data, loading, error }] = useDispatchRouteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDispatchRouteMutation(baseOptions?: Apollo.MutationHookOptions<DispatchRouteMutation, DispatchRouteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DispatchRouteMutation, DispatchRouteMutationVariables>(DispatchRouteDocument, options);
      }
export type DispatchRouteMutationHookResult = ReturnType<typeof useDispatchRouteMutation>;
export type DispatchRouteMutationResult = Apollo.MutationResult<DispatchRouteMutation>;
export type DispatchRouteMutationOptions = Apollo.BaseMutationOptions<DispatchRouteMutation, DispatchRouteMutationVariables>;
export const UpdateBookmarkDocument = gql`
    mutation UpdateBookmark($id: Int!, $bookmark: Boolean!) {
  updateBookmark(id: $id, bookmark: $bookmark) {
    id
    bookmark
  }
}
    `;
export type UpdateBookmarkMutationFn = Apollo.MutationFunction<UpdateBookmarkMutation, UpdateBookmarkMutationVariables>;

/**
 * __useUpdateBookmarkMutation__
 *
 * To run a mutation, you first call `useUpdateBookmarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBookmarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBookmarkMutation, { data, loading, error }] = useUpdateBookmarkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      bookmark: // value for 'bookmark'
 *   },
 * });
 */
export function useUpdateBookmarkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBookmarkMutation, UpdateBookmarkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBookmarkMutation, UpdateBookmarkMutationVariables>(UpdateBookmarkDocument, options);
      }
export type UpdateBookmarkMutationHookResult = ReturnType<typeof useUpdateBookmarkMutation>;
export type UpdateBookmarkMutationResult = Apollo.MutationResult<UpdateBookmarkMutation>;
export type UpdateBookmarkMutationOptions = Apollo.BaseMutationOptions<UpdateBookmarkMutation, UpdateBookmarkMutationVariables>;
export const UpdateTourTemplateDocument = gql`
    mutation UpdateTourTemplate($id: Int!, $input: TourTemplateInput!) {
  updateTourTemplate(id: $id, input: $input) {
    id
    name
    subcontractorId
    dispatcherId
    capacity
    fuel
    price
    currency
    startDate
    endDate
    originalStartDate
    startExceptionDate
    endExceptionDate
    exceptionDates
    note
    driverName
    driverPhoneNumber
    licensePlate
    createdBy
    lastUpdatedBy
    createdAt
    updatedAt
    responsiblePerson
    groupingTag
    isPriceEquallyDivided
  }
}
    `;
export type UpdateTourTemplateMutationFn = Apollo.MutationFunction<UpdateTourTemplateMutation, UpdateTourTemplateMutationVariables>;

/**
 * __useUpdateTourTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateTourTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTourTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTourTemplateMutation, { data, loading, error }] = useUpdateTourTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTourTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTourTemplateMutation, UpdateTourTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTourTemplateMutation, UpdateTourTemplateMutationVariables>(UpdateTourTemplateDocument, options);
      }
export type UpdateTourTemplateMutationHookResult = ReturnType<typeof useUpdateTourTemplateMutation>;
export type UpdateTourTemplateMutationResult = Apollo.MutationResult<UpdateTourTemplateMutation>;
export type UpdateTourTemplateMutationOptions = Apollo.BaseMutationOptions<UpdateTourTemplateMutation, UpdateTourTemplateMutationVariables>;
export const ConnectTerminalDocument = gql`
    mutation ConnectTerminal($terminalId: Int!) {
  connectTerminal(terminalId: $terminalId) {
    roles
    lastName
    fullName
    firstName
    department
    createdAt
    id
    internalTerminalId
    internalTerminal {
      name
      id
    }
  }
}
    `;
export type ConnectTerminalMutationFn = Apollo.MutationFunction<ConnectTerminalMutation, ConnectTerminalMutationVariables>;

/**
 * __useConnectTerminalMutation__
 *
 * To run a mutation, you first call `useConnectTerminalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectTerminalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectTerminalMutation, { data, loading, error }] = useConnectTerminalMutation({
 *   variables: {
 *      terminalId: // value for 'terminalId'
 *   },
 * });
 */
export function useConnectTerminalMutation(baseOptions?: Apollo.MutationHookOptions<ConnectTerminalMutation, ConnectTerminalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConnectTerminalMutation, ConnectTerminalMutationVariables>(ConnectTerminalDocument, options);
      }
export type ConnectTerminalMutationHookResult = ReturnType<typeof useConnectTerminalMutation>;
export type ConnectTerminalMutationResult = Apollo.MutationResult<ConnectTerminalMutation>;
export type ConnectTerminalMutationOptions = Apollo.BaseMutationOptions<ConnectTerminalMutation, ConnectTerminalMutationVariables>;
export const GetStatusDocument = gql`
    query GetStatus {
  status
}
    `;

/**
 * __useGetStatusQuery__
 *
 * To run a query within a React component, call `useGetStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStatusQuery(baseOptions?: Apollo.QueryHookOptions<GetStatusQuery, GetStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStatusQuery, GetStatusQueryVariables>(GetStatusDocument, options);
      }
export function useGetStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStatusQuery, GetStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStatusQuery, GetStatusQueryVariables>(GetStatusDocument, options);
        }
export type GetStatusQueryHookResult = ReturnType<typeof useGetStatusQuery>;
export type GetStatusLazyQueryHookResult = ReturnType<typeof useGetStatusLazyQuery>;
export type GetStatusQueryResult = Apollo.QueryResult<GetStatusQuery, GetStatusQueryVariables>;
export const GetCardTypeDocument = gql`
    query GetCardType($id: Int!) {
  card(id: $id) {
    id
    type
  }
}
    `;

/**
 * __useGetCardTypeQuery__
 *
 * To run a query within a React component, call `useGetCardTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCardTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCardTypeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCardTypeQuery(baseOptions: Apollo.QueryHookOptions<GetCardTypeQuery, GetCardTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCardTypeQuery, GetCardTypeQueryVariables>(GetCardTypeDocument, options);
      }
export function useGetCardTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCardTypeQuery, GetCardTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCardTypeQuery, GetCardTypeQueryVariables>(GetCardTypeDocument, options);
        }
export type GetCardTypeQueryHookResult = ReturnType<typeof useGetCardTypeQuery>;
export type GetCardTypeLazyQueryHookResult = ReturnType<typeof useGetCardTypeLazyQuery>;
export type GetCardTypeQueryResult = Apollo.QueryResult<GetCardTypeQuery, GetCardTypeQueryVariables>;
export const GetAllCardsDocument = gql`
    query getAllCards($search: String, $withTerminal: Boolean) {
  allCards(search: $search, withTerminal: $withTerminal) {
    id
    name
    type
    locations {
      ...LocationsFields
    }
  }
}
    ${LocationsFieldsFragmentDoc}`;

/**
 * __useGetAllCardsQuery__
 *
 * To run a query within a React component, call `useGetAllCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCardsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      withTerminal: // value for 'withTerminal'
 *   },
 * });
 */
export function useGetAllCardsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllCardsQuery, GetAllCardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllCardsQuery, GetAllCardsQueryVariables>(GetAllCardsDocument, options);
      }
export function useGetAllCardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllCardsQuery, GetAllCardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllCardsQuery, GetAllCardsQueryVariables>(GetAllCardsDocument, options);
        }
export type GetAllCardsQueryHookResult = ReturnType<typeof useGetAllCardsQuery>;
export type GetAllCardsLazyQueryHookResult = ReturnType<typeof useGetAllCardsLazyQuery>;
export type GetAllCardsQueryResult = Apollo.QueryResult<GetAllCardsQuery, GetAllCardsQueryVariables>;
export const GetCardDocument = gql`
    query GetCard($id: Int!) {
  card(id: $id) {
    id
    name
    type
    locations {
      ...LocationsFields
    }
    contacts {
      ...CardContactFields
    }
    customerAccounts {
      ...CustomerAccountFields
    }
    files {
      id
      originalname
      mimetype
      path
      size
      createdAt
      createdBy
    }
    notes {
      id
      title
      text
      createdBy
      lastUpdatedBy
      updatedAt
    }
    createdAt
    updatedAt
  }
}
    ${LocationsFieldsFragmentDoc}
${CardContactFieldsFragmentDoc}
${CustomerAccountFieldsFragmentDoc}`;

/**
 * __useGetCardQuery__
 *
 * To run a query within a React component, call `useGetCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCardQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCardQuery(baseOptions: Apollo.QueryHookOptions<GetCardQuery, GetCardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCardQuery, GetCardQueryVariables>(GetCardDocument, options);
      }
export function useGetCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCardQuery, GetCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCardQuery, GetCardQueryVariables>(GetCardDocument, options);
        }
export type GetCardQueryHookResult = ReturnType<typeof useGetCardQuery>;
export type GetCardLazyQueryHookResult = ReturnType<typeof useGetCardLazyQuery>;
export type GetCardQueryResult = Apollo.QueryResult<GetCardQuery, GetCardQueryVariables>;
export const GetCardsDocument = gql`
    query GetCards {
  cards {
    id
    name
    type
    locations {
      ...LocationsFields
    }
    contacts {
      ...CardContactFields
    }
    customerAccounts {
      ...CustomerAccountFields
    }
    createdAt
    updatedAt
  }
}
    ${LocationsFieldsFragmentDoc}
${CardContactFieldsFragmentDoc}
${CustomerAccountFieldsFragmentDoc}`;

/**
 * __useGetCardsQuery__
 *
 * To run a query within a React component, call `useGetCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCardsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCardsQuery(baseOptions?: Apollo.QueryHookOptions<GetCardsQuery, GetCardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCardsQuery, GetCardsQueryVariables>(GetCardsDocument, options);
      }
export function useGetCardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCardsQuery, GetCardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCardsQuery, GetCardsQueryVariables>(GetCardsDocument, options);
        }
export type GetCardsQueryHookResult = ReturnType<typeof useGetCardsQuery>;
export type GetCardsLazyQueryHookResult = ReturnType<typeof useGetCardsLazyQuery>;
export type GetCardsQueryResult = Apollo.QueryResult<GetCardsQuery, GetCardsQueryVariables>;
export const GetCheckpointDocument = gql`
    query GetCheckpoint($id: Int!) {
  checkpoint(id: $id) {
    id
    name
    createdBy
    updatedAt
    lastUpdatedBy
    files {
      originalname
      path
      id
      createdAt
      mimetype
      size
      createdBy
    }
    contacts {
      ...CardContactFields
    }
  }
}
    ${CardContactFieldsFragmentDoc}`;

/**
 * __useGetCheckpointQuery__
 *
 * To run a query within a React component, call `useGetCheckpointQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCheckpointQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCheckpointQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCheckpointQuery(baseOptions: Apollo.QueryHookOptions<GetCheckpointQuery, GetCheckpointQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCheckpointQuery, GetCheckpointQueryVariables>(GetCheckpointDocument, options);
      }
export function useGetCheckpointLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCheckpointQuery, GetCheckpointQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCheckpointQuery, GetCheckpointQueryVariables>(GetCheckpointDocument, options);
        }
export type GetCheckpointQueryHookResult = ReturnType<typeof useGetCheckpointQuery>;
export type GetCheckpointLazyQueryHookResult = ReturnType<typeof useGetCheckpointLazyQuery>;
export type GetCheckpointQueryResult = Apollo.QueryResult<GetCheckpointQuery, GetCheckpointQueryVariables>;
export const GetCheckpointsDocument = gql`
    query GetCheckpoints($search: String) {
  checkpoints(search: $search) {
    id
    name
    locations {
      ...LocationsFields
    }
  }
}
    ${LocationsFieldsFragmentDoc}`;

/**
 * __useGetCheckpointsQuery__
 *
 * To run a query within a React component, call `useGetCheckpointsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCheckpointsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCheckpointsQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetCheckpointsQuery(baseOptions?: Apollo.QueryHookOptions<GetCheckpointsQuery, GetCheckpointsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCheckpointsQuery, GetCheckpointsQueryVariables>(GetCheckpointsDocument, options);
      }
export function useGetCheckpointsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCheckpointsQuery, GetCheckpointsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCheckpointsQuery, GetCheckpointsQueryVariables>(GetCheckpointsDocument, options);
        }
export type GetCheckpointsQueryHookResult = ReturnType<typeof useGetCheckpointsQuery>;
export type GetCheckpointsLazyQueryHookResult = ReturnType<typeof useGetCheckpointsLazyQuery>;
export type GetCheckpointsQueryResult = Apollo.QueryResult<GetCheckpointsQuery, GetCheckpointsQueryVariables>;
export const GetContactDocument = gql`
    query GetContact($id: Int!) {
  cardContact(id: $id) {
    id
    name
    role
    email
    phoneNumber
    tags {
      id
      title
    }
    note
    lastUpdatedBy
    createdBy
    updatedAt
  }
}
    `;

/**
 * __useGetContactQuery__
 *
 * To run a query within a React component, call `useGetContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetContactQuery(baseOptions: Apollo.QueryHookOptions<GetContactQuery, GetContactQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContactQuery, GetContactQueryVariables>(GetContactDocument, options);
      }
export function useGetContactLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContactQuery, GetContactQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContactQuery, GetContactQueryVariables>(GetContactDocument, options);
        }
export type GetContactQueryHookResult = ReturnType<typeof useGetContactQuery>;
export type GetContactLazyQueryHookResult = ReturnType<typeof useGetContactLazyQuery>;
export type GetContactQueryResult = Apollo.QueryResult<GetContactQuery, GetContactQueryVariables>;
export const GetContactsForCardDocument = gql`
    query GetContactsForCard($cardId: Int, $contactId: Int) {
  cardContacts(cardId: $cardId, contactId: $contactId) {
    id
    name
    tags {
      title
    }
    phoneNumber
    email
    note
  }
}
    `;

/**
 * __useGetContactsForCardQuery__
 *
 * To run a query within a React component, call `useGetContactsForCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactsForCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactsForCardQuery({
 *   variables: {
 *      cardId: // value for 'cardId'
 *      contactId: // value for 'contactId'
 *   },
 * });
 */
export function useGetContactsForCardQuery(baseOptions?: Apollo.QueryHookOptions<GetContactsForCardQuery, GetContactsForCardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContactsForCardQuery, GetContactsForCardQueryVariables>(GetContactsForCardDocument, options);
      }
export function useGetContactsForCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContactsForCardQuery, GetContactsForCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContactsForCardQuery, GetContactsForCardQueryVariables>(GetContactsForCardDocument, options);
        }
export type GetContactsForCardQueryHookResult = ReturnType<typeof useGetContactsForCardQuery>;
export type GetContactsForCardLazyQueryHookResult = ReturnType<typeof useGetContactsForCardLazyQuery>;
export type GetContactsForCardQueryResult = Apollo.QueryResult<GetContactsForCardQuery, GetContactsForCardQueryVariables>;
export const GetCustomerDocument = gql`
    query GetCustomer($id: Int!) {
  customer(id: $id) {
    id
    name
    createdBy
    updatedAt
    lastUpdatedBy
    files {
      originalname
      path
      id
      createdAt
      mimetype
      size
      createdBy
    }
    contacts {
      ...CardContactFields
    }
  }
}
    ${CardContactFieldsFragmentDoc}`;

/**
 * __useGetCustomerQuery__
 *
 * To run a query within a React component, call `useGetCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCustomerQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerQuery, GetCustomerQueryVariables>(GetCustomerDocument, options);
      }
export function useGetCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerQuery, GetCustomerQueryVariables>(GetCustomerDocument, options);
        }
export type GetCustomerQueryHookResult = ReturnType<typeof useGetCustomerQuery>;
export type GetCustomerLazyQueryHookResult = ReturnType<typeof useGetCustomerLazyQuery>;
export type GetCustomerQueryResult = Apollo.QueryResult<GetCustomerQuery, GetCustomerQueryVariables>;
export const GetCustomersDocument = gql`
    query GetCustomers($search: String) {
  customers(search: $search) {
    id
    name
    locations {
      ...LocationsFields
    }
  }
}
    ${LocationsFieldsFragmentDoc}`;

/**
 * __useGetCustomersQuery__
 *
 * To run a query within a React component, call `useGetCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomersQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetCustomersQuery(baseOptions?: Apollo.QueryHookOptions<GetCustomersQuery, GetCustomersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomersQuery, GetCustomersQueryVariables>(GetCustomersDocument, options);
      }
export function useGetCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomersQuery, GetCustomersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomersQuery, GetCustomersQueryVariables>(GetCustomersDocument, options);
        }
export type GetCustomersQueryHookResult = ReturnType<typeof useGetCustomersQuery>;
export type GetCustomersLazyQueryHookResult = ReturnType<typeof useGetCustomersLazyQuery>;
export type GetCustomersQueryResult = Apollo.QueryResult<GetCustomersQuery, GetCustomersQueryVariables>;
export const SearchCustomersDocument = gql`
    query SearchCustomers($customersSearch: String) {
  customers(search: $customersSearch) {
    id
    name
  }
}
    `;

/**
 * __useSearchCustomersQuery__
 *
 * To run a query within a React component, call `useSearchCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCustomersQuery({
 *   variables: {
 *      customersSearch: // value for 'customersSearch'
 *   },
 * });
 */
export function useSearchCustomersQuery(baseOptions?: Apollo.QueryHookOptions<SearchCustomersQuery, SearchCustomersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchCustomersQuery, SearchCustomersQueryVariables>(SearchCustomersDocument, options);
      }
export function useSearchCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchCustomersQuery, SearchCustomersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchCustomersQuery, SearchCustomersQueryVariables>(SearchCustomersDocument, options);
        }
export type SearchCustomersQueryHookResult = ReturnType<typeof useSearchCustomersQuery>;
export type SearchCustomersLazyQueryHookResult = ReturnType<typeof useSearchCustomersLazyQuery>;
export type SearchCustomersQueryResult = Apollo.QueryResult<SearchCustomersQuery, SearchCustomersQueryVariables>;
export const GetCustomerAccountDocument = gql`
    query GetCustomerAccount($id: Int!) {
  customerAccount(id: $id) {
    ...CustomerAccountFields
  }
}
    ${CustomerAccountFieldsFragmentDoc}`;

/**
 * __useGetCustomerAccountQuery__
 *
 * To run a query within a React component, call `useGetCustomerAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerAccountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCustomerAccountQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerAccountQuery, GetCustomerAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerAccountQuery, GetCustomerAccountQueryVariables>(GetCustomerAccountDocument, options);
      }
export function useGetCustomerAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerAccountQuery, GetCustomerAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerAccountQuery, GetCustomerAccountQueryVariables>(GetCustomerAccountDocument, options);
        }
export type GetCustomerAccountQueryHookResult = ReturnType<typeof useGetCustomerAccountQuery>;
export type GetCustomerAccountLazyQueryHookResult = ReturnType<typeof useGetCustomerAccountLazyQuery>;
export type GetCustomerAccountQueryResult = Apollo.QueryResult<GetCustomerAccountQuery, GetCustomerAccountQueryVariables>;
export const GetCustomerAccountsForCardDocument = gql`
    query GetCustomerAccountsForCard($cardId: Int!) {
  customerAccounts(cardId: $cardId) {
    ...CustomerAccountFields
  }
}
    ${CustomerAccountFieldsFragmentDoc}`;

/**
 * __useGetCustomerAccountsForCardQuery__
 *
 * To run a query within a React component, call `useGetCustomerAccountsForCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerAccountsForCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerAccountsForCardQuery({
 *   variables: {
 *      cardId: // value for 'cardId'
 *   },
 * });
 */
export function useGetCustomerAccountsForCardQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerAccountsForCardQuery, GetCustomerAccountsForCardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerAccountsForCardQuery, GetCustomerAccountsForCardQueryVariables>(GetCustomerAccountsForCardDocument, options);
      }
export function useGetCustomerAccountsForCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerAccountsForCardQuery, GetCustomerAccountsForCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerAccountsForCardQuery, GetCustomerAccountsForCardQueryVariables>(GetCustomerAccountsForCardDocument, options);
        }
export type GetCustomerAccountsForCardQueryHookResult = ReturnType<typeof useGetCustomerAccountsForCardQuery>;
export type GetCustomerAccountsForCardLazyQueryHookResult = ReturnType<typeof useGetCustomerAccountsForCardLazyQuery>;
export type GetCustomerAccountsForCardQueryResult = Apollo.QueryResult<GetCustomerAccountsForCardQuery, GetCustomerAccountsForCardQueryVariables>;
export const GetDepartmentsDocument = gql`
    query GetDepartments {
  departments {
    id
    name
  }
}
    `;

/**
 * __useGetDepartmentsQuery__
 *
 * To run a query within a React component, call `useGetDepartmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDepartmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDepartmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDepartmentsQuery(baseOptions?: Apollo.QueryHookOptions<GetDepartmentsQuery, GetDepartmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDepartmentsQuery, GetDepartmentsQueryVariables>(GetDepartmentsDocument, options);
      }
export function useGetDepartmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDepartmentsQuery, GetDepartmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDepartmentsQuery, GetDepartmentsQueryVariables>(GetDepartmentsDocument, options);
        }
export type GetDepartmentsQueryHookResult = ReturnType<typeof useGetDepartmentsQuery>;
export type GetDepartmentsLazyQueryHookResult = ReturnType<typeof useGetDepartmentsLazyQuery>;
export type GetDepartmentsQueryResult = Apollo.QueryResult<GetDepartmentsQuery, GetDepartmentsQueryVariables>;
export const GetDeviationForEmailDocument = gql`
    query GetDeviationForEmail($id: Int!) {
  deviation(id: $id) {
    id
    localMessageCustomers
    englishMessageCustomers
    customers {
      id
      name
      contacts {
        id
        name
        email
      }
    }
    deviationToCustomer {
      customerId
      deviationId
      lastEmailSent
    }
    deviationToLocation {
      location {
        card {
          id
        }
      }
      deviationId
      lastEmailSent
    }
  }
}
    `;

/**
 * __useGetDeviationForEmailQuery__
 *
 * To run a query within a React component, call `useGetDeviationForEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeviationForEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeviationForEmailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDeviationForEmailQuery(baseOptions: Apollo.QueryHookOptions<GetDeviationForEmailQuery, GetDeviationForEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDeviationForEmailQuery, GetDeviationForEmailQueryVariables>(GetDeviationForEmailDocument, options);
      }
export function useGetDeviationForEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeviationForEmailQuery, GetDeviationForEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDeviationForEmailQuery, GetDeviationForEmailQueryVariables>(GetDeviationForEmailDocument, options);
        }
export type GetDeviationForEmailQueryHookResult = ReturnType<typeof useGetDeviationForEmailQuery>;
export type GetDeviationForEmailLazyQueryHookResult = ReturnType<typeof useGetDeviationForEmailLazyQuery>;
export type GetDeviationForEmailQueryResult = Apollo.QueryResult<GetDeviationForEmailQuery, GetDeviationForEmailQueryVariables>;
export const GetDeviationToLocationForDeviationIdDocument = gql`
    query GetDeviationToLocationForDeviationId($deviationId: Int!) {
  deviationToLocationForDeviationId(deviationId: $deviationId) {
    id
    deviationId
    locationId
    lastEmailSent
    pallets
    locationItemNote
    packages
    palletSpace
    weight
    reason
    otherReason
    newCustomer
    isDangerous
  }
}
    `;

/**
 * __useGetDeviationToLocationForDeviationIdQuery__
 *
 * To run a query within a React component, call `useGetDeviationToLocationForDeviationIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeviationToLocationForDeviationIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeviationToLocationForDeviationIdQuery({
 *   variables: {
 *      deviationId: // value for 'deviationId'
 *   },
 * });
 */
export function useGetDeviationToLocationForDeviationIdQuery(baseOptions: Apollo.QueryHookOptions<GetDeviationToLocationForDeviationIdQuery, GetDeviationToLocationForDeviationIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDeviationToLocationForDeviationIdQuery, GetDeviationToLocationForDeviationIdQueryVariables>(GetDeviationToLocationForDeviationIdDocument, options);
      }
export function useGetDeviationToLocationForDeviationIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeviationToLocationForDeviationIdQuery, GetDeviationToLocationForDeviationIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDeviationToLocationForDeviationIdQuery, GetDeviationToLocationForDeviationIdQueryVariables>(GetDeviationToLocationForDeviationIdDocument, options);
        }
export type GetDeviationToLocationForDeviationIdQueryHookResult = ReturnType<typeof useGetDeviationToLocationForDeviationIdQuery>;
export type GetDeviationToLocationForDeviationIdLazyQueryHookResult = ReturnType<typeof useGetDeviationToLocationForDeviationIdLazyQuery>;
export type GetDeviationToLocationForDeviationIdQueryResult = Apollo.QueryResult<GetDeviationToLocationForDeviationIdQuery, GetDeviationToLocationForDeviationIdQueryVariables>;
export const GetDeviationsDocument = gql`
    query GetDeviations($startDate: String!, $endDate: String!, $searchTerm: String) {
  deviations(startDate: $startDate, endDate: $endDate, searchTerm: $searchTerm) {
    id
    cause
    fault
    routeId
    route {
      id
      routeId
      type
      licensePlate
      capacity
      transportationDate
      subcontractor {
        id
        name
      }
      legs {
        arrivalTime
        position
        location {
          city
          country
        }
      }
    }
    faultLocation {
      id
      name
      address
      postalCode
      city
      country
      card {
        name
      }
    }
    note
    consequence
    actualArrivalTime
    createdBy
  }
}
    `;

/**
 * __useGetDeviationsQuery__
 *
 * To run a query within a React component, call `useGetDeviationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeviationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeviationsQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useGetDeviationsQuery(baseOptions: Apollo.QueryHookOptions<GetDeviationsQuery, GetDeviationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDeviationsQuery, GetDeviationsQueryVariables>(GetDeviationsDocument, options);
      }
export function useGetDeviationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeviationsQuery, GetDeviationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDeviationsQuery, GetDeviationsQueryVariables>(GetDeviationsDocument, options);
        }
export type GetDeviationsQueryHookResult = ReturnType<typeof useGetDeviationsQuery>;
export type GetDeviationsLazyQueryHookResult = ReturnType<typeof useGetDeviationsLazyQuery>;
export type GetDeviationsQueryResult = Apollo.QueryResult<GetDeviationsQuery, GetDeviationsQueryVariables>;
export const GetDeviationsForRouteDocument = gql`
    query GetDeviationsForRoute($routeId: Int!) {
  deviationsForRoute(routeId: $routeId) {
    id
    cause
    fault
    routeId
    emailSent
    faultLocation {
      id
      name
      address
      postalCode
      city
      country
      card {
        name
      }
    }
    route {
      legs {
        arrivalTime
      }
    }
    note
    consequence
    actualArrivalTime
    createdBy
  }
}
    `;

/**
 * __useGetDeviationsForRouteQuery__
 *
 * To run a query within a React component, call `useGetDeviationsForRouteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeviationsForRouteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeviationsForRouteQuery({
 *   variables: {
 *      routeId: // value for 'routeId'
 *   },
 * });
 */
export function useGetDeviationsForRouteQuery(baseOptions: Apollo.QueryHookOptions<GetDeviationsForRouteQuery, GetDeviationsForRouteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDeviationsForRouteQuery, GetDeviationsForRouteQueryVariables>(GetDeviationsForRouteDocument, options);
      }
export function useGetDeviationsForRouteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeviationsForRouteQuery, GetDeviationsForRouteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDeviationsForRouteQuery, GetDeviationsForRouteQueryVariables>(GetDeviationsForRouteDocument, options);
        }
export type GetDeviationsForRouteQueryHookResult = ReturnType<typeof useGetDeviationsForRouteQuery>;
export type GetDeviationsForRouteLazyQueryHookResult = ReturnType<typeof useGetDeviationsForRouteLazyQuery>;
export type GetDeviationsForRouteQueryResult = Apollo.QueryResult<GetDeviationsForRouteQuery, GetDeviationsForRouteQueryVariables>;
export const GetLocationDeviationsDocument = gql`
    query GetLocationDeviations($startDate: String!, $endDate: String!, $searchTerm: String, $terminalIds: [Int!]) {
  getLocationDeviations(
    startDate: $startDate
    endDate: $endDate
    searchTerm: $searchTerm
    terminalIds: $terminalIds
  ) {
    id
    deviationId
    routeId
    faultLocationId
    toLocationId
    deviationApplicableDate
    routeNameOrFaultLocation
    customerId
    customerName
    pallets
    packages
    palletSpace
    weight
    reason
    otherReason
    newCustomer
    locationItemNote
    cause
    fault
    faultLocation
    toLocation
    fromToToCountry
    toCity
    customerLocation
    type
    emailSent
    emailSentTimestamp
    consequence
    actualArrivalTime
    createdBy
    lastUpdatedBy
    isDangerous
  }
}
    `;

/**
 * __useGetLocationDeviationsQuery__
 *
 * To run a query within a React component, call `useGetLocationDeviationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationDeviationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationDeviationsQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      searchTerm: // value for 'searchTerm'
 *      terminalIds: // value for 'terminalIds'
 *   },
 * });
 */
export function useGetLocationDeviationsQuery(baseOptions: Apollo.QueryHookOptions<GetLocationDeviationsQuery, GetLocationDeviationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLocationDeviationsQuery, GetLocationDeviationsQueryVariables>(GetLocationDeviationsDocument, options);
      }
export function useGetLocationDeviationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLocationDeviationsQuery, GetLocationDeviationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLocationDeviationsQuery, GetLocationDeviationsQueryVariables>(GetLocationDeviationsDocument, options);
        }
export type GetLocationDeviationsQueryHookResult = ReturnType<typeof useGetLocationDeviationsQuery>;
export type GetLocationDeviationsLazyQueryHookResult = ReturnType<typeof useGetLocationDeviationsLazyQuery>;
export type GetLocationDeviationsQueryResult = Apollo.QueryResult<GetLocationDeviationsQuery, GetLocationDeviationsQueryVariables>;
export const GetFilesDocument = gql`
    query GetFiles {
  files {
    id
    originalname
    mimetype
    path
    size
    createdAt
    createdBy
    tags {
      id
      title
    }
  }
}
    `;

/**
 * __useGetFilesQuery__
 *
 * To run a query within a React component, call `useGetFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFilesQuery(baseOptions?: Apollo.QueryHookOptions<GetFilesQuery, GetFilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFilesQuery, GetFilesQueryVariables>(GetFilesDocument, options);
      }
export function useGetFilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFilesQuery, GetFilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFilesQuery, GetFilesQueryVariables>(GetFilesDocument, options);
        }
export type GetFilesQueryHookResult = ReturnType<typeof useGetFilesQuery>;
export type GetFilesLazyQueryHookResult = ReturnType<typeof useGetFilesLazyQuery>;
export type GetFilesQueryResult = Apollo.QueryResult<GetFilesQuery, GetFilesQueryVariables>;
export const GetDocumentFileTagsDocument = gql`
    query GetDocumentFileTags {
  documentFileTags {
    id
    title
  }
}
    `;

/**
 * __useGetDocumentFileTagsQuery__
 *
 * To run a query within a React component, call `useGetDocumentFileTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentFileTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentFileTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDocumentFileTagsQuery(baseOptions?: Apollo.QueryHookOptions<GetDocumentFileTagsQuery, GetDocumentFileTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocumentFileTagsQuery, GetDocumentFileTagsQueryVariables>(GetDocumentFileTagsDocument, options);
      }
export function useGetDocumentFileTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocumentFileTagsQuery, GetDocumentFileTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocumentFileTagsQuery, GetDocumentFileTagsQueryVariables>(GetDocumentFileTagsDocument, options);
        }
export type GetDocumentFileTagsQueryHookResult = ReturnType<typeof useGetDocumentFileTagsQuery>;
export type GetDocumentFileTagsLazyQueryHookResult = ReturnType<typeof useGetDocumentFileTagsLazyQuery>;
export type GetDocumentFileTagsQueryResult = Apollo.QueryResult<GetDocumentFileTagsQuery, GetDocumentFileTagsQueryVariables>;
export const GetCardFilesDocument = gql`
    query GetCardFiles($cardId: Int!) {
  card(id: $cardId) {
    files {
      createdBy
      createdAt
      mimetype
      id
      originalname
      path
      size
      updatedAt
    }
  }
}
    `;

/**
 * __useGetCardFilesQuery__
 *
 * To run a query within a React component, call `useGetCardFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCardFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCardFilesQuery({
 *   variables: {
 *      cardId: // value for 'cardId'
 *   },
 * });
 */
export function useGetCardFilesQuery(baseOptions: Apollo.QueryHookOptions<GetCardFilesQuery, GetCardFilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCardFilesQuery, GetCardFilesQueryVariables>(GetCardFilesDocument, options);
      }
export function useGetCardFilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCardFilesQuery, GetCardFilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCardFilesQuery, GetCardFilesQueryVariables>(GetCardFilesDocument, options);
        }
export type GetCardFilesQueryHookResult = ReturnType<typeof useGetCardFilesQuery>;
export type GetCardFilesLazyQueryHookResult = ReturnType<typeof useGetCardFilesLazyQuery>;
export type GetCardFilesQueryResult = Apollo.QueryResult<GetCardFilesQuery, GetCardFilesQueryVariables>;
export const GetFixedTrafficLegsDocument = gql`
    query GetFixedTrafficLegs($terminalId: Int!) {
  fixedTrafficLegs(terminalId: $terminalId) {
    id
    routeId
    tourId
    capacity
    days
    arrivalTime
    departureTime
    departureCity
    arrivalCity
    subcontractor {
      id
      name
    }
    load
    unload
    note
    startDate
    endDate
    startExceptionDate
    endExceptionDate
  }
}
    `;

/**
 * __useGetFixedTrafficLegsQuery__
 *
 * To run a query within a React component, call `useGetFixedTrafficLegsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFixedTrafficLegsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFixedTrafficLegsQuery({
 *   variables: {
 *      terminalId: // value for 'terminalId'
 *   },
 * });
 */
export function useGetFixedTrafficLegsQuery(baseOptions: Apollo.QueryHookOptions<GetFixedTrafficLegsQuery, GetFixedTrafficLegsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFixedTrafficLegsQuery, GetFixedTrafficLegsQueryVariables>(GetFixedTrafficLegsDocument, options);
      }
export function useGetFixedTrafficLegsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFixedTrafficLegsQuery, GetFixedTrafficLegsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFixedTrafficLegsQuery, GetFixedTrafficLegsQueryVariables>(GetFixedTrafficLegsDocument, options);
        }
export type GetFixedTrafficLegsQueryHookResult = ReturnType<typeof useGetFixedTrafficLegsQuery>;
export type GetFixedTrafficLegsLazyQueryHookResult = ReturnType<typeof useGetFixedTrafficLegsLazyQuery>;
export type GetFixedTrafficLegsQueryResult = Apollo.QueryResult<GetFixedTrafficLegsQuery, GetFixedTrafficLegsQueryVariables>;
export const GetHolidaysDocument = gql`
    query GetHolidays($country: String!) {
  holidays(country: $country) {
    id
    title
    date
    country
  }
}
    `;

/**
 * __useGetHolidaysQuery__
 *
 * To run a query within a React component, call `useGetHolidaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHolidaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHolidaysQuery({
 *   variables: {
 *      country: // value for 'country'
 *   },
 * });
 */
export function useGetHolidaysQuery(baseOptions: Apollo.QueryHookOptions<GetHolidaysQuery, GetHolidaysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHolidaysQuery, GetHolidaysQueryVariables>(GetHolidaysDocument, options);
      }
export function useGetHolidaysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHolidaysQuery, GetHolidaysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHolidaysQuery, GetHolidaysQueryVariables>(GetHolidaysDocument, options);
        }
export type GetHolidaysQueryHookResult = ReturnType<typeof useGetHolidaysQuery>;
export type GetHolidaysLazyQueryHookResult = ReturnType<typeof useGetHolidaysLazyQuery>;
export type GetHolidaysQueryResult = Apollo.QueryResult<GetHolidaysQuery, GetHolidaysQueryVariables>;
export const GetGridValuesForDateDocument = gql`
    query GetGridValuesForDate($internalTerminalId: Int!, $valueAddedDate: String!, $gridType: String!) {
  getGridValues(
    internalTerminalId: $internalTerminalId
    valueAddedDate: $valueAddedDate
    gridType: $gridType
  ) {
    id
    gridEntryId
    gridEntries
    valueAddedDate
    columnHeaders
    rowHeaders
    gridName
    internalTerminalId
    isGridDeleted
    gridType
    isNumberGridWithTotal
  }
}
    `;

/**
 * __useGetGridValuesForDateQuery__
 *
 * To run a query within a React component, call `useGetGridValuesForDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGridValuesForDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGridValuesForDateQuery({
 *   variables: {
 *      internalTerminalId: // value for 'internalTerminalId'
 *      valueAddedDate: // value for 'valueAddedDate'
 *      gridType: // value for 'gridType'
 *   },
 * });
 */
export function useGetGridValuesForDateQuery(baseOptions: Apollo.QueryHookOptions<GetGridValuesForDateQuery, GetGridValuesForDateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGridValuesForDateQuery, GetGridValuesForDateQueryVariables>(GetGridValuesForDateDocument, options);
      }
export function useGetGridValuesForDateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGridValuesForDateQuery, GetGridValuesForDateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGridValuesForDateQuery, GetGridValuesForDateQueryVariables>(GetGridValuesForDateDocument, options);
        }
export type GetGridValuesForDateQueryHookResult = ReturnType<typeof useGetGridValuesForDateQuery>;
export type GetGridValuesForDateLazyQueryHookResult = ReturnType<typeof useGetGridValuesForDateLazyQuery>;
export type GetGridValuesForDateQueryResult = Apollo.QueryResult<GetGridValuesForDateQuery, GetGridValuesForDateQueryVariables>;
export const GetGridValuesForTrafficDocument = gql`
    query GetGridValuesForTraffic($internalTerminalId: Int!, $valueAddedDate: String!, $gridType: String!) {
  getGridValuesForTraffic(
    internalTerminalId: $internalTerminalId
    valueAddedDate: $valueAddedDate
    gridType: $gridType
  ) {
    id
    gridName
    gridEntryId
    internalTerminalId
    columnHeaders
    rowHeaders
    gridEntries
    valueAddedDate
    location
    gridType
    isNumberGridWithTotal
  }
}
    `;

/**
 * __useGetGridValuesForTrafficQuery__
 *
 * To run a query within a React component, call `useGetGridValuesForTrafficQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGridValuesForTrafficQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGridValuesForTrafficQuery({
 *   variables: {
 *      internalTerminalId: // value for 'internalTerminalId'
 *      valueAddedDate: // value for 'valueAddedDate'
 *      gridType: // value for 'gridType'
 *   },
 * });
 */
export function useGetGridValuesForTrafficQuery(baseOptions: Apollo.QueryHookOptions<GetGridValuesForTrafficQuery, GetGridValuesForTrafficQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGridValuesForTrafficQuery, GetGridValuesForTrafficQueryVariables>(GetGridValuesForTrafficDocument, options);
      }
export function useGetGridValuesForTrafficLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGridValuesForTrafficQuery, GetGridValuesForTrafficQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGridValuesForTrafficQuery, GetGridValuesForTrafficQueryVariables>(GetGridValuesForTrafficDocument, options);
        }
export type GetGridValuesForTrafficQueryHookResult = ReturnType<typeof useGetGridValuesForTrafficQuery>;
export type GetGridValuesForTrafficLazyQueryHookResult = ReturnType<typeof useGetGridValuesForTrafficLazyQuery>;
export type GetGridValuesForTrafficQueryResult = Apollo.QueryResult<GetGridValuesForTrafficQuery, GetGridValuesForTrafficQueryVariables>;
export const GetInternalTerminalFromIdDocument = gql`
    query GetInternalTerminalFromId($internalTerminalId: Int!) {
  internalTerminal(id: $internalTerminalId) {
    id
    name
    reportExportedAt
    reportExportedBy
    terminal {
      locations {
        id
        country
      }
    }
  }
}
    `;

/**
 * __useGetInternalTerminalFromIdQuery__
 *
 * To run a query within a React component, call `useGetInternalTerminalFromIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInternalTerminalFromIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInternalTerminalFromIdQuery({
 *   variables: {
 *      internalTerminalId: // value for 'internalTerminalId'
 *   },
 * });
 */
export function useGetInternalTerminalFromIdQuery(baseOptions: Apollo.QueryHookOptions<GetInternalTerminalFromIdQuery, GetInternalTerminalFromIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInternalTerminalFromIdQuery, GetInternalTerminalFromIdQueryVariables>(GetInternalTerminalFromIdDocument, options);
      }
export function useGetInternalTerminalFromIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInternalTerminalFromIdQuery, GetInternalTerminalFromIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInternalTerminalFromIdQuery, GetInternalTerminalFromIdQueryVariables>(GetInternalTerminalFromIdDocument, options);
        }
export type GetInternalTerminalFromIdQueryHookResult = ReturnType<typeof useGetInternalTerminalFromIdQuery>;
export type GetInternalTerminalFromIdLazyQueryHookResult = ReturnType<typeof useGetInternalTerminalFromIdLazyQuery>;
export type GetInternalTerminalFromIdQueryResult = Apollo.QueryResult<GetInternalTerminalFromIdQuery, GetInternalTerminalFromIdQueryVariables>;
export const GetInternalTerminalReportInfoDocument = gql`
    query GetInternalTerminalReportInfo($internalTerminalId: Int!, $dateForAddInfo: String!, $forTerminal: Boolean!) {
  internalTerminalReportInfo(
    internalTerminalId: $internalTerminalId
    dateForAddInfo: $dateForAddInfo
    forTerminal: $forTerminal
  ) {
    id
    internalTerminalId
    dateForAddInfo
    info
    infoType
    isAddedFromTerminal
    replyInfo
    replyText
    files {
      id
      originalname
      mimetype
      path
      size
    }
  }
}
    `;

/**
 * __useGetInternalTerminalReportInfoQuery__
 *
 * To run a query within a React component, call `useGetInternalTerminalReportInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInternalTerminalReportInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInternalTerminalReportInfoQuery({
 *   variables: {
 *      internalTerminalId: // value for 'internalTerminalId'
 *      dateForAddInfo: // value for 'dateForAddInfo'
 *      forTerminal: // value for 'forTerminal'
 *   },
 * });
 */
export function useGetInternalTerminalReportInfoQuery(baseOptions: Apollo.QueryHookOptions<GetInternalTerminalReportInfoQuery, GetInternalTerminalReportInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInternalTerminalReportInfoQuery, GetInternalTerminalReportInfoQueryVariables>(GetInternalTerminalReportInfoDocument, options);
      }
export function useGetInternalTerminalReportInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInternalTerminalReportInfoQuery, GetInternalTerminalReportInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInternalTerminalReportInfoQuery, GetInternalTerminalReportInfoQueryVariables>(GetInternalTerminalReportInfoDocument, options);
        }
export type GetInternalTerminalReportInfoQueryHookResult = ReturnType<typeof useGetInternalTerminalReportInfoQuery>;
export type GetInternalTerminalReportInfoLazyQueryHookResult = ReturnType<typeof useGetInternalTerminalReportInfoLazyQuery>;
export type GetInternalTerminalReportInfoQueryResult = Apollo.QueryResult<GetInternalTerminalReportInfoQuery, GetInternalTerminalReportInfoQueryVariables>;
export const GetInternalTerminalsDocument = gql`
    query GetInternalTerminals($fetchFromAllDepartments: Boolean) {
  internalTerminals(fetchFromAllDepartments: $fetchFromAllDepartments) {
    id
    terminal {
      id
      name
      locations {
        ...LocationsFields
      }
      contacts {
        id
        name
        email
        role
        tags {
          title
        }
      }
    }
  }
}
    ${LocationsFieldsFragmentDoc}`;

/**
 * __useGetInternalTerminalsQuery__
 *
 * To run a query within a React component, call `useGetInternalTerminalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInternalTerminalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInternalTerminalsQuery({
 *   variables: {
 *      fetchFromAllDepartments: // value for 'fetchFromAllDepartments'
 *   },
 * });
 */
export function useGetInternalTerminalsQuery(baseOptions?: Apollo.QueryHookOptions<GetInternalTerminalsQuery, GetInternalTerminalsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInternalTerminalsQuery, GetInternalTerminalsQueryVariables>(GetInternalTerminalsDocument, options);
      }
export function useGetInternalTerminalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInternalTerminalsQuery, GetInternalTerminalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInternalTerminalsQuery, GetInternalTerminalsQueryVariables>(GetInternalTerminalsDocument, options);
        }
export type GetInternalTerminalsQueryHookResult = ReturnType<typeof useGetInternalTerminalsQuery>;
export type GetInternalTerminalsLazyQueryHookResult = ReturnType<typeof useGetInternalTerminalsLazyQuery>;
export type GetInternalTerminalsQueryResult = Apollo.QueryResult<GetInternalTerminalsQuery, GetInternalTerminalsQueryVariables>;
export const GetMasterValueBoxDocument = gql`
    query GetMasterValueBox($internalTerminalId: Int!, $forTerminal: Boolean!) {
  getMasterValueBoxes(
    internalTerminalId: $internalTerminalId
    forTerminal: $forTerminal
  ) {
    id
    internalTerminalId
    boxName
    frequency
    isFixedBox
    isYesNoBox
    isTimeBox
    isAddedFromTerminal
  }
}
    `;

/**
 * __useGetMasterValueBoxQuery__
 *
 * To run a query within a React component, call `useGetMasterValueBoxQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMasterValueBoxQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMasterValueBoxQuery({
 *   variables: {
 *      internalTerminalId: // value for 'internalTerminalId'
 *      forTerminal: // value for 'forTerminal'
 *   },
 * });
 */
export function useGetMasterValueBoxQuery(baseOptions: Apollo.QueryHookOptions<GetMasterValueBoxQuery, GetMasterValueBoxQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMasterValueBoxQuery, GetMasterValueBoxQueryVariables>(GetMasterValueBoxDocument, options);
      }
export function useGetMasterValueBoxLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMasterValueBoxQuery, GetMasterValueBoxQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMasterValueBoxQuery, GetMasterValueBoxQueryVariables>(GetMasterValueBoxDocument, options);
        }
export type GetMasterValueBoxQueryHookResult = ReturnType<typeof useGetMasterValueBoxQuery>;
export type GetMasterValueBoxLazyQueryHookResult = ReturnType<typeof useGetMasterValueBoxLazyQuery>;
export type GetMasterValueBoxQueryResult = Apollo.QueryResult<GetMasterValueBoxQuery, GetMasterValueBoxQueryVariables>;
export const GetValueBoxForDateDocument = gql`
    query GetValueBoxForDate($internalTerminalId: Int!, $date: String!, $forTerminal: Boolean!) {
  getValueBoxesForDay(
    internalTerminalId: $internalTerminalId
    date: $date
    forTerminal: $forTerminal
  ) {
    boxId
    entryId
    boxNameText
    boxValue
    isReadOnly
    isFixedBox
    isYesNoBox
    isTimeBox
    isAddedFromTerminal
  }
}
    `;

/**
 * __useGetValueBoxForDateQuery__
 *
 * To run a query within a React component, call `useGetValueBoxForDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetValueBoxForDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetValueBoxForDateQuery({
 *   variables: {
 *      internalTerminalId: // value for 'internalTerminalId'
 *      date: // value for 'date'
 *      forTerminal: // value for 'forTerminal'
 *   },
 * });
 */
export function useGetValueBoxForDateQuery(baseOptions: Apollo.QueryHookOptions<GetValueBoxForDateQuery, GetValueBoxForDateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetValueBoxForDateQuery, GetValueBoxForDateQueryVariables>(GetValueBoxForDateDocument, options);
      }
export function useGetValueBoxForDateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetValueBoxForDateQuery, GetValueBoxForDateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetValueBoxForDateQuery, GetValueBoxForDateQueryVariables>(GetValueBoxForDateDocument, options);
        }
export type GetValueBoxForDateQueryHookResult = ReturnType<typeof useGetValueBoxForDateQuery>;
export type GetValueBoxForDateLazyQueryHookResult = ReturnType<typeof useGetValueBoxForDateLazyQuery>;
export type GetValueBoxForDateQueryResult = Apollo.QueryResult<GetValueBoxForDateQuery, GetValueBoxForDateQueryVariables>;
export const GetCardTypeLocationsDocument = gql`
    query GetCardTypeLocations($cardType: String!, $search: String) {
  getCardTypeLocations(cardType: $cardType, search: $search) {
    id
    name
    address
    city
    country
    postalCode
    card {
      name
      type
      id
    }
  }
}
    `;

/**
 * __useGetCardTypeLocationsQuery__
 *
 * To run a query within a React component, call `useGetCardTypeLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCardTypeLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCardTypeLocationsQuery({
 *   variables: {
 *      cardType: // value for 'cardType'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetCardTypeLocationsQuery(baseOptions: Apollo.QueryHookOptions<GetCardTypeLocationsQuery, GetCardTypeLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCardTypeLocationsQuery, GetCardTypeLocationsQueryVariables>(GetCardTypeLocationsDocument, options);
      }
export function useGetCardTypeLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCardTypeLocationsQuery, GetCardTypeLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCardTypeLocationsQuery, GetCardTypeLocationsQueryVariables>(GetCardTypeLocationsDocument, options);
        }
export type GetCardTypeLocationsQueryHookResult = ReturnType<typeof useGetCardTypeLocationsQuery>;
export type GetCardTypeLocationsLazyQueryHookResult = ReturnType<typeof useGetCardTypeLocationsLazyQuery>;
export type GetCardTypeLocationsQueryResult = Apollo.QueryResult<GetCardTypeLocationsQuery, GetCardTypeLocationsQueryVariables>;
export const GetLocationDocument = gql`
    query GetLocation($id: Int!) {
  location(id: $id) {
    ...LocationsFields
  }
}
    ${LocationsFieldsFragmentDoc}`;

/**
 * __useGetLocationQuery__
 *
 * To run a query within a React component, call `useGetLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLocationQuery(baseOptions: Apollo.QueryHookOptions<GetLocationQuery, GetLocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLocationQuery, GetLocationQueryVariables>(GetLocationDocument, options);
      }
export function useGetLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLocationQuery, GetLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLocationQuery, GetLocationQueryVariables>(GetLocationDocument, options);
        }
export type GetLocationQueryHookResult = ReturnType<typeof useGetLocationQuery>;
export type GetLocationLazyQueryHookResult = ReturnType<typeof useGetLocationLazyQuery>;
export type GetLocationQueryResult = Apollo.QueryResult<GetLocationQuery, GetLocationQueryVariables>;
export const GetLocationForSelectDocument = gql`
    query GetLocationForSelect($locationId: Int!) {
  location(id: $locationId) {
    id
    name
    address
    city
    country
    postalCode
    card {
      name
      type
      id
    }
  }
}
    `;

/**
 * __useGetLocationForSelectQuery__
 *
 * To run a query within a React component, call `useGetLocationForSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationForSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationForSelectQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useGetLocationForSelectQuery(baseOptions: Apollo.QueryHookOptions<GetLocationForSelectQuery, GetLocationForSelectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLocationForSelectQuery, GetLocationForSelectQueryVariables>(GetLocationForSelectDocument, options);
      }
export function useGetLocationForSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLocationForSelectQuery, GetLocationForSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLocationForSelectQuery, GetLocationForSelectQueryVariables>(GetLocationForSelectDocument, options);
        }
export type GetLocationForSelectQueryHookResult = ReturnType<typeof useGetLocationForSelectQuery>;
export type GetLocationForSelectLazyQueryHookResult = ReturnType<typeof useGetLocationForSelectLazyQuery>;
export type GetLocationForSelectQueryResult = Apollo.QueryResult<GetLocationForSelectQuery, GetLocationForSelectQueryVariables>;
export const GetLocationsForCardDocument = gql`
    query GetLocationsForCard($cardId: Int) {
  locations(cardId: $cardId) {
    ...LocationsFields
  }
}
    ${LocationsFieldsFragmentDoc}`;

/**
 * __useGetLocationsForCardQuery__
 *
 * To run a query within a React component, call `useGetLocationsForCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationsForCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationsForCardQuery({
 *   variables: {
 *      cardId: // value for 'cardId'
 *   },
 * });
 */
export function useGetLocationsForCardQuery(baseOptions?: Apollo.QueryHookOptions<GetLocationsForCardQuery, GetLocationsForCardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLocationsForCardQuery, GetLocationsForCardQueryVariables>(GetLocationsForCardDocument, options);
      }
export function useGetLocationsForCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLocationsForCardQuery, GetLocationsForCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLocationsForCardQuery, GetLocationsForCardQueryVariables>(GetLocationsForCardDocument, options);
        }
export type GetLocationsForCardQueryHookResult = ReturnType<typeof useGetLocationsForCardQuery>;
export type GetLocationsForCardLazyQueryHookResult = ReturnType<typeof useGetLocationsForCardLazyQuery>;
export type GetLocationsForCardQueryResult = Apollo.QueryResult<GetLocationsForCardQuery, GetLocationsForCardQueryVariables>;
export const GetLocationsForLegDocument = gql`
    query GetLocationsForLeg($locationsSearch: String) {
  locations(search: $locationsSearch) {
    id
    name
    address
    city
    country
    postalCode
    card {
      name
      type
      id
    }
  }
}
    `;

/**
 * __useGetLocationsForLegQuery__
 *
 * To run a query within a React component, call `useGetLocationsForLegQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationsForLegQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationsForLegQuery({
 *   variables: {
 *      locationsSearch: // value for 'locationsSearch'
 *   },
 * });
 */
export function useGetLocationsForLegQuery(baseOptions?: Apollo.QueryHookOptions<GetLocationsForLegQuery, GetLocationsForLegQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLocationsForLegQuery, GetLocationsForLegQueryVariables>(GetLocationsForLegDocument, options);
      }
export function useGetLocationsForLegLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLocationsForLegQuery, GetLocationsForLegQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLocationsForLegQuery, GetLocationsForLegQueryVariables>(GetLocationsForLegDocument, options);
        }
export type GetLocationsForLegQueryHookResult = ReturnType<typeof useGetLocationsForLegQuery>;
export type GetLocationsForLegLazyQueryHookResult = ReturnType<typeof useGetLocationsForLegLazyQuery>;
export type GetLocationsForLegQueryResult = Apollo.QueryResult<GetLocationsForLegQuery, GetLocationsForLegQueryVariables>;
export const GetLocationsDocument = gql`
    query GetLocations {
  locations {
    ...LocationsFields
  }
}
    ${LocationsFieldsFragmentDoc}`;

/**
 * __useGetLocationsQuery__
 *
 * To run a query within a React component, call `useGetLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLocationsQuery(baseOptions?: Apollo.QueryHookOptions<GetLocationsQuery, GetLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLocationsQuery, GetLocationsQueryVariables>(GetLocationsDocument, options);
      }
export function useGetLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLocationsQuery, GetLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLocationsQuery, GetLocationsQueryVariables>(GetLocationsDocument, options);
        }
export type GetLocationsQueryHookResult = ReturnType<typeof useGetLocationsQuery>;
export type GetLocationsLazyQueryHookResult = ReturnType<typeof useGetLocationsLazyQuery>;
export type GetLocationsQueryResult = Apollo.QueryResult<GetLocationsQuery, GetLocationsQueryVariables>;
export const GetNotesForCardDocument = gql`
    query GetNotesForCard($cardId: Int!) {
  card(id: $cardId) {
    id
    notes {
      createdBy
      createdAt
      id
      lastUpdatedBy
      text
      title
      updatedAt
      deletedBy
    }
  }
}
    `;

/**
 * __useGetNotesForCardQuery__
 *
 * To run a query within a React component, call `useGetNotesForCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotesForCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotesForCardQuery({
 *   variables: {
 *      cardId: // value for 'cardId'
 *   },
 * });
 */
export function useGetNotesForCardQuery(baseOptions: Apollo.QueryHookOptions<GetNotesForCardQuery, GetNotesForCardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNotesForCardQuery, GetNotesForCardQueryVariables>(GetNotesForCardDocument, options);
      }
export function useGetNotesForCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotesForCardQuery, GetNotesForCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNotesForCardQuery, GetNotesForCardQueryVariables>(GetNotesForCardDocument, options);
        }
export type GetNotesForCardQueryHookResult = ReturnType<typeof useGetNotesForCardQuery>;
export type GetNotesForCardLazyQueryHookResult = ReturnType<typeof useGetNotesForCardLazyQuery>;
export type GetNotesForCardQueryResult = Apollo.QueryResult<GetNotesForCardQuery, GetNotesForCardQueryVariables>;
export const GetOrderDocument = gql`
    query GetOrder($id: Int!) {
  order(id: $id) {
    id
    transportationDate
    customerId
    note
    pallets
    packages
    palletSpace
    weight
    updatedAt
    createdBy
    lastUpdatedBy
  }
}
    `;

/**
 * __useGetOrderQuery__
 *
 * To run a query within a React component, call `useGetOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrderQuery(baseOptions: Apollo.QueryHookOptions<GetOrderQuery, GetOrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrderQuery, GetOrderQueryVariables>(GetOrderDocument, options);
      }
export function useGetOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrderQuery, GetOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrderQuery, GetOrderQueryVariables>(GetOrderDocument, options);
        }
export type GetOrderQueryHookResult = ReturnType<typeof useGetOrderQuery>;
export type GetOrderLazyQueryHookResult = ReturnType<typeof useGetOrderLazyQuery>;
export type GetOrderQueryResult = Apollo.QueryResult<GetOrderQuery, GetOrderQueryVariables>;
export const GetOrdersDocument = gql`
    query GetOrders {
  orders {
    id
    transportationDate
    customerId
    note
    pallets
    palletSpace
    weight
    packages
    updatedAt
    createdBy
    lastUpdatedBy
    customer {
      name
    }
  }
}
    `;

/**
 * __useGetOrdersQuery__
 *
 * To run a query within a React component, call `useGetOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrdersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrdersQuery(baseOptions?: Apollo.QueryHookOptions<GetOrdersQuery, GetOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrdersQuery, GetOrdersQueryVariables>(GetOrdersDocument, options);
      }
export function useGetOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrdersQuery, GetOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrdersQuery, GetOrdersQueryVariables>(GetOrdersDocument, options);
        }
export type GetOrdersQueryHookResult = ReturnType<typeof useGetOrdersQuery>;
export type GetOrdersLazyQueryHookResult = ReturnType<typeof useGetOrdersLazyQuery>;
export type GetOrdersQueryResult = Apollo.QueryResult<GetOrdersQuery, GetOrdersQueryVariables>;
export const GetPeakPlanningDebitsDocument = gql`
    query GetPeakPlanningDebits($startDate: String!, $endDate: String!) {
  peakPlanningDebits(startDate: $startDate, endDate: $endDate) {
    id
    status
    sporadicRouteId
    sporadicRoute {
      id
      driverName
      licensePlate
      agreedPrice
      currency
      driverPhoneNumber
      capacity
      routeId
      deletedBy
      createdBy
      lastUpdatedBy
      updatedAt
      dispatcherId
      note
      subcontractorId
      legs {
        id
        locationId
        location {
          name
          address
          postalCode
          city
          country
        }
        note
        arrivalTime
        departureTime
        position
        load
        unload
        loadingListItems {
          id
          locationId
          packages
          pallets
          palletSpace
          weight
          note
          lastUpdatedBy
          createdBy
          updatedAt
        }
      }
    }
    location {
      id
      name
      address
      city
      country
    }
    productionDate
    customerId
    customer {
      name
    }
    customerNumber
    salesContactId
    salesContact {
      name
    }
    destinationCountry
    bookedPPL
    sentPPL
    note
    selfCost
    selfCostCurrency
    customerCost
    customerCurrency
    createdAt
    updatedAt
    createdBy
    lastUpdatedBy
  }
}
    `;

/**
 * __useGetPeakPlanningDebitsQuery__
 *
 * To run a query within a React component, call `useGetPeakPlanningDebitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPeakPlanningDebitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPeakPlanningDebitsQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetPeakPlanningDebitsQuery(baseOptions: Apollo.QueryHookOptions<GetPeakPlanningDebitsQuery, GetPeakPlanningDebitsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPeakPlanningDebitsQuery, GetPeakPlanningDebitsQueryVariables>(GetPeakPlanningDebitsDocument, options);
      }
export function useGetPeakPlanningDebitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPeakPlanningDebitsQuery, GetPeakPlanningDebitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPeakPlanningDebitsQuery, GetPeakPlanningDebitsQueryVariables>(GetPeakPlanningDebitsDocument, options);
        }
export type GetPeakPlanningDebitsQueryHookResult = ReturnType<typeof useGetPeakPlanningDebitsQuery>;
export type GetPeakPlanningDebitsLazyQueryHookResult = ReturnType<typeof useGetPeakPlanningDebitsLazyQuery>;
export type GetPeakPlanningDebitsQueryResult = Apollo.QueryResult<GetPeakPlanningDebitsQuery, GetPeakPlanningDebitsQueryVariables>;
export const GetPeakPlanningItemsDocument = gql`
    query GetPeakPlanningItems($startDate: String!, $endDate: String!, $departmentId: String!) {
  peakPlanningItems(
    startDate: $startDate
    endDate: $endDate
    departmentId: $departmentId
  ) {
    id
    note
    status
    generated
    responsible
    routeId
    productType
    productCountry
    productionDate
    subcontractor {
      name
    }
    peakPlanningLocations {
      id
      loadStart
      loadEnd
      bookedPPL
      position
      location {
        id
        name
        address
        country
        city
        postalCode
      }
    }
    sporadicRoute {
      id
      driverName
      licensePlate
      agreedPrice
      currency
      driverPhoneNumber
      capacity
      routeId
      deletedBy
      createdBy
      lastUpdatedBy
      updatedAt
      deletedAt
      debitsCount
      dispatcherId
      note
      subcontractorId
      legs {
        id
        locationId
        location {
          name
          address
          postalCode
          city
          country
        }
        note
        arrivalTime
        departureTime
        position
        load
        unload
        loadingListItems {
          id
          locationId
          packages
          pallets
          palletSpace
          weight
          note
          lastUpdatedBy
          createdBy
          updatedAt
        }
      }
    }
    subcontractorId
    capacity
    unloaded
    unusedCapacity
    utilization
    debitLines
  }
}
    `;

/**
 * __useGetPeakPlanningItemsQuery__
 *
 * To run a query within a React component, call `useGetPeakPlanningItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPeakPlanningItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPeakPlanningItemsQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      departmentId: // value for 'departmentId'
 *   },
 * });
 */
export function useGetPeakPlanningItemsQuery(baseOptions: Apollo.QueryHookOptions<GetPeakPlanningItemsQuery, GetPeakPlanningItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPeakPlanningItemsQuery, GetPeakPlanningItemsQueryVariables>(GetPeakPlanningItemsDocument, options);
      }
export function useGetPeakPlanningItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPeakPlanningItemsQuery, GetPeakPlanningItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPeakPlanningItemsQuery, GetPeakPlanningItemsQueryVariables>(GetPeakPlanningItemsDocument, options);
        }
export type GetPeakPlanningItemsQueryHookResult = ReturnType<typeof useGetPeakPlanningItemsQuery>;
export type GetPeakPlanningItemsLazyQueryHookResult = ReturnType<typeof useGetPeakPlanningItemsLazyQuery>;
export type GetPeakPlanningItemsQueryResult = Apollo.QueryResult<GetPeakPlanningItemsQuery, GetPeakPlanningItemsQueryVariables>;
export const GetAllRouteGroupTagsDocument = gql`
    query GetAllRouteGroupTags($transportDate: String!) {
  getAllRouteGroupTags(transportDate: $transportDate)
}
    `;

/**
 * __useGetAllRouteGroupTagsQuery__
 *
 * To run a query within a React component, call `useGetAllRouteGroupTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllRouteGroupTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllRouteGroupTagsQuery({
 *   variables: {
 *      transportDate: // value for 'transportDate'
 *   },
 * });
 */
export function useGetAllRouteGroupTagsQuery(baseOptions: Apollo.QueryHookOptions<GetAllRouteGroupTagsQuery, GetAllRouteGroupTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllRouteGroupTagsQuery, GetAllRouteGroupTagsQueryVariables>(GetAllRouteGroupTagsDocument, options);
      }
export function useGetAllRouteGroupTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllRouteGroupTagsQuery, GetAllRouteGroupTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllRouteGroupTagsQuery, GetAllRouteGroupTagsQueryVariables>(GetAllRouteGroupTagsDocument, options);
        }
export type GetAllRouteGroupTagsQueryHookResult = ReturnType<typeof useGetAllRouteGroupTagsQuery>;
export type GetAllRouteGroupTagsLazyQueryHookResult = ReturnType<typeof useGetAllRouteGroupTagsLazyQuery>;
export type GetAllRouteGroupTagsQueryResult = Apollo.QueryResult<GetAllRouteGroupTagsQuery, GetAllRouteGroupTagsQueryVariables>;
export const GetIncomingRoutesToTerminalDocument = gql`
    query GetIncomingRoutesToTerminal($terminalId: Int!, $date: String!) {
  incomingRoutesToTerminal(terminalId: $terminalId, date: $date) {
    inbound {
      id
      routeEntityId
      routeId
      subcontractorId
      subcontractorName
      licensePlateAndRouteNote
      capacity
      arrivalTime
      departureTime
    }
    outbound {
      id
      routeEntityId
      routeId
      subcontractorId
      subcontractorName
      licensePlateAndRouteNote
      capacity
      arrivalTime
      departureTime
    }
  }
}
    `;

/**
 * __useGetIncomingRoutesToTerminalQuery__
 *
 * To run a query within a React component, call `useGetIncomingRoutesToTerminalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIncomingRoutesToTerminalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIncomingRoutesToTerminalQuery({
 *   variables: {
 *      terminalId: // value for 'terminalId'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetIncomingRoutesToTerminalQuery(baseOptions: Apollo.QueryHookOptions<GetIncomingRoutesToTerminalQuery, GetIncomingRoutesToTerminalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIncomingRoutesToTerminalQuery, GetIncomingRoutesToTerminalQueryVariables>(GetIncomingRoutesToTerminalDocument, options);
      }
export function useGetIncomingRoutesToTerminalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIncomingRoutesToTerminalQuery, GetIncomingRoutesToTerminalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIncomingRoutesToTerminalQuery, GetIncomingRoutesToTerminalQueryVariables>(GetIncomingRoutesToTerminalDocument, options);
        }
export type GetIncomingRoutesToTerminalQueryHookResult = ReturnType<typeof useGetIncomingRoutesToTerminalQuery>;
export type GetIncomingRoutesToTerminalLazyQueryHookResult = ReturnType<typeof useGetIncomingRoutesToTerminalLazyQuery>;
export type GetIncomingRoutesToTerminalQueryResult = Apollo.QueryResult<GetIncomingRoutesToTerminalQuery, GetIncomingRoutesToTerminalQueryVariables>;
export const GetLegsMatchingShipmentDocument = gql`
    query GetLegsMatchingShipment($fromLocationId: Int!, $toLocationId: Int!, $transportDate: String!) {
  fetchLegsMatchingShipment(
    fromLocationId: $fromLocationId
    toLocationId: $toLocationId
    transportDate: $transportDate
  ) {
    id
    position
    arrivalTime
    route {
      id
      routeId
      type
      routeLock {
        id
        lockedAt
        lockedBy
        lockedByUserId
        isChecklistLocked
      }
    }
  }
}
    `;

/**
 * __useGetLegsMatchingShipmentQuery__
 *
 * To run a query within a React component, call `useGetLegsMatchingShipmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLegsMatchingShipmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLegsMatchingShipmentQuery({
 *   variables: {
 *      fromLocationId: // value for 'fromLocationId'
 *      toLocationId: // value for 'toLocationId'
 *      transportDate: // value for 'transportDate'
 *   },
 * });
 */
export function useGetLegsMatchingShipmentQuery(baseOptions: Apollo.QueryHookOptions<GetLegsMatchingShipmentQuery, GetLegsMatchingShipmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLegsMatchingShipmentQuery, GetLegsMatchingShipmentQueryVariables>(GetLegsMatchingShipmentDocument, options);
      }
export function useGetLegsMatchingShipmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLegsMatchingShipmentQuery, GetLegsMatchingShipmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLegsMatchingShipmentQuery, GetLegsMatchingShipmentQueryVariables>(GetLegsMatchingShipmentDocument, options);
        }
export type GetLegsMatchingShipmentQueryHookResult = ReturnType<typeof useGetLegsMatchingShipmentQuery>;
export type GetLegsMatchingShipmentLazyQueryHookResult = ReturnType<typeof useGetLegsMatchingShipmentLazyQuery>;
export type GetLegsMatchingShipmentQueryResult = Apollo.QueryResult<GetLegsMatchingShipmentQuery, GetLegsMatchingShipmentQueryVariables>;
export const GetMultipleDatesDocument = gql`
    query GetMultipleDates($id: Int!) {
  multipleDates(id: $id)
}
    `;

/**
 * __useGetMultipleDatesQuery__
 *
 * To run a query within a React component, call `useGetMultipleDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMultipleDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMultipleDatesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMultipleDatesQuery(baseOptions: Apollo.QueryHookOptions<GetMultipleDatesQuery, GetMultipleDatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMultipleDatesQuery, GetMultipleDatesQueryVariables>(GetMultipleDatesDocument, options);
      }
export function useGetMultipleDatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMultipleDatesQuery, GetMultipleDatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMultipleDatesQuery, GetMultipleDatesQueryVariables>(GetMultipleDatesDocument, options);
        }
export type GetMultipleDatesQueryHookResult = ReturnType<typeof useGetMultipleDatesQuery>;
export type GetMultipleDatesLazyQueryHookResult = ReturnType<typeof useGetMultipleDatesLazyQuery>;
export type GetMultipleDatesQueryResult = Apollo.QueryResult<GetMultipleDatesQuery, GetMultipleDatesQueryVariables>;
export const GetProductionGroupDocument = gql`
    query GetProductionGroup($terminalId: Int!, $startDate: String!, $endDate: String!) {
  getProductionGroup(
    terminalId: $terminalId
    startDate: $startDate
    endDate: $endDate
  )
}
    `;

/**
 * __useGetProductionGroupQuery__
 *
 * To run a query within a React component, call `useGetProductionGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductionGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductionGroupQuery({
 *   variables: {
 *      terminalId: // value for 'terminalId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetProductionGroupQuery(baseOptions: Apollo.QueryHookOptions<GetProductionGroupQuery, GetProductionGroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductionGroupQuery, GetProductionGroupQueryVariables>(GetProductionGroupDocument, options);
      }
export function useGetProductionGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductionGroupQuery, GetProductionGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductionGroupQuery, GetProductionGroupQueryVariables>(GetProductionGroupDocument, options);
        }
export type GetProductionGroupQueryHookResult = ReturnType<typeof useGetProductionGroupQuery>;
export type GetProductionGroupLazyQueryHookResult = ReturnType<typeof useGetProductionGroupLazyQuery>;
export type GetProductionGroupQueryResult = Apollo.QueryResult<GetProductionGroupQuery, GetProductionGroupQueryVariables>;
export const GetRouteDocument = gql`
    query GetRoute($id: Int!) {
  route(id: $id) {
    ...TransportAgreementFields
    routeAgreementNo
    agreedPrice
    isCreatedFromTerminal
    isCreatedFromPlanned
    capacity
    kilometer
    weight
    currency
    dispatcherId
    driverName
    driverPhoneNumber
    routeDriverName
    routeDriverPhoneNumber
    routeCarRegNumber
    routeTrailerRegNumber
    isCopyDriverPlateInfo
    lastUpdatedBy
    updatedAt
    createdBy
    deletedAt
    deletedBy
    files {
      id
      mimetype
      originalname
      path
      size
      createdBy
      createdAt
      tags {
        id
        title
      }
    }
    ftl {
      cdc
      cmr
      currency
      customerId
      customerNumber
      customerReference
      id
      invoiceNote
      price
    }
    routeLock {
      id
      routeId
      lockedAt
      lockedBy
      isChecklistLocked
    }
    costAllocation {
      id
      items {
        id
        departmentId
        cost
        includedKpi
        includedPrice
        bringCost
        additionalCost
        additionalDiscount
        type
        comment
      }
    }
    id
    invoiceNote
    invoiceNumber
    legs {
      gateNumber
      carRegistrationNumber
      trailerRegistrationNumber
      routeDriverName
      routeDriverPhoneNumber
      arrivalTime
      departureTime
      fuel
      id
      load
      note
      position
      unload
      isNonArrivalMarked
      nonArrivalMarkedFrom
      productionDate
      transportationDate
      transportationDateOffset
      locationId
      loadingListItems {
        id
        location {
          id
          name
          address
          postalCode
          city
          country
        }
        locationId
        packages
        pallets
        palletSpace
        weight
        checked
        isDangerous
        classification
        unNumber
        isLimitedQty
        note
        isImportedFromShipment
        customWaybillNo
        lastUpdatedBy
        createdBy
        updatedAt
      }
    }
    licensePlate
    note
    externalNote
    routeId
    subcontractorId
    transportationDate
    type
    tourRoute {
      id
      bookmarkTourRouteTemplate {
        bookmark
      }
    }
  }
}
    ${TransportAgreementFieldsFragmentDoc}`;

/**
 * __useGetRouteQuery__
 *
 * To run a query within a React component, call `useGetRouteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRouteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRouteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRouteQuery(baseOptions: Apollo.QueryHookOptions<GetRouteQuery, GetRouteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRouteQuery, GetRouteQueryVariables>(GetRouteDocument, options);
      }
export function useGetRouteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRouteQuery, GetRouteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRouteQuery, GetRouteQueryVariables>(GetRouteDocument, options);
        }
export type GetRouteQueryHookResult = ReturnType<typeof useGetRouteQuery>;
export type GetRouteLazyQueryHookResult = ReturnType<typeof useGetRouteLazyQuery>;
export type GetRouteQueryResult = Apollo.QueryResult<GetRouteQuery, GetRouteQueryVariables>;
export const GetRouteLegForViewLoadingListDocument = gql`
    query GetRouteLegForViewLoadingList($legId: Int!) {
  routeLegForViewLoadingList(legId: $legId) {
    id
    load
    position
    locationId
    loadingListItems {
      id
      locationId
      packages
      pallets
      palletSpace
      weight
      checked
      isDangerous
      classification
      unNumber
      isLimitedQty
      note
      isImportedFromShipment
      customWaybillNo
    }
    route {
      createdBy
      updatedAt
      lastUpdatedBy
      deletedBy
      deletedAt
    }
  }
}
    `;

/**
 * __useGetRouteLegForViewLoadingListQuery__
 *
 * To run a query within a React component, call `useGetRouteLegForViewLoadingListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRouteLegForViewLoadingListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRouteLegForViewLoadingListQuery({
 *   variables: {
 *      legId: // value for 'legId'
 *   },
 * });
 */
export function useGetRouteLegForViewLoadingListQuery(baseOptions: Apollo.QueryHookOptions<GetRouteLegForViewLoadingListQuery, GetRouteLegForViewLoadingListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRouteLegForViewLoadingListQuery, GetRouteLegForViewLoadingListQueryVariables>(GetRouteLegForViewLoadingListDocument, options);
      }
export function useGetRouteLegForViewLoadingListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRouteLegForViewLoadingListQuery, GetRouteLegForViewLoadingListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRouteLegForViewLoadingListQuery, GetRouteLegForViewLoadingListQueryVariables>(GetRouteLegForViewLoadingListDocument, options);
        }
export type GetRouteLegForViewLoadingListQueryHookResult = ReturnType<typeof useGetRouteLegForViewLoadingListQuery>;
export type GetRouteLegForViewLoadingListLazyQueryHookResult = ReturnType<typeof useGetRouteLegForViewLoadingListLazyQuery>;
export type GetRouteLegForViewLoadingListQueryResult = Apollo.QueryResult<GetRouteLegForViewLoadingListQuery, GetRouteLegForViewLoadingListQueryVariables>;
export const GetRouteLegsForProdAppDocument = gql`
    query GetRouteLegsForProdApp($unitId: String!, $event: String!, $futureHours: Int!, $cetDateTimeString: String, $pastHours: Int, $gateNumbers: [String!], $tripStatus: String) {
  fetchRoutesForProdApp(
    unitId: $unitId
    event: $event
    futureHours: $futureHours
    cetDateTimeString: $cetDateTimeString
    pastHours: $pastHours
    gateNumbers: $gateNumbers
    tripStatus: $tripStatus
  ) {
    id
    position
    transportationDate
    arrivalTime
    actualArrivalTime
    actualArrivalDate
    departureTime
    actualDepartureTime
    actualDepartureDate
    gateNumber
    carRegistrationNumber
    trailerRegistrationNumber
    routeDriverName
    sealNumber
    tripStartDateTime
    tripEndDateTime
    actualCapacity
    route {
      id
      routeId
      capacity
      kilometer
      type
      departureAddress {
        city
        country
      }
      arrivalAddress {
        city
        country
      }
    }
  }
}
    `;

/**
 * __useGetRouteLegsForProdAppQuery__
 *
 * To run a query within a React component, call `useGetRouteLegsForProdAppQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRouteLegsForProdAppQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRouteLegsForProdAppQuery({
 *   variables: {
 *      unitId: // value for 'unitId'
 *      event: // value for 'event'
 *      futureHours: // value for 'futureHours'
 *      cetDateTimeString: // value for 'cetDateTimeString'
 *      pastHours: // value for 'pastHours'
 *      gateNumbers: // value for 'gateNumbers'
 *      tripStatus: // value for 'tripStatus'
 *   },
 * });
 */
export function useGetRouteLegsForProdAppQuery(baseOptions: Apollo.QueryHookOptions<GetRouteLegsForProdAppQuery, GetRouteLegsForProdAppQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRouteLegsForProdAppQuery, GetRouteLegsForProdAppQueryVariables>(GetRouteLegsForProdAppDocument, options);
      }
export function useGetRouteLegsForProdAppLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRouteLegsForProdAppQuery, GetRouteLegsForProdAppQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRouteLegsForProdAppQuery, GetRouteLegsForProdAppQueryVariables>(GetRouteLegsForProdAppDocument, options);
        }
export type GetRouteLegsForProdAppQueryHookResult = ReturnType<typeof useGetRouteLegsForProdAppQuery>;
export type GetRouteLegsForProdAppLazyQueryHookResult = ReturnType<typeof useGetRouteLegsForProdAppLazyQuery>;
export type GetRouteLegsForProdAppQueryResult = Apollo.QueryResult<GetRouteLegsForProdAppQuery, GetRouteLegsForProdAppQueryVariables>;
export const GetRouteLegsForProdAppWithTokenDocument = gql`
    query GetRouteLegsForProdAppWithToken($unitId: String!, $event: String!, $futureHours: Int!, $cetDateTimeString: String, $pastHours: Int, $gateNumbers: [String!], $tripStatus: String) {
  fetchRoutesForProdAppWithToken(
    unitId: $unitId
    event: $event
    futureHours: $futureHours
    cetDateTimeString: $cetDateTimeString
    pastHours: $pastHours
    gateNumbers: $gateNumbers
    tripStatus: $tripStatus
  ) {
    id
    position
    transportationDate
    arrivalTime
    actualArrivalTime
    actualArrivalDate
    departureTime
    actualDepartureTime
    actualDepartureDate
    gateNumber
    carRegistrationNumber
    trailerRegistrationNumber
    routeDriverName
    sealNumber
    tripStartDateTime
    tripEndDateTime
    actualCapacity
    route {
      id
      routeId
      capacity
      kilometer
      type
      departureAddress {
        city
        country
      }
      arrivalAddress {
        city
        country
      }
    }
  }
}
    `;

/**
 * __useGetRouteLegsForProdAppWithTokenQuery__
 *
 * To run a query within a React component, call `useGetRouteLegsForProdAppWithTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRouteLegsForProdAppWithTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRouteLegsForProdAppWithTokenQuery({
 *   variables: {
 *      unitId: // value for 'unitId'
 *      event: // value for 'event'
 *      futureHours: // value for 'futureHours'
 *      cetDateTimeString: // value for 'cetDateTimeString'
 *      pastHours: // value for 'pastHours'
 *      gateNumbers: // value for 'gateNumbers'
 *      tripStatus: // value for 'tripStatus'
 *   },
 * });
 */
export function useGetRouteLegsForProdAppWithTokenQuery(baseOptions: Apollo.QueryHookOptions<GetRouteLegsForProdAppWithTokenQuery, GetRouteLegsForProdAppWithTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRouteLegsForProdAppWithTokenQuery, GetRouteLegsForProdAppWithTokenQueryVariables>(GetRouteLegsForProdAppWithTokenDocument, options);
      }
export function useGetRouteLegsForProdAppWithTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRouteLegsForProdAppWithTokenQuery, GetRouteLegsForProdAppWithTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRouteLegsForProdAppWithTokenQuery, GetRouteLegsForProdAppWithTokenQueryVariables>(GetRouteLegsForProdAppWithTokenDocument, options);
        }
export type GetRouteLegsForProdAppWithTokenQueryHookResult = ReturnType<typeof useGetRouteLegsForProdAppWithTokenQuery>;
export type GetRouteLegsForProdAppWithTokenLazyQueryHookResult = ReturnType<typeof useGetRouteLegsForProdAppWithTokenLazyQuery>;
export type GetRouteLegsForProdAppWithTokenQueryResult = Apollo.QueryResult<GetRouteLegsForProdAppWithTokenQuery, GetRouteLegsForProdAppWithTokenQueryVariables>;
export const GetRouteLockDocument = gql`
    query GetRouteLock($routeId: Int!) {
  routeLock(routeId: $routeId) {
    lockedBy
    lockedAt
    isChecklistLocked
  }
}
    `;

/**
 * __useGetRouteLockQuery__
 *
 * To run a query within a React component, call `useGetRouteLockQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRouteLockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRouteLockQuery({
 *   variables: {
 *      routeId: // value for 'routeId'
 *   },
 * });
 */
export function useGetRouteLockQuery(baseOptions: Apollo.QueryHookOptions<GetRouteLockQuery, GetRouteLockQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRouteLockQuery, GetRouteLockQueryVariables>(GetRouteLockDocument, options);
      }
export function useGetRouteLockLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRouteLockQuery, GetRouteLockQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRouteLockQuery, GetRouteLockQueryVariables>(GetRouteLockDocument, options);
        }
export type GetRouteLockQueryHookResult = ReturnType<typeof useGetRouteLockQuery>;
export type GetRouteLockLazyQueryHookResult = ReturnType<typeof useGetRouteLockLazyQuery>;
export type GetRouteLockQueryResult = Apollo.QueryResult<GetRouteLockQuery, GetRouteLockQueryVariables>;
export const GetTransportAgreementDataDocument = gql`
    query GetTransportAgreementData($id: Int!) {
  route(id: $id) {
    ...TransportAgreementFields
  }
}
    ${TransportAgreementFieldsFragmentDoc}`;

/**
 * __useGetTransportAgreementDataQuery__
 *
 * To run a query within a React component, call `useGetTransportAgreementDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransportAgreementDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransportAgreementDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTransportAgreementDataQuery(baseOptions: Apollo.QueryHookOptions<GetTransportAgreementDataQuery, GetTransportAgreementDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTransportAgreementDataQuery, GetTransportAgreementDataQueryVariables>(GetTransportAgreementDataDocument, options);
      }
export function useGetTransportAgreementDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTransportAgreementDataQuery, GetTransportAgreementDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTransportAgreementDataQuery, GetTransportAgreementDataQueryVariables>(GetTransportAgreementDataDocument, options);
        }
export type GetTransportAgreementDataQueryHookResult = ReturnType<typeof useGetTransportAgreementDataQuery>;
export type GetTransportAgreementDataLazyQueryHookResult = ReturnType<typeof useGetTransportAgreementDataLazyQuery>;
export type GetTransportAgreementDataQueryResult = Apollo.QueryResult<GetTransportAgreementDataQuery, GetTransportAgreementDataQueryVariables>;
export const ListAssociatedRoutesDocument = gql`
    query ListAssociatedRoutes($id: Int!) {
  associatedRoutes(id: $id) {
    id
    routeId
    routePosition
    tourId
    routeName
    tourName
    departureLocation
    departureTime
    arrivalLocation
    arrivalTime
    subContractorName
    frequency
  }
}
    `;

/**
 * __useListAssociatedRoutesQuery__
 *
 * To run a query within a React component, call `useListAssociatedRoutesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAssociatedRoutesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAssociatedRoutesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useListAssociatedRoutesQuery(baseOptions: Apollo.QueryHookOptions<ListAssociatedRoutesQuery, ListAssociatedRoutesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAssociatedRoutesQuery, ListAssociatedRoutesQueryVariables>(ListAssociatedRoutesDocument, options);
      }
export function useListAssociatedRoutesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAssociatedRoutesQuery, ListAssociatedRoutesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAssociatedRoutesQuery, ListAssociatedRoutesQueryVariables>(ListAssociatedRoutesDocument, options);
        }
export type ListAssociatedRoutesQueryHookResult = ReturnType<typeof useListAssociatedRoutesQuery>;
export type ListAssociatedRoutesLazyQueryHookResult = ReturnType<typeof useListAssociatedRoutesLazyQuery>;
export type ListAssociatedRoutesQueryResult = Apollo.QueryResult<ListAssociatedRoutesQuery, ListAssociatedRoutesQueryVariables>;
export const ListRoutesDocument = gql`
    query ListRoutes($startDate: String!, $endDate: String!, $type: RouteType) {
  routes(startDate: $startDate, endDate: $endDate, type: $type) {
    id
    isCreatedFromTerminal
    isCreatedFromPlanned
    routeId
    invoiceNumber
    invoiceNote
    transportationDate
    dispatcher {
      name
    }
    subcontractor {
      name
    }
    agreedPrice
    currency
    driverName
    driverPhoneNumber
    licensePlate
    fuel
    capacity
    kilometer
    weight
    note
    externalNote
    legs {
      isNonArrivalMarked
      arrivalTime
      departureTime
      position
      location {
        name
        city
        country
      }
    }
    ftl {
      customerNumber
      price
      currency
      invoiceNote
      cmr
      cdc
    }
    tour {
      name
    }
  }
}
    `;

/**
 * __useListRoutesQuery__
 *
 * To run a query within a React component, call `useListRoutesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListRoutesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListRoutesQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useListRoutesQuery(baseOptions: Apollo.QueryHookOptions<ListRoutesQuery, ListRoutesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListRoutesQuery, ListRoutesQueryVariables>(ListRoutesDocument, options);
      }
export function useListRoutesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListRoutesQuery, ListRoutesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListRoutesQuery, ListRoutesQueryVariables>(ListRoutesDocument, options);
        }
export type ListRoutesQueryHookResult = ReturnType<typeof useListRoutesQuery>;
export type ListRoutesLazyQueryHookResult = ReturnType<typeof useListRoutesLazyQuery>;
export type ListRoutesQueryResult = Apollo.QueryResult<ListRoutesQuery, ListRoutesQueryVariables>;
export const PingRouteLockUserDocument = gql`
    query PingRouteLockUser($routeId: Int!) {
  pingUser(routeId: $routeId)
}
    `;

/**
 * __usePingRouteLockUserQuery__
 *
 * To run a query within a React component, call `usePingRouteLockUserQuery` and pass it any options that fit your needs.
 * When your component renders, `usePingRouteLockUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePingRouteLockUserQuery({
 *   variables: {
 *      routeId: // value for 'routeId'
 *   },
 * });
 */
export function usePingRouteLockUserQuery(baseOptions: Apollo.QueryHookOptions<PingRouteLockUserQuery, PingRouteLockUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PingRouteLockUserQuery, PingRouteLockUserQueryVariables>(PingRouteLockUserDocument, options);
      }
export function usePingRouteLockUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PingRouteLockUserQuery, PingRouteLockUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PingRouteLockUserQuery, PingRouteLockUserQueryVariables>(PingRouteLockUserDocument, options);
        }
export type PingRouteLockUserQueryHookResult = ReturnType<typeof usePingRouteLockUserQuery>;
export type PingRouteLockUserLazyQueryHookResult = ReturnType<typeof usePingRouteLockUserLazyQuery>;
export type PingRouteLockUserQueryResult = Apollo.QueryResult<PingRouteLockUserQuery, PingRouteLockUserQueryVariables>;
export const RefetchRouteLegDocument = gql`
    query RefetchRouteLeg($legId: Int!, $terminalId: Int!) {
  refetchLatestLeg(legId: $legId, terminalId: $terminalId) {
    ...LiveUpdateRouteLeg
  }
}
    ${LiveUpdateRouteLegFragmentDoc}`;

/**
 * __useRefetchRouteLegQuery__
 *
 * To run a query within a React component, call `useRefetchRouteLegQuery` and pass it any options that fit your needs.
 * When your component renders, `useRefetchRouteLegQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRefetchRouteLegQuery({
 *   variables: {
 *      legId: // value for 'legId'
 *      terminalId: // value for 'terminalId'
 *   },
 * });
 */
export function useRefetchRouteLegQuery(baseOptions: Apollo.QueryHookOptions<RefetchRouteLegQuery, RefetchRouteLegQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RefetchRouteLegQuery, RefetchRouteLegQueryVariables>(RefetchRouteLegDocument, options);
      }
export function useRefetchRouteLegLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RefetchRouteLegQuery, RefetchRouteLegQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RefetchRouteLegQuery, RefetchRouteLegQueryVariables>(RefetchRouteLegDocument, options);
        }
export type RefetchRouteLegQueryHookResult = ReturnType<typeof useRefetchRouteLegQuery>;
export type RefetchRouteLegLazyQueryHookResult = ReturnType<typeof useRefetchRouteLegLazyQuery>;
export type RefetchRouteLegQueryResult = Apollo.QueryResult<RefetchRouteLegQuery, RefetchRouteLegQueryVariables>;
export const GetSporadicRouteTemplateDocument = gql`
    query GetSporadicRouteTemplate($id: Int!) {
  sporadicRouteTemplate(id: $id) {
    id
    name
    test
    driverName
    licensePlate
    agreedPrice
    currency
    driverPhoneNumber
    capacity
    kilometer
    weight
    routeId
    deletedBy
    createdBy
    lastUpdatedBy
    updatedAt
    dispatcher {
      id
    }
    note
    externalNote
    subcontractor {
      id
    }
    files {
      originalname
      id
      path
      createdBy
      createdAt
    }
    ftl {
      id
      cdc
      cmr
      currency
      customerId
      customerNumber
      customerReference
      invoiceNote
      price
    }
    legs {
      fuel
      gateNumber
      carRegistrationNumber
      trailerRegistrationNumber
      routeDriverName
      routeDriverPhoneNumber
      id
      locationId
      note
      arrivalTime
      departureTime
      position
      load
      unload
      transportationDateOffset
      location {
        deletedBy
      }
      loadingListItems {
        id
        locationId
        packages
        pallets
        palletSpace
        weight
        note
        checked
        isDangerous
        classification
        unNumber
        isLimitedQty
        lastUpdatedBy
        createdBy
        updatedAt
      }
    }
  }
}
    `;

/**
 * __useGetSporadicRouteTemplateQuery__
 *
 * To run a query within a React component, call `useGetSporadicRouteTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSporadicRouteTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSporadicRouteTemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSporadicRouteTemplateQuery(baseOptions: Apollo.QueryHookOptions<GetSporadicRouteTemplateQuery, GetSporadicRouteTemplateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSporadicRouteTemplateQuery, GetSporadicRouteTemplateQueryVariables>(GetSporadicRouteTemplateDocument, options);
      }
export function useGetSporadicRouteTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSporadicRouteTemplateQuery, GetSporadicRouteTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSporadicRouteTemplateQuery, GetSporadicRouteTemplateQueryVariables>(GetSporadicRouteTemplateDocument, options);
        }
export type GetSporadicRouteTemplateQueryHookResult = ReturnType<typeof useGetSporadicRouteTemplateQuery>;
export type GetSporadicRouteTemplateLazyQueryHookResult = ReturnType<typeof useGetSporadicRouteTemplateLazyQuery>;
export type GetSporadicRouteTemplateQueryResult = Apollo.QueryResult<GetSporadicRouteTemplateQuery, GetSporadicRouteTemplateQueryVariables>;
export const SporadicRouteTemplatesDocument = gql`
    query SporadicRouteTemplates {
  sporadicRouteTemplates {
    id
    name
    routeId
    createdAt
    legs {
      location {
        country
      }
      position
    }
  }
}
    `;

/**
 * __useSporadicRouteTemplatesQuery__
 *
 * To run a query within a React component, call `useSporadicRouteTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSporadicRouteTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSporadicRouteTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSporadicRouteTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<SporadicRouteTemplatesQuery, SporadicRouteTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SporadicRouteTemplatesQuery, SporadicRouteTemplatesQueryVariables>(SporadicRouteTemplatesDocument, options);
      }
export function useSporadicRouteTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SporadicRouteTemplatesQuery, SporadicRouteTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SporadicRouteTemplatesQuery, SporadicRouteTemplatesQueryVariables>(SporadicRouteTemplatesDocument, options);
        }
export type SporadicRouteTemplatesQueryHookResult = ReturnType<typeof useSporadicRouteTemplatesQuery>;
export type SporadicRouteTemplatesLazyQueryHookResult = ReturnType<typeof useSporadicRouteTemplatesLazyQuery>;
export type SporadicRouteTemplatesQueryResult = Apollo.QueryResult<SporadicRouteTemplatesQuery, SporadicRouteTemplatesQueryVariables>;
export const GetSubcontractorDocument = gql`
    query GetSubcontractor($id: Int!) {
  subcontractor(id: $id) {
    id
    name
    createdBy
    updatedAt
    lastUpdatedBy
    files {
      originalname
      path
      id
      createdAt
      mimetype
      size
      createdBy
    }
    contacts {
      ...CardContactFields
    }
  }
}
    ${CardContactFieldsFragmentDoc}`;

/**
 * __useGetSubcontractorQuery__
 *
 * To run a query within a React component, call `useGetSubcontractorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractorQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSubcontractorQuery(baseOptions: Apollo.QueryHookOptions<GetSubcontractorQuery, GetSubcontractorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubcontractorQuery, GetSubcontractorQueryVariables>(GetSubcontractorDocument, options);
      }
export function useGetSubcontractorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubcontractorQuery, GetSubcontractorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubcontractorQuery, GetSubcontractorQueryVariables>(GetSubcontractorDocument, options);
        }
export type GetSubcontractorQueryHookResult = ReturnType<typeof useGetSubcontractorQuery>;
export type GetSubcontractorLazyQueryHookResult = ReturnType<typeof useGetSubcontractorLazyQuery>;
export type GetSubcontractorQueryResult = Apollo.QueryResult<GetSubcontractorQuery, GetSubcontractorQueryVariables>;
export const GetSubcontractorsDocument = gql`
    query GetSubcontractors($search: String, $withTerminal: Boolean) {
  subcontractors(search: $search, withTerminal: $withTerminal) {
    id
    name
    locations {
      ...LocationsFields
    }
    contacts {
      id
      name
    }
  }
}
    ${LocationsFieldsFragmentDoc}`;

/**
 * __useGetSubcontractorsQuery__
 *
 * To run a query within a React component, call `useGetSubcontractorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractorsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      withTerminal: // value for 'withTerminal'
 *   },
 * });
 */
export function useGetSubcontractorsQuery(baseOptions?: Apollo.QueryHookOptions<GetSubcontractorsQuery, GetSubcontractorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubcontractorsQuery, GetSubcontractorsQueryVariables>(GetSubcontractorsDocument, options);
      }
export function useGetSubcontractorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubcontractorsQuery, GetSubcontractorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubcontractorsQuery, GetSubcontractorsQueryVariables>(GetSubcontractorsDocument, options);
        }
export type GetSubcontractorsQueryHookResult = ReturnType<typeof useGetSubcontractorsQuery>;
export type GetSubcontractorsLazyQueryHookResult = ReturnType<typeof useGetSubcontractorsLazyQuery>;
export type GetSubcontractorsQueryResult = Apollo.QueryResult<GetSubcontractorsQuery, GetSubcontractorsQueryVariables>;
export const SearchSubcontractorsDocument = gql`
    query SearchSubcontractors($subcontractorsSearch: String) {
  subcontractors(search: $subcontractorsSearch) {
    id
    name
    contacts {
      id
      name
    }
  }
}
    `;

/**
 * __useSearchSubcontractorsQuery__
 *
 * To run a query within a React component, call `useSearchSubcontractorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchSubcontractorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchSubcontractorsQuery({
 *   variables: {
 *      subcontractorsSearch: // value for 'subcontractorsSearch'
 *   },
 * });
 */
export function useSearchSubcontractorsQuery(baseOptions?: Apollo.QueryHookOptions<SearchSubcontractorsQuery, SearchSubcontractorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchSubcontractorsQuery, SearchSubcontractorsQueryVariables>(SearchSubcontractorsDocument, options);
      }
export function useSearchSubcontractorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchSubcontractorsQuery, SearchSubcontractorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchSubcontractorsQuery, SearchSubcontractorsQueryVariables>(SearchSubcontractorsDocument, options);
        }
export type SearchSubcontractorsQueryHookResult = ReturnType<typeof useSearchSubcontractorsQuery>;
export type SearchSubcontractorsLazyQueryHookResult = ReturnType<typeof useSearchSubcontractorsLazyQuery>;
export type SearchSubcontractorsQueryResult = Apollo.QueryResult<SearchSubcontractorsQuery, SearchSubcontractorsQueryVariables>;
export const GetTagsDocument = gql`
    query GetTags {
  cardContactTags {
    id
    title
  }
}
    `;

/**
 * __useGetTagsQuery__
 *
 * To run a query within a React component, call `useGetTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTagsQuery(baseOptions?: Apollo.QueryHookOptions<GetTagsQuery, GetTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTagsQuery, GetTagsQueryVariables>(GetTagsDocument, options);
      }
export function useGetTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTagsQuery, GetTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTagsQuery, GetTagsQueryVariables>(GetTagsDocument, options);
        }
export type GetTagsQueryHookResult = ReturnType<typeof useGetTagsQuery>;
export type GetTagsLazyQueryHookResult = ReturnType<typeof useGetTagsLazyQuery>;
export type GetTagsQueryResult = Apollo.QueryResult<GetTagsQuery, GetTagsQueryVariables>;
export const GetOneTerminalDocument = gql`
    query GetOneTerminal($id: Int!) {
  terminal(id: $id) {
    id
    name
    isInternalTerminal
    createdBy
    updatedAt
    lastUpdatedBy
    locations {
      id
    }
    files {
      originalname
      path
      id
      createdAt
      mimetype
      size
      createdBy
    }
    contacts {
      ...CardContactFields
    }
  }
}
    ${CardContactFieldsFragmentDoc}`;

/**
 * __useGetOneTerminalQuery__
 *
 * To run a query within a React component, call `useGetOneTerminalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneTerminalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOneTerminalQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOneTerminalQuery(baseOptions: Apollo.QueryHookOptions<GetOneTerminalQuery, GetOneTerminalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOneTerminalQuery, GetOneTerminalQueryVariables>(GetOneTerminalDocument, options);
      }
export function useGetOneTerminalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOneTerminalQuery, GetOneTerminalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOneTerminalQuery, GetOneTerminalQueryVariables>(GetOneTerminalDocument, options);
        }
export type GetOneTerminalQueryHookResult = ReturnType<typeof useGetOneTerminalQuery>;
export type GetOneTerminalLazyQueryHookResult = ReturnType<typeof useGetOneTerminalLazyQuery>;
export type GetOneTerminalQueryResult = Apollo.QueryResult<GetOneTerminalQuery, GetOneTerminalQueryVariables>;
export const GetTerminalsDocument = gql`
    query GetTerminals($search: String) {
  terminals(search: $search) {
    id
    name
    locations {
      ...LocationsFields
    }
  }
}
    ${LocationsFieldsFragmentDoc}`;

/**
 * __useGetTerminalsQuery__
 *
 * To run a query within a React component, call `useGetTerminalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTerminalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTerminalsQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetTerminalsQuery(baseOptions?: Apollo.QueryHookOptions<GetTerminalsQuery, GetTerminalsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTerminalsQuery, GetTerminalsQueryVariables>(GetTerminalsDocument, options);
      }
export function useGetTerminalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTerminalsQuery, GetTerminalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTerminalsQuery, GetTerminalsQueryVariables>(GetTerminalsDocument, options);
        }
export type GetTerminalsQueryHookResult = ReturnType<typeof useGetTerminalsQuery>;
export type GetTerminalsLazyQueryHookResult = ReturnType<typeof useGetTerminalsLazyQuery>;
export type GetTerminalsQueryResult = Apollo.QueryResult<GetTerminalsQuery, GetTerminalsQueryVariables>;
export const BookmarkedDispatchedRoutesDocument = gql`
    query BookmarkedDispatchedRoutes($date: String) {
  bookmarkedDispatchedRoutes(date: $date) {
    id
    routeId
    tourRouteId
    capacity
    truckLicencePlateNo
    legs {
      id
      loadingListItems {
        id
        packages
        pallets
        palletSpace
        weight
        note
        checked
      }
    }
    status
  }
}
    `;

/**
 * __useBookmarkedDispatchedRoutesQuery__
 *
 * To run a query within a React component, call `useBookmarkedDispatchedRoutesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookmarkedDispatchedRoutesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookmarkedDispatchedRoutesQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useBookmarkedDispatchedRoutesQuery(baseOptions?: Apollo.QueryHookOptions<BookmarkedDispatchedRoutesQuery, BookmarkedDispatchedRoutesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BookmarkedDispatchedRoutesQuery, BookmarkedDispatchedRoutesQueryVariables>(BookmarkedDispatchedRoutesDocument, options);
      }
export function useBookmarkedDispatchedRoutesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BookmarkedDispatchedRoutesQuery, BookmarkedDispatchedRoutesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BookmarkedDispatchedRoutesQuery, BookmarkedDispatchedRoutesQueryVariables>(BookmarkedDispatchedRoutesDocument, options);
        }
export type BookmarkedDispatchedRoutesQueryHookResult = ReturnType<typeof useBookmarkedDispatchedRoutesQuery>;
export type BookmarkedDispatchedRoutesLazyQueryHookResult = ReturnType<typeof useBookmarkedDispatchedRoutesLazyQuery>;
export type BookmarkedDispatchedRoutesQueryResult = Apollo.QueryResult<BookmarkedDispatchedRoutesQuery, BookmarkedDispatchedRoutesQueryVariables>;
export const GetOneTourRouteDocument = gql`
    query GetOneTourRoute($routeId: Int!) {
  getTourRoute(routeId: $routeId) {
    id
    routeId
    note
    externalNote
    days
    price
    createdAt
    updatedAt
    files {
      originalname
      id
      path
      createdBy
      createdAt
      mimetype
      size
      tags {
        id
        title
        createdAt
        updatedAt
      }
    }
    ftl {
      cdc
      cmr
      currency
      customerId
      customerNumber
      customerReference
      id
      invoiceNote
      price
    }
    routeDriverName
    routeDriverPhoneNumber
    routeCarRegNumber
    routeTrailerRegNumber
    isCopyDriverPlateInfo
    capacity
    kilometer
    weight
    startDate
    originalStartDate
    endDate
    startExceptionDate
    endExceptionDate
    exceptionDates
    routeDateType
    tourRouteType
    legs {
      id
      fuel
      locationId
      gateNumber
      carRegistrationNumber
      trailerRegistrationNumber
      routeDriverName
      routeDriverPhoneNumber
      note
      arrivalTime
      departureTime
      position
      load
      unload
      transportationDateOffset
      location {
        name
        city
        country
      }
      loadingListItems {
        id
        locationId
        packages
        pallets
        palletSpace
        weight
        checked
        isDangerous
        classification
        unNumber
        isLimitedQty
        note
        lastUpdatedBy
        createdBy
        updatedAt
      }
    }
    costAllocations {
      id
      departmentId
      cost
      includedKpi
      includedPrice
      bringCost
      additionalCost
      additionalDiscount
      type
      comment
    }
  }
}
    `;

/**
 * __useGetOneTourRouteQuery__
 *
 * To run a query within a React component, call `useGetOneTourRouteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneTourRouteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOneTourRouteQuery({
 *   variables: {
 *      routeId: // value for 'routeId'
 *   },
 * });
 */
export function useGetOneTourRouteQuery(baseOptions: Apollo.QueryHookOptions<GetOneTourRouteQuery, GetOneTourRouteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOneTourRouteQuery, GetOneTourRouteQueryVariables>(GetOneTourRouteDocument, options);
      }
export function useGetOneTourRouteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOneTourRouteQuery, GetOneTourRouteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOneTourRouteQuery, GetOneTourRouteQueryVariables>(GetOneTourRouteDocument, options);
        }
export type GetOneTourRouteQueryHookResult = ReturnType<typeof useGetOneTourRouteQuery>;
export type GetOneTourRouteLazyQueryHookResult = ReturnType<typeof useGetOneTourRouteLazyQuery>;
export type GetOneTourRouteQueryResult = Apollo.QueryResult<GetOneTourRouteQuery, GetOneTourRouteQueryVariables>;
export const GetTourRouteTemplatesDocument = gql`
    query GetTourRouteTemplates($fetchBookmarked: Boolean) {
  tourRouteTemplates(fetchBookmarked: $fetchBookmarked) {
    id
    routeId
    price
    days
    note
    externalNote
    position
    departureLocation {
      city
      country
    }
    arrivalLocation {
      city
      country
    }
    tour {
      id
      name
      capacity
      currency
      isActive
      fuel
      startDate
      endDate
      startExceptionDate
      endExceptionDate
      subcontractor {
        name
      }
      dispatcher {
        name
      }
      createdBy
      lastUpdatedBy
      updatedAt
    }
    legs {
      id
      locationId
      location {
        name
        city
        country
      }
      note
      arrivalTime
      departureTime
      position
      load
      unload
      transportationDateOffset
      loadingListItems {
        id
        locationId
        packages
        pallets
        palletSpace
        weight
        note
        checked
        isDangerous
        classification
        unNumber
        isLimitedQty
        lastUpdatedBy
        createdBy
        updatedAt
      }
    }
  }
}
    `;

/**
 * __useGetTourRouteTemplatesQuery__
 *
 * To run a query within a React component, call `useGetTourRouteTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTourRouteTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTourRouteTemplatesQuery({
 *   variables: {
 *      fetchBookmarked: // value for 'fetchBookmarked'
 *   },
 * });
 */
export function useGetTourRouteTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<GetTourRouteTemplatesQuery, GetTourRouteTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTourRouteTemplatesQuery, GetTourRouteTemplatesQueryVariables>(GetTourRouteTemplatesDocument, options);
      }
export function useGetTourRouteTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTourRouteTemplatesQuery, GetTourRouteTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTourRouteTemplatesQuery, GetTourRouteTemplatesQueryVariables>(GetTourRouteTemplatesDocument, options);
        }
export type GetTourRouteTemplatesQueryHookResult = ReturnType<typeof useGetTourRouteTemplatesQuery>;
export type GetTourRouteTemplatesLazyQueryHookResult = ReturnType<typeof useGetTourRouteTemplatesLazyQuery>;
export type GetTourRouteTemplatesQueryResult = Apollo.QueryResult<GetTourRouteTemplatesQuery, GetTourRouteTemplatesQueryVariables>;
export const GetGroupingTagDocument = gql`
    query GetGroupingTag {
  tourTemplatesWithGroupTag
}
    `;

/**
 * __useGetGroupingTagQuery__
 *
 * To run a query within a React component, call `useGetGroupingTagQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupingTagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupingTagQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGroupingTagQuery(baseOptions?: Apollo.QueryHookOptions<GetGroupingTagQuery, GetGroupingTagQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGroupingTagQuery, GetGroupingTagQueryVariables>(GetGroupingTagDocument, options);
      }
export function useGetGroupingTagLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGroupingTagQuery, GetGroupingTagQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGroupingTagQuery, GetGroupingTagQueryVariables>(GetGroupingTagDocument, options);
        }
export type GetGroupingTagQueryHookResult = ReturnType<typeof useGetGroupingTagQuery>;
export type GetGroupingTagLazyQueryHookResult = ReturnType<typeof useGetGroupingTagLazyQuery>;
export type GetGroupingTagQueryResult = Apollo.QueryResult<GetGroupingTagQuery, GetGroupingTagQueryVariables>;
export const GetOtherToursDocument = gql`
    query GetOtherTours($excludeId: Int!, $searchTerm: String) {
  otherTours(excludeId: $excludeId, searchTerm: $searchTerm) {
    id
    name
    subcontractorId
    capacity
    fuel
    price
    currency
    startDate
    endDate
    isActive
    note
    createdBy
    lastUpdatedBy
    updatedAt
    startExceptionDate
    endExceptionDate
    subcontractor {
      name
    }
  }
}
    `;

/**
 * __useGetOtherToursQuery__
 *
 * To run a query within a React component, call `useGetOtherToursQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOtherToursQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOtherToursQuery({
 *   variables: {
 *      excludeId: // value for 'excludeId'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useGetOtherToursQuery(baseOptions: Apollo.QueryHookOptions<GetOtherToursQuery, GetOtherToursQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOtherToursQuery, GetOtherToursQueryVariables>(GetOtherToursDocument, options);
      }
export function useGetOtherToursLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOtherToursQuery, GetOtherToursQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOtherToursQuery, GetOtherToursQueryVariables>(GetOtherToursDocument, options);
        }
export type GetOtherToursQueryHookResult = ReturnType<typeof useGetOtherToursQuery>;
export type GetOtherToursLazyQueryHookResult = ReturnType<typeof useGetOtherToursLazyQuery>;
export type GetOtherToursQueryResult = Apollo.QueryResult<GetOtherToursQuery, GetOtherToursQueryVariables>;
export const GetResponsiblePersonDocument = gql`
    query GetResponsiblePerson {
  tourTemplatesWithResponsiblePerson
}
    `;

/**
 * __useGetResponsiblePersonQuery__
 *
 * To run a query within a React component, call `useGetResponsiblePersonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResponsiblePersonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResponsiblePersonQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetResponsiblePersonQuery(baseOptions?: Apollo.QueryHookOptions<GetResponsiblePersonQuery, GetResponsiblePersonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetResponsiblePersonQuery, GetResponsiblePersonQueryVariables>(GetResponsiblePersonDocument, options);
      }
export function useGetResponsiblePersonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetResponsiblePersonQuery, GetResponsiblePersonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetResponsiblePersonQuery, GetResponsiblePersonQueryVariables>(GetResponsiblePersonDocument, options);
        }
export type GetResponsiblePersonQueryHookResult = ReturnType<typeof useGetResponsiblePersonQuery>;
export type GetResponsiblePersonLazyQueryHookResult = ReturnType<typeof useGetResponsiblePersonLazyQuery>;
export type GetResponsiblePersonQueryResult = Apollo.QueryResult<GetResponsiblePersonQuery, GetResponsiblePersonQueryVariables>;
export const GetTourTemplateDocument = gql`
    query GetTourTemplate($id: Int!) {
  tourTemplate(id: $id) {
    id
    name
    tourAgreementNo
    subcontractorId
    subcontractor {
      name
    }
    dispatcherId
    dispatcher {
      name
    }
    createdBy
    capacity
    fuel
    price
    currency
    startDate
    endDate
    originalStartDate
    startExceptionDate
    endExceptionDate
    exceptionDates
    note
    driverName
    licensePlate
    driverPhoneNumber
    responsiblePerson
    groupingTag
    isPriceEquallyDivided
    isNewVersion
    routes {
      id
      capacity
      kilometer
      weight
      startDate
      originalStartDate
      endDate
      startExceptionDate
      endExceptionDate
      exceptionDates
      routeDateType
      tourRouteType
      routeId
      note
      externalNote
      routeDriverName
      routeDriverPhoneNumber
      routeCarRegNumber
      routeTrailerRegNumber
      isCopyDriverPlateInfo
      ftl {
        cdc
        cmr
        currency
        customerId
        customerNumber
        customerReference
        id
        invoiceNote
        price
      }
      files {
        originalname
        id
        path
        createdBy
        createdAt
        mimetype
        size
        tags {
          id
          title
          createdAt
          updatedAt
        }
      }
      days
      legs {
        id
        fuel
        locationId
        gateNumber
        carRegistrationNumber
        trailerRegistrationNumber
        routeDriverName
        routeDriverPhoneNumber
        location {
          name
          city
          country
          postalCode
          address
          deletedBy
        }
        note
        arrivalTime
        departureTime
        position
        load
        unload
        transportationDateOffset
        loadingListItems {
          id
          locationId
          packages
          pallets
          palletSpace
          weight
          note
          checked
          isDangerous
          classification
          unNumber
          isLimitedQty
          lastUpdatedBy
          createdBy
          updatedAt
        }
      }
      price
      costAllocations {
        id
        departmentId
        cost
        includedKpi
        includedPrice
        bringCost
        additionalCost
        additionalDiscount
        type
        comment
      }
      createdAt
      updatedAt
    }
    createdBy
    lastUpdatedBy
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetTourTemplateQuery__
 *
 * To run a query within a React component, call `useGetTourTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTourTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTourTemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTourTemplateQuery(baseOptions: Apollo.QueryHookOptions<GetTourTemplateQuery, GetTourTemplateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTourTemplateQuery, GetTourTemplateQueryVariables>(GetTourTemplateDocument, options);
      }
export function useGetTourTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTourTemplateQuery, GetTourTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTourTemplateQuery, GetTourTemplateQueryVariables>(GetTourTemplateDocument, options);
        }
export type GetTourTemplateQueryHookResult = ReturnType<typeof useGetTourTemplateQuery>;
export type GetTourTemplateLazyQueryHookResult = ReturnType<typeof useGetTourTemplateLazyQuery>;
export type GetTourTemplateQueryResult = Apollo.QueryResult<GetTourTemplateQuery, GetTourTemplateQueryVariables>;
export const GetTourTemplatesDocument = gql`
    query GetTourTemplates {
  tourTemplates {
    id
    name
    subcontractorId
    capacity
    fuel
    price
    currency
    startDate
    endDate
    originalStartDate
    isActive
    note
    createdBy
    lastUpdatedBy
    updatedAt
    startExceptionDate
    endExceptionDate
    exceptionDates
    responsiblePerson
    groupingTag
    isPriceEquallyDivided
    isNewVersion
    subcontractor {
      name
    }
    dispatcher {
      name
    }
  }
}
    `;

/**
 * __useGetTourTemplatesQuery__
 *
 * To run a query within a React component, call `useGetTourTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTourTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTourTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTourTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<GetTourTemplatesQuery, GetTourTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTourTemplatesQuery, GetTourTemplatesQueryVariables>(GetTourTemplatesDocument, options);
      }
export function useGetTourTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTourTemplatesQuery, GetTourTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTourTemplatesQuery, GetTourTemplatesQueryVariables>(GetTourTemplatesDocument, options);
        }
export type GetTourTemplatesQueryHookResult = ReturnType<typeof useGetTourTemplatesQuery>;
export type GetTourTemplatesLazyQueryHookResult = ReturnType<typeof useGetTourTemplatesLazyQuery>;
export type GetTourTemplatesQueryResult = Apollo.QueryResult<GetTourTemplatesQuery, GetTourTemplatesQueryVariables>;
export const GetMeDocument = gql`
    query GetMe {
  me {
    id
    createdAt
    department
    firstName
    fullName
    configuration
    trafficAccess
    terminalAccess
    customsAccess
    admin
    internalTerminal {
      terminalId
      id
      name
      terminal {
        name
        locations {
          id
          country
        }
      }
    }
    lastName
    roles
    internalTerminalId
  }
}
    `;

/**
 * __useGetMeQuery__
 *
 * To run a query within a React component, call `useGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeQuery(baseOptions?: Apollo.QueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
      }
export function useGetMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
        }
export type GetMeQueryHookResult = ReturnType<typeof useGetMeQuery>;
export type GetMeLazyQueryHookResult = ReturnType<typeof useGetMeLazyQuery>;
export type GetMeQueryResult = Apollo.QueryResult<GetMeQuery, GetMeQueryVariables>;
export const GetTftConfigDocument = gql`
    query GetTftConfig {
  getTftConfig {
    id
    tftConfiguration
  }
}
    `;

/**
 * __useGetTftConfigQuery__
 *
 * To run a query within a React component, call `useGetTftConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTftConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTftConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTftConfigQuery(baseOptions?: Apollo.QueryHookOptions<GetTftConfigQuery, GetTftConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTftConfigQuery, GetTftConfigQueryVariables>(GetTftConfigDocument, options);
      }
export function useGetTftConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTftConfigQuery, GetTftConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTftConfigQuery, GetTftConfigQueryVariables>(GetTftConfigDocument, options);
        }
export type GetTftConfigQueryHookResult = ReturnType<typeof useGetTftConfigQuery>;
export type GetTftConfigLazyQueryHookResult = ReturnType<typeof useGetTftConfigLazyQuery>;
export type GetTftConfigQueryResult = Apollo.QueryResult<GetTftConfigQuery, GetTftConfigQueryVariables>;
export const GetUserWithTerminalDocument = gql`
    query GetUserWithTerminal {
  getUserTerminalData {
    internalTerminalId
    reportExportedAt
    reportExportedBy
    userFullName
  }
}
    `;

/**
 * __useGetUserWithTerminalQuery__
 *
 * To run a query within a React component, call `useGetUserWithTerminalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserWithTerminalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserWithTerminalQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserWithTerminalQuery(baseOptions?: Apollo.QueryHookOptions<GetUserWithTerminalQuery, GetUserWithTerminalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserWithTerminalQuery, GetUserWithTerminalQueryVariables>(GetUserWithTerminalDocument, options);
      }
export function useGetUserWithTerminalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserWithTerminalQuery, GetUserWithTerminalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserWithTerminalQuery, GetUserWithTerminalQueryVariables>(GetUserWithTerminalDocument, options);
        }
export type GetUserWithTerminalQueryHookResult = ReturnType<typeof useGetUserWithTerminalQuery>;
export type GetUserWithTerminalLazyQueryHookResult = ReturnType<typeof useGetUserWithTerminalLazyQuery>;
export type GetUserWithTerminalQueryResult = Apollo.QueryResult<GetUserWithTerminalQuery, GetUserWithTerminalQueryVariables>;
export const UpdateTftConfigurationDocument = gql`
    mutation UpdateTftConfiguration($input: UserConfigurationInput!) {
  updateTftConfiguration(input: $input) {
    id
  }
}
    `;
export type UpdateTftConfigurationMutationFn = Apollo.MutationFunction<UpdateTftConfigurationMutation, UpdateTftConfigurationMutationVariables>;

/**
 * __useUpdateTftConfigurationMutation__
 *
 * To run a mutation, you first call `useUpdateTftConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTftConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTftConfigurationMutation, { data, loading, error }] = useUpdateTftConfigurationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTftConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTftConfigurationMutation, UpdateTftConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTftConfigurationMutation, UpdateTftConfigurationMutationVariables>(UpdateTftConfigurationDocument, options);
      }
export type UpdateTftConfigurationMutationHookResult = ReturnType<typeof useUpdateTftConfigurationMutation>;
export type UpdateTftConfigurationMutationResult = Apollo.MutationResult<UpdateTftConfigurationMutation>;
export type UpdateTftConfigurationMutationOptions = Apollo.BaseMutationOptions<UpdateTftConfigurationMutation, UpdateTftConfigurationMutationVariables>;
export const UpdateUserConfigurationDocument = gql`
    mutation UpdateUserConfiguration($input: UserConfigurationInput!) {
  updateConfiguration(input: $input) {
    id
    createdAt
    department
    firstName
    fullName
    configuration
    internalTerminal {
      terminalId
      id
      name
      terminal {
        name
      }
    }
    lastName
    roles
    internalTerminalId
  }
}
    `;
export type UpdateUserConfigurationMutationFn = Apollo.MutationFunction<UpdateUserConfigurationMutation, UpdateUserConfigurationMutationVariables>;

/**
 * __useUpdateUserConfigurationMutation__
 *
 * To run a mutation, you first call `useUpdateUserConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserConfigurationMutation, { data, loading, error }] = useUpdateUserConfigurationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserConfigurationMutation, UpdateUserConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserConfigurationMutation, UpdateUserConfigurationMutationVariables>(UpdateUserConfigurationDocument, options);
      }
export type UpdateUserConfigurationMutationHookResult = ReturnType<typeof useUpdateUserConfigurationMutation>;
export type UpdateUserConfigurationMutationResult = Apollo.MutationResult<UpdateUserConfigurationMutation>;
export type UpdateUserConfigurationMutationOptions = Apollo.BaseMutationOptions<UpdateUserConfigurationMutation, UpdateUserConfigurationMutationVariables>;
export const ChecklistResetSubDocument = gql`
    subscription ChecklistResetSub($ownerUserId: String!, $forGroup: String!) {
  checklistReset(ownerUserId: $ownerUserId, forGroup: $forGroup) {
    ownerUserId
    forGroup
  }
}
    `;

/**
 * __useChecklistResetSubSubscription__
 *
 * To run a query within a React component, call `useChecklistResetSubSubscription` and pass it any options that fit your needs.
 * When your component renders, `useChecklistResetSubSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChecklistResetSubSubscription({
 *   variables: {
 *      ownerUserId: // value for 'ownerUserId'
 *      forGroup: // value for 'forGroup'
 *   },
 * });
 */
export function useChecklistResetSubSubscription(baseOptions: Apollo.SubscriptionHookOptions<ChecklistResetSubSubscription, ChecklistResetSubSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ChecklistResetSubSubscription, ChecklistResetSubSubscriptionVariables>(ChecklistResetSubDocument, options);
      }
export type ChecklistResetSubSubscriptionHookResult = ReturnType<typeof useChecklistResetSubSubscription>;
export type ChecklistResetSubSubscriptionResult = Apollo.SubscriptionResult<ChecklistResetSubSubscription>;
export const RouteLockSubDocument = gql`
    subscription RouteLockSub($routeId: Int!) {
  routeLockSub(routeId: $routeId) {
    lockedBy
    lockedAt
    lock
    routeId
    reload
  }
}
    `;

/**
 * __useRouteLockSubSubscription__
 *
 * To run a query within a React component, call `useRouteLockSubSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRouteLockSubSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRouteLockSubSubscription({
 *   variables: {
 *      routeId: // value for 'routeId'
 *   },
 * });
 */
export function useRouteLockSubSubscription(baseOptions: Apollo.SubscriptionHookOptions<RouteLockSubSubscription, RouteLockSubSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<RouteLockSubSubscription, RouteLockSubSubscriptionVariables>(RouteLockSubDocument, options);
      }
export type RouteLockSubSubscriptionHookResult = ReturnType<typeof useRouteLockSubSubscription>;
export type RouteLockSubSubscriptionResult = Apollo.SubscriptionResult<RouteLockSubSubscription>;
export const RouteLockPingSubDocument = gql`
    subscription RouteLockPingSub($routeId: Int!) {
  routeLockPing(routeId: $routeId) {
    lockedByUserId
    pingedByUserName
    routeId
  }
}
    `;

/**
 * __useRouteLockPingSubSubscription__
 *
 * To run a query within a React component, call `useRouteLockPingSubSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRouteLockPingSubSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRouteLockPingSubSubscription({
 *   variables: {
 *      routeId: // value for 'routeId'
 *   },
 * });
 */
export function useRouteLockPingSubSubscription(baseOptions: Apollo.SubscriptionHookOptions<RouteLockPingSubSubscription, RouteLockPingSubSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<RouteLockPingSubSubscription, RouteLockPingSubSubscriptionVariables>(RouteLockPingSubDocument, options);
      }
export type RouteLockPingSubSubscriptionHookResult = ReturnType<typeof useRouteLockPingSubSubscription>;
export type RouteLockPingSubSubscriptionResult = Apollo.SubscriptionResult<RouteLockPingSubSubscription>;
export const ShipmentLinkUnlinkSubDocument = gql`
    subscription ShipmentLinkUnlinkSub($terminalId: Int!, $transDate: String!) {
  shipmentLinkUnlinkSub(terminalId: $terminalId, transDate: $transDate) {
    terminalId
    transDate
    isLink
    routeId
  }
}
    `;

/**
 * __useShipmentLinkUnlinkSubSubscription__
 *
 * To run a query within a React component, call `useShipmentLinkUnlinkSubSubscription` and pass it any options that fit your needs.
 * When your component renders, `useShipmentLinkUnlinkSubSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShipmentLinkUnlinkSubSubscription({
 *   variables: {
 *      terminalId: // value for 'terminalId'
 *      transDate: // value for 'transDate'
 *   },
 * });
 */
export function useShipmentLinkUnlinkSubSubscription(baseOptions: Apollo.SubscriptionHookOptions<ShipmentLinkUnlinkSubSubscription, ShipmentLinkUnlinkSubSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ShipmentLinkUnlinkSubSubscription, ShipmentLinkUnlinkSubSubscriptionVariables>(ShipmentLinkUnlinkSubDocument, options);
      }
export type ShipmentLinkUnlinkSubSubscriptionHookResult = ReturnType<typeof useShipmentLinkUnlinkSubSubscription>;
export type ShipmentLinkUnlinkSubSubscriptionResult = Apollo.SubscriptionResult<ShipmentLinkUnlinkSubSubscription>;
export const ShipmentListUpdateSubDocument = gql`
    subscription ShipmentListUpdateSub($startDate: String!, $endDate: String!) {
  shipmentListUpdateSub(startDate: $startDate, endDate: $endDate) {
    shipmentDate
  }
}
    `;

/**
 * __useShipmentListUpdateSubSubscription__
 *
 * To run a query within a React component, call `useShipmentListUpdateSubSubscription` and pass it any options that fit your needs.
 * When your component renders, `useShipmentListUpdateSubSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShipmentListUpdateSubSubscription({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useShipmentListUpdateSubSubscription(baseOptions: Apollo.SubscriptionHookOptions<ShipmentListUpdateSubSubscription, ShipmentListUpdateSubSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ShipmentListUpdateSubSubscription, ShipmentListUpdateSubSubscriptionVariables>(ShipmentListUpdateSubDocument, options);
      }
export type ShipmentListUpdateSubSubscriptionHookResult = ReturnType<typeof useShipmentListUpdateSubSubscription>;
export type ShipmentListUpdateSubSubscriptionResult = Apollo.SubscriptionResult<ShipmentListUpdateSubSubscription>;
export const ShipmentPartialUpdateSubDocument = gql`
    subscription ShipmentPartialUpdateSub($shipmentId: Int!, $userId: String!) {
  shipmentPartialUpdateSub(shipmentId: $shipmentId, userId: $userId) {
    shipmentId
    orderId
    forFieldName
    forFieldValue
    userId
  }
}
    `;

/**
 * __useShipmentPartialUpdateSubSubscription__
 *
 * To run a query within a React component, call `useShipmentPartialUpdateSubSubscription` and pass it any options that fit your needs.
 * When your component renders, `useShipmentPartialUpdateSubSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShipmentPartialUpdateSubSubscription({
 *   variables: {
 *      shipmentId: // value for 'shipmentId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useShipmentPartialUpdateSubSubscription(baseOptions: Apollo.SubscriptionHookOptions<ShipmentPartialUpdateSubSubscription, ShipmentPartialUpdateSubSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ShipmentPartialUpdateSubSubscription, ShipmentPartialUpdateSubSubscriptionVariables>(ShipmentPartialUpdateSubDocument, options);
      }
export type ShipmentPartialUpdateSubSubscriptionHookResult = ReturnType<typeof useShipmentPartialUpdateSubSubscription>;
export type ShipmentPartialUpdateSubSubscriptionResult = Apollo.SubscriptionResult<ShipmentPartialUpdateSubSubscription>;
export const TruckFillAndTimeUpdateDocument = gql`
    subscription TruckFillAndTimeUpdate($terminalId: Int!) {
  truckFillAndTimeUpdate(terminalId: $terminalId) {
    refreshWholeGrid
    terminalId
    routeLeg {
      ...LiveUpdateRouteLeg
    }
  }
}
    ${LiveUpdateRouteLegFragmentDoc}`;

/**
 * __useTruckFillAndTimeUpdateSubscription__
 *
 * To run a query within a React component, call `useTruckFillAndTimeUpdateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTruckFillAndTimeUpdateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTruckFillAndTimeUpdateSubscription({
 *   variables: {
 *      terminalId: // value for 'terminalId'
 *   },
 * });
 */
export function useTruckFillAndTimeUpdateSubscription(baseOptions: Apollo.SubscriptionHookOptions<TruckFillAndTimeUpdateSubscription, TruckFillAndTimeUpdateSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<TruckFillAndTimeUpdateSubscription, TruckFillAndTimeUpdateSubscriptionVariables>(TruckFillAndTimeUpdateDocument, options);
      }
export type TruckFillAndTimeUpdateSubscriptionHookResult = ReturnType<typeof useTruckFillAndTimeUpdateSubscription>;
export type TruckFillAndTimeUpdateSubscriptionResult = Apollo.SubscriptionResult<TruckFillAndTimeUpdateSubscription>;
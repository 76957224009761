import {
  Grid,
  Typography,
  makeStyles,
  Button,
  Box,
  Theme,
  Tooltip,
  IconButton,
  withStyles,
  CircularProgress,
} from '@material-ui/core';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DocumentFile,
  Maybe,
  RouteType,
  useGetInternalTerminalsQuery,
} from '../generated/graphql';
import { COUNTRIES_MAP } from '../lib/constants';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { format } from 'date-fns';
import { useUserConfiguration } from '../providers/UserConfigurationProvider';
import { getTimeAsNumberOfMinutes } from '../lib/date';
import { useHttpClient } from '../providers/HttpClientProvider';
import { DashboardTFTReadOnly } from '../components/DashboardTFTReadOnly';
import PreviousIcon from '@mui/icons-material/NavigateBefore';
import NextIcon from '@mui/icons-material/NavigateNext';
import { LoadingAndError } from '../utils/LoadingAndError';
import { getUserName } from '../lib/useRoles';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import List from '@mui/material/List';
import { DataTerminal } from './TrafficDashboard';
import ListItem from '@mui/material/ListItem';
import { Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export const styleForCountryModal = {
  // eslint-disable-next-line @typescript-eslint/prefer-as-const
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50vw',
  height: '60vh',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflowY: 'scroll' as const,
};

export const StyledAccordionSummary = withStyles({
  root: {
    minHeight: 20,
    height: 30,
    maxHeight: 30,
    backgroundColor: '#dedede',
    '&.Mui-expanded': {
      minHeight: 20,
      height: 30,
      maxHeight: 30,
      backgroundColor: '#dedede',
    },
  },
})(AccordionSummary);

const useStyles = makeStyles((theme: Theme) => ({
  rootGrid: {
    marginTop: 2,
  },
  filterButtonRoot: {
    marginLeft: 2,
    border: '2px solid #e8e8e8',
    borderRadius: 3,
    marginTop: 4,
    padding: 2,
  },
  filterButton: {
    marginTop: 2,
    marginBottom: 2,
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(0, 0.5),
    },
  },
  btnSelected: {
    border: '1px solid #68BC46',
    backgroundColor: '#AAE590',
  },
  inputValTime: {
    height: 15,
    width: 120,
    fontSize: 15,
  },
}));

export interface ListIncomingRoutesLegs {
  id: number;
  arrivalTime: string;
  departureTime?: Maybe<string>;
  actualArrivalTime?: Maybe<string>;
  actualDepartureTime?: Maybe<string>;
  productionDate: string;
  transportationDate: string;
  loadCar?: Maybe<number>;
  loadCarPPL?: Maybe<number>;
  loadCages?: Maybe<number>;
  loadTrailer?: Maybe<number>;
  loadTrailerPPL?: Maybe<number>;
  unloadCar?: Maybe<number>;
  unloadCarPPL?: Maybe<number>;
  unloadCages?: Maybe<number>;
  unloadTrailer?: Maybe<number>;
  unloadTrailerPPL?: Maybe<number>;
  note: string;
  unload: boolean;
  load: boolean;
  terminalNote: string;
  lastUpdatedBy?: Maybe<string>;
  updatedAt: string;
  isNonArrivalMarked?: boolean;
  files: Pick<
    DocumentFile,
    'id' | 'size' | 'path' | 'mimetype' | 'originalname'
  >[];
  loadingListItems: {
    pallets?: Maybe<number>;
    palletSpace?: Maybe<number>;
    isDangerous: boolean;
  }[];
  location?: Maybe<{
    id: number;
    city: string;
    country: string;
    name?: string;
  }>;
  route: {
    id: number;
    isCreatedFromTerminal: boolean;
    isCreatedFromPlanned: boolean;
    type: RouteType;
    routeId: string;
    capacity?: Maybe<number>;
    licensePlate?: string;
    note?: string;
    trailerCarrierId: string;
    carCarrierId: string;
    tourRoute?: Maybe<{
      tourId: number;
    }>;
    transportationDate: string;
    driverName: string;
    subcontractor: {
      id: number;
      name: string;
    };
    legs: {
      position?: Maybe<number>;
      location?: Maybe<{
        city: string;
        country: string;
        name: string;
      }>;
    }[];
  };
}

export function TrafficTFT() {
  const { t } = useTranslation();
  const { httpClient } = useHttpClient();
  const classes = useStyles();
  const NEW_DATE_FORMAT = 'yyyy-MM-dd HH:mm';
  const { getTFTReadOnlyTime, setMasterDateForTFTReadOnly } =
    useUserConfiguration();
  const [user] = useState<string>(getUserName());
  const { data, loading, error } = useGetInternalTerminalsQuery({
    variables: {
      fetchFromAllDepartments: true,
    },
    fetchPolicy: 'cache-and-network',
  });
  const startHour = parseInt(getTFTReadOnlyTime(true).split(':')[0]);
  const startMinute = parseInt(getTFTReadOnlyTime(true).split(':')[1]);
  const endHour = parseInt(getTFTReadOnlyTime(false).split(':')[0]);
  const endMinute = parseInt(getTFTReadOnlyTime(false).split(':')[1]);
  const [displayTerminals, setDisplayTerminals] = useState<boolean>(false);
  const [isHideRegistered, setisHideRegistered] = useState<boolean>(false);
  const [startDateTime, setStartDateTime] = useState(
    new Date().setHours(startHour, startMinute),
  );
  const [endDateTime, setEndDateTime] = useState(
    new Date(new Date().getTime() + 24 * 60 * 60 * 1000).setHours(
      endHour,
      endMinute,
    ),
  );
  const [isBothDateValid, setBothDateValid] = useState<boolean>(false);
  const [gridTop, setGridTop] = useState(0);
  const [reportStartDate, setReportStartDate] = useState<string>(
    format(startDateTime, NEW_DATE_FORMAT),
  );
  const [reportEndDate, setReportEndDate] = useState<string>(
    format(endDateTime, NEW_DATE_FORMAT),
  );
  const [expanded, setExpanded] = useState<string | undefined>(
    localStorage.getItem('reportToTerminalExpanded_' + user) ?? undefined,
  );
  const [legsData, setLegsData] = useState<ListIncomingRoutesLegs[]>([]);
  const [exportExcelLoading, setExportExcelLoading] = useState(false);
  const [selectedTerminals, setSelectedTerminals] = useState<DataTerminal[]>(
    [],
  );
  const [tftLoading, setTFTLoading] = useState(false);

  const isValidTime = (startTime: string, endTime: string): boolean => {
    const startMins = getTimeAsNumberOfMinutes(startTime);
    const endMins = getTimeAsNumberOfMinutes(endTime);
    return startMins < endMins;
  };

  const handleStartDateChanged = useCallback((value) => {
    if (value && value != 'Invalid Date') {
      setStartDateTime(value);
      //Mon Jun 19 2023 10:00:00 GMT+0530
      setReportStartDate(format(new Date(value.toString()), NEW_DATE_FORMAT));
    } else {
      setReportStartDate('');
    }
  }, []);

  const handleEndDateChanged = useCallback((value) => {
    if (value && value != 'Invalid Date') {
      setEndDateTime(value);
      setReportEndDate(format(new Date(value.toString()), NEW_DATE_FORMAT));
    } else {
      setReportEndDate('');
    }
  }, []);

  const changeDatesAction = (isNext: boolean) => {
    const newDateStart = isNext
      ? new Date(new Date(reportStartDate).getTime() + 1000 * 60 * 60 * 24)
      : new Date(new Date(reportStartDate).getTime() - 1000 * 60 * 60 * 24);
    const newDateEnd = isNext
      ? new Date(new Date(reportEndDate).getTime() + 1000 * 60 * 60 * 24)
      : new Date(new Date(reportEndDate).getTime() - 1000 * 60 * 60 * 24);

    setStartDateTime(newDateStart.getTime());
    setEndDateTime(newDateEnd.getTime());
    const newTFTStartDateTime = format(newDateStart, NEW_DATE_FORMAT);
    const newTFTEndDateTime = format(newDateEnd, NEW_DATE_FORMAT);

    setReportStartDate(newTFTStartDateTime);
    setReportEndDate(newTFTEndDateTime);
    setMasterDateForTFTReadOnly(`${newTFTStartDateTime}#${newTFTEndDateTime}`);

    if (selectedTerminals && selectedTerminals.length > 0) {
      getRouteLegsData(newTFTStartDateTime, newTFTEndDateTime);
    }
  };

  useEffect(() => {
    if (reportStartDate !== '' && reportEndDate !== '') {
      if (reportStartDate.split(' ')[0] === reportEndDate.split(' ')[0]) {
        const isTimeValid = isValidTime(
          reportStartDate.split(' ')[1],
          reportEndDate.split(' ')[1],
        );
        setBothDateValid(isTimeValid);
      } else {
        setBothDateValid(true);
      }
    } else {
      setBothDateValid(false);
    }
  }, [reportStartDate, reportEndDate]);

  useEffect(() => {
    if (selectedTerminals && selectedTerminals.length > 0 && isBothDateValid) {
      getRouteLegsData(reportStartDate, reportEndDate);
      setMasterDateForTFTReadOnly(`${reportStartDate}#${reportEndDate}`);
    }
    const element = document.getElementById('tftGrid');
    const positions = element?.getBoundingClientRect();

    if (positions) {
      setGridTop(positions.top);
    }
  }, [selectedTerminals]);

  const getRouteLegsData = (startDateTime: string, endDateTime: string) => {
    httpClient
      .getIncomingRouteLegs({
        terminalId:
          selectedTerminals.length > 0
            ? selectedTerminals.map((terminal) => terminal.terminalId)
            : [],
        startDateTime,
        endDateTime,
        palletSum: true,
      })
      .then((res) => {
        if (res.data && res.data.status === 'OK') {
          setLegsData(res.data.data);
          setTFTLoading(false);
        } else if (res.data && res.data.status === 'FAIL') {
          setLegsData([]);
          setTFTLoading(false);
          console.error('# tft API error=', res.data.message);
        }
      })
      .catch((e) => {
        console.error('# Dashboard FF error=', e);
      });
  };
  const internalTerminals = (data?.internalTerminals || [])
    .filter(
      (item) =>
        typeof item !== 'undefined' && item.id && item.terminal?.locations[0],
    )
    .map((x) => {
      const location = x.terminal.locations[0];
      const locationId = x.terminal.locations[0].id;
      const locationString =
        location != null
          ? `${location.address}, ${location.city}, ${location.countryLong}`
          : '';
      const terminalLocationCountry: string = x.terminal.locations[0]?.country;

      return {
        id: x.id,
        name: x.terminal.name,
        location: locationString,
        terminalLocationCountry: terminalLocationCountry,
        locationId: locationId,
      };
    });

  const countryList: string[] = internalTerminals?.length
    ? Array.from(
        new Set(
          internalTerminals.flatMap((item) => item.terminalLocationCountry),
        ),
      )
    : [];

  const selectAllForCountry = (countryCode: string) => {
    const allTerminalsId: DataTerminal[] = [];
    const allLocationsId: number[] = [];

    if (internalTerminals && internalTerminals.length > 0) {
      internalTerminals
        .filter((it) => it.terminalLocationCountry === countryCode)
        .forEach((it) => {
          //passing locationId
          allTerminalsId.push({
            terminalId: it.id,
            shortCountryCode: it.terminalLocationCountry,
            name: it.name,
          });
          allLocationsId.push(it.locationId);
        });
      setSelectedTerminals(allTerminalsId);
    }
  };
  const handleChange =
    (country: string) =>
    (_event: React.SyntheticEvent, isExpanded: boolean) => {
      localStorage.setItem('reportToTerminalExpanded_' + user, country);
      setExpanded(isExpanded ? country : undefined);
    };

  const onExportTFTClicked = () => {
    if (legsData && legsData.length > 0) {
      setExportExcelLoading(true);
      httpClient
        .downloadTftExcelBlob({
          terminalId:
            selectedTerminals.length > 0
              ? selectedTerminals.map((terminal) => terminal.terminalId)
              : [],
          startDateTime: reportStartDate,
          endDateTime: reportEndDate,
        })
        .then((res) => {
          const blob = res.data;
          const url = window.URL.createObjectURL(blob);
          const anchor = document.createElement('a');
          anchor.setAttribute('href', url);
          anchor.setAttribute(
            'download',
            `TFT-(${reportStartDate}_to_${reportEndDate})`,
          );
          setExportExcelLoading(false);
          anchor.click();
          window.URL.revokeObjectURL(url);
        });
    }
  };

  return (
    <Grid container spacing={1} className={classes.rootGrid}>
      <Modal
        open={displayTerminals}
        onClose={() => setDisplayTerminals(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableScrollLock
      >
        <Box sx={styleForCountryModal}>
          <IconButton
            style={{ marginLeft: 550 }}
            onClick={() => setDisplayTerminals(false)}
            edge="start"
          >
            <CloseIcon fontSize="large" color="action" />
          </IconButton>
          <LoadingAndError
            error={error}
            loading={loading}
            data={internalTerminals}
          >
            {({ loadedData }) => (
              <Grid item>
                {countryList.flatMap((ctryItem) => (
                  <Accordion
                    expanded={expanded === ctryItem}
                    onChange={handleChange(ctryItem)}
                  >
                    <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography variant="subtitle1">
                        <strong>
                          {
                            COUNTRIES_MAP[
                              ctryItem as keyof typeof COUNTRIES_MAP
                            ]
                          }
                        </strong>
                      </Typography>
                    </StyledAccordionSummary>
                    <AccordionDetails>
                      <Button
                        variant="outlined"
                        color="default"
                        size="small"
                        onClick={() => {
                          setSelectedTerminals([]);
                          selectAllForCountry(ctryItem);
                        }}
                      >
                        {`-Select All from ${
                          COUNTRIES_MAP[ctryItem as keyof typeof COUNTRIES_MAP]
                        }-`}
                      </Button>

                      <List dense={true}>
                        {loadedData
                          .filter((l) => l.terminalLocationCountry === ctryItem)
                          .map((item) => (
                            <>
                              <ListItem key={item.id} divider>
                                <Typography
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    if (
                                      selectedTerminals.length > 0 &&
                                      item.terminalLocationCountry ===
                                        selectedTerminals[0].shortCountryCode
                                    ) {
                                      if (
                                        selectedTerminals.findIndex(
                                          (i) => i.terminalId === item.id,
                                        ) > -1
                                      ) {
                                        setSelectedTerminals(
                                          selectedTerminals.filter(
                                            (i) => i.terminalId !== item.id,
                                          ),
                                        );
                                      } else {
                                        setSelectedTerminals([
                                          ...selectedTerminals,
                                          {
                                            terminalId: item.id,
                                            shortCountryCode:
                                              item.terminalLocationCountry,
                                            name: item.name,
                                          },
                                        ]);
                                      }
                                    } else {
                                      setSelectedTerminals([
                                        {
                                          terminalId: item.id,
                                          shortCountryCode:
                                            item.terminalLocationCountry,
                                          name: item.name,
                                        },
                                      ]);
                                    }
                                  }}
                                  variant="body2"
                                  color={
                                    selectedTerminals &&
                                    selectedTerminals.findIndex(
                                      (t) => t.terminalId === item.id,
                                    ) >= 0
                                      ? 'primary'
                                      : 'inherit'
                                  }
                                >
                                  {item.name}
                                </Typography>
                              </ListItem>
                            </>
                          ))}
                      </List>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Grid>
            )}
          </LoadingAndError>
        </Box>
      </Modal>
      <Grid item container>
        <Box className={classes.filterButtonRoot}>
          <Button
            style={{ marginRight: 12, marginLeft: 5 }}
            variant="contained"
            color="primary"
            onClick={() => {
              setDisplayTerminals(!displayTerminals);
            }}
          >
            {t('attributes.selectTerminals')}
          </Button>
          <Tooltip title={`Previous Date`}>
            <IconButton
              style={{
                backgroundColor: '#e2e2e2',
                height: 38,
                width: 38,
                marginRight: 12,
                marginTop: 2,
              }}
              onClick={() => {
                changeDatesAction(false);
              }}
            >
              <PreviousIcon />
            </IconButton>
          </Tooltip>
          <KeyboardDateTimePicker
            variant="inline"
            ampm={false}
            allowKeyboardControl={true}
            label="Production Start"
            value={startDateTime}
            onChange={handleStartDateChanged}
            format="yyyy-MM-dd HH:mm"
            id="startDT"
            InputProps={{
              style: {
                fontSize: 15,
              },
              classes: { input: classes.inputValTime },
              maxRows: 1,
            }}
            invalidDateMessage={'Invalid DateTime'}
            maxDate={endDateTime}
            maxDateMessage={'Invalid Range'}
            onError={(e) => {
              if (e) setBothDateValid(false);
            }}
            KeyboardButtonProps={{
              disabled: true,
              style: { display: 'none' },
            }}
          />
          <KeyboardDateTimePicker
            variant="inline"
            ampm={false}
            allowKeyboardControl={true}
            label="Production End"
            value={endDateTime}
            onChange={handleEndDateChanged}
            format="yyyy-MM-dd HH:mm"
            id="endDT"
            InputProps={{
              style: {
                fontSize: 15,
              },
              classes: { input: classes.inputValTime },
              maxRows: 1,
            }}
            minDate={startDateTime}
            minDateMessage={'Invalid Range'}
            invalidDateMessage={'Invalid DateTime'}
            onError={(e) => {
              if (e) setBothDateValid(false);
            }}
            KeyboardButtonProps={{
              disabled: true,
              style: { display: 'none' },
            }}
          />
          <Tooltip title={`Next Date`}>
            <IconButton
              style={{
                backgroundColor: '#e2e2e2',
                height: 38,
                width: 38,
                marginTop: 2,
                marginRight: 12,
              }}
              onClick={() => {
                changeDatesAction(true);
              }}
            >
              <NextIcon />
            </IconButton>
          </Tooltip>
          <Button
            style={{ marginRight: 12, marginLeft: 6 }}
            variant="contained"
            color="primary"
            disabled={!isBothDateValid}
            onClick={() => {
              if (
                reportStartDate !== '' &&
                reportEndDate !== '' &&
                isBothDateValid
              ) {
                if (selectedTerminals && selectedTerminals.length > 0) {
                  getRouteLegsData(reportStartDate, reportEndDate);
                }
                setMasterDateForTFTReadOnly(
                  `${reportStartDate}#${reportEndDate}`,
                );
              }
            }}
          >
            {`VIEW`}
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ marginRight: 10 }}
            disabled={exportExcelLoading || legsData.length === 0}
            onClick={onExportTFTClicked}
            size="medium"
          >
            {exportExcelLoading
              ? `${t('validation.loadingApi')}`
              : `${t('attributes.getExcel')}`}
          </Button>
        </Box>
      </Grid>
      {selectedTerminals.length > 0 ? (
        <Grid container>
          <Grid item xs={2} style={{ justifyContent: 'flex-end' }}>
            <Typography variant="subtitle1" align="center">
              <strong>{`${t('validation.selectedTerminals')}`}</strong>
            </Typography>
          </Grid>
          <Grid item container xs={10} style={{ justifyContent: 'flex-start' }}>
            <div className={classes.filterButton}>
              {selectedTerminals.map((terminal) => (
                <Typography variant="subtitle1" align="center">
                  {`${terminal.name},`}
                </Typography>
              ))}
            </div>
          </Grid>
        </Grid>
      ) : null}
      {selectedTerminals.length > 0 ? (
        tftLoading ? (
          <Box display="flex" justifyContent="center" mt="30vh" width={'100%'}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Grid container item xs={12} spacing={2}>
              <div
                style={{
                  width: '100%',
                  backgroundColor: '#68bc46',
                  marginTop: 12,
                }}
              >
                <Typography variant="subtitle1" align="center">
                  <strong>{`${t('attributes.tftTitle')}`}</strong>
                </Typography>
              </div>
              <Grid container xs={12} style={{ marginTop: 3 }}>
                <Grid
                  item
                  xs={2}
                  direction={'row'}
                  style={{ justifyContent: 'flex-end' }}
                >
                  {legsData.length === 0 ? null : (
                    <Button
                      variant={isHideRegistered ? 'contained' : 'outlined'}
                      color="primary"
                      onClick={() => {
                        setisHideRegistered(!isHideRegistered);
                      }}
                    >
                      {!isHideRegistered
                        ? t('attributes.hideRegistered')
                        : t('attributes.showRegistered')}
                    </Button>
                  )}
                </Grid>
                <Grid
                  item
                  xs={1}
                  style={{ justifyContent: 'flex-start' }}
                ></Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} id="tftGrid">
              <DashboardTFTReadOnly
                legs={legsData}
                isHideRegistered={isHideRegistered}
                onGetTFTExcelClicked={() => onExportTFTClicked()}
                gridTop={gridTop}
              />
            </Grid>
          </>
        )
      ) : (
        <Grid item xs={12}>
          <Typography variant="subtitle1" align="center">
            <strong>{t('validation.plsSelectTerminal')}</strong>
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}
